/** @format */
import React, { useEffect, useState } from "react";
import { Modal, Form, Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import {
  Button,
  TextField,
  createTheme,
  useMediaQuery,
  ThemeProvider,
  Typography,
  Box,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as Yup from "yup";
import axios from "axios";
import Header from "../Header";
import {
  faPenToSquare,
  faTrashCan,
  faBackwardStep,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const validationSchema = Yup.object().shape({
  storeName: Yup.string()
    .required("Store name is required")
    .matches(
      /^[A-Za-z0-9 ]{3,20}$/,
      "Store name should be 3-20 alphanumeric characters."
    ),
  address: Yup.string()
    .required("Address is required")
    .matches(
      /^[A-Za-z0-9 ]{3,20}$/,
      "Address should be 3-20 alphanumeric characters."
    ),
});
const theme = createTheme({
  breakpoints: { values: { xs: 0, sm: 500, md: 769, lg: 1024, xl: 1440 } },
  palette: {
    warning: {
      main: "#ffc107", // Main warning color
      light: "#ffb74d", // Light variation of warning color
      dark: "#f57c00", // Dark variation of warning color
      contrastText: "#000", // Text color on warning
    },
  },
});
const StorePage = () => {
  const [open, setOpen] = useState(0);
  const [stores, setStores] = useState([]);
  const [items, setItems] = useState([]);
  const [units, setUnits] = useState([]);
  const [models, setModels] = useState([]);
  const [storeItems, setStoreItems] = useState([]);
  const [itemValues, setItemValues] = useState([]);
  const [warnings, setWarings] = useState([0, 0]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isUnderMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const fetchAllData = async () => {
    try {
      const [store, item, unit, model, storeItem] = await Promise.all([
        axios.get("https://enin.server.highendtradingplc.com/store"),
        axios.get("https://enin.server.highendtradingplc.com/item"),
        axios.get("https://enin.server.highendtradingplc.com/unit"),
        axios.get("https://enin.server.highendtradingplc.com/model"),
        axios.get("https://enin.server.highendtradingplc.com/store_item"),
      ]);
      setStores(store.data);
      setItems(item.data);
      setUnits(unit.data);
      setModels(model.data);
      setStoreItems(storeItem.data);
      console.log(store.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const formik = useFormik({
    initialValues: {
      storeName: "",
      address: "",
      id: "",
    },

    validationSchema,
    onSubmit: async (values) => {
      if (isSubmitted) return;
                    setIsSubmitted(true);
      try {
        if (values.id === "") {
          const { storeName } = values;
          const storeexist = stores.find(
            (store) => store?.storeName === storeName + ""
          );
          if (storeexist) {
            formik.setErrors({
              storeName: "Store already exists.",
            });
            setIsSubmitted(false);
            return;
          }
          await axios
            .post("https://enin.server.highendtradingplc.com/store/add", values)
            .then(async (res) => {
              for (let i = 0; i < items.length; i++) {
                await axios.post(
                  "https://enin.server.highendtradingplc.com/store_item/add",
                  {
                    itemId: items[i].Id,
                    storeId: res.data.data.Id,
                    quantity: 0,
                  }
                );
              }
            });
        } else {
          const { storeName } = values;
          const storeexist = stores.find(
            (store) =>
              store.Id !== values.id && store?.storeName === storeName + ""
          );
          if (storeexist) {
            formik.setErrors({
              storeName: "Store already exists.",
            });
            setIsSubmitted(false);
            return;
          } else {
            if (warnings[0] === 0) {
              warnings[0] = 2;
              warnings[1] = values.id;
              setWarings([...warnings]);
              setIsSubmitted(false);
              return;
            }

            await axios.put(
              "https://enin.server.highendtradingplc.com/store/update/" +
                values.id,
              values
            );
            setWarings([0, 0]);
          }
        }
        clear();
      } catch (error) {
        console.log(error);
      }
      setIsSubmitted(false);
    },
  });
  const clear = () => {
    formik.values.storeName = "";
    formik.values.address = "";
    fetchAllData();
    setOpen(0);
  };
  const handleAdd = () => {
    formik.values.address = "";
    formik.values.storeName = "";
    formik.resetForm();
    setOpen(1);
  };

  const mergeData = (leftData, rightData) => {
    let mergedData = [];
    let i = 0,
      j = 0;
    while (i < leftData.length && j < rightData.length) {
      if (leftData[i]?.quantity >= rightData[j]?.quantity) {
        mergedData.push(leftData[i]);
        i++;
      } else {
        mergedData.push(rightData[j]);
        j++;
      }
    }
    let leftDataTail = leftData.filter((d, l) => l >= i);
    let rightDataTail = rightData.filter((d, r) => r >= j);
    return mergedData.concat(leftDataTail?.concat(rightDataTail));
  };

  const sortData = (data, depth = 0) => {
    if (data.length < 2) return data;
    let midValue = Math.round(data.length / 2);
    let leftData = data.filter((d, i) => i < midValue);
    let rightData = data.filter((d, i) => i >= midValue);
    let leftSortData = sortData(leftData, depth + 1);
    let rightSortData = sortData(rightData, depth + 1);
    let sortedData = mergeData(leftSortData, rightSortData);
    return sortedData;
  };
  const handleView = (row) => {
    const storeItem = storeItems.filter((s) => s.storeId === row.Id);
    const sort = sortData(storeItem);
    setItemValues(sort);
    setOpen(2);
  };

  const handleUpdate = (Id) => {
    const store = stores.find((s) => s.Id === Id);
    formik.setValues({
      storeName: store.storeName,
      address: store.address,
      id: Id,
    });
    setOpen(1);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        "https://enin.server.highendtradingplc.com/store/delete/" + id
      );
      fetchAllData();
    } catch (error) {}
  };

  const columns = [
    {
      field: "id",
      headerName: "No",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Id",
      headerAlign: "center",
      align: "center",
      headerName: "Store Id",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "storeName",
      headerAlign: "center",
      align: "center",
      headerName: "Store Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "address",
      headerAlign: "center",
      align: "center",
      headerName: "Address",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "action",
      headerAlign: "center",
      align: "center",
      headerName: "Action",
      width: 400,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <>
          <Button
            onClick={() => handleView(params.row)}
            sx={{
              width: "90px",
              borderColor: "primary.main",
              fontSize: {
                xs: "0.7rem",
                sm: "0.8rem",
                md: "1rem",
              },
              padding: {
                xs: "3px",
                sm: "4px",
                md: "5px",
                lg: "6px",
              },
              "&:hover": {
                backgroundColor: "primary.main",
                color: "white",
              },
            }}
            variant="outlined"
            color="primary"
            className="m-1 col"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{
                    float: "right",
                    marginRight: "-1px",
                    marginTop: "2px",
                    marginBottom: "-4px",
                  }}
                  icon={faEye}
                />
              </div>
              <div className="col-7">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-10px",
                    marginTop: "-1px",
                    marginBottom: "-7px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "0.85rem",
                        sm: "0.85rem",
                        md: "1rem",
                      },
                    }}
                  >
                    View
                  </Typography>
                </h6>
              </div>
            </div>
          </Button>
          <Button
            onClick={() => handleUpdate(params.row.Id)}
            sx={{
              maxWidth: "90px",
              borderColor: "warning.main",
              fontSize: {
                xs: "0.7rem",
                sm: "0.75rem",
                md: "1rem",
              },
              padding: {
                xs: "3px",
                sm: "4px",
                md: "5px",
                lg: "6px",
              },
              "&:hover": {
                backgroundColor: "warning.main",
                color: "white",
              },
            }}
            variant="outlined"
            color="warning"
            className="m-1 col"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{
                    float: "right",
                    marginRight: "-1px",
                    marginTop: "2px",
                    marginBottom: "-4px",
                  }}
                  icon={faPenToSquare}
                />
              </div>
              <div className="col-7">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-10px",
                    marginTop: "-1px",
                    marginBottom: "-7px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "0.85rem",
                        sm: "0.85rem",
                        md: "1rem",
                      },
                    }}
                  >
                    Edit
                  </Typography>
                </h6>
              </div>
            </div>
          </Button>
          <Button
            onClick={(e) => {
              warnings[1] = params.row.Id;
              warnings[0] = 1;
              setWarings([...warnings]);
            }}
            sx={{
              maxWidth: "110px",
              borderColor: "error.main",
              fontSize: {
                xs: "0.7rem",
                sm: "0.85rem",
                md: "1rem",
              },
              padding: {
                xs: "3px",
                sm: "4px",
                md: "5px",
                lg: "6px",
              },
              "&:hover": {
                backgroundColor: "error.main",
                color: "white",
              },
            }}
            variant="outlined"
            color="error"
            className="mr-1 col"
          >
            <div
              className="row "
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{
                    float: "right",
                    marginRight: "-1px",
                    marginTop: "2px",
                    marginBottom: "-4px",
                  }}
                  icon={faTrashCan}
                />
              </div>
              <div className="col-8">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-10px",
                    marginTop: "-1px",
                    marginBottom: "-7px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "0.85rem",
                        sm: "0.85rem",
                        md: "1rem",
                      },
                    }}
                  >
                    Delete
                  </Typography>
                </h6>{" "}
              </div>
            </div>
          </Button>
        </>
      ),
    },
  ];
  const storeRows = stores.map((store, index) => {
    let newStore = store;
    newStore.id = index + 1;
    return newStore;
  });
  const columnItem = [
    {
      field: "Id",
      headerName: "No",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "description",
      headerName: "description",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "model",
      headerName: "Model",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "code",
      headerName: "Code",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "unit",
      headerName: "Unit",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },

    {
      field: "qty",
      headerName: "Available Qty",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
  ];
  const rows = itemValues.map((itemValue, index) => {
    const item = items.find((i) => i.Id === itemValue?.itemId);
    const unit = units.find((u) => u.Id === item?.unitId);
    const model = models.find((u) => u.Id === item?.modelId);

    return {
      Id: index + 1,
      description: item?.description,
      code: item?.code,
      model: model?.modelName,
      unit: unit?.unitName,
      qty: itemValue?.quantity,
    };
  });

  return (
    <ThemeProvider theme={theme}>
      <Typography
        sx={{
          fontSize: {
            xs: "12px",
            sm: "12px", //500
            md: "0.85rem", //768
            lg: "17px", //1024
          },
        }}
      >
        <div>
          <Header />
        </div>
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: "13px",
            sm: "14px", //500
            md: "16px", //768
            lg: "18px", //1024
          },
        }}
      >
        <main
          className=""
          style={
            isMobile && !isUnderMobile
              ? { marginTop: "190px" }
              : isUnderMobile
              ? { marginTop: "170px" }
              : isTablet
              ? { marginTop: "133px" }
              : { marginTop: "150px" }
          }
        >
          <center>
            <div
              style={
                isUnderMobile
                  ? {
                      minWidth: "500px",
                      overflow: "scroll",
                      marginBottom: "-5px",
                    }
                  : { maxWidth: "1100px" }
              }
              className="text-left"
            >
              <div
                className="tab-container "
                style={
                  isUnderMobile
                    ? { marginTop: "0", marginBottom: "-5px" }
                    : {
                        marginTop: "-20px",
                        marginBottom: "-5px",
                      }
                }
              >
                <Link
                  className="p-2"
                  onClick={(e) => {
                    window.location.reload();
                  }}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "5px 5px 0 0",
                    border: "solid 0.05em rgb(181, 181, 181)",
                  }}
                >
                  Store
                </Link>
                <Link className="p-2" to={`/purchase_delivery/1`}>
                  Purchase Item
                </Link>
                <Link className="p-2" to={`/store_transfer`}>
                  Transfer Item
                </Link>

                <Link className="p-2" to={`/delivery_item/1`}>
                  Delivery Item
                </Link>
                <Link className="p-2" to={`/adjustment_delivery/1`}>
                  Adjustment Item
                </Link>
              </div>
              <div className="card card-info card-outline">
                <div className="card-header">
                  {open === 0 && (
                    <Button
                      sx={{
                        width: "150px",
                        borderColor: "info.main",
                        fontSize: {
                          xs: "0.7rem",
                          sm: "0.85rem",
                          md: "1rem",
                        },
                        padding: {
                          xs: "0",
                          sm: "0",
                          md: "1px",
                          lg: "3px",
                        },
                        "&:hover": {
                          backgroundColor: "info.main",
                          color: "white",
                        },
                      }}
                      variant="outlined"
                      color="info"
                      onClick={handleAdd}
                    >
                      <h4
                        style={{
                          float: "left",
                          marginTop: "-1px",
                          marginRight: "5px",
                          marginBottom: "-2px",
                        }}
                      >
                        +
                      </h4>
                      <strong
                        style={{
                          float: "left",
                          marginTop: "0",
                          marginBottom: "-2px",
                        }}
                      >
                        {" "}
                        Create New
                      </strong>
                    </Button>
                  )}
                  {open > 0 && (
                    <div>
                      {open === 1 && (
                        <h5 style={{ float: "left" }}>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: "1.3rem",
                                sm: "1.3rem",
                                md: "1.5rem",
                              },
                            }}
                          >
                            {formik.values.id === "" ? "Create New" : "Update"}{" "}
                            Store
                          </Typography>
                        </h5>
                      )}
                      <Button
                        onClick={(e) => {
                          clear();
                        }}
                        sx={{
                          width: "100px",
                          borderColor: "success.main",
                          fontSize: {
                            xs: "0.85rem",
                            sm: "0.85rem",
                            md: "1rem",
                          },
                          padding: {
                            xs: "4px",
                            sm: "5px",
                            md: "6px",
                            lg: "6px",
                          },
                          "&:hover": {
                            backgroundColor: "success.main",
                            color: "white",
                          },
                        }}
                        variant="outlined"
                        color="success"
                        className="m-1"
                        style={{ float: "right" }}
                      >
                        <div
                          className="row"
                          style={{
                            marginBottom: "-10px",
                            marginLeft: "-10px",
                          }}
                        >
                          <div className="col">
                            <FontAwesomeIcon
                              style={{ float: "right", marginRight: "-1px" }}
                              icon={faBackwardStep}
                            />
                          </div>
                          <div className="col-7">
                            <h6
                              style={{
                                float: "left",
                                marginLeft: "-10px",
                                marginTop: "-1px",
                                marginBottom: "-7px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: {
                                    xs: "0.85rem",
                                    sm: "0.85rem",
                                    md: "1rem",
                                  },
                                }}
                              >
                                Back
                              </Typography>
                            </h6>
                          </div>
                        </div>
                      </Button>
                    </div>
                  )}
                </div>
                <div className="card-body">
                  <div>
                    {(open === 0 || open === 2) && (
                      <div
                        style={{
                          backgroundColor: "rgb(235, 235, 235)",
                        }}
                      >
                        <span>Home / Store {open === 2 && `/ Item`}</span>
                      </div>
                    )}
                    {(open === 0 || open === 2) && <hr />}
                    {(open === 0 || open === 2) && (
                      <center>
                        <div
                          style={{
                            overflow: "scroll",
                          }}
                        >
                          <Box
                            sx={{
                              height: 500,

                              width: {
                                lg: "700px",
                                md: "650",
                                sm: "600",
                                xs: "550",
                              },
                              "& .super-app-theme--header": {
                                backgroundColor: "#1de5ec",
                                color: "black",
                                fontSize: {
                                  xs: "13px",
                                  sm: "15px", //500
                                  md: "17px", //768
                                  lg: "18px", //1024
                                },
                              },
                            }}
                          >
                            <DataGrid
                              rows={open === 0 ? storeRows : rows}
                              disableColumnFilter
                              disableColumnSelector
                              disableDensitySelector
                              sx={{
                                "& .MuiDataGrid-cell": {
                                  fontSize: {
                                    xs: "12px",
                                    sm: "14px", //500
                                    md: "16px", //768
                                    lg: "17px", //1024
                                  },
                                },
                              }}
                              columns={open === 0 ? columns : columnItem}
                              slots={{ toolbar: GridToolbar }}
                              getRowId={(row) => row.Id}
                              slotProps={{
                                toolbar: {
                                  showQuickFilter: true,
                                },
                              }}
                              initialState={
                                open === 0
                                  ? {
                                      ...stores.initialState,
                                      pagination: {
                                        paginationModel: { pageSize: 5 },
                                      },
                                    }
                                  : {
                                      ...itemValues.initialState,
                                      pagination: {
                                        paginationModel: { pageSize: 5 },
                                      },
                                    }
                              }
                              pageSizeOptions={[5, 10, 15]}
                            />
                          </Box>
                        </div>{" "}
                      </center>
                    )}
                  </div>
                  {open === 1 && (
                    <center>
                      <div>
                        <Form className="" onSubmit={formik.handleSubmit}>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={12}>
                              <TextField
                                id="outlined-basic"
                                sx={{
                                  width: "250px",
                                  "& .MuiInputBase-root": {
                                    fontSize: {
                                      xs: "0.7rem",
                                      sm: "0.85rem",
                                      md: "0.9rem",
                                    },
                                    padding: {
                                      xs: "1px",
                                      sm: "2px",
                                      md: "3px",
                                      lg: "4px",
                                    },
                                  },
                                }}
                                label="Store Name"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="storeName"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.storeName}
                                error={
                                  formik.touched.storeName &&
                                  formik.errors.storeName
                                }
                                helperText={
                                  formik.touched.storeName &&
                                  formik.errors.storeName
                                    ? `${formik.errors.storeName}`
                                    : ""
                                }
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={12}>
                              <TextField
                                id="outlined-basic"
                                sx={{
                                  width: "250px",
                                  "& .MuiInputBase-root": {
                                    fontSize: {
                                      xs: "0.7rem",
                                      sm: "0.85rem",
                                      md: "0.9rem",
                                    },
                                    padding: {
                                      xs: "1px",
                                      sm: "2px",
                                      md: "3px",
                                      lg: "4px",
                                    },
                                  },
                                }}
                                label="Address"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="address"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.address}
                                error={
                                  formik.touched.address &&
                                  formik.errors.address
                                }
                                helperText={
                                  formik.touched.address &&
                                  formik.errors.address
                                    ? `${formik.errors.address}`
                                    : ""
                                }
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={12}>
                              <Button
                                sx={{
                                  width: "250px",
                                  borderColor: "success.main",
                                  fontSize: {
                                    xs: "0.85rem",
                                    sm: "0.85rem",
                                    md: "1rem",
                                  },
                                  padding: {
                                    xs: "4px",
                                    sm: "5px",
                                    md: "6px",
                                    lg: "6px",
                                  },
                                  "&:hover": {
                                    backgroundColor: "success.main",
                                    color: "white",
                                  },
                                }}
                                variant="outlined"
                                color="success"
                                type="submit"
                              >
                                {formik.values.id === "" ? "Create" : "Update"}
                              </Button>
                            </Col>
                          </Form.Group>
                        </Form>
                      </div>
                    </center>
                  )}
                </div>
              </div>
            </div>
            <Modal show={warnings[0] !== 0} onHide={() => setWarings([0, 0])}>
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <Typography variant="h6">
                  Are you sure you want to{" "}
                  {warnings[0] === 1 ? "delete" : "update"} this store?
                </Typography>
                <Row>
                  <center>
                    <Button
                      className="col mr-4"
                      style={{ maxWidth: "100px" }}
                      variant="contained"
                      color="error"
                      onClick={() => {
                        if (warnings[0] === 1) {
                          handleDelete(warnings[1]);
                          setWarings([0, 0]);
                        } else if (warnings[0] === 2) {
                          formik.handleSubmit();
                        }
                      }}
                    >
                      Confirm
                    </Button>
                    <Button
                      className="col ml-4"
                      style={{ maxWidth: "100px" }}
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        warnings[0] = 0;
                        setWarings([...warnings]);
                      }}
                    >
                      Cancel
                    </Button>
                  </center>
                </Row>
              </Modal.Body>
            </Modal>
          </center>
        </main>
      </Typography>
    </ThemeProvider>
  );
};

export default StorePage;
