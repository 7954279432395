import React, { useEffect, useRef, useState } from "react";
import Header from "../Header";

import {
  Modal,
  Form,
  Col,
  Row,
  Label,
  Container,
  Table,
} from "react-bootstrap";
import {
  Autocomplete,
  Box,
  MenuItem,
  TextField,
  Button,
  createTheme,
  useMediaQuery,
  ThemeProvider,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  faBackwardStep,
  faFloppyDisk,
  faCode,
  faCalendar,
  faPenToSquare,
  faTrashCan,
  faXmark,
  faEye,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import { jwtDecode } from "jwt-decode";

const validationSchema = Yup.object().shape({
  reason: Yup.string().min(3, "Too Short!").max(50, "Too High!"),
  date: Yup.date().required("Date is required!"),
});
const theme = createTheme({
  breakpoints: { values: { xs: 0, sm: 500, md: 769, lg: 1024, xl: 1440 } },
  palette: {
    warning: {
      main: "#ffc107", // Main warning color
      light: "#ffb74d", // Light variation of warning color
      dark: "#f57c00", // Dark variation of warning color
      contrastText: "#000", // Text color on warning
    },
  },
});
const Adjustment = () => {
  const [open, setOpen] = useState(0);
  const [openStore, setOpenStore] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [items, setItems] = useState([]);
  const [units, setUnits] = useState([]);
  const [models, setModels] = useState([]);
  const [itemId, setItemId] = useState(null);
  const [itemCheck, setItemCheck] = useState(null);
  const [itemValue, setItemValue] = useState([]);
  const [valids, setValid] = useState([0, 0]);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [users, setUsers] = useState([]);
  const [adjustments, setAdjustments] = useState([]);
  const [adjustmentItems, setAdjustmentItems] = useState([]);
  const [logicals, setLogicals] = useState([]);
  const [openMessage, setOpenMessage] = useState(false);
  const [messages, setMessages] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [update, setUpdate] = useState({
    itemId: "",
    quantity: "",
    statusId: "",
    withHold: "",
  });

  const userData = jwtDecode(sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const contentRef = useRef();
  const reactToPrint = useReactToPrint({
    contentRef,
  });
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isUnderMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const fetchAllData = async () => {
    try {
      const [customer, item, unit, damege, damegeItem, user, model, logical] =
        await Promise.all([
          axios.get("https://enin.server.highendtradingplc.com/customer"),
          axios.get("https://enin.server.highendtradingplc.com/item"),
          axios.get("https://enin.server.highendtradingplc.com/unit"),
          axios.get("https://enin.server.highendtradingplc.com/damege"),
          axios.get("https://enin.server.highendtradingplc.com/damege_item"),
          axios.get("https://enin.server.highendtradingplc.com/user"),
          axios.get("https://enin.server.highendtradingplc.com/model"),
          axios.get("https://enin.server.highendtradingplc.com/logical_stock"),
        ]);
      setCustomers(customer.data);
      setItems(item.data.filter((i) => i.statusId === 2));
      setUnits(unit.data);
      setAdjustments(damege.data);
      setAdjustmentItems(damegeItem.data);
      setUsers(user.data?.filter((user) => user.roleId >= 2));
      setModels(model.data);
      setLogicals(logical.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const handleOpenStore = () => {
    setOpenStore(!openStore);
  };

  const itemData = {
    options: items,
    getOptionLabel: (option) => option.description,
  };
  const formik = useFormik({
    initialValues: {
      userId: "",
      date: "",
      reason: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      if (itemValue.length === 0) return;
      if (isSubmitted) return;
      setIsSubmitted(true);
      // Get the current time
      const currentTime = new Date();
      const hours = currentTime.getHours();
      const minutes = currentTime.getMinutes();
      const seconds = currentTime.getSeconds();

      // Append the current time to the date value
      values.date = `${values.date} ${hours}:${minutes}:${seconds}`;

      values.userId = userData.Id;
      setOpen(2);
      try {
        await axios
          .post("https://enin.server.highendtradingplc.com/damege/add", {
            date: values.date,
            reason: values.reason,
            userId: userData.Id,
            statusId: 1,
          })
          .then((res1) => {
            itemValue.map(async (itemvalue) => {
              await axios.post(
                "https://enin.server.highendtradingplc.com/damege_item/add",
                {
                  remainingQuantity: itemvalue.quantity,
                  quantity: itemvalue.quantity,
                  itemId: itemvalue.itemId,
                  statusId: parseInt(itemvalue.statusId),
                  adjustmentId: res1.data.data.Id,
                  remark: itemvalue.remark,
                }
              );
              await axios
                .post(
                  "https://enin.server.highendtradingplc.com/transaction/add",
                  {
                    quantity: parseInt(itemvalue.quantity),
                    statusId: parseInt(itemvalue.statusId) === 1 ? 1 : 2,
                    date: values.date,
                  }
                )
                .then(async (res) => {
                  const logical = logicals.find(
                    (l) => l.itemId === itemvalue.itemId
                  );
                  await axios.post(
                    "https://enin.server.highendtradingplc.com/logical_stock/add",
                    {
                      quantity:
                        parseInt(itemvalue.statusId) === 1
                          ? parseInt(logical.quantity) +
                            parseInt(itemvalue.quantity)
                          : parseInt(logical.quantity) -
                            parseInt(itemvalue.quantity),
                      itemId: itemvalue.itemId,
                      date: values.date,
                      statusId: parseInt(itemvalue.statusId) === 1 ? 1 : 2,
                      transactionId: res.data.data.Id,
                      ADPRS_ID: res1.data.data.Id,
                    }
                  );
                });
            });
          });
        fetchAllData();
      } catch (error) {
        console.log(error);
      }
      setIsSubmitted(false);
    },
  });
  const handleBack = () => {
    formik.values.date = "";
    formik.values.reason = "";
    setItemId(null);
    setItemValue([]);
    fetchAllData();
    setOpen(0);
  };
  const handleValidity = (i) => {
    valids[i] = 1;
    setValid([...valids]);
  };
  const handelView = (row) => {
    const adjustmentItem = adjustmentItems.filter(
      (a) => a.adjustmentId === row.Id
    );
    console.log(adjustmentItems);
    setItemValue(adjustmentItem);
    formik.values.userId = row.userId;
    formik.values.date = new Date(
      new Date(row.date).setHours(new Date(row.date).getHours() - 4)
    );
    setOpen(2);
  };

  const columns = [
    {
      field: "id",
      headerName: "No",
      align: "center",
      headerAlign: "center",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "Id",
      headerName: "Adjust Id",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "userId",
      headerName: "Application User",
      flex: 1,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
      valueGetter: (params) =>
        users.find((user) => user.Id === params)?.fullName || "",
    },
    {
      field: "reason",
      headerName: "Reason",
      flex: 1,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
      valueGetter: (params) =>
        `${new Date(params).getUTCDate()}/${
          new Date(params).getUTCMonth() + 1
        }/${new Date(params).getUTCFullYear()}`,
    },
    {
      field: "action",
      headerName: "Action",
      width: 220,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <Button
            onClick={(e) => {
              handelView(params.row);
            }}
            sx={{
              width: "90px",
              borderColor: "primary.main",
              fontSize: {
                xs: "0.7rem",
                sm: "0.85rem",
                md: "1rem",
              },
              padding: {
                xs: "3px",
                sm: "4px",
                md: "5px",
                lg: "6px",
              },
              "&:hover": {
                backgroundColor: "primary.main",
                color: "white",
              },
            }}
            variant="outlined"
            color="primary"
            className="m-1"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-7px" }}
                  icon={faEye}
                />
              </div>
              <div className="col-7">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-3px",
                  }}
                >
                  View
                </h6>
              </div>
            </div>
          </Button>
        </>
      ),
    },
  ];
  const rows = adjustments.map((adjustment, index) => {
    console.log(
      new Date(adjustment.date).setHours(
        new Date(adjustment.date).getHours() - 4
      )
    );
    return {
      id: index + 1,
      Id: adjustment.Id,
      userId: adjustment.userId,
      reason: adjustment.reason,
      date: new Date(adjustment.date).setHours(
        new Date(adjustment.date).getHours() - 4
      ),
    };
  });
  const columnItem = [
    {
      field: "id",
      headerName: "No",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Id",
      headerName: "Item ID",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "description",
      headerName: "description",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "model",
      headerName: "Model",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "code",
      headerName: "Code",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "unit",
      headerName: "Unit",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "qty",
      headerName: "Available Qty",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "type",
      headerName: "Adjust Type",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "remark",
      headerName: "Remark",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ];

  const itemrows = itemValue.map((itemvalue, index) => {
    const item = items.find((i) => i.Id === itemvalue.itemId);
    const unit = units.find((u) => u.Id === item.unitId);
    const model = models.find((u) => u.Id === item.modelId);

    return {
      id: index + 1,
      Id: item.Id,
      description: item.description,
      code: item.code,
      model: model?.modelName,
      unit: unit?.unitName,
      remark: itemvalue.remark,
      qty: itemvalue.quantity,
      type: itemvalue.statusId === 1 ? "Add Qty" : "Remove Qty",
    };
  });

  const handelInvoice = () => {
    let data = [];
    for (let index = itemValue.length; index < 5; index++) {
      data.push(
        <tr key={index}>
          <td>{index + 1}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      );
    }
    return data;
  };
  return (
    <ThemeProvider theme={theme}>
      <Typography
        sx={{
          fontSize: {
            xs: "12px",
            sm: "12px", //500
            md: "0.85rem", //768
            lg: "17px", //1024
          },
        }}
      >
        <div>
          <Header />
        </div>
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: "13px",
            sm: "14px", //500
            md: "16px", //768
            lg: "18px", //1024
          },
        }}
      >
        <div>
          <main
            className=""
            style={
              isMobile && !isUnderMobile
                ? { marginTop: "190px" }
                : isUnderMobile
                ? { marginTop: "170px" }
                : isTablet
                ? { marginTop: "133px" }
                : { marginTop: "150px" }
            }
          >
            <center>
              <div
                style={
                  isUnderMobile
                    ? {
                        minWidth: "500px",
                        overflow: "scroll",
                        marginBottom: "-5px",
                      }
                    : { maxWidth: "1100px" }
                }
                className="text-left"
              >
                <div
                  className="tab-container "
                  style={
                    isUnderMobile
                      ? { marginTop: "0", marginBottom: "-5px" }
                      : {
                          marginTop: "-20px",
                          marginBottom: "-5px",
                        }
                  }
                >
                  <Link className="p-2" to={`/purchase`}>
                    Purchase
                  </Link>
                  <Link className="p-2" to={`/stock_available/1`}>
                    Stock Available
                  </Link>

                  <Link
                    className="p-2"
                    onClick={(e) => {
                      window.location.reload();
                    }}
                    style={{
                      backgroundColor: "white",
                      borderRadius: "5px 5px 0 0",
                      border: "solid 0.05em rgb(181, 181, 181)",
                    }}
                  >
                    Adjustment
                  </Link>
                  <Link className="p-2" to={`/payment`}>
                    Bank
                  </Link>
                </div>
                <Snackbar
                  open={openMessage}
                  onClose={(e) => setOpenMessage(false)}
                  autoHideDuration={3000}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  <Alert
                    variant="outlined"
                    severity="error"
                    onClose={(e) => setOpenMessage(false)}
                    sx={{ bgcolor: "background.paper" }}
                  >
                    {messages}
                  </Alert>
                </Snackbar>
                <div className="card card-info card-outline">
                  {(open === 1 || open === 0 || open === 3) && (
                    <>
                      <div className="card-header">
                        {open === 0 && (
                          <Button
                            sx={{
                              width: "150px",
                              borderColor: "info.main",
                              fontSize: {
                                xs: "0.7rem",
                                sm: "0.85rem",
                                md: "1rem",
                              },
                              padding: {
                                xs: "0",
                                sm: "0",
                                md: "1px",
                                lg: "3px",
                              },
                              "&:hover": {
                                backgroundColor: "info.main",
                                color: "white",
                              },
                            }}
                            variant="outlined"
                            color="info"
                            onClick={(e) => setOpen(1)}
                          >
                            <h4
                              style={{
                                float: "left",
                                marginRight: "5px",
                                marginTop: "-1",
                                marginBottom: "-2px",
                              }}
                            >
                              +
                            </h4>
                            <strong
                              style={{
                                float: "left",
                                marginTop: "0",
                                marginBottom: "-2px",
                              }}
                            >
                              Create New
                            </strong>
                          </Button>
                        )}
                        {(open === 1 || open === 3) && (
                          <div>
                            {open === 1 && (
                              <div>
                                <h5 style={{ float: "left" }}>
                                  <Typography
                                    sx={{
                                      fontSize: {
                                        xs: "1.3rem",
                                        sm: "1.3rem",
                                        md: "1.5rem",
                                      },
                                    }}
                                  >
                                    New Adjustment Entry
                                  </Typography>
                                </h5>
                              </div>
                            )}

                            <Button
                              onClick={handleBack}
                              sx={{
                                width: "100px",
                                borderColor: "success.main",
                                fontSize: {
                                  xs: "0.85rem",
                                  sm: "0.85rem",
                                  md: "1rem",
                                },
                                padding: {
                                  xs: "4px",
                                  sm: "5px",
                                  md: "6px",
                                  lg: "6px",
                                },
                                "&:hover": {
                                  backgroundColor: "success.main",
                                  color: "white",
                                },
                              }}
                              variant="outlined"
                              color="success"
                              type="submit"
                              className="m-1"
                              style={{ float: "right" }}
                            >
                              <div
                                className="row"
                                style={{
                                  marginBottom: "-10px",
                                  marginLeft: "-10px",
                                }}
                              >
                                <div className="col">
                                  <FontAwesomeIcon
                                    style={{
                                      float: "right",
                                      marginRight: "-1px",
                                      marginTop: "2px",
                                      marginBottom: "-4px",
                                    }}
                                    icon={faBackwardStep}
                                  />
                                </div>
                                <div className="col-7">
                                  <h6
                                    style={{
                                      float: "left",
                                      marginLeft: "-10px",
                                      marginTop: "-1px",
                                      marginBottom: "-7px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: {
                                          xs: "0.85rem",
                                          sm: "0.85rem",
                                          md: "1rem",
                                        },
                                      }}
                                    >
                                      Back
                                    </Typography>
                                  </h6>
                                </div>
                              </div>
                            </Button>
                          </div>
                        )}
                      </div>
                      <div className="card-body">
                        {open === 1 && (
                          <div>
                            <Typography
                              sx={{
                                fontSize: {
                                  xs: "0.85rem",
                                  sm: "0.85rem",
                                  md: "1rem",
                                },
                              }}
                            >
                              <div
                                className="nav-bra pl-3 ml-1 p-2 "
                                style={{
                                  backgroundColor: "rgb(235, 235, 235)",
                                }}
                              >
                                <span>Adjustment / Create</span>
                              </div>
                            </Typography>
                            <hr />
                            <div>
                              <div>
                                <Form className="">
                                  <Form.Group as={Row} className="mb-3">
                                    <Col sm={4} className="mb-3">
                                      {" "}
                                      <TextField
                                        sx={{
                                          width: "250px",
                                          "& .MuiInputBase-root": {
                                            fontSize: {
                                              xs: "0.7rem",
                                              sm: "0.85rem",
                                              md: "0.9rem",
                                            },
                                            padding: {
                                              xs: "1px",
                                              sm: "2px",
                                              md: "3px",
                                              lg: "4px",
                                            },
                                          },
                                        }}
                                        label="Reason"
                                        variant="outlined"
                                        size="small"
                                        type="text"
                                        name="reason"
                                        fullWidth
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.reason}
                                        error={
                                          formik.touched.reason &&
                                          formik.errors.reason
                                        }
                                        helperText={
                                          formik.touched.reason &&
                                          formik.errors.reason
                                        }
                                      />
                                    </Col>
                                    <Col lg={4} className="mb-3">
                                      <TextField
                                        id="outlined-basic"
                                        disabled
                                        label={
                                          <div style={{ float: "left" }}>
                                            <FontAwesomeIcon
                                              icon={faCode}
                                              style={{
                                                float: "left",
                                                marginRight: "10px",
                                              }}
                                            />
                                            <span> adjustmentID</span>
                                          </div>
                                        }
                                        variant="outlined"
                                        type="text"
                                        size="small"
                                        name="purchaseID"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={adjustments.length + 1}
                                        sx={{
                                          width: "250px",
                                          "& .MuiInputBase-root": {
                                            fontSize: {
                                              xs: "0.7rem",
                                              sm: "0.85rem",
                                              md: "0.9rem",
                                            },
                                            padding: {
                                              xs: "1px",
                                              sm: "2px",
                                              md: "3px",
                                              lg: "4px",
                                            },
                                          },
                                        }}
                                      />
                                    </Col>

                                    <Col lg={4} className="mb-3 ">
                                      <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.date}
                                        type="date"
                                        label={
                                          <div
                                            style={{
                                              float: "left",
                                              width: "70px",
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faCalendar}
                                              size="1x"
                                              style={{
                                                float: "left",
                                                marginTop: "5px",
                                                marginRight: "5px",
                                              }}
                                            />
                                            <h4>Date</h4>
                                          </div>
                                        }
                                        size="small"
                                        name="date"
                                        error={
                                          formik.touched.date &&
                                          formik.errors.date
                                        }
                                        helperText={
                                          formik.touched.date &&
                                          formik.errors.date
                                            ? `${formik.errors.date}`
                                            : ""
                                        }
                                        sx={{
                                          width: "250px",
                                          "& .MuiInputBase-root": {
                                            fontSize: {
                                              xs: "0.7rem",
                                              sm: "0.85rem",
                                              md: "0.9rem",
                                            },
                                            padding: {
                                              xs: "1px",
                                              sm: "2px",
                                              md: "3px",
                                              lg: "4px",
                                            },
                                          },
                                        }}
                                      />
                                    </Col>
                                  </Form.Group>
                                </Form>
                              </div>
                              <Formik
                                initialValues={{
                                  itemId: "",
                                  quantity: "",
                                  remark: "",
                                  statusId: "",
                                }}
                                validationSchema={Yup.object().shape({
                                  quantity: Yup.number().required(
                                    "Quantity is required"
                                  ),
                                  remark:
                                    Yup.string().required("Remark is required"),
                                  statusId:
                                    Yup.number().required("Type is required"),
                                })}
                                onSubmit={async (values) => {
                                  try {
                                    if (itemCheck === null) {
                                      return;
                                    }
                                    const checkItem = itemValue?.find(
                                      (i) => i?.itemId === itemId.Id
                                    );

                                    if (!!checkItem) {
                                      setOpenMessage(true);
                                      setMessages("You use the same item!");
                                      return;
                                    }
                                    const item = items.find(
                                      (i) => i.Id === values.itemId
                                    );
                                    if (
                                      parseInt(
                                        logicals.find(
                                          (l) => l.itemId === item?.Id
                                        )?.quantity
                                      ) < values.quantity &&
                                      values.statusId === 2
                                    ) {
                                      setOpenMessage(true);
                                      setMessages(
                                        `Only available quantity is ${
                                          logicals.find(
                                            (l) => l.itemId === item.Id
                                          )?.quantity
                                        }`
                                      );
                                      return;
                                    }
                                    setItemValue([
                                      ...itemValue,
                                      {
                                        itemId: values.itemId,
                                        remark: values.remark,
                                        quantity: values.quantity,
                                        statusId: values.statusId,
                                      },
                                    ]);

                                    setItemId(null);
                                  } catch (error) {
                                    console.log(error);
                                  } finally {
                                    values.quantity = "";
                                    values.itemId = "";
                                    values.remark = "";
                                    values.statusId = "";
                                  }
                                }}
                              >
                                {(props) => (
                                  <div className="">
                                    <hr />
                                    <h3>Adjustment Item</h3>
                                    <hr />
                                    <Form onSubmit={props.handleSubmit}>
                                      <Form.Group
                                        as={Row}
                                        controlId="formHorizontalUsername"
                                      >
                                        <Col lg={4} className="mb-3">
                                          <Autocomplete
                                            {...itemData}
                                            id="controlled-demo"
                                            size="small"
                                            name="itemId"
                                            value={itemId}
                                            sx={{
                                              width: "250px",
                                              "& .MuiInputBase-root": {
                                                fontSize: {
                                                  xs: "0.7rem",
                                                  sm: "0.85rem",
                                                  md: "0.9rem",
                                                },
                                                padding: {
                                                  xs: "1px",
                                                  sm: "2px",
                                                  md: "3px",
                                                  lg: "4px",
                                                },
                                              },
                                            }}
                                            onBlur={(e) => handleValidity(1)}
                                            onChange={(event, newValue) => {
                                              if (!newValue?.Id) {
                                                setItemCheck(null);
                                                setItemId(null);
                                                props.values.itemId = "";
                                                return;
                                              }
                                              setItemId(newValue);
                                              props.values.itemId =
                                                newValue?.Id;
                                              setItemCheck(5);
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label={
                                                  <div
                                                    style={{ float: "left" }}
                                                  >
                                                    <span>Select Item</span>
                                                  </div>
                                                }
                                                error={
                                                  valids[1] === 1 &&
                                                  itemCheck === null
                                                }
                                              />
                                            )}
                                          />
                                        </Col>
                                        <Col lg={4} className="mb-3">
                                          <TextField
                                            sx={{
                                              width: "250px",
                                              "& .MuiInputBase-root": {
                                                fontSize: {
                                                  xs: "0.7rem",
                                                  sm: "0.85rem",
                                                  md: "0.9rem",
                                                },
                                                padding: {
                                                  xs: "1px",
                                                  sm: "2px",
                                                  md: "3px",
                                                  lg: "4px",
                                                },
                                              },
                                            }}
                                            label="Select Adjustment Type"
                                            variant="outlined"
                                            size="small"
                                            type="text"
                                            name="statusId"
                                            fullWidth
                                            select
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.statusId}
                                            error={
                                              props.touched.statusId &&
                                              !!props.errors.statusId
                                            }
                                            helperText={
                                              props.touched.statusId &&
                                              props.errors.statusId
                                            }
                                          >
                                            <MenuItem key={0} value={null}>
                                              None
                                            </MenuItem>
                                            <MenuItem key={1} value={1}>
                                              Add Quantity
                                            </MenuItem>
                                            <MenuItem key={1} value={2}>
                                              Remove Quantity
                                            </MenuItem>
                                          </TextField>
                                        </Col>
                                        <Col lg={3} className="mb-3">
                                          <TextField
                                            id="outlined-basic"
                                            label="Qty"
                                            variant="outlined"
                                            type="text"
                                            size="small"
                                            name="quantity"
                                            onChange={props.handleChange}
                                            value={props.values.quantity}
                                            onBlur={props.handleBlur}
                                            error={
                                              props.touched.quantity &&
                                              props.errors.quantity
                                            }
                                            helperText={
                                              props.touched.quantity &&
                                              props.errors.quantity
                                                ? `${props.errors.quantity}`
                                                : ""
                                            }
                                            sx={{
                                              width: "250px",
                                              "& .MuiInputBase-root": {
                                                fontSize: {
                                                  xs: "0.7rem",
                                                  sm: "0.85rem",
                                                  md: "0.9rem",
                                                },
                                                padding: {
                                                  xs: "1px",
                                                  sm: "2px",
                                                  md: "3px",
                                                  lg: "4px",
                                                },
                                              },
                                            }}
                                          />
                                        </Col>

                                        <Col lg={4}>
                                          {" "}
                                          <TextField
                                            sx={{
                                              width: "250px",
                                              "& .MuiInputBase-root": {
                                                fontSize: {
                                                  xs: "0.7rem",
                                                  sm: "0.85rem",
                                                  md: "0.9rem",
                                                },
                                                padding: {
                                                  xs: "1px",
                                                  sm: "2px",
                                                  md: "3px",
                                                  lg: "4px",
                                                },
                                              },
                                            }}
                                            label="Remark"
                                            variant="outlined"
                                            size="small"
                                            type="text"
                                            name="remark"
                                            fullWidth
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.remark}
                                            error={
                                              props.touched.remark &&
                                              props.errors.remark
                                            }
                                            helperText={
                                              props.touched.remark &&
                                              props.errors.remark
                                            }
                                          />
                                        </Col>

                                        <Col lg={5} className="mb-3 mt-3">
                                          <Button
                                            sx={{
                                              width: "200px",
                                              borderColor: "primary.main",
                                              fontSize: {
                                                xs: "0.85rem",
                                                sm: "0.85rem",
                                                md: "1rem",
                                              },
                                              padding: {
                                                xs: "4px",
                                                sm: "5px",
                                                md: "6px",
                                                lg: "6px",
                                              },
                                              "&:hover": {
                                                backgroundColor: "primary.main",
                                                color: "white",
                                              },
                                            }}
                                            variant="outlined"
                                            color="primary"
                                            type="submit"
                                          >
                                            <h4
                                              style={{
                                                float: "left",
                                                marginRight: "5px",
                                                marginTop: "-1px",
                                                marginBottom: "-2px",
                                              }}
                                            >
                                              +
                                            </h4>
                                            <strong
                                              style={{
                                                float: "left",
                                                marginTop: "0",
                                                marginBottom: "-2px",
                                              }}
                                            >
                                              Add to list
                                            </strong>
                                          </Button>
                                        </Col>
                                      </Form.Group>
                                    </Form>
                                    <div>
                                      <div style={{ overflow: "scroll" }}>
                                        <div style={{ minWidth: "1000px" }}>
                                          <table className="summary-table ">
                                            <thead className="bg-info">
                                              <tr>
                                                <th>No</th>
                                                <th>Item</th>
                                                <th>Model</th>
                                                <th>Unit</th>
                                                <th>Qty</th>
                                                <th>Adjust Type</th>
                                                <th>Remark</th>
                                                <th
                                                  style={{ minWidth: "200px" }}
                                                >
                                                  Action
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {itemValue.map(
                                                (request, index) => (
                                                  <tr key={index}>
                                                    <td>{index + 1}</td>

                                                    <td>
                                                      {items.map((item) =>
                                                        item.Id ===
                                                        parseInt(request.itemId)
                                                          ? item.description
                                                          : ""
                                                      )}
                                                    </td>
                                                    <td>
                                                      {items.map((item) =>
                                                        item.Id ===
                                                        parseInt(request.itemId)
                                                          ? models.find(
                                                              (model) =>
                                                                model.Id ===
                                                                item.modelId
                                                            )?.modelName
                                                          : ""
                                                      )}
                                                    </td>
                                                    <td>
                                                      {items.map((item) =>
                                                        item.Id ===
                                                        parseInt(request.itemId)
                                                          ? units.find(
                                                              (unit) =>
                                                                unit.Id ===
                                                                item.unitId
                                                            )?.unitName
                                                          : ""
                                                      )}
                                                    </td>
                                                    <td>
                                                      {openUpdate &&
                                                      request.itemId ===
                                                        update.itemId ? (
                                                        <input
                                                          type="number"
                                                          minLength={1}
                                                          maxLength={1000}
                                                          className="form-control"
                                                          required
                                                          style={{
                                                            width: "10vw",
                                                          }}
                                                          value={
                                                            update.quantity ===
                                                            ""
                                                              ? request.quantity
                                                              : update.quantity
                                                          }
                                                          onChange={(e) => {
                                                            if (
                                                              parseInt(
                                                                e.target.value
                                                              ) < 1 ||
                                                              parseInt(
                                                                e.target.value
                                                              ) > 10000
                                                            ) {
                                                              setUpdate(
                                                                (prev) => ({
                                                                  ...prev,
                                                                  quantity: 1,
                                                                })
                                                              );
                                                            } else {
                                                              setUpdate(
                                                                (prev) => ({
                                                                  ...prev,
                                                                  quantity:
                                                                    parseInt(
                                                                      e.target
                                                                        .value
                                                                    ),
                                                                })
                                                              );
                                                            }
                                                          }}
                                                        />
                                                      ) : (
                                                        request.quantity
                                                      )}
                                                    </td>
                                                    <td>
                                                      {request.statusId === 1
                                                        ? "Add Quantity"
                                                        : "Remove Quantity"}
                                                    </td>
                                                    <td>
                                                      {openUpdate &&
                                                      request.itemId ===
                                                        update.itemId ? (
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          required
                                                          style={{
                                                            width: "10vw",
                                                          }}
                                                          value={update.remark}
                                                          onChange={(e) => {
                                                            setUpdate(
                                                              (prev) => ({
                                                                ...prev,
                                                                remark:
                                                                  e.target
                                                                    .value,
                                                              })
                                                            );
                                                          }}
                                                        />
                                                      ) : (
                                                        request.remark
                                                      )}
                                                    </td>

                                                    <td>
                                                      {(request.itemId !==
                                                        update.itemId ||
                                                        !openUpdate) && (
                                                        <div>
                                                          <Button
                                                            sx={{
                                                              width: "90px",
                                                              borderColor:
                                                                "warning.main",
                                                              fontSize: {
                                                                xs: "0.7rem",
                                                                sm: "0.85rem",
                                                                md: "1rem",
                                                              },
                                                              padding: {
                                                                xs: "3px",
                                                                sm: "4px",
                                                                md: "5px",
                                                                lg: "6px",
                                                              },
                                                              "&:hover": {
                                                                backgroundColor:
                                                                  "warning.main",
                                                                color: "white",
                                                              },
                                                            }}
                                                            variant="outlined"
                                                            color="warning"
                                                            className="mr-3"
                                                            onClick={(e) => {
                                                              setOpenUpdate(
                                                                true
                                                              );
                                                              setUpdate({
                                                                itemId:
                                                                  request.itemId,
                                                                quantity:
                                                                  request.quantity,
                                                                remark:
                                                                  request.remark,
                                                              });
                                                            }}
                                                          >
                                                            <div
                                                              className="row "
                                                              style={{
                                                                marginBottom:
                                                                  "-10px",
                                                                marginLeft:
                                                                  "-10px",
                                                              }}
                                                            >
                                                              <div className="col">
                                                                <FontAwesomeIcon
                                                                  style={{
                                                                    float:
                                                                      "right",
                                                                  }}
                                                                  icon={
                                                                    faPenToSquare
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="col-6">
                                                                <h6
                                                                  style={{
                                                                    float:
                                                                      "left",
                                                                    marginLeft:
                                                                      "-10px",
                                                                  }}
                                                                >
                                                                  Edit
                                                                </h6>
                                                              </div>
                                                            </div>
                                                          </Button>
                                                          <Button
                                                            sx={{
                                                              width: "110px",
                                                              borderColor:
                                                                "error.main",
                                                              fontSize: {
                                                                xs: "0.7rem",
                                                                sm: "0.85rem",
                                                                md: "1rem",
                                                              },
                                                              padding: {
                                                                xs: "3px",
                                                                sm: "4px",
                                                                md: "5px",
                                                                lg: "6px",
                                                              },
                                                              "&:hover": {
                                                                backgroundColor:
                                                                  "error.main",
                                                                color: "white",
                                                              },
                                                            }}
                                                            variant="outlined"
                                                            color="error"
                                                            onClick={(e) => {
                                                              const newItemValue =
                                                                itemValue?.filter(
                                                                  (i) =>
                                                                    parseInt(
                                                                      i.itemId
                                                                    ) !==
                                                                    parseInt(
                                                                      request.itemId
                                                                    )
                                                                );
                                                              setItemValue(
                                                                newItemValue
                                                              );
                                                            }}
                                                          >
                                                            <div
                                                              className="row"
                                                              style={{
                                                                marginBottom:
                                                                  "-10px",
                                                                marginLeft:
                                                                  "-10px",
                                                              }}
                                                            >
                                                              <div className="col">
                                                                <FontAwesomeIcon
                                                                  style={{
                                                                    float:
                                                                      "right",
                                                                    marginRight:
                                                                      "-5px",
                                                                  }}
                                                                  icon={
                                                                    faTrashCan
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="col-8">
                                                                <h6
                                                                  style={{
                                                                    float:
                                                                      "left",
                                                                    marginLeft:
                                                                      "-3px",
                                                                  }}
                                                                >
                                                                  Delete
                                                                </h6>
                                                              </div>
                                                            </div>
                                                          </Button>
                                                        </div>
                                                      )}

                                                      {openUpdate &&
                                                        request.itemId ===
                                                          update.itemId && (
                                                          <div>
                                                            <Button
                                                              sx={{
                                                                width: "90px",
                                                                borderColor:
                                                                  "success.main",
                                                                fontSize: {
                                                                  xs: "0.7rem",
                                                                  sm: "0.85rem",
                                                                  md: "1rem",
                                                                },
                                                                padding: {
                                                                  xs: "3px",
                                                                  sm: "4px",
                                                                  md: "5px",
                                                                  lg: "6px",
                                                                },
                                                                "&:hover": {
                                                                  backgroundColor:
                                                                    "success.main",
                                                                  color:
                                                                    "white",
                                                                },
                                                              }}
                                                              variant="outlined"
                                                              color="success"
                                                              className="mr-3"
                                                              onClick={(e) => {
                                                                itemValue[
                                                                  index
                                                                ].quantity =
                                                                  !!update.quantity
                                                                    ? parseInt(
                                                                        update.quantity
                                                                      )
                                                                    : parseFloat(
                                                                        itemValue[
                                                                          index
                                                                        ]
                                                                          .quantity
                                                                      );
                                                                itemValue[
                                                                  index
                                                                ].remark =
                                                                  update.remark;

                                                                setItemValue([
                                                                  ...itemValue,
                                                                ]);
                                                                update.itemId =
                                                                  "";

                                                                update.quantity =
                                                                  "";
                                                                update.remark =
                                                                  "";

                                                                setOpenUpdate(
                                                                  false
                                                                );
                                                              }}
                                                            >
                                                              <div
                                                                className="row "
                                                                style={{
                                                                  marginBottom:
                                                                    "-10px",
                                                                  marginLeft:
                                                                    "-20px",
                                                                }}
                                                              >
                                                                <div className="col">
                                                                  <FontAwesomeIcon
                                                                    style={{
                                                                      float:
                                                                        "right",
                                                                    }}
                                                                    className="fa-regular"
                                                                    icon={
                                                                      faFloppyDisk
                                                                    }
                                                                  />
                                                                </div>
                                                                <div className="col-6">
                                                                  <h6
                                                                    style={{
                                                                      float:
                                                                        "left",
                                                                      marginLeft:
                                                                        "-10px",
                                                                    }}
                                                                  >
                                                                    Save
                                                                  </h6>
                                                                </div>
                                                              </div>
                                                            </Button>
                                                            <Button
                                                              sx={{
                                                                width: "100px",
                                                                borderColor:
                                                                  "error.main",
                                                                fontSize: {
                                                                  xs: "0.7rem",
                                                                  sm: "0.85rem",
                                                                  md: "1rem",
                                                                },
                                                                padding: {
                                                                  xs: "3px",
                                                                  sm: "4px",
                                                                  md: "5px",
                                                                  lg: "6px",
                                                                },
                                                                "&:hover": {
                                                                  backgroundColor:
                                                                    "error.main",
                                                                  color:
                                                                    "white",
                                                                },
                                                              }}
                                                              variant="outlined"
                                                              color="error"
                                                              onClick={(e) => {
                                                                setOpenUpdate(
                                                                  false
                                                                );
                                                                setUpdate({
                                                                  itemId: "",
                                                                  remark: "",
                                                                  quantity: "",
                                                                });
                                                              }}
                                                            >
                                                              <div
                                                                className="row"
                                                                style={{
                                                                  marginBottom:
                                                                    "-10px",
                                                                  marginLeft:
                                                                    "-20px",
                                                                }}
                                                              >
                                                                <div className="col">
                                                                  <FontAwesomeIcon
                                                                    style={{
                                                                      float:
                                                                        "right",
                                                                      marginRight:
                                                                        "-5px",
                                                                    }}
                                                                    icon={
                                                                      faXmark
                                                                    }
                                                                  />
                                                                </div>
                                                                <div className="col-8">
                                                                  <h6
                                                                    style={{
                                                                      float:
                                                                        "left",
                                                                      marginLeft:
                                                                        "-3px",
                                                                    }}
                                                                  >
                                                                    Cancle
                                                                  </h6>
                                                                </div>
                                                              </div>
                                                            </Button>
                                                          </div>
                                                        )}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Formik>
                            </div>
                            <center className="mt-3">
                              <Row className="mb-3">
                                <Col>
                                  <Button
                                    sx={{
                                      width: "150px",
                                      borderColor: "primary.main",
                                      fontSize: {
                                        xs: "0.7rem",
                                        sm: "0.85rem",
                                        md: "1rem",
                                      },
                                      padding: {
                                        xs: "3px",
                                        sm: "4px",
                                        md: "5px",
                                        lg: "6px",
                                      },
                                      "&:hover": {
                                        backgroundColor: "primary.main",
                                        color: "white",
                                      },
                                    }}
                                    variant="outlined"
                                    color="primary"
                                    onClick={(e) => setItemValue([])}
                                  >
                                    <div
                                      className="row"
                                      style={{
                                        marginBottom: "-10px",
                                        marginLeft: "-15px",
                                      }}
                                    >
                                      <div className="col-2">
                                        <FontAwesomeIcon
                                          style={{
                                            float: "left",
                                            marginLeft: "0px",
                                            marginTop: "2px",
                                            marginBottom: "-4px",
                                          }}
                                          icon={faBackwardStep}
                                        />
                                      </div>
                                      <div className="col-10">
                                        <h6
                                          style={{
                                            float: "left",
                                            marginLeft: "-5px",
                                            marginTop: "-1px",
                                            marginBottom: "-7px",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: {
                                                xs: "0.85rem",
                                                sm: "0.85rem",
                                                md: "1rem",
                                              },
                                            }}
                                          >
                                            Back to list
                                          </Typography>
                                        </h6>
                                      </div>
                                    </div>
                                  </Button>
                                </Col>
                                <Col>
                                  <Form onSubmit={formik.handleSubmit}>
                                    <Button
                                      sx={{
                                        width: "150px",
                                        borderColor: "primary.main",
                                        fontSize: {
                                          xs: "0.7rem",
                                          sm: "0.85rem",
                                          md: "1rem",
                                        },
                                        padding: {
                                          xs: "3px",
                                          sm: "4px",
                                          md: "5px",
                                          lg: "6px",
                                        },
                                        "&:hover": {
                                          backgroundColor: "primary.main",
                                          color: "white",
                                        },
                                      }}
                                      variant="outlined"
                                      color="primary"
                                      type="submit"
                                    >
                                      <div
                                        className="row"
                                        style={{
                                          marginBottom: "-10px",
                                          marginLeft: "-15px",
                                        }}
                                      >
                                        <div className="col-3">
                                          <FontAwesomeIcon
                                            style={{
                                              float: "left",
                                              marginLeft: "0px",
                                              marginTop: "2px",
                                              marginBottom: "-4px",
                                            }}
                                            icon={faFloppyDisk}
                                          />
                                        </div>
                                        <div className="col-9">
                                          <h6
                                            style={{
                                              float: "left",
                                              marginLeft: "-5px",
                                              marginTop: "-1px",
                                              marginBottom: "-7px",
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: {
                                                  xs: "0.85rem",
                                                  sm: "0.85rem",
                                                  md: "1rem",
                                                },
                                              }}
                                            >
                                              Submit
                                            </Typography>
                                          </h6>
                                        </div>
                                      </div>
                                    </Button>
                                  </Form>
                                </Col>
                              </Row>
                            </center>
                          </div>
                        )}
                        {(open === 0 || open === 3) && (
                          <div style={{ overflow: "scroll" }}>
                            <Box
                              sx={{
                                height: 500,
                                width: {
                                  lg: "100%",
                                  md: "1000px",
                                  sm: "1000px",
                                  xs: "1000px",
                                },
                                "& .super-app-theme--header": {
                                  backgroundColor: "#1de5ec",
                                  color: "black",
                                  fontSize: {
                                    xs: "13px",
                                    sm: "15px", //500
                                    md: "17px", //768
                                    lg: "18px", //1024
                                  },
                                },
                              }}
                            >
                              <DataGrid
                                rows={open ? itemrows : rows}
                                disableColumnFilter
                                disableColumnSelector
                                disableDensitySelector
                                sx={{
                                  "& .MuiDataGrid-cell": {
                                    fontSize: {
                                      xs: "12px",
                                      sm: "14px", //500
                                      md: "16px", //768
                                      lg: "17px", //1024
                                    },
                                  },
                                }}
                                columns={open ? columnItem : columns}
                                slots={{ toolbar: GridToolbar }}
                                slotProps={{
                                  toolbar: {
                                    showQuickFilter: true,
                                  },
                                }}
                                initialState={
                                  open
                                    ? {
                                        ...itemValue.initialState,
                                        pagination: {
                                          paginationModel: { pageSize: 5 },
                                        },
                                      }
                                    : {
                                        ...adjustments.initialState,
                                        pagination: {
                                          paginationModel: { pageSize: 5 },
                                        },
                                      }
                                }
                                pageSizeOptions={[5, 10, 15]}
                                getRowId={(row) => row.Id}
                              />
                            </Box>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {open === 2 && (
                    <div style={{ overflow: "scroll" }}>
                      <div className="card-body" style={{ minWidth: "1000px" }}>
                        <Container ref={contentRef}>
                          <div className="row">
                            <div className="col-sm-6 ">
                              <br />
                              <h1
                                style={{
                                  marginLeft: "5vw",
                                  marginBottom: "2vh",
                                }}
                              >
                                Invoice
                              </h1>
                              <i
                                style={{
                                  marginLeft: "7vw",
                                  marginBottom: "2vh",
                                }}
                                className="fas fa-home fa-3x"
                              />
                            </div>

                            <div className="col-sm-6 ">
                              <br />
                              <div
                                style={{
                                  fontSize: 18,
                                  textAlign: "right",
                                }}
                              >
                                <p style={{ margin: "0", fontSize: 24 }}>
                                  <b> Company Name</b>
                                </p>
                                <p style={{ margin: "0" }}>+251987654321</p>
                                <p style={{ margin: "0" }}>email@gmail.com</p>
                                <p style={{ margin: "0" }}>address1</p>
                                <p style={{ margin: "0" }}>address2</p>
                              </div>
                            </div>
                          </div>
                          <h4>Adjustment</h4>
                          <hr />
                          <Row className="">
                            <Col sm={2}>
                              <p>
                                <b>BILL To</b>
                              </p>
                            </Col>

                            <Col sm={5}>
                              <div>
                                <p>
                                  <b>Application User : </b>
                                  {
                                    users.find(
                                      (u) => u.Id === formik.values.userId
                                    )?.fullName
                                  }
                                </p>
                              </div>
                            </Col>
                            <Col sm={5}>
                              <div>
                                <p>
                                  <b>Date : </b>
                                  {`${new Date(formik.values.date).getDate()}/${
                                    new Date(formik.values.date).getMonth() + 1
                                  }/${new Date().getFullYear(
                                    formik.values.date
                                  )}`}
                                </p>
                              </div>
                            </Col>
                          </Row>
                          <br />
                          <br />

                          <br />
                          <Row>
                            <Table
                              className="text-center"
                              style={{ width: "100%" }}
                            >
                              <tr>
                                <th>No</th>
                                <th>Code</th>
                                <th>Desc</th>
                                <th>Model</th>
                                <th>Unit</th>
                                <th>Qty</th>
                                <th>Remark</th>
                                <th>Adjustment Type</th>
                              </tr>

                              <tbody>
                                {itemValue.map((request, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      {items.map((item) =>
                                        item.Id === parseInt(request.itemId)
                                          ? item.code
                                          : ""
                                      )}
                                    </td>
                                    <td>
                                      {items.map((item) =>
                                        item.Id === parseInt(request.itemId)
                                          ? item.description
                                          : ""
                                      )}
                                    </td>
                                    <td>
                                      {items.map((item) =>
                                        item.Id === parseInt(request.itemId)
                                          ? models.map((model) =>
                                              model.Id ===
                                              parseInt(item.modelId)
                                                ? model.modelName
                                                : ""
                                            )
                                          : ""
                                      )}
                                    </td>
                                    <td>
                                      {items.map((item) =>
                                        item.Id === parseInt(request.itemId)
                                          ? units.map((unit) =>
                                              unit.Id === parseInt(item.unitId)
                                                ? unit.unitName
                                                : ""
                                            )
                                          : ""
                                      )}
                                    </td>

                                    <td>{request.quantity}</td>
                                    <td>{request.remark}</td>
                                    <td>
                                      {request.statusId === 1
                                        ? "Add Quantity"
                                        : "Remove Quantity"}
                                    </td>
                                  </tr>
                                ))}
                                {itemValue.length < 5 ? handelInvoice() : ""}
                              </tbody>
                            </Table>
                          </Row>
                        </Container>
                      </div>
                      <div className="card-footer">
                        <Button
                          onClick={() => {
                            reactToPrint();
                            handleBack();
                          }}
                          sx={{
                            width: "100px",
                            borderColor: "primary.main",
                            fontSize: {
                              xs: "0.7rem",
                              sm: "0.85rem",
                              md: "1rem",
                            },
                            padding: {
                              xs: "3px",
                              sm: "4px",
                              md: "5px",
                              lg: "6px",
                            },
                            "&:hover": {
                              backgroundColor: "primary.main",
                              color: "white",
                            },
                          }}
                          variant="outlined"
                          color="primary"
                          className="ml-3 mt-1"
                          style={{ float: "left" }}
                        >
                          <div
                            className="row"
                            style={{
                              marginBottom: "-10px",
                              marginLeft: "-10px",
                            }}
                          >
                            <div className="col">
                              <FontAwesomeIcon
                                style={{
                                  float: "right",
                                  marginRight: "-1px",
                                  marginTop: "2px",
                                  marginBottom: "-4px",
                                }}
                                icon={faPrint}
                              />
                            </div>
                            <div className="col-7">
                              <h6
                                style={{
                                  float: "left",
                                  marginLeft: "-10px",
                                  marginTop: "-1px",
                                  marginBottom: "-7px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: {
                                      xs: "0.85rem",
                                      sm: "0.85rem",
                                      md: "1rem",
                                    },
                                  }}
                                >
                                  Print
                                </Typography>
                              </h6>
                            </div>
                          </div>
                        </Button>

                        <Button
                          onClick={(e) => {
                            handleBack();
                          }}
                          sx={{
                            width: "90px",
                            borderColor: "success.main",
                            fontSize: {
                              xs: "0.7rem",
                              sm: "0.85rem",
                              md: "1rem",
                            },
                            padding: {
                              xs: "3px",
                              sm: "4px",
                              md: "5px",
                              lg: "6px",
                            },
                            "&:hover": {
                              backgroundColor: "success.main",
                              color: "white",
                            },
                          }}
                          variant="outlined"
                          color="success"
                          className="ml-3 mt-1"
                          style={{ float: "left" }}
                        >
                          <div
                            className="row"
                            style={{
                              marginBottom: "-10px",
                              marginLeft: "-10px",
                            }}
                          >
                            <div className="col">
                              <FontAwesomeIcon
                                style={{
                                  float: "right",
                                  marginRight: "-1px",
                                  marginTop: "2px",
                                  marginBottom: "-4px",
                                }}
                                icon={faBackwardStep}
                              />
                            </div>
                            <div className="col-7">
                              <h6
                                style={{
                                  float: "left",
                                  marginLeft: "-10px",
                                  marginTop: "-1px",
                                  marginBottom: "-7px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: {
                                      xs: "0.85rem",
                                      sm: "0.85rem",
                                      md: "1rem",
                                    },
                                  }}
                                >
                                  Back
                                </Typography>
                              </h6>
                            </div>
                          </div>
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                <Formik
                  initialValues={{
                    fullName: "",
                    tinNumber: "",
                    address: "",
                    primaryPhoneNumber: "",
                    secondaryPhoneNumber: "",
                  }}
                  validationSchema={Yup.object().shape({
                    fullName: Yup.string().required("Full name is required."),
                    primaryPhoneNumber: Yup.string()
                      .matches(
                        /^\d{9,10}$/,
                        "Phone number must be 9 or 10 digits."
                      )
                      .required("Phone number is required."),
                    secondaryPhoneNumber: Yup.string().matches(
                      /^\d{9,10}$/,
                      "Phone number must be 9 or 10 digits."
                    ),
                    address: Yup.string().required("Address is required"),
                    tinNumber: Yup.string().matches(
                      /^\d{10}$/,
                      "Tin number must be 10 digits."
                    ),
                  })}
                  onSubmit={async (values) => {
                    if (isSubmitted) return;
                    setIsSubmitted(true);
                    try {
                      await axios.post(
                        "https://enin.server.highendtradingplc.com/customer/add",
                        values
                      );
                    } catch (error) {
                      console.log(error);
                    } finally {
                      fetchAllData();
                      setOpenStore(false);
                    }
                    setIsSubmitted(false);
                  }}
                >
                  {(props) => (
                    <Modal
                      show={openStore}
                      onHide={handleOpenStore}
                      style={{ minWidth: "700px" }}
                    >
                      <Modal.Header closeButton>
                        <span>Adjustment / Customer / Create</span>
                      </Modal.Header>
                      <Modal.Body>
                        <Form
                          className="m-2 ml-5 pl-5"
                          onSubmit={props.handleSubmit}
                        >
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={8}>
                              <TextField
                                id="outlined-basic"
                                label="Full Name"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="fullName"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.fullName}
                                isInvalid={
                                  props.touched.fullName &&
                                  props.errors.fullName
                                }
                                style={{ maxWidth: "300px" }}
                              />

                              {props.touched.fullName &&
                                props.errors.fullName && (
                                  <div className="text-danger mt-1">
                                    {props.errors.fullName}
                                  </div>
                                )}
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={8}>
                              <TextField
                                id="outlined-basic"
                                label="Till Number"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="tinNumber"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.tinNumber}
                                isInvalid={
                                  props.touched.tinNumber &&
                                  props.errors.tinNumber
                                }
                                style={{ maxWidth: "300px" }}
                              />

                              {props.touched.tinNumber &&
                                props.errors.tinNumber && (
                                  <div className="text-danger mt-1">
                                    {props.errors.tinNumber}
                                  </div>
                                )}
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={8}>
                              <TextField
                                id="outlined-basic"
                                label="Phone Number"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="primaryPhoneNumber"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.primaryPhoneNumber}
                                isInvalid={
                                  props.touched.primaryPhoneNumber &&
                                  props.errors.primaryPhoneNumber
                                }
                                style={{ maxWidth: "300px" }}
                              />

                              {props.touched.primaryPhoneNumber &&
                                props.errors.primaryPhoneNumber && (
                                  <div className="text-danger mt-1">
                                    {props.errors.primaryPhoneNumber}
                                  </div>
                                )}
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={8}>
                              <TextField
                                id="outlined-basic"
                                label="Phone Number"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="secondaryPhoneNumber"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.secondaryPhoneNumber}
                                isInvalid={
                                  props.touched.secondaryPhoneNumber &&
                                  props.errors.secondaryPhoneNumber
                                }
                                style={{ maxWidth: "300px" }}
                              />

                              {props.touched.secondaryPhoneNumber &&
                                props.errors.secondaryPhoneNumber && (
                                  <div className="text-danger mt-1">
                                    {props.errors.secondaryPhoneNumber}
                                  </div>
                                )}
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={8}>
                              <TextField
                                id="outlined-basic"
                                label="Address"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="address"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.address}
                                isInvalid={
                                  props.touched.address && props.errors.address
                                }
                                style={{ maxWidth: "300px" }}
                              />

                              {props.touched.address &&
                                props.errors.address && (
                                  <div className="text-danger mt-1">
                                    {props.errors.address}
                                  </div>
                                )}
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={12}>
                              <Button
                                style={{ width: "62%" }}
                                variant="outline-success"
                                type="submit"
                              >
                                Create
                              </Button>
                            </Col>
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                    </Modal>
                  )}
                </Formik>
              </div>
            </center>
          </main>
        </div>
      </Typography>
    </ThemeProvider>
  );
};

export default Adjustment;
