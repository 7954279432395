/** @format */

// import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./page/HomePage";
import CustomerPage from "./page/CustomerPage";
import SupplierPage from "./page/SupplierPage";
import PurchasePage from "./page/PurchasePage";
import SalesPage from "./page/SalesPage";
import PurchaseReport from "./page/purchaseReport";
import SalesReport from "./page/SalesReport";
import StockReport from "./page/StockReport";
import LoginHistory from "./page/LoginHistory";
import UserMng from "./page/UserMng";
import RoleMng from "./page/RoleMng";
import AccountMng from "./page/AccountMng";
import UnitPage from "./page/UnitPage";
import Adjustment from "./page/Adjustment";
import Delivery from "./page/Delivery";
import Login from "./Login";
import ItemPage from "./page/ItemPage";
import StockAvailable from "./page/StockAvailable";
import Store from "./page/Store";
import AdjustmentPage from "./page/Adjustment";
import RequestPage from "./page/RequestPage";
import ReservePage from "./page/ReservePage";
import QuotaPage from "./page/QuotaPage";
import ModelPage from "./page/ModelPage";
import TaxPage from "./page/TaxPage";
import PaymentMethod from "./page/PaymentMethod";
import QueueOrderPage from "./page/QueueOrderPage";
import Scanner from "./page/Scanner";
import PurchaseItemDelivery from "./page/PurchaseItemDelivery";
import StoreTransfer from "./page/StoreTransfer";
import DeliveryItem from "./page/DeliveryItem";
import DeliveryReport from "./page/DeliveryReport";
import AdjustmentDelivery from "./page/AdjustmentDelivery";
import ProtectedRoute from "./ProtectedRoute";
import QuantityReport from "./page/QuantityReport";
import BackUp from "./page/BackUp";

const Home = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/home"
          element={
            <ProtectedRoute requiredRole={[2, 3, 4, 5]}>
              <HomePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/backup"
          element={
            <ProtectedRoute requiredRole={[2]}>
              <BackUp />
            </ProtectedRoute>
          }
        />
        <Route
          path="/customers"
          element={
            <ProtectedRoute requiredRole={[2, 3]}>
              <CustomerPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/supplier"
          element={
            <ProtectedRoute requiredRole={[2]}>
              <SupplierPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/item"
          element={
            <ProtectedRoute requiredRole={[2, 4]}>
              <ItemPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sales/:id"
          element={
            <ProtectedRoute requiredRole={[2, 4]}>
              <SalesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/store"
          element={
            <ProtectedRoute requiredRole={[2, 5]}>
              <Store />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reserve/:id"
          element={
            <ProtectedRoute requiredRole={[2, 3]}>
              <ReservePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/quota/:id"
          element={
            <ProtectedRoute requiredRole={[2, 3]}>
              <QuotaPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/adjustment"
          element={
            <ProtectedRoute requiredRole={[2]}>
              <AdjustmentPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/request"
          element={
            <ProtectedRoute requiredRole={[2, 3]}>
              <RequestPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/purchase"
          element={
            <ProtectedRoute requiredRole={[2, 5]}>
              <PurchasePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/purchase_delivery/:id"
          element={
            <ProtectedRoute requiredRole={[2, 5]}>
              <PurchaseItemDelivery />
            </ProtectedRoute>
          }
        />
        <Route
          path="/delivery_report"
          element={
            <ProtectedRoute requiredRole={[2, 5]}>
              <DeliveryReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/delivery_item/:id"
          element={
            <ProtectedRoute requiredRole={[2, 5]}>
              <DeliveryItem />
            </ProtectedRoute>
          }
        />
        <Route
          path="/stock_available/:id"
          element={
            <ProtectedRoute requiredRole={[2]}>
              <StockAvailable />
            </ProtectedRoute>
          }
        />
        <Route
          path="/purchase_report"
          element={
            <ProtectedRoute requiredRole={[2]}>
              <PurchaseReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sales_report"
          element={
            <ProtectedRoute requiredRole={[2, 4]}>
              <SalesReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/stock_report"
          element={
            <ProtectedRoute requiredRole={[2]}>
              <StockReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/login_history"
          element={
            <ProtectedRoute requiredRole={[2]}>
              <LoginHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user_mgt"
          element={
            <ProtectedRoute requiredRole={[1, 2]}>
              <UserMng />
            </ProtectedRoute>
          }
        />
        <Route
          path="/role_mgt"
          element={
            <ProtectedRoute requiredRole={[1, 2]}>
              <RoleMng />
            </ProtectedRoute>
          }
        />
        <Route
          path="/account_mgt"
          element={
            <ProtectedRoute requiredRole={[0]}>
              <AccountMng />
            </ProtectedRoute>
          }
        />
        <Route
          path="/adjustment"
          element={
            <ProtectedRoute requiredRole={[2]}>
              <Adjustment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/unit"
          element={
            <ProtectedRoute requiredRole={[2]}>
              <UnitPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tax"
          element={
            <ProtectedRoute requiredRole={[2]}>
              <TaxPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/model"
          element={
            <ProtectedRoute requiredRole={[2]}>
              <ModelPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/quantity_report"
          element={
            <ProtectedRoute requiredRole={[2]}>
              <QuantityReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/delivery/:id"
          element={
            <ProtectedRoute requiredRole={[2, 5]}>
              <Delivery />
            </ProtectedRoute>
          }
        />
        <Route
          path="/adjustment_delivery/:id"
          element={
            <ProtectedRoute requiredRole={[2, 5]}>
              <AdjustmentDelivery />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payment"
          element={
            <ProtectedRoute requiredRole={[2]}>
              <PaymentMethod />
            </ProtectedRoute>
          }
        />
        <Route
          path="/queue_order/:id"
          element={
            <ProtectedRoute requiredRole={[2, 3]}>
              <QueueOrderPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/scanner"
          element={
            <ProtectedRoute requiredRole={[2, 3, 4, 5]}>
              <Scanner />
            </ProtectedRoute>
          }
        />
        <Route
          path="/store_transfer"
          element={
            <ProtectedRoute requiredRole={[2, 5]}>
              <StoreTransfer />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Home;
