import React, { useState, useEffect } from "react";
import { Modal, Form, Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import {
  Button,
  TextField,
  createTheme,
  useMediaQuery,
  ThemeProvider,
  Typography,
  Box,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import axios from "axios";
import Header from "../Header";
import {
  faBackwardStep,
  faPenToSquare,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const theme = createTheme({
  breakpoints: { values: { xs: 0, sm: 500, md: 769, lg: 1024, xl: 1440 } },
  palette: {
    warning: {
      main: "#ffc107", // Main warning color
      light: "#ffb74d", // Light variation of warning color
      dark: "#f57c00", // Dark variation of warning color
      contrastText: "#000", // Text color on warning
    },
  },
});
const validationSchema = Yup.object().shape({
  modelName: Yup.string()
    .matches(
      /^[A-Za-z0-9 -]{1,20}$/,
      `Model should be 1-20 alphanumeric and - characters.`
    )
    .required("Model is required"),
});

const ModelPage = () => {
  const [open, setOpen] = useState(false);
  const [models, setModels] = useState([]);
  const [progressOpen, setProgressOpen] = useState(false);
  const [warnings, setWarings] = useState([0, 0]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleOpen = () => {
    setOpen(false);
  };
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isUnderMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const fetchAllData = async () => {
    try {
      const [model] = await Promise.all([
        axios.get("https://enin.server.highendtradingplc.com/model"),
      ]);
      setModels(model.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  const formik = useFormik({
    initialValues: {
      modelName: "",
      id: "",
    },

    validationSchema,
    onSubmit: async (values) => {
      if (isSubmitted) return;
      setIsSubmitted(true);
      try {
        if (values.id === "") {
          let check = models.find((mm) => mm.modelName === values.modelName);
          if (check) {
            formik.setErrors({
              modelName: "Model already exists.",
            });
            setIsSubmitted(false);
            return;
          } else {
            await axios.post(
              `https://enin.server.highendtradingplc.com/model/add`,
              values
            );
            setOpen(false);
          }
        } else {
          let check = models.find(
            (mm) => mm.Id !== values.id && mm.modelName === values.modelName
          );

          if (check) {
            formik.setErrors({
              modelName: "Model already exists.",
            });
            setIsSubmitted(false);
            return;
          } else {
            if (warnings[0] === 0) {
              warnings[0] = 2;
              warnings[1] = values.id;
              setWarings([...warnings]);
              setIsSubmitted(false);
              return;
            }

            await axios.put(
              `https://enin.server.highendtradingplc.com/model/update/` +
                values.id,
              values
            );
            setWarings([0, 0]);
            setOpen(false);
            clear();
          }
        }
        fetchAllData();
      } catch (error) {
        console.log(error);
      }
      setIsSubmitted(false);
    },
  });
  const clear = () => {
    formik.values.modelName = "";
    formik.values.id = "";
    fetchAllData();
  };
  const handleAdd = () => {
    formik.values.modelName = "";
    setOpen(true);
    formik.setErrors([]);
  };
  const handleUpdate = (Id) => {
    const model = models.find((u) => u.Id === Id);
    formik.values.modelName = model.modelName;
    formik.values.id = Id;
    setOpen(true);
    formik.setErrors([]);
  };
  const handleDelete = async (Id) => {
    try {
      await axios.delete(
        `https://enin.server.highendtradingplc.com/model/delete/` + Id
      );
      fetchAllData();
    } catch (error) {}
  };
  const style = {
    backgroundColor: "white",
    borderRadius: "5px 5px 0 0",
    border: "solid 0.05em rgb(181, 181, 181)",
  };

  const columnModel = [
    {
      field: "id",
      headerName: "No",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Id",
      headerName: "Model Id",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
    },

    {
      field: "modelName",
      headerName: "Model",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",

      flex: 3,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <>
          <Button
            onClick={() => handleUpdate(params.row.Id)}
            sx={{
              maxWidth: "110px",
              borderColor: "warning.main",
              fontSize: {
                xs: "0.7rem",
                sm: "0.85rem",
                md: "1rem",
              },
              padding: {
                xs: "3px",
                sm: "4px",
                md: "5px",
                lg: "6px",
              },
              "&:hover": {
                backgroundColor: "warning.main",
                color: "white",
              },
            }}
            variant="outlined"
            color="warning"
            className="mr-1 col"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{
                    float: "right",
                    marginRight: "-1px",
                    marginTop: "2px",
                    marginBottom: "-4px",
                  }}
                  icon={faPenToSquare}
                />
              </div>
              <div className="col-6">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-10px",
                    marginTop: "-1px",
                    marginBottom: "-7px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "0.85rem",
                        sm: "0.85rem",
                        md: "1rem",
                      },
                    }}
                  >
                    Edit
                  </Typography>
                </h6>
              </div>
            </div>
          </Button>
          <Button
            onClick={(e) => {
              warnings[1] = params.row.Id;
              warnings[0] = 1;
              setWarings([...warnings]);
            }}
            sx={{
              maxWidth: "120px",
              borderColor: "error.main",
              fontSize: {
                xs: "0.7rem",
                sm: "0.85rem",
                md: "1rem",
              },
              padding: {
                xs: "3px",
                sm: "4px",
                md: "5px",
                lg: "6px",
              },
              "&:hover": {
                backgroundColor: "error.main",
                color: "white",
              },
            }}
            variant="outlined"
            color="error"
            className="col"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{
                    float: "right",
                    marginRight: "-1px",
                    marginTop: "2px",
                    marginBottom: "-4px",
                  }}
                  icon={faTrashCan}
                />
              </div>
              <div className="col-8">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-10px",
                    marginTop: "-1px",
                    marginBottom: "-7px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "0.85rem",
                        sm: "0.85rem",
                        md: "1rem",
                      },
                    }}
                  >
                    Delete
                  </Typography>
                </h6>
              </div>
            </div>
          </Button>
        </>
      ),
    },
  ];
  const rows = models.map((model, index) => {
    let newModel = model;
    newModel.id = index + 1;
    return newModel;
  });
  return (
    <ThemeProvider theme={theme}>
      <Typography
        sx={{
          fontSize: {
            xs: "12px",
            sm: "12px", //500
            md: "0.85rem", //768
            lg: "17px", //1024
          },
        }}
      >
        <div>
          <Header />
        </div>
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: "13px",
            sm: "14px", //500
            md: "16px", //768
            lg: "18px", //1024
          },
        }}
      >
        <div>
          <main
            className="inventory-page"
            style={
              isMobile && !isUnderMobile
                ? { marginTop: "190px" }
                : isUnderMobile
                ? { marginTop: "170px" }
                : isTablet
                ? { marginTop: "133px" }
                : { marginTop: "150px" }
            }
          >
            <center>
              <div
                style={
                  isUnderMobile
                    ? {
                        minWidth: "500px",
                        overflow: "scroll",
                        marginBottom: "-5px",
                      }
                    : { maxWidth: "1100px" }
                }
                className="text-left"
              >
                <div
                  className="tab-container "
                  style={
                    isUnderMobile
                      ? { marginTop: "0", marginBottom: "-5px" }
                      : {
                          marginTop: "-20px",
                          marginBottom: "-5px",
                        }
                  }
                >
                  <Link className="p-2" to={`/item`}>
                    Item
                  </Link>
                  <Link className="p-2" to={`/unit`}>
                    Unit
                  </Link>
                  <Link
                    className="p-2"
                    onClick={(e) => {
                      window.location.reload();
                    }}
                    style={{
                      backgroundColor: "white",
                      borderRadius: "5px 5px 0 0",
                      border: "solid 0.05em rgb(181, 181, 181)",
                    }}
                  >
                    Model
                  </Link>
                  <Link className="p-2" to={`/tax`}>
                    Tax
                  </Link>
                </div>
                <Backdrop
                  sx={(theme) => ({
                    color: "#fff",
                    zIndex: theme.zIndex.drawer + 1,
                  })}
                  open={progressOpen}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <div className="card card-info card-outline">
                  <div className="card-header">
                    {!open && (
                      <Button
                        sx={{
                          width: "150px",
                          borderColor: "info.main",
                          fontSize: {
                            xs: "0.7rem",
                            sm: "0.85rem",
                            md: "1rem",
                          },
                          padding: {
                            xs: "0",
                            sm: "0",
                            md: "1px",
                            lg: "3px",
                          },
                          "&:hover": {
                            backgroundColor: "info.main",
                            color: "white",
                          },
                        }}
                        variant="outlined"
                        color="info"
                        onClick={handleAdd}
                      >
                        <h4
                          style={{
                            float: "left",
                            marginTop: "-1px",
                            marginRight: "5px",
                            marginBottom: "-2px",
                          }}
                        >
                          +
                        </h4>
                        <strong
                          style={{
                            float: "left",
                            marginTop: "0",
                            marginBottom: "-2px",
                          }}
                        >
                          {" "}
                          Create New
                        </strong>
                      </Button>
                    )}
                    {open && (
                      <div>
                        <h5 style={{ float: "left" }}>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: "1.3rem",
                                sm: "1.3rem",
                                md: "1.5rem",
                              },
                            }}
                          >
                            {formik.values.id === "" ? "Create New" : "Update"}{" "}
                            Model
                          </Typography>
                        </h5>
                        <Button
                          onClick={handleOpen}
                          sx={{
                            width: "100px",
                            borderColor: "success.main",
                            fontSize: {
                              xs: "0.85rem",
                              sm: "0.85rem",
                              md: "1rem",
                            },
                            padding: {
                              xs: "4px",
                              sm: "5px",
                              md: "6px",
                              lg: "6px",
                            },
                            "&:hover": {
                              backgroundColor: "success.main",
                              color: "white",
                            },
                          }}
                          variant="outlined"
                          color="success"
                          className="m-1"
                          style={{ float: "right" }}
                        >
                          <div
                            className="row"
                            style={{
                              marginBottom: "-10px",
                              marginLeft: "-10px",
                            }}
                          >
                            <div className="col">
                              <FontAwesomeIcon
                                style={{
                                  float: "right",
                                  marginRight: "-1px",
                                  marginTop: "2px",
                                  marginBottom: "-4px",
                                }}
                                icon={faBackwardStep}
                              />
                            </div>
                            <div className="col-7">
                              <h6
                                style={{
                                  float: "left",
                                  marginLeft: "-10px",
                                  marginTop: "-1px",
                                  marginBottom: "-7px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: {
                                      xs: "0.85rem",
                                      sm: "0.85rem",
                                      md: "1rem",
                                    },
                                  }}
                                >
                                  Back
                                </Typography>
                              </h6>
                            </div>
                          </div>
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className="card-body">
                    <div>
                      <div>
                        <div
                          style={{
                            backgroundColor: "rgb(235, 235, 235)",
                          }}
                        >
                          {!open && (
                            <div>
                              <Typography
                                sx={{
                                  fontSize: {
                                    xs: "0.85rem",
                                    sm: "0.85rem",
                                    md: "1rem",
                                  },
                                }}
                              >
                                <div
                                  className="nav-bra pl-3 ml-1 p-2"
                                  style={{
                                    backgroundColor: "rgb(235, 235, 235)",
                                    height: "40px",
                                  }}
                                >
                                  <span style={{ float: "left" }}>
                                    Home / Model{" "}
                                  </span>
                                </div>
                              </Typography>
                            </div>
                          )}
                          {open && (
                            <div>
                              <Typography
                                sx={{
                                  fontSize: {
                                    xs: "0.85rem",
                                    sm: "0.85rem",
                                    md: "1rem",
                                  },
                                }}
                              >
                                <div
                                  className="nav-bra pl-3 ml-1 p-2"
                                  style={{
                                    backgroundColor: "rgb(235, 235, 235)",
                                    height: "40px",
                                  }}
                                >
                                  <span>
                                    Home / Model /{" "}
                                    {formik.values.id === ""
                                      ? "Create"
                                      : "Update"}{" "}
                                  </span>
                                </div>
                              </Typography>
                            </div>
                          )}
                        </div>
                        <hr />
                      </div>
                      {!open && (
                        <center>
                          <div
                            style={{
                              overflow: "scroll",
                            }}
                          >
                            <Box
                              sx={{
                                height: 500,

                                width: {
                                  lg: "650px",
                                  md: "600",
                                  sm: "550",
                                  xs: "500",
                                },
                                "& .super-app-theme--header": {
                                  backgroundColor: "#1de5ec",
                                  color: "black",
                                  fontSize: {
                                    xs: "13px",
                                    sm: "15px", //500
                                    md: "17px", //768
                                    lg: "18px", //1024
                                  },
                                },
                              }}
                            >
                              <DataGrid
                                rows={rows}
                                disableColumnFilter
                                disableColumnSelector
                                disableDensitySelector
                                sx={{
                                  "& .MuiDataGrid-cell": {
                                    fontSize: {
                                      xs: "12px",
                                      sm: "14px", //500
                                      md: "16px", //768
                                      lg: "17px", //1024
                                    },
                                  },
                                }}
                                columns={columnModel}
                                slots={{ toolbar: GridToolbar }}
                                slotProps={{
                                  toolbar: {
                                    showQuickFilter: true,
                                  },
                                }}
                                pageSize={5}
                                getRowId={(row) => row.Id}
                              />
                            </Box>
                          </div>
                        </center>
                      )}
                    </div>
                    {open && (
                      <center>
                        <div>
                          <Form
                            className="ml-5 pl-5"
                            onSubmit={formik.handleSubmit}
                          >
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formHorizontalUsername"
                            >
                              <Col sm={12}>
                                <TextField
                                  id="outlined-basic"
                                  sx={{
                                    width: "250px",
                                    "& .MuiInputBase-root": {
                                      fontSize: {
                                        xs: "0.7rem",
                                        sm: "0.85rem",
                                        md: "0.9rem",
                                      },
                                      padding: {
                                        xs: "1px",
                                        sm: "2px",
                                        md: "3px",
                                        lg: "4px",
                                      },
                                    },
                                  }}
                                  label="Model Name"
                                  variant="outlined"
                                  type="text"
                                  size="small"
                                  name="modelName"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.modelName}
                                  error={
                                    formik.touched.modelName &&
                                    formik.errors.modelName
                                  }
                                  helperText={
                                    formik.touched.modelName &&
                                    formik.errors.modelName
                                      ? `${formik.errors.modelName}`
                                      : ""
                                  }
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formHorizontalUsername"
                            >
                              <Col sm={12}>
                                <Button
                                  sx={{
                                    width: "250px",
                                    borderColor: "success.main",
                                    fontSize: {
                                      xs: "0.85rem",
                                      sm: "0.85rem",
                                      md: "1rem",
                                    },
                                    padding: {
                                      xs: "4px",
                                      sm: "5px",
                                      md: "6px",
                                      lg: "6px",
                                    },
                                    "&:hover": {
                                      backgroundColor: "success.main",
                                      color: "white",
                                    },
                                  }}
                                  variant="outlined"
                                  color="success"
                                  type="submit"
                                >
                                  {formik.values.id === ""
                                    ? "Create"
                                    : "Update"}
                                </Button>
                              </Col>
                            </Form.Group>
                          </Form>
                        </div>
                      </center>
                    )}
                  </div>
                </div>
              </div>
              <Modal show={warnings[0] !== 0} onHide={() => setWarings([0, 0])}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <Typography variant="h6">
                    Are you sure you want to{" "}
                    {warnings[0] === 1 ? "delete" : "update"} this model?
                  </Typography>
                  <Row>
                    <center>
                      <Button
                        className="col mr-4"
                        style={{ maxWidth: "100px" }}
                        variant="contained"
                        color="error"
                        onClick={() => {
                          if (warnings[0] === 1) {
                            handleDelete(warnings[1]);
                            setWarings([0, 0]);
                          } else if (warnings[0] === 2) {
                            formik.handleSubmit();
                          }
                        }}
                      >
                        Confirm
                      </Button>
                      <Button
                        className="col ml-4"
                        style={{ maxWidth: "100px" }}
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          warnings[0] = 0;
                          setWarings([...warnings]);
                        }}
                      >
                        Cancel
                      </Button>
                    </center>
                  </Row>
                </Modal.Body>
              </Modal>
            </center>
          </main>
        </div>
      </Typography>
    </ThemeProvider>
  );
};

export default ModelPage;
