/** @format */
import React, { useEffect, useRef, useState } from "react";
import Header from "../Header";

import { Modal, Form, Col, Row, Table } from "react-bootstrap";
import {
  Autocomplete,
  TextField,
  Container,
  Button,
  createTheme,
  useMediaQuery,
  ThemeProvider,
  Typography,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useReactToPrint } from "react-to-print";
import {
  faUser,
  faBackwardStep,
  faFloppyDisk,
  faCalendar,
  faCode,
  faPenToSquare,
  faTrashCan,
  faXmark,
  faEye,
  faPrint,
  faTrashRestore,
  faWallet,
  faMoneyBillTransfer,
  faMoneyBill1Wave,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  PlaylistAddCheckCircleOutlined,
  ReceiptOutlined,
} from "@mui/icons-material";
import QRCode from "qrcode";
import HexCharacterManipulator from "./AllDataFetch";
import { jwtDecode } from "jwt-decode";
const validationSchema = Yup.object().shape({
  customerId: Yup.string().required("Customer is required!"),
  paymentMethodId: Yup.string().required("Payment Method is required!"),
  date: Yup.date().required("Date is required!"),
  remark: Yup.string().min(3, "Too Short!").max(50, "Too Long!"),
  totalPrice: Yup.number().required("Price is required."),
  transferidId: Yup.string().matches(
    /^[A-Za-z0-9-]{3,20}$/,
    "Transfer ID should be 3-20 alphanumeric characters."
  ),
  reciptNumber: Yup.string()
    .matches(
      /^[0-9]{4}$/,
      "Recept number must be exactly 4 numeric characters."
    )
    .required("Recept number is required."),
  fsNumber: Yup.string()
    .matches(
      /^[0-9]{0,20}$/,
      "FS number must be less than 20 numeric characters."
    )
    .required("FS number is required."),
  acNumber: Yup.string()
    .matches(
      /^[0-9]{0,20}$/,
      "MRC number must be less than 20 numeric characters."
    )
    .required("MRC number is required."),
  refNumber: Yup.string()
    .matches(
      /^[A-Za-z0-9-]{3,20}$/,
      "Ref number should be 3-20 alphanumeric characters."
    )
    .required("Ref number is required."),
});
const theme = createTheme({
  breakpoints: { values: { xs: 0, sm: 500, md: 769, lg: 1024, xl: 1440 } },
  palette: {
    warning: {
      main: "#ffc107", // Main warning color
      light: "#ffb74d", // Light variation of warning color
      dark: "#f57c00", // Dark variation of warning color
      contrastText: "#000", // Text color on warning
    },
  },
});

function SalesPage() {
  const [customers, setCustomers] = useState([]);
  const [items, setItems] = useState([]);
  const [units, setUnits] = useState([]);
  const [models, setModels] = useState([]);
  const [itemId, setItemId] = useState(null);
  const [itemCheck, setItemCheck] = useState(null);
  const [customerCheck, setCustomerCheck] = useState(null);
  const [paymentValue, setPaymentValue] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [valids, setValid] = useState([0, 0, 0]);
  const [itemValue, setItemValue] = useState([]);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [sales, setSales] = useState([]);
  const [newSales, setNewSales] = useState([]);
  const [allDataItems, setAllDataItems] = useState([]);
  const [saleItems, setSaleItems] = useState([]);
  const [customerValue, setCustomerValue] = useState(null);
  const [taxs, setTaxs] = useState([]);
  const [openSales, setOpenSales] = useState(0);
  const [newRequests, setNewRequests] = useState([]);
  const [users, setUsers] = useState([]);
  const [discountValue, setDiscountValue] = useState(0);
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [logicals, setLogicals] = useState([]);
  const [allData, setAllData] = useState([]);
  const [openMessage, setOpenMessage] = useState(false);
  const [messages, setMessages] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [update, setUpdate] = useState({
    itemId: "",
    quantity: "",
    price: "",
    discount: "",
    remark: "",
  });
  const [price, setPrice] = useState({
    total: 0,
    grandTotal: 0,
    tax: 0,
    withHold: 0,
  });
  const userData = jwtDecode(sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const contentRef = useRef();
  const reactToPrint = useReactToPrint({ contentRef });
  const { id } = useParams();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isUnderMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const mergeData = (leftData, rightData) => {
    let mergedData = [];
    let i = 0,
      j = 0;
    while (i < leftData.length && j < rightData.length) {
      if (new Date(leftData[i]?.date) >= new Date(rightData[j]?.date)) {
        mergedData.push(leftData[i]);
        i++;
      } else {
        mergedData.push(rightData[j]);
        j++;
      }
    }
    let leftDataTail = leftData.filter((d, l) => l >= i);
    let rightDataTail = rightData.filter((d, r) => r >= j);
    return mergedData.concat(leftDataTail?.concat(rightDataTail));
  };

  const sortData = (data, depth = 0) => {
    if (data.length < 2) return data;
    let midValue = Math.round(data.length / 2);
    let leftData = data.filter((d, i) => i < midValue);
    let rightData = data.filter((d, i) => i >= midValue);
    let leftSortData = sortData(leftData, depth + 1);
    let rightSortData = sortData(rightData, depth + 1);
    let sortedData = mergeData(leftSortData, rightSortData);
    return sortedData;
  };
  const fetchAllData = async () => {
    try {
      const [
        customer,
        item,
        unit,
        sale,
        requestItem,
        user,
        saleItem,
        model,
        tax,
        paymentMethod,
        logical,
        request,
        reserve,
        reserveItem,
        queue,
      ] = await Promise.all([
        axios.get("https://enin.server.highendtradingplc.com/customer"),
        axios.get("https://enin.server.highendtradingplc.com/item"),
        axios.get("https://enin.server.highendtradingplc.com/unit"),
        axios.get("https://enin.server.highendtradingplc.com/sale"),
        axios.get("https://enin.server.highendtradingplc.com/request_item"),
        axios.get("https://enin.server.highendtradingplc.com/user"),
        axios.get("https://enin.server.highendtradingplc.com/sale_item"),
        axios.get("https://enin.server.highendtradingplc.com/model"),
        axios.get("https://enin.server.highendtradingplc.com/tax"),
        axios.get("https://enin.server.highendtradingplc.com/payment_method"),
        axios.get("https://enin.server.highendtradingplc.com/logical_stock"),
        axios.get("https://enin.server.highendtradingplc.com/request"),
        axios.get("https://enin.server.highendtradingplc.com/reserve"),
        axios.get("https://enin.server.highendtradingplc.com/reserve_item"),
        axios.get("https://enin.server.highendtradingplc.com/queue_order"),
      ]);
      const combineData = sortData([
        ...reserve.data?.filter((r) => r.statusId === 2),
        ...queue.data?.filter((q) => q.statusId === 2),
        ...request.data?.filter((r) => r.statusId === 1 || r.statusId === 3),
      ]);
      setAllData(combineData);

      setCustomers(customer.data);
      setItems(item.data);
      setUnits(unit.data);
      setSales(sale.data);
      setAllDataItems([
        ...requestItem.data,
        ...reserveItem.data,
        ...queue.data,
      ]);
      setUsers(user.data?.filter((user) => user.roleId >= 2));
      setSaleItems(saleItem.data);
      setModels(model.data);
      setTaxs(tax.data);
      setPaymentMethods(paymentMethod.data);
      setLogicals(logical.data);
      const data = sale.data.filter(
        (s) =>
          s.statusId === 1 ||
          s.statusId === 10 ||
          s.statusId === 9 ||
          s.statusId === 8
      );
      const saleList = sale.data.filter(
        (s) => s.statusId > 1 && s.statusId < 5
      );
      setNewSales(parseInt(id) === 1 ? data : saleList);
    } catch (error) {
      console.log(error);
    }
  };
  // const handleOpenNewSale = () => {
  //   const sale = sales.filter((s) => s.statusId === 1);
  //   setNewSales(sale);
  //   setOpenSales(0);
  // };
  // const handleOpenNewSaleList = () => {
  //   const sale = sales.filter((s) => s.statusId > 1 && s.statusId < 5);
  //   setNewSales(sale);
  //   setOpenSales(0);
  // };
  useEffect(() => {
    fetchAllData();
  }, []);
  const itemData = {
    options: items,
    getOptionLabel: (option) => option.description,
  };
  const customerData = {
    options: customers,
    getOptionLabel: (option) => option.fullName,
  };
  const paymentData = {
    options: paymentMethods,
    getOptionLabel: (option) => option.name,
  };

  const handleOpenRequestItem = (row) => {
    if (row.statusId === 3 && parseInt(id) === 2) return;
    if (parseInt(id) === 1) {
      const requestItem = allDataItems.map((r) => {
        if (
          r?.requestId === row.requestId ||
          r?.reserveId === row.requestId ||
          r?.Id === row.requestId
        ) {
          const item = items.find((i) => i.Id === r.itemId);
          const tax = taxs.find((t) => t.Id === item.taxId);
          price.tax += parseFloat(
            ((r.totalPrice - r.discount) * tax.taxValue) / 100
          );

          price.total += r.totalPrice - r.discount;
          if (item.statusId === 1 && parseFloat(r.unitPrice) >= 3000) {
            price.withHold += parseFloat(0.02 * r.totalPrice);
          }
          if (item.statusId === 2 && parseFloat(r.unitPrice) >= 10000) {
            price.withHold += parseFloat(0.02 * r.totalPrice);
          }
          return {
            itemId: r?.itemId,
            quantity: r?.quantity,
            price: r?.unitPrice,
            totalPrice: r?.totalPrice,
            discount: r?.discount,
            remark: "",
            statusId: item.statusId,
            taxId: item.taxId,
            unitId: item.unitId,
          };
        }
      });
      price.grandTotal = price.tax + price.withHold + price.total;
      setNewRequests(row);
      setItemValue(requestItem.filter((r) => !!r));
      const cust = customers.find((c) => c.Id === row.customerId);
      setCustomerValue(cust);
      setCustomerCheck(5);
      formik.values.paidPrice = row.paidPrice;
      formik.values.customerId = row.customerId;
      setOpenSales(1);
    } else {
      const requestItem = saleItems.map((r) => {
        if (r.saleId === row.Id) {
          const item = items.find((i) => i.Id === r.itemId);
          const tax = taxs.find((t) => t.Id === item.taxId);
          price.tax += parseFloat(
            ((r.totalPrice - r.discount) * tax.taxValue) / 100
          );

          price.total += r.totalPrice - r.discount;
          if (item.statusId === 1 && parseFloat(r.unitPrice) >= 3000) {
            price.withHold += parseFloat(0.02 * r.totalPrice);
          }
          if (item.statusId === 2 && parseFloat(r.unitPrice) >= 10000) {
            price.withHold += parseFloat(0.02 * r.totalPrice);
          }
          return {
            itemId: r.itemId,
            quantity: r.quantity,
            price: r.unitPrice,
            totalPrice: r.totalPrice,
            discount: r.discount,
            remark: r.remark,
            statusId: item.statusId,
            taxId: item.taxId,
            unitId: item.unitId,
          };
        }
      });
      price.grandTotal = price.tax + price.withHold + price.total;
      setNewRequests(row);
      setItemValue(requestItem.filter((r) => !!r));
      formik.values.reciptNumber = row.reciptNumber;
      formik.values.fsNumber = row.fsNumber;
      formik.values.acNumber = row.acNumber;
      formik.values.paymentMethodId = row.paymentMethodId;
      formik.values.date = row.date;
      formik.values.Id = row.Id;
      const cust = customers.find((c) => c.Id === row.customerId);
      const hexCharacterManipulator = new HexCharacterManipulator();
      let charTohexa = hexCharacterManipulator.charTohexa(
        [price.total, price.tax, price.withHold],
        price.grandTotal
      );
      const data = `
                    Sale ID : ${charTohexa},
                    Customer Name : ${cust.fullName},
                    Customer Phone : ${cust.primaryPhoneNumber},
                    Grand Price : ${price.grandTotal}`;
      generateQRCode(data);
      setCustomerValue(cust);
      setOpenSales(2);
    }
  };

  const formik = useFormik({
    initialValues: {
      date: "",
      reciptNumber: "",
      fsNumber: "",
      acNumber: "",
      remark: "",
      refNumber: "",
      paymentMethodId: "",
      transferidId: "",
      totalPrice: "",
      paidPrice: "",
      Id: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      if (itemValue.length === 0) return;
      if (isSubmitted) return;
      setIsSubmitted(true);
      if (values.totalPrice + values.paidPrice !== price.grandTotal) {
        setIsSubmitted(false);
        return;
      }
      // Get the current time
      const currentTime = new Date();
      const hours = currentTime.getHours();
      const minutes = currentTime.getMinutes();
      const seconds = currentTime.getSeconds();

      // Append the current time to the date value
      values.date = `${values.date} ${hours}:${minutes}:${seconds}`;

      const customer = customers.find((c) => c.Id === values.customerId);
      const hexCharacterManipulator = new HexCharacterManipulator();
      let charTohexa = hexCharacterManipulator.charTohexa(
        [price.total, price.tax, price.withHold],
        price.grandTotal
      );
      const data = `
                    Sale ID : ${charTohexa},
                    Customer Name : ${customer.fullName},
                    Customer Phone : ${customer.primaryPhoneNumber},
                    Grand Price : ${price.grandTotal}`;
      generateQRCode(data);
      setOpenSales(2);
      try {
        await axios
          .post("https://enin.server.highendtradingplc.com/sale/add", {
            requestId: newRequests.requestId,
            customerId: values.customerId,
            totalPrice: price.grandTotal,
            date: values.date,
            reciptNumber: values.reciptNumber,
            fsNumber: values.fsNumber,
            acNumber: values.acNumber,
            refNumber: values.reciptNumber,
            statusId: 2,
            remark: values.remark,
            paymentMethodId: values.paymentMethodId,
            userId: userData.Id,
            transferidId: values.transferidId,
          })
          .then(async (res) => {
            if (newRequests.requestId.includes("QUE")) {
              await axios.put(
                "https://enin.server.highendtradingplc.com/queue_order/update/" +
                  newRequests.requestId,
                {
                  statusId: 3,
                }
              );
            } else if (newRequests.requestId.includes("RES")) {
              await axios.put(
                "https://enin.server.highendtradingplc.com/reserve/update/" +
                  newRequests.requestId,
                {
                  statusId: 3,
                }
              );
            } else if (newRequests.requestId.includes("REQ")) {
              await axios.put(
                "https://enin.server.highendtradingplc.com/request/update/" +
                  newRequests.requestId,
                {
                  statusId: 2,
                }
              );
            } else {
              await axios.put(
                "https://enin.server.highendtradingplc.com/request/update/" +
                  newRequests.requestId,
                {
                  statusId: 4,
                }
              );
            }
            for (let i = 0; i <= itemValue.length - 1; ) {
              let newItem = items.find((ii) => ii.Id === itemValue[i].itemId);
              if (newItem.statusId === 2) {
                await axios
                  .post(
                    "https://enin.server.highendtradingplc.com/transaction/add",
                    {
                      quantity: parseInt(itemValue[i].quantity),
                      statusId: 2,
                      date: values.date,
                    }
                  )
                  .then(async (res) => {
                    const logical = logicals.find(
                      (l) => l.itemId === itemValue[i].itemId
                    );
                    await axios.post(
                      "https://enin.server.highendtradingplc.com/logical_stock/add",
                      {
                        quantity:
                          parseInt(logical?.quantity || 0) -
                          parseInt(itemValue[i].quantity),
                        itemId: itemValue[i].itemId,
                        date: values.date,
                        statusId: 2,
                        transactionId: res.data.data.Id,
                        ADPRS_ID: newRequests.Id,
                      }
                    );
                  });
              }
              await axios.post(
                "https://enin.server.highendtradingplc.com/sale_item/add",
                {
                  itemId: itemValue[i].itemId,
                  saleId: res.data.data.Id,
                  unitPrice: itemValue[i].price,
                  quantity: itemValue[i].quantity,
                  totalPrice: itemValue[i].totalPrice,
                  remainingQuantity: itemValue[i].quantity,
                  delivered: false,
                  discount: itemValue[i].discount,
                  remark: itemValue[i].remark,
                }
              );
              i = i + 1;
            }
          });
        fetchAllData();
      } catch (error) {
        console.error(error);
      }
      setIsSubmitted(false);
    },
  });
  const clearData = () => {
    price.total = 0;
    price.withHold = 0;
    price.grandTotal = 0;
    price.tax = 0;
    setItemValue([]);
  };

  const handleBack = () => {
    formik.values.fsNumber = "";
    formik.values.acNumber = "";
    formik.values.refNumber = "";
    formik.values.paidPrice = "";
    formik.values.customerId = "";
    formik.values.paymentMethodId = "";
    formik.values.transferidId = "";
    formik.values.totalPrice = "";
    formik.values.date = "";
    formik.values.reciptNumber = "";
    formik.values.remark = "";
    formik.values.Id = "";
    setValid([0, 0, 0]);
    setNewRequests([]);
    setPaymentValue(null);
    setItemValue([]);
    setCustomerValue(null);
    setCustomerCheck(null);
    setPrice({
      total: 0,
      discount: 0,
      withHold: 0,
      grandTotal: 0,
      tax: 0,
    });
    setOpenSales(0);
  };

  const generateQRCode = (text) => {
    QRCode.toDataURL(text)
      .then((url) => {
        setQrCodeUrl(url); // Set the QR code image URL
      })
      .catch((err) => console.error("Error generating QR code:", err));
  };
  const handleValidity = (i) => {
    valids[i] = 1;
    setValid([...valids]);
  };
  const columns = [
    {
      field: "id",
      headerName: "No",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },
    parseInt(id) === 1
      ? {
          field: "requestId",
          headerName: "Request Id",
          headerAlign: "center",
          align: "center",
          flex: 0.7,
          headerClassName: "super-app-theme--header",
        }
      : {
          field: "Id",
          headerName: "Sales Id",
          headerAlign: "center",
          align: "center",
          flex: 0.7,
          headerClassName: "super-app-theme--header",
        },

    {
      field: "fullName",
      headerName: "Customer Name",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "phoneNumber",
      headerName: "Phone No",
      headerAlign: "center",
      align: "center",
      flex: 0.8,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "userId",
      headerName: "Customer of",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        users.find((user) => user.Id === params)?.fullName || "",
    },

    {
      field: "remark",
      headerName: "Remark",
      headerAlign: "center",
      align: "center",
      flex: 0.8,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      flex: 0.75,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 150,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <>
          <Button
            onClick={(e) => handleOpenRequestItem(params.row)}
            sx={{
              width: "90px",
              borderColor: "primary.main",
              fontSize: {
                xs: "0.85rem",
                sm: "0.85rem",
                md: "1rem",
              },
              padding: {
                xs: "4px",
                sm: "5px",
                md: "6px",
                lg: "6px",
              },
              "&:hover": {
                backgroundColor: "primary.main",
                color: "white",
              },
            }}
            variant="outlined"
            color="primary"
            className="m-1"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{
                    float: "right",
                    marginRight: "-1px",
                    marginTop: "2px",
                    marginBottom: "-4px",
                  }}
                  icon={faEye}
                />
              </div>
              <div className="col-6">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-10px",
                    marginTop: "-1px",
                    marginBottom: "-7px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "0.85rem",
                        sm: "0.85rem",
                        md: "1rem",
                      },
                    }}
                  >
                    View
                  </Typography>
                </h6>
              </div>
            </div>
          </Button>
        </>
      ),
    },
  ];
  const allDataRows = allData?.map((data, index) => {
    const cust = customers.find((c) => c.Id === data.customerId);
    let requestType = 0;
    if (data?.untilDate && data.statusId === 2) {
      requestType = 4;
    } else if (data?.untilDate && data.statusId === 3) {
      requestType = 3;
    } else if (data.statusId === 2) {
      requestType = 2;
    } else if (data.statusId === 1) {
      requestType = 1;
    }
    return {
      id: index + 1,
      Id: index + 1,
      requestId: data.Id,
      fullName: cust.fullName,
      phoneNumber: cust.primaryPhoneNumber,
      userId: cust.userId,
      remark: data?.remark || "",
      paidPrice: data?.firstPayment || 0,
      customerId: data.customerId,
      status:
        requestType === 1
          ? "New Request"
          : requestType === 2
          ? "New Queue Order"
          : requestType === 3
          ? "New Quotation"
          : "New Reserve",
    };
  });
  const rows = newSales?.map((sale, index) => {
    const cust = customers.find((c) => c.Id === sale.customerId);
    return {
      id: index + 1,
      Id: sale.Id,
      reciptNumber: sale.reciptNumber,
      fsNumber: sale.fsNumber,
      acNumber: sale.acNumber,
      refNumber: sale.refNumber,
      fullName: cust.fullName,
      phoneNumber: cust.primaryPhoneNumber,
      customerId: sale.customerId,
      paymentMethodId: sale.paymentMethodId,
      userId: cust.userId,
      date: sale.date,
      requestId: sale.requestId,
      remark: sale.remark,
      statusId: sale.statusId,
      paidPrice: sale.totalPrice || 0,
      status: sale.statusId === 3 ? "Rejected" : "Delivery",
    };
  });
  const style = {
    backgroundColor: "white",
    borderRadius: "5px 5px 0 0",
    border: "solid 0.05em rgb(181, 181, 181)",
  };
  const handelInvoice = () => {
    let data = [];
    for (let index = itemValue.length; index < 5; index++) {
      data.push(
        <tr key={index}>
          <td>{index + 1}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      );
    }
    return data;
  };
  return (
    <ThemeProvider theme={theme}>
      <Typography
        sx={{
          fontSize: {
            xs: "12px",
            sm: "12px", //500
            md: "0.85rem", //768
            lg: "17px", //1024
          },
        }}
      >
        <div>
          <Header />
        </div>
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: "13px",
            sm: "14px", //500
            md: "16px", //768
            lg: "18px", //1024
          },
        }}
      >
        <div>
          {" "}
          <main
            className=""
            style={
              isMobile && !isUnderMobile
                ? { marginTop: "190px" }
                : isUnderMobile
                ? { marginTop: "170px" }
                : isTablet
                ? { marginTop: "133px" }
                : { marginTop: "150px" }
            }
          >
            <center>
              <div
                style={
                  isUnderMobile
                    ? {
                        minWidth: "500px",
                        overflow: "scroll",
                        marginBottom: "-5px",
                      }
                    : { maxWidth: "1100px" }
                }
                className="text-left"
              >
                <div
                  className="tab-container "
                  style={
                    isUnderMobile
                      ? { marginTop: "0", marginBottom: "-5px" }
                      : {
                          marginTop: "-20px",
                          marginBottom: "-5px",
                        }
                  }
                >
                  <Link
                    className="p-2"
                    onClick={(e) => {
                      if (parseInt(id) === 1) window.location.reload();
                      else window.location.replace(`/sales/1`);
                    }}
                    style={parseInt(id) === 1 ? style : {}}
                  >
                    Request list
                  </Link>
                  <Link
                    className="p-2"
                    style={parseInt(id) === 2 ? style : {}}
                    onClick={(e) => {
                      if (parseInt(id) === 2) window.location.reload();
                      else window.location.replace(`/sales/2`);
                    }}
                  >
                    Sales List
                  </Link>
                </div>
                <Snackbar
                  open={openMessage}
                  onClose={(e) => setOpenMessage(false)}
                  autoHideDuration={6000}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  <Alert
                    variant="outlined"
                    severity="error"
                    onClose={(e) => setOpenMessage(false)}
                    sx={{ bgcolor: "background.paper" }}
                  >
                    {messages}
                  </Alert>
                </Snackbar>
                <div className="card card-info card-outline">
                  {openSales === 1 && (
                    <div>
                      <div className="card-header">
                        <span>
                          <h4 style={{ float: "left" }}>
                            {" "}
                            <Typography
                              sx={{
                                fontSize: {
                                  xs: "1.3rem",
                                  sm: "1.3rem",
                                  md: "1.5rem",
                                },
                              }}
                            >
                              New Sales Entry
                            </Typography>
                          </h4>
                          <Button
                            onClick={handleBack}
                            sx={{
                              width: "100px",
                              borderColor: "success.main",
                              fontSize: {
                                xs: "0.85rem",
                                sm: "0.85rem",
                                md: "1rem",
                              },
                              padding: {
                                xs: "4px",
                                sm: "5px",
                                md: "6px",
                                lg: "6px",
                              },
                              "&:hover": {
                                backgroundColor: "success.main",
                                color: "white",
                              },
                            }}
                            variant="outlined"
                            color="success"
                            type="submit"
                            className="m-1"
                            style={{ float: "right" }}
                          >
                            <div
                              className="row"
                              style={{
                                marginBottom: "-10px",
                                marginLeft: "-10px",
                              }}
                            >
                              <div className="col">
                                <FontAwesomeIcon
                                  style={{
                                    float: "right",
                                    marginRight: "-1px",
                                    marginTop: "2px",
                                    marginBottom: "-4px",
                                  }}
                                  icon={faBackwardStep}
                                />
                              </div>
                              <div className="col-7">
                                <h6
                                  style={{
                                    float: "left",
                                    marginLeft: "-10px",
                                    marginTop: "-1px",
                                    marginBottom: "-7px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: {
                                        xs: "0.85rem",
                                        sm: "0.85rem",
                                        md: "1rem",
                                      },
                                    }}
                                  >
                                    Back
                                  </Typography>
                                </h6>
                              </div>
                            </div>
                          </Button>
                        </span>
                      </div>

                      <div className="card-body" style={{ overflow: "hidden" }}>
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "0.85rem",
                              sm: "0.85rem",
                              md: "1rem",
                            },
                          }}
                        >
                          <div
                            className="nav-bra pl-3 ml-1 p-2 "
                            style={{
                              backgroundColor: "rgb(235, 235, 235)",
                            }}
                          >
                            <span>Sales / Create</span>
                          </div>
                        </Typography>
                        <hr />
                        <div>
                          <div>
                            <Form className="">
                              <Form.Group
                                as={Row}
                                controlId="formHorizontalUsername"
                              >
                                <Col lg={4} className="mb-3">
                                  <Autocomplete
                                    {...customerData}
                                    id="controlled-demo"
                                    size="small"
                                    variant="outlined"
                                    readOnly
                                    name="customerId"
                                    onBlur={(e) => handleValidity(0)}
                                    value={customerValue}
                                    onChange={(event, newValue) => {
                                      if (!newValue?.Id) {
                                        setCustomerCheck(null);
                                        return;
                                      }
                                      setCustomerValue(newValue);
                                      formik.values.customerId = newValue?.Id;
                                      setCustomerCheck(5);
                                    }}
                                    sx={{
                                      "& .MuiInputBase-root": {
                                        fontSize: {
                                          xs: "0.7rem",
                                          sm: "0.85rem",
                                          md: "1rem",
                                        },
                                        padding: {
                                          xs: "1px",
                                          sm: "2px",
                                          md: "3px",
                                          lg: "4px",
                                        },
                                      },
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        sx={{ width: "250px" }}
                                        error={
                                          valids[0] === 1 &&
                                          customerCheck === null
                                        }
                                        label={
                                          <div style={{ float: "left" }}>
                                            <FontAwesomeIcon
                                              icon={faUser}
                                              style={{
                                                float: "left",
                                                marginRight: "10px",
                                              }}
                                            />
                                            <span>Customer</span>
                                          </div>
                                        }
                                      />
                                    )}
                                  />
                                </Col>
                                <Col lg={4} className="mb-3">
                                  <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.date}
                                    type="date"
                                    label={
                                      <div
                                        style={{ float: "left", width: "70px" }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faCalendar}
                                          size="1x"
                                          style={{
                                            float: "left",
                                            marginTop: "5px",
                                            marginRight: "5px",
                                          }}
                                        />
                                        <h4>Date</h4>
                                      </div>
                                    }
                                    size="small"
                                    name="date"
                                    error={
                                      formik.touched.date && formik.errors.date
                                    }
                                    helperText={
                                      formik.touched.date && formik.errors.date
                                        ? `${formik.errors.date}`
                                        : ""
                                    }
                                    sx={{
                                      width: "250px",
                                      "& .MuiInputBase-root": {
                                        fontSize: {
                                          xs: "0.7rem",
                                          sm: "0.85rem",
                                          md: "0.9rem",
                                        },
                                        padding: {
                                          xs: "1px",
                                          sm: "2px",
                                          md: "3px",
                                          lg: "4px",
                                        },
                                      },
                                    }}
                                  />
                                </Col>
                                <Col lg={4} className="mb-3">
                                  <TextField
                                    id="outlined-basic"
                                    label={
                                      <div style={{ float: "left" }}>
                                        <ReceiptOutlined />

                                        <span> Recipt Number</span>
                                      </div>
                                    }
                                    variant="outlined"
                                    type="text"
                                    size="small"
                                    name="reciptNumber"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.reciptNumber}
                                    error={
                                      formik.touched.reciptNumber &&
                                      formik.errors.reciptNumber
                                    }
                                    helperText={
                                      formik.touched.reciptNumber &&
                                      formik.errors.reciptNumber
                                        ? `${formik.errors.reciptNumber}`
                                        : ""
                                    }
                                    sx={{
                                      width: "250px",
                                      "& .MuiInputBase-root": {
                                        fontSize: {
                                          xs: "0.7rem",
                                          sm: "0.85rem",
                                          md: "0.9rem",
                                        },
                                        padding: {
                                          xs: "1px",
                                          sm: "2px",
                                          md: "3px",
                                          lg: "4px",
                                        },
                                      },
                                    }}
                                  />
                                </Col>
                              </Form.Group>
                              <Form.Group
                                as={Row}
                                controlId="formHorizontalUsername"
                              >
                                <Col lg={4} className="mb-3">
                                  <TextField
                                    id="outlined-basic"
                                    label={
                                      <div style={{ float: "left" }}>
                                        <PlaylistAddCheckCircleOutlined
                                          style={{
                                            float: "left",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span>Remark</span>
                                      </div>
                                    }
                                    variant="outlined"
                                    type="text"
                                    size="small"
                                    name="remark"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.remark}
                                    error={
                                      formik.touched.remark &&
                                      formik.errors.remark
                                    }
                                    helperText={
                                      formik.touched.remark &&
                                      formik.errors.remark
                                        ? `${formik.errors.remark}`
                                        : ""
                                    }
                                    sx={{
                                      width: "250px",
                                      "& .MuiInputBase-root": {
                                        fontSize: {
                                          xs: "0.7rem",
                                          sm: "0.85rem",
                                          md: "0.9rem",
                                        },
                                        padding: {
                                          xs: "1px",
                                          sm: "2px",
                                          md: "3px",
                                          lg: "4px",
                                        },
                                      },
                                    }}
                                  />
                                </Col>
                                <Col lg={4} className="mb-3">
                                  <TextField
                                    id="outlined-basic"
                                    label={
                                      <div style={{ float: "left" }}>
                                        <FontAwesomeIcon
                                          icon={faCode}
                                          style={{
                                            float: "left",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span> Fs Number</span>
                                      </div>
                                    }
                                    variant="outlined"
                                    type="text"
                                    size="small"
                                    name="fsNumber"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.fsNumber}
                                    error={
                                      formik.touched.fsNumber &&
                                      formik.errors.fsNumber
                                    }
                                    helperText={
                                      formik.touched.fsNumber &&
                                      formik.errors.fsNumber
                                        ? `${formik.errors.fsNumber}`
                                        : ""
                                    }
                                    sx={{
                                      width: "250px",
                                      "& .MuiInputBase-root": {
                                        fontSize: {
                                          xs: "0.7rem",
                                          sm: "0.85rem",
                                          md: "0.9rem",
                                        },
                                        padding: {
                                          xs: "1px",
                                          sm: "2px",
                                          md: "3px",
                                          lg: "4px",
                                        },
                                      },
                                    }}
                                  />
                                </Col>
                                <Col lg={4} className="mb-3">
                                  <TextField
                                    id="outlined-basic"
                                    label={
                                      <div style={{ float: "left" }}>
                                        <FontAwesomeIcon
                                          icon={faCode}
                                          style={{
                                            float: "left",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span> MRC Number</span>
                                      </div>
                                    }
                                    variant="outlined"
                                    type="text"
                                    size="small"
                                    name="acNumber"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.acNumber}
                                    error={
                                      formik.touched.acNumber &&
                                      formik.errors.acNumber
                                    }
                                    helperText={
                                      formik.touched.acNumber &&
                                      formik.errors.acNumber
                                        ? `${formik.errors.acNumber}`
                                        : ""
                                    }
                                    sx={{
                                      width: "250px",
                                      "& .MuiInputBase-root": {
                                        fontSize: {
                                          xs: "0.7rem",
                                          sm: "0.85rem",
                                          md: "0.9rem",
                                        },
                                        padding: {
                                          xs: "1px",
                                          sm: "2px",
                                          md: "3px",
                                          lg: "4px",
                                        },
                                      },
                                    }}
                                  />
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row}>
                                <Col lg={4} className="mb-3">
                                  <TextField
                                    id="outlined-basic"
                                    label={
                                      <div style={{ float: "left" }}>
                                        <FontAwesomeIcon
                                          icon={faCode}
                                          style={{
                                            float: "left",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span> REF Number</span>
                                      </div>
                                    }
                                    variant="outlined"
                                    type="text"
                                    size="small"
                                    name="refNumber"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.refNumber}
                                    error={
                                      formik.touched.refNumber &&
                                      formik.errors.refNumber
                                    }
                                    helperText={
                                      formik.touched.refNumber &&
                                      formik.errors.refNumber
                                        ? `${formik.errors.refNumber}`
                                        : ""
                                    }
                                    sx={{
                                      width: "250px",
                                      "& .MuiInputBase-root": {
                                        fontSize: {
                                          xs: "0.7rem",
                                          sm: "0.85rem",
                                          md: "0.9rem",
                                        },
                                        padding: {
                                          xs: "1px",
                                          sm: "2px",
                                          md: "3px",
                                          lg: "4px",
                                        },
                                      },
                                    }}
                                  />
                                </Col>
                              </Form.Group>
                            </Form>
                          </div>
                          <Formik
                            initialValues={{
                              itemId: "",
                              quantity: "",
                              price: "",
                              discount: "",
                              taxId: "",
                              withHold: "",
                              unitId: "",
                              statusId: "",
                              remark: "",
                            }}
                            validationSchema={Yup.object().shape({
                              quantity: Yup.number()
                                .required("Quantity is required")
                                .min(1, "Too Low!")
                                .max(10000, "Too Hihg"),
                              price: Yup.number()
                                .required("Price is required")
                                .min(1, "Too Low!")
                                .max(1000000, "Too Hihg"),
                            })}
                            onSubmit={async (values) => {
                              try {
                                if (itemCheck === null) {
                                  return;
                                }
                                const checkItem = itemValue?.find(
                                  (i) => i?.itemId === itemId.Id
                                );

                                if (!!checkItem) {
                                  setMessages("You use the same item!");
                                  setOpenMessage(true);
                                  return;
                                }
                                const item = items.find(
                                  (i) => i.Id === values.itemId
                                );
                                if (
                                  parseInt(
                                    logicals.find((l) => l.itemId === item.Id)
                                      ?.quantity
                                  ) < values.quantity
                                ) {
                                  setMessages(
                                    `Only available quantity is ${
                                      logicals.find((l) => l.itemId === item.Id)
                                        ?.quantity
                                    }`
                                  );
                                  setOpenMessage(true);
                                  return;
                                }

                                values.totalPrice =
                                  values.quantity * values.price;
                                let tax = taxs.find(
                                  (tt) => tt.Id === itemId.taxId
                                );
                                price.tax += parseFloat(
                                  ((values.totalPrice -
                                    parseFloat(discountValue)) *
                                    tax.taxValue) /
                                    100
                                );
                                price.total +=
                                  values.totalPrice - parseFloat(discountValue);

                                if (
                                  item.statusId === 1 &&
                                  parseFloat(values.price) >= 3000
                                ) {
                                  price.withHold += parseFloat(
                                    0.02 * values.totalPrice
                                  );
                                }
                                if (
                                  item.statusId === 2 &&
                                  parseFloat(values.price) >= 10000
                                ) {
                                  price.withHold += parseFloat(
                                    0.02 * values.totalPrice
                                  );
                                }
                                setItemValue([
                                  ...itemValue,
                                  {
                                    unitId: item.unitId,
                                    itemId: values.itemId,
                                    taxId: item.taxId,
                                    quantity: values.quantity,
                                    statusId: item.statusId,
                                    remark: values.remark,
                                    price: values.price,
                                    totalPrice: values.price * values.quantity,
                                    discount: discountValue,
                                  },
                                ]);
                                price.grandTotal =
                                  price.total + price.tax + price.withHold;
                                setItemId(null);
                              } catch (error) {
                                console.log(error);
                              } finally {
                                values.quantity = "";
                                values.unitId = "";
                                values.price = "";
                                values.untilDate = "";
                                values.remark = "";
                                setDiscountValue(0);
                              }
                            }}
                          >
                            {(props) => (
                              <div className="">
                                <hr />
                                <h3>Sales Item</h3>
                                <hr />
                                <Form onSubmit={props.handleSubmit}>
                                  <Form.Group
                                    as={Row}
                                    controlId="formHorizontalUsername"
                                  >
                                    <Col lg={4} className="mb-3">
                                      <Autocomplete
                                        {...itemData}
                                        id="controlled-demo"
                                        size="small"
                                        name="itemId"
                                        value={itemId}
                                        onBlur={(e) => handleValidity(1)}
                                        onChange={(event, newValue) => {
                                          if (!newValue?.Id) {
                                            setItemCheck(null);
                                            setItemId(null);
                                            props.values.itemId = "";
                                            return;
                                          }
                                          setItemId(newValue);
                                          props.values.itemId = newValue?.Id;
                                          setItemCheck(5);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            sx={{
                                              width: "250px",
                                              "& .MuiInputBase-root": {
                                                fontSize: {
                                                  xs: "0.7rem",
                                                  sm: "0.85rem",
                                                  md: "0.9rem",
                                                },
                                                padding: {
                                                  xs: "1px",
                                                  sm: "2px",
                                                  md: "3px",
                                                  lg: "4px",
                                                },
                                              },
                                            }}
                                            label={
                                              <div style={{ float: "left" }}>
                                                <span>Select Item</span>
                                              </div>
                                            }
                                            error={
                                              valids[1] === 1 &&
                                              itemCheck === null
                                            }
                                          />
                                        )}
                                      />
                                    </Col>

                                    <Col lg={4} className="mb-3">
                                      <TextField
                                        id="outlined-basic"
                                        label="Remain Qty"
                                        InputLabelProps={{
                                          shrink: !!itemId,
                                        }}
                                        disabled
                                        variant="outlined"
                                        type="number"
                                        size="small"
                                        value={
                                          !!itemId
                                            ? logicals.find(
                                                (l) =>
                                                  l.itemId ===
                                                  props.values.itemId
                                              )?.quantity
                                            : ""
                                        }
                                        sx={{
                                          width: "250px",
                                          "& .MuiInputBase-root": {
                                            fontSize: {
                                              xs: "0.7rem",
                                              sm: "0.85rem",
                                              md: "0.9rem",
                                            },
                                            padding: {
                                              xs: "1px",
                                              sm: "2px",
                                              md: "3px",
                                              lg: "4px",
                                            },
                                          },
                                        }}
                                      />
                                    </Col>
                                    <Col lg={4} className="mb-3">
                                      <TextField
                                        id="outlined-basic"
                                        label="Qty"
                                        variant="outlined"
                                        type="number"
                                        size="small"
                                        name="quantity"
                                        onChange={props.handleChange}
                                        value={props.values.quantity}
                                        onBlur={props.handleBlur}
                                        error={
                                          props.touched.quantity &&
                                          props.errors.quantity
                                        }
                                        helperText={
                                          props.touched.quantity &&
                                          props.errors.quantity
                                            ? `${props.errors.quantity}`
                                            : ""
                                        }
                                        sx={{
                                          width: "250px",
                                          "& .MuiInputBase-root": {
                                            fontSize: {
                                              xs: "0.7rem",
                                              sm: "0.85rem",
                                              md: "0.9rem",
                                            },
                                            padding: {
                                              xs: "1px",
                                              sm: "2px",
                                              md: "3px",
                                              lg: "4px",
                                            },
                                          },
                                        }}
                                      />
                                    </Col>

                                    <Col lg={4} className="mb-3">
                                      <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        label="Price"
                                        type="text"
                                        size="small"
                                        name="price"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.price}
                                        error={
                                          props.touched.price &&
                                          props.errors.price
                                        }
                                        helperText={
                                          props.touched.price &&
                                          props.errors.price
                                            ? `${props.errors.price}`
                                            : ""
                                        }
                                        sx={{
                                          width: "250px",
                                          "& .MuiInputBase-root": {
                                            fontSize: {
                                              xs: "0.7rem",
                                              sm: "0.85rem",
                                              md: "0.9rem",
                                            },
                                            padding: {
                                              xs: "1px",
                                              sm: "2px",
                                              md: "3px",
                                              lg: "4px",
                                            },
                                          },
                                        }}
                                      />
                                    </Col>

                                    <Col lg={4} className="mb-3">
                                      <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        label="Discount"
                                        type="number"
                                        size="small"
                                        name="discount"
                                        value={
                                          discountValue === null
                                            ? ""
                                            : discountValue
                                        }
                                        onChange={(event) => {
                                          if (event.target.value === "") {
                                            setDiscountValue(0);
                                            return;
                                          }
                                          let count =
                                            (parseFloat(event.target.value) *
                                              100) /
                                            (props.values.quantity *
                                              props.values.price);
                                          console.log(count);
                                          if (count < 0 || count > 20) {
                                            setDiscountValue(0);
                                            return;
                                          }
                                          setDiscountValue(
                                            parseFloat(event.target.value)
                                          );
                                        }}
                                        onBlur={props.handleBlur}
                                        error={
                                          props.touched.discount &&
                                          props.errors.discount
                                        }
                                        helperText={
                                          props.touched.discount &&
                                          props.errors.discount
                                            ? `${props.errors.discount}`
                                            : ""
                                        }
                                        sx={{
                                          width: "250px",
                                          "& .MuiInputBase-root": {
                                            fontSize: {
                                              xs: "0.7rem",
                                              sm: "0.85rem",
                                              md: "0.9rem",
                                            },
                                            padding: {
                                              xs: "1px",
                                              sm: "2px",
                                              md: "3px",
                                              lg: "4px",
                                            },
                                          },
                                        }}
                                      />
                                    </Col>
                                    <Col lg={4} className="mb-3">
                                      <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        label="Remark"
                                        type="text"
                                        size="small"
                                        name="remark"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.remark}
                                        error={
                                          props.touched.remark &&
                                          props.errors.remark
                                        }
                                        helperText={
                                          props.touched.remark &&
                                          props.errors.remark
                                            ? `${props.errors.remark}`
                                            : ""
                                        }
                                        sx={{
                                          width: "250px",
                                          "& .MuiInputBase-root": {
                                            fontSize: {
                                              xs: "0.7rem",
                                              sm: "0.85rem",
                                              md: "0.9rem",
                                            },
                                            padding: {
                                              xs: "1px",
                                              sm: "2px",
                                              md: "3px",
                                              lg: "4px",
                                            },
                                          },
                                        }}
                                      />
                                    </Col>
                                    <Col lg={2} className="mb-3">
                                      <Button
                                        sx={{
                                          width: "200px",
                                          borderColor: "primary.main",
                                          fontSize: {
                                            xs: "0.85rem",
                                            sm: "0.85rem",
                                            md: "1rem",
                                          },
                                          padding: {
                                            xs: "4px",
                                            sm: "5px",
                                            md: "6px",
                                            lg: "6px",
                                          },
                                          "&:hover": {
                                            backgroundColor: "primary.main",
                                            color: "white",
                                          },
                                        }}
                                        variant="outlined"
                                        color="primary"
                                        type="submit"
                                      >
                                        <h4
                                          style={{
                                            float: "left",
                                            marginRight: "5px",
                                            marginTop: "-1px",
                                            marginBottom: "-2px",
                                          }}
                                        >
                                          +
                                        </h4>
                                        <strong
                                          style={{
                                            float: "left",
                                            marginTop: "0",
                                            marginBottom: "-2px",
                                          }}
                                        >
                                          Add to list
                                        </strong>
                                      </Button>
                                    </Col>
                                  </Form.Group>
                                </Form>
                                <div style={{ overflow: "scroll" }}>
                                  <div style={{ minWidth: "1000px" }}>
                                    <table className="summary-table ">
                                      <thead className="bg-info">
                                        <tr>
                                          <th>Id</th>
                                          <th>Code</th>
                                          <th>Desc</th>
                                          <th>Model</th>
                                          <th>Unit</th>
                                          <th>U.Price</th> <th>Qty</th>
                                          <th>Discount</th>
                                          <th>T.Price</th>
                                          <th>Remark</th>
                                          <th style={{ minWidth: "240px" }}>
                                            Action
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {itemValue.map((request, index) => (
                                          <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                              {items.map((item) =>
                                                item.Id ===
                                                parseInt(request.itemId)
                                                  ? item.code
                                                  : ""
                                              )}
                                            </td>
                                            <td>
                                              {items.map((item) =>
                                                item.Id ===
                                                parseInt(request.itemId)
                                                  ? item.description
                                                  : ""
                                              )}
                                            </td>
                                            <td>
                                              {items.map((item) =>
                                                item.Id ===
                                                parseInt(request.itemId)
                                                  ? models.find(
                                                      (model) =>
                                                        model.Id ===
                                                        item.modelId
                                                    )?.modelName
                                                  : ""
                                              )}
                                            </td>
                                            <td>
                                              {
                                                units.find(
                                                  (unit) =>
                                                    unit.Id === request.unitId
                                                )?.unitName
                                              }
                                            </td>
                                            <td>
                                              {openUpdate &&
                                              request.itemId ===
                                                update.itemId ? (
                                                <input
                                                  type="number"
                                                  minLength={1}
                                                  maxLength={20}
                                                  className="form-control"
                                                  required
                                                  style={{ width: "120px" }}
                                                  value={
                                                    update.price === ""
                                                      ? request.price
                                                      : update.price
                                                  }
                                                  onChange={(e) => {
                                                    if (
                                                      parseInt(e.target.value) <
                                                        1 ||
                                                      parseInt(e.target.value) >
                                                        1000000
                                                    ) {
                                                      setUpdate((prev) => ({
                                                        ...prev,
                                                        price: 1,
                                                      }));
                                                    } else {
                                                      setUpdate((prev) => ({
                                                        ...prev,
                                                        price: parseInt(
                                                          e.target.value
                                                        ),
                                                      }));
                                                    }
                                                  }}
                                                />
                                              ) : (
                                                request.price
                                              )}
                                            </td>{" "}
                                            <td>
                                              {openUpdate &&
                                              request.itemId ===
                                                update.itemId ? (
                                                <input
                                                  type="number"
                                                  minLength={1}
                                                  maxLength={1000}
                                                  className="form-control"
                                                  required
                                                  style={{ width: "100px" }}
                                                  value={
                                                    update.quantity === ""
                                                      ? request.quantity
                                                      : update.quantity
                                                  }
                                                  onChange={(e) => {
                                                    if (
                                                      parseInt(e.target.value) <
                                                        1 ||
                                                      parseInt(e.target.value) >
                                                        10000
                                                    ) {
                                                      setUpdate((prev) => ({
                                                        ...prev,
                                                        quantity: 1,
                                                      }));
                                                    } else {
                                                      setUpdate((prev) => ({
                                                        ...prev,
                                                        quantity: parseInt(
                                                          e.target.value
                                                        ),
                                                      }));
                                                    }
                                                  }}
                                                />
                                              ) : (
                                                request.quantity
                                              )}
                                            </td>
                                            <td>
                                              {openUpdate &&
                                              request.itemId ===
                                                update.itemId ? (
                                                <input
                                                  type="number"
                                                  minLength={1}
                                                  maxLength={20}
                                                  className="form-control"
                                                  required
                                                  style={{ width: "100px" }}
                                                  value={
                                                    update.discount === ""
                                                      ? request.discount
                                                      : update.discount
                                                  }
                                                  onChange={(e) => {
                                                    let count =
                                                      (parseFloat(
                                                        e.target.value
                                                      ) *
                                                        100) /
                                                      (update.quantity *
                                                        update.price);
                                                    if (
                                                      count < 0 ||
                                                      count > 20
                                                    ) {
                                                      setUpdate((prev) => ({
                                                        ...prev,
                                                        discount: 1,
                                                      }));
                                                    } else {
                                                      setUpdate((prev) => ({
                                                        ...prev,
                                                        discount: parseInt(
                                                          e.target.value
                                                        ),
                                                      }));
                                                    }
                                                  }}
                                                />
                                              ) : (
                                                request.discount
                                              )}
                                            </td>
                                            <td>
                                              {openUpdate &&
                                              request.itemId ===
                                                update.itemId ? (
                                                <input
                                                  type="number"
                                                  minLength={1}
                                                  readOnly
                                                  maxLength={20}
                                                  className="form-control"
                                                  required
                                                  style={{ width: "120px" }}
                                                  value={
                                                    update.price === "" &&
                                                    update.quantity === ""
                                                      ? request.totalPrice
                                                      : update.price === ""
                                                      ? request.price *
                                                        update.quantity
                                                      : update.quantity === ""
                                                      ? update.price *
                                                        request.quantity
                                                      : update.price *
                                                        update.quantity
                                                  }
                                                />
                                              ) : (
                                                request.totalPrice
                                              )}
                                            </td>
                                            <td>
                                              {openUpdate &&
                                              request.itemId ===
                                                update.itemId ? (
                                                <input
                                                  type="text"
                                                  minLength={1}
                                                  maxLength={20}
                                                  className="form-control"
                                                  required
                                                  style={{ width: "120px" }}
                                                  value={
                                                    update.remark === ""
                                                      ? request.remark
                                                      : update.remark
                                                  }
                                                  onChange={(e) => {
                                                    setUpdate((prev) => ({
                                                      ...prev,
                                                      remark: e.target.value,
                                                    }));
                                                  }}
                                                />
                                              ) : (
                                                request.remark
                                              )}
                                            </td>
                                            <td>
                                              {(request.itemId !==
                                                update.itemId ||
                                                !openUpdate) && (
                                                <div>
                                                  <Button
                                                    onClick={(e) => {
                                                      setOpenUpdate(true);
                                                      setUpdate({
                                                        itemId: request.itemId,
                                                        quantity:
                                                          request.quantity,
                                                        price: request.price,
                                                        discount:
                                                          request.discount,
                                                        totalPrice:
                                                          request.totalPrice,
                                                        remark: request.remark,
                                                      });
                                                    }}
                                                    sx={{
                                                      width: "90px",
                                                      borderColor:
                                                        "warning.main",
                                                      fontSize: {
                                                        xs: "0.7rem",
                                                        sm: "0.85rem",
                                                        md: "1rem",
                                                      },
                                                      padding: {
                                                        xs: "3px",
                                                        sm: "4px",
                                                        md: "5px",
                                                        lg: "6px",
                                                      },
                                                      "&:hover": {
                                                        backgroundColor:
                                                          "warning.main",
                                                        color: "white",
                                                      },
                                                    }}
                                                    className="mr-3"
                                                    variant="outlined"
                                                    color="warning"
                                                  >
                                                    <div
                                                      className="row"
                                                      style={{
                                                        marginBottom: "-10px",
                                                        marginLeft: "-10px",
                                                      }}
                                                    >
                                                      <div className="col">
                                                        <FontAwesomeIcon
                                                          style={{
                                                            float: "right",
                                                            marginRight: "-1px",
                                                            marginTop: "2px",
                                                            marginBottom:
                                                              "-4px",
                                                          }}
                                                          icon={faPenToSquare}
                                                        />
                                                      </div>
                                                      <div className="col-6">
                                                        <h6
                                                          style={{
                                                            float: "left",
                                                            marginLeft: "-10px",
                                                            marginTop: "-1px",
                                                            marginBottom:
                                                              "-7px",
                                                          }}
                                                        >
                                                          <Typography
                                                            sx={{
                                                              fontSize: {
                                                                xs: "0.85rem",
                                                                sm: "0.85rem",
                                                                md: "1rem",
                                                              },
                                                            }}
                                                          >
                                                            Edit
                                                          </Typography>
                                                        </h6>
                                                      </div>
                                                    </div>
                                                  </Button>
                                                  <Button
                                                    onClick={(e) => {
                                                      const newItem =
                                                        itemValue.find(
                                                          (i) =>
                                                            parseInt(
                                                              i.itemId
                                                            ) ===
                                                            parseInt(
                                                              request.itemId
                                                            )
                                                        );
                                                      const tax = taxs.find(
                                                        (t) =>
                                                          t.Id ===
                                                          newItem?.taxId
                                                      );

                                                      const priceVat =
                                                        parseFloat(
                                                          newItem.totalPrice -
                                                            newItem.discount
                                                        ) *
                                                        parseFloat(
                                                          tax?.taxValue / 100
                                                        );
                                                      const newItemValue =
                                                        itemValue?.filter(
                                                          (i) =>
                                                            parseInt(
                                                              i.itemId
                                                            ) !==
                                                            parseInt(
                                                              request.itemId
                                                            )
                                                        );
                                                      setItemValue(
                                                        newItemValue
                                                      );

                                                      price.total =
                                                        parseFloat(
                                                          price.total
                                                        ) -
                                                        parseFloat(
                                                          newItem.totalPrice -
                                                            newItem.discount
                                                        );
                                                      price.tax =
                                                        parseFloat(price.tax) -
                                                        priceVat;
                                                      price.withHold =
                                                        parseFloat(
                                                          price.withHold
                                                        ) -
                                                        (newItem.statusId ===
                                                          1 &&
                                                        parseFloat(
                                                          newItem.price
                                                        ) >= 3000
                                                          ? parseFloat(
                                                              newItem.price
                                                            ) *
                                                            0.02 *
                                                            parseFloat(
                                                              newItem.quantity
                                                            )
                                                          : newItem.statusId ===
                                                              2 &&
                                                            parseFloat(
                                                              newItem.price
                                                            ) >= 10000
                                                          ? parseFloat(
                                                              newItem.price
                                                            ) *
                                                            0.02 *
                                                            parseFloat(
                                                              newItem.quantity
                                                            )
                                                          : 0);
                                                      price.grandTotal =
                                                        price.total +
                                                        price.tax +
                                                        price.withHold;
                                                    }}
                                                    sx={{
                                                      width: "110px",
                                                      borderColor: "error.main",
                                                      fontSize: {
                                                        xs: "0.7rem",
                                                        sm: "0.85rem",
                                                        md: "1rem",
                                                      },
                                                      padding: {
                                                        xs: "3px",
                                                        sm: "4px",
                                                        md: "5px",
                                                        lg: "6px",
                                                      },
                                                      "&:hover": {
                                                        backgroundColor:
                                                          "error.main",
                                                        color: "white",
                                                      },
                                                    }}
                                                    variant="outlined"
                                                    color="error"
                                                  >
                                                    <div
                                                      className="row"
                                                      style={{
                                                        marginBottom: "-10px",
                                                        marginLeft: "-10px",
                                                      }}
                                                    >
                                                      <div className="col">
                                                        <FontAwesomeIcon
                                                          style={{
                                                            float: "right",
                                                            marginRight: "-1px",
                                                            marginTop: "2px",
                                                            marginBottom:
                                                              "-4px",
                                                          }}
                                                          icon={faTrashCan}
                                                        />
                                                      </div>
                                                      <div className="col-8">
                                                        <h6
                                                          style={{
                                                            float: "left",
                                                            marginLeft: "-10px",
                                                            marginTop: "-1px",
                                                            marginBottom:
                                                              "-7px",
                                                          }}
                                                        >
                                                          <Typography
                                                            sx={{
                                                              fontSize: {
                                                                xs: "0.85rem",
                                                                sm: "0.85rem",
                                                                md: "1rem",
                                                              },
                                                            }}
                                                          >
                                                            Delete
                                                          </Typography>
                                                        </h6>
                                                      </div>
                                                    </div>
                                                  </Button>
                                                </div>
                                              )}

                                              {openUpdate &&
                                                request.itemId ===
                                                  update.itemId && (
                                                  <div>
                                                    <Button
                                                      onClick={(e) => {
                                                        const item = items.find(
                                                          (i) =>
                                                            i.Id ===
                                                            update.itemId
                                                        );
                                                        if (
                                                          parseInt(
                                                            logicals.find(
                                                              (l) =>
                                                                l.itemId ===
                                                                item.Id
                                                            )?.quantity
                                                          ) < update.quantity &&
                                                          item.statusId === 2
                                                        ) {
                                                          setMessages(
                                                            `Only available quantity is ${
                                                              logicals.find(
                                                                (l) =>
                                                                  l.itemId ===
                                                                  item.Id
                                                              )?.quantity
                                                            }`
                                                          );
                                                          setOpenMessage(true);
                                                          return;
                                                        }
                                                        if (
                                                          parseFloat(
                                                            update.discount
                                                          ) >
                                                          parseFloat(
                                                            update.quantity
                                                          ) *
                                                            parseFloat(
                                                              update.price
                                                            ) *
                                                            0.2
                                                        ) {
                                                          setMessages(
                                                            `Invalid Discount`
                                                          );
                                                          setOpenMessage(true);
                                                          return;
                                                        }
                                                        itemValue[
                                                          index
                                                        ].quantity =
                                                          !!update.quantity
                                                            ? parseInt(
                                                                update.quantity
                                                              )
                                                            : parseFloat(
                                                                itemValue[index]
                                                                  .quantity
                                                              );
                                                        itemValue[index].price =
                                                          !!update.price
                                                            ? parseInt(
                                                                update.price
                                                              )
                                                            : parseFloat(
                                                                itemValue[index]
                                                                  .price
                                                              );

                                                        itemValue[
                                                          index
                                                        ].discount =
                                                          !!update.discount
                                                            ? parseFloat(
                                                                update.discount
                                                              )
                                                            : parseFloat(
                                                                itemValue[index]
                                                                  .discount
                                                              );

                                                        itemValue[
                                                          index
                                                        ].totalPrice =
                                                          itemValue[index]
                                                            .quantity *
                                                          itemValue[index]
                                                            .price;
                                                        itemValue[
                                                          index
                                                        ].remark =
                                                          update.remark;
                                                        setItemValue([
                                                          ...itemValue,
                                                        ]);
                                                        update.itemId = "";
                                                        update.price = "";
                                                        update.quantity = "";
                                                        price.total =
                                                          price.withHold =
                                                          price.tax =
                                                            0;

                                                        let service = 0,
                                                          good = 0;
                                                        itemValue.map((iv) => {
                                                          const tax = taxs.find(
                                                            (t) =>
                                                              t.Id === iv.taxId
                                                          );
                                                          if (
                                                            iv.statusId === 1 &&
                                                            parseFloat(
                                                              iv.price
                                                            ) >= 3000
                                                          ) {
                                                            service =
                                                              service +
                                                              parseFloat(
                                                                0.02 *
                                                                  parseFloat(
                                                                    iv.price
                                                                  ) *
                                                                  parseInt(
                                                                    iv.quantity
                                                                  )
                                                              );
                                                          }
                                                          if (
                                                            iv.statusId === 2 &&
                                                            parseFloat(
                                                              iv.price
                                                            ) >= 10000
                                                          ) {
                                                            good =
                                                              good +
                                                              parseFloat(
                                                                0.02 *
                                                                  parseFloat(
                                                                    iv.price
                                                                  ) *
                                                                  parseInt(
                                                                    iv.quantity
                                                                  )
                                                              );
                                                          }
                                                          price.tax =
                                                            parseFloat(
                                                              price.tax
                                                            ) +
                                                            parseFloat(
                                                              iv.totalPrice -
                                                                iv.discount
                                                            ) *
                                                              parseFloat(
                                                                parseInt(
                                                                  tax?.taxValue
                                                                ) / 100
                                                              );
                                                          price.total =
                                                            parseFloat(
                                                              price.total
                                                            ) +
                                                            parseFloat(
                                                              iv.totalPrice -
                                                                iv.discount
                                                            );
                                                        });
                                                        price.withHold =
                                                          good + service;
                                                        price.grandTotal =
                                                          price.total +
                                                          price.tax +
                                                          price.withHold;
                                                        setOpenUpdate(false);
                                                      }}
                                                      sx={{
                                                        width: "90px",
                                                        borderColor:
                                                          "success.main",
                                                        fontSize: {
                                                          xs: "0.7rem",
                                                          sm: "0.85rem",
                                                          md: "1rem",
                                                        },
                                                        padding: {
                                                          xs: "3px",
                                                          sm: "4px",
                                                          md: "5px",
                                                          lg: "6px",
                                                        },
                                                        "&:hover": {
                                                          backgroundColor:
                                                            "success.main",
                                                          color: "white",
                                                        },
                                                      }}
                                                      variant="outlined"
                                                      color="success"
                                                      className="mr-3"
                                                    >
                                                      <div
                                                        className="row"
                                                        style={{
                                                          marginBottom: "-10px",
                                                          marginLeft: "-10px",
                                                        }}
                                                      >
                                                        <div className="col">
                                                          <FontAwesomeIcon
                                                            style={{
                                                              float: "right",
                                                              marginRight:
                                                                "-1px",
                                                              marginTop: "2px",
                                                              marginBottom:
                                                                "-4px",
                                                            }}
                                                            icon={faFloppyDisk}
                                                          />
                                                        </div>
                                                        <div className="col-6">
                                                          <h6
                                                            style={{
                                                              float: "left",
                                                              marginLeft:
                                                                "-10px",
                                                              marginTop: "-1px",
                                                              marginBottom:
                                                                "-7px",
                                                            }}
                                                          >
                                                            <Typography
                                                              sx={{
                                                                fontSize: {
                                                                  xs: "0.85rem",
                                                                  sm: "0.85rem",
                                                                  md: "1rem",
                                                                },
                                                              }}
                                                            >
                                                              Save
                                                            </Typography>
                                                          </h6>
                                                        </div>
                                                      </div>
                                                    </Button>
                                                    <Button
                                                      onClick={(e) => {
                                                        setOpenUpdate(false);
                                                        setUpdate({
                                                          itemId: "",
                                                          price: "",
                                                          quantity: "",
                                                        });
                                                      }}
                                                      sx={{
                                                        width: "100px",
                                                        borderColor:
                                                          "error.main",
                                                        fontSize: {
                                                          xs: "0.7rem",
                                                          sm: "0.85rem",
                                                          md: "1rem",
                                                        },
                                                        padding: {
                                                          xs: "3px",
                                                          sm: "4px",
                                                          md: "5px",
                                                          lg: "6px",
                                                        },
                                                        "&:hover": {
                                                          backgroundColor:
                                                            "error.main",
                                                          color: "white",
                                                        },
                                                      }}
                                                      variant="outlined"
                                                      color="error"
                                                    >
                                                      <div
                                                        className="row"
                                                        style={{
                                                          marginBottom: "-10px",
                                                          marginLeft: "-10px",
                                                        }}
                                                      >
                                                        <div className="col">
                                                          <FontAwesomeIcon
                                                            style={{
                                                              float: "right",
                                                              marginRight:
                                                                "-1px",
                                                              marginTop: "2px",
                                                              marginBottom:
                                                                "-4px",
                                                            }}
                                                            icon={faXmark}
                                                          />
                                                        </div>
                                                        <div className="col-8">
                                                          <h6
                                                            style={{
                                                              float: "left",
                                                              marginLeft:
                                                                "-10px",
                                                              marginTop: "-1px",
                                                              marginBottom:
                                                                "-7px",
                                                            }}
                                                          >
                                                            <Typography
                                                              sx={{
                                                                fontSize: {
                                                                  xs: "0.85rem",
                                                                  sm: "0.85rem",
                                                                  md: "1rem",
                                                                },
                                                              }}
                                                            >
                                                              Cancle
                                                            </Typography>
                                                          </h6>
                                                        </div>
                                                      </div>
                                                    </Button>
                                                  </div>
                                                )}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                    <div className="row">
                                      <div className="col"></div>
                                      <div
                                        className="col mt-1"
                                        style={{
                                          textAlign: "right",
                                          marginBottom: "20px",
                                          float: "right",
                                        }}
                                      >
                                        <Form.Group
                                          as={Row}
                                          controlId="formHorizontalUsername"
                                          style={{ width: "500px" }}
                                        >
                                          <Col>
                                            <Form.Label>Total : </Form.Label>
                                          </Col>
                                          <Col>
                                            <Form.Control
                                              id="outlined-basic"
                                              disabled
                                              label="Qty"
                                              variant="outlined"
                                              type="text"
                                              size="small"
                                              name="qty"
                                              value={price.total.toLocaleString()}
                                              style={{ width: "150px" }}
                                            />
                                          </Col>
                                        </Form.Group>
                                        <Form.Group
                                          as={Row}
                                          controlId="formHorizontalUsername"
                                          style={{ width: "500px" }}
                                        >
                                          <Col>
                                            <Form.Label>Vat : </Form.Label>
                                          </Col>
                                          <Col>
                                            <Form.Control
                                              id="outlined-basic"
                                              disabled
                                              label="Qty"
                                              variant="outlined"
                                              type="text"
                                              size="small"
                                              name="qty"
                                              value={price.tax.toLocaleString()}
                                              style={{ width: "150px" }}
                                            />
                                          </Col>
                                        </Form.Group>
                                        <Form.Group
                                          as={Row}
                                          controlId="formHorizontalUsername"
                                          style={{ width: "500px" }}
                                        >
                                          <Col>
                                            <Form.Label>WithHold : </Form.Label>
                                          </Col>
                                          <Col>
                                            <Form.Control
                                              id="outlined-basic"
                                              disabled
                                              label="Qty"
                                              variant="outlined"
                                              type="text"
                                              size="small"
                                              name="qty"
                                              value={price.withHold.toLocaleString()}
                                              style={{ width: "150px" }}
                                            />
                                          </Col>
                                        </Form.Group>

                                        <Form.Group
                                          as={Row}
                                          controlId="formHorizontalUsername"
                                          style={{ width: "500px" }}
                                        >
                                          <Col>
                                            <Form.Label>
                                              Grand Total :{" "}
                                            </Form.Label>
                                          </Col>
                                          <Col>
                                            <Form.Control
                                              id="outlined-basic"
                                              disabled
                                              label="Qty"
                                              variant="outlined"
                                              type="text"
                                              size="small"
                                              name="qty"
                                              value={price.grandTotal.toLocaleString()}
                                              style={{ width: "150px" }}
                                            />
                                          </Col>
                                        </Form.Group>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Formik>
                        </div>
                        <Form onSubmit={formik.handleSubmit} className="mt-3">
                          <Form.Group as={Row}>
                            <Col lg={4} className="mb-3">
                              <Autocomplete
                                {...paymentData}
                                id="controlled-demo1"
                                size="small"
                                variant="outlined"
                                name="paymentMethodId"
                                onBlur={(e) => handleValidity(2)}
                                value={paymentValue}
                                onChange={(event, newValue) => {
                                  if (!newValue?.Id) {
                                    setPaymentValue(null);
                                    return;
                                  }
                                  setPaymentValue(newValue);
                                  formik.values.paymentMethodId = newValue?.Id;
                                }}
                                sx={{
                                  "& .MuiInputBase-root": {
                                    fontSize: {
                                      xs: "0.7rem",
                                      sm: "0.85rem",
                                      md: "0.9rem",
                                    },
                                    padding: {
                                      xs: "1px",
                                      sm: "2px",
                                      md: "3px",
                                      lg: "4px",
                                    },
                                  },
                                }}
                                renderInput={(row) => (
                                  <TextField
                                    {...row}
                                    sx={{ width: "250px" }}
                                    error={
                                      (valids[2] === 1 &&
                                        paymentValue === null) ||
                                      formik.errors.paymentMethodId
                                    }
                                    helperText={
                                      formik.errors.paymentMethodId
                                        ? formik.errors.paymentMethodId
                                        : paymentValue?.account
                                    }
                                    label={
                                      <div style={{ float: "left" }}>
                                        <FontAwesomeIcon
                                          icon={faWallet}
                                          style={{
                                            float: "left",
                                            marginRight: "10px",
                                            fontSize: 24,
                                          }}
                                        />
                                        <span>Bank Name</span>
                                      </div>
                                    }
                                  />
                                )}
                              />
                            </Col>
                            <Col lg={4} className="mb-3">
                              <TextField
                                id="outlined-basic"
                                label={
                                  <div style={{ float: "left" }}>
                                    <FontAwesomeIcon
                                      icon={faMoneyBillTransfer}
                                      style={{
                                        float: "left",
                                        marginRight: "10px",
                                        fontSize: 24,
                                      }}
                                    />
                                    <span>Transfer ID</span>
                                  </div>
                                }
                                variant="outlined"
                                type="text"
                                size="small"
                                name="transferidId"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.transferidId}
                                error={
                                  formik.errors.transferidId &&
                                  formik.touched.transferidId
                                }
                                helperText={
                                  formik.errors.transferidId &&
                                  formik.touched.transferidId
                                    ? formik.errors.transferidId
                                    : ""
                                }
                                sx={{
                                  width: "250px",
                                  "& .MuiInputBase-root": {
                                    fontSize: {
                                      xs: "0.7rem",
                                      sm: "0.85rem",
                                      md: "0.9rem",
                                    },
                                    padding: {
                                      xs: "1px",
                                      sm: "2px",
                                      md: "3px",
                                      lg: "4px",
                                    },
                                  },
                                }}
                              />
                            </Col>
                            <Col lg={4} className="mb-3">
                              <TextField
                                id="outlined-basic"
                                label={
                                  <div style={{ float: "left" }}>
                                    <FontAwesomeIcon
                                      icon={faMoneyBill1Wave}
                                      style={{
                                        float: "left",
                                        marginRight: "10px",
                                        fontSize: 24,
                                      }}
                                    />
                                    <span>Remaining Price</span>
                                  </div>
                                }
                                variant="outlined"
                                type="number"
                                size="small"
                                name="totalPrice"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.totalPrice}
                                error={
                                  parseFloat(formik.values.paidPrice) +
                                    parseFloat(formik.values.totalPrice) !==
                                  parseFloat(price.grandTotal)
                                }
                                helperText={`Remaining Price is  ${(
                                  parseFloat(price.grandTotal) -
                                  parseFloat(formik.values.paidPrice)
                                ).toLocaleString()} birr!`}
                                sx={{
                                  width: "250px",
                                  "& .MuiInputBase-root": {
                                    fontSize: {
                                      xs: "0.7rem",
                                      sm: "0.85rem",
                                      md: "0.9rem",
                                    },
                                    padding: {
                                      xs: "1px",
                                      sm: "2px",
                                      md: "3px",
                                      lg: "4px",
                                    },
                                  },
                                }}
                              />
                            </Col>
                          </Form.Group>
                        </Form>
                        <center className="mt-3">
                          <div style={{ overflow: "scroll" }}>
                            <Row
                              className="mb-3 p-2"
                              style={{
                                minWidth: "550px",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              <Col>
                                <Button
                                  sx={{
                                    width: "150px",
                                    borderColor: "primary.main",
                                    fontSize: {
                                      xs: "0.7rem",
                                      sm: "0.85rem",
                                      md: "1rem",
                                    },
                                    padding: {
                                      xs: "3px",
                                      sm: "4px",
                                      md: "5px",
                                      lg: "6px",
                                    },
                                    "&:hover": {
                                      backgroundColor: "primary.main",
                                      color: "white",
                                    },
                                  }}
                                  variant="outlined"
                                  color="primary"
                                  onClick={clearData}
                                >
                                  <div
                                    className="row"
                                    style={{
                                      marginBottom: "-10px",
                                      marginLeft: "-15px",
                                    }}
                                  >
                                    <div className="col-2">
                                      <FontAwesomeIcon
                                        style={{
                                          float: "left",
                                          marginLeft: "0px",
                                          marginTop: "2px",
                                          marginBottom: "-4px",
                                        }}
                                        icon={faBackwardStep}
                                      />
                                    </div>
                                    <div className="col-10">
                                      <h6
                                        style={{
                                          float: "left",
                                          marginLeft: "-5px",
                                          marginTop: "-1px",
                                          marginBottom: "-7px",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: {
                                              xs: "0.85rem",
                                              sm: "0.85rem",
                                              md: "1rem",
                                            },
                                          }}
                                        >
                                          Back to list
                                        </Typography>
                                      </h6>
                                    </div>
                                  </div>
                                </Button>
                              </Col>
                              <Col>
                                <Form onSubmit={formik.handleSubmit}>
                                  <Button
                                    sx={{
                                      width: "150px",
                                      borderColor: "primary.main",
                                      fontSize: {
                                        xs: "0.7rem",
                                        sm: "0.85rem",
                                        md: "1rem",
                                      },
                                      padding: {
                                        xs: "3px",
                                        sm: "4px",
                                        md: "5px",
                                        lg: "6px",
                                      },
                                      "&:hover": {
                                        backgroundColor: "primary.main",
                                        color: "white",
                                      },
                                    }}
                                    variant="outlined"
                                    color="primary"
                                    type="submit"
                                  >
                                    <div
                                      className="row"
                                      style={{
                                        marginBottom: "-10px",
                                        marginLeft: "-15px",
                                      }}
                                    >
                                      <div className="col-3">
                                        <FontAwesomeIcon
                                          style={{
                                            float: "left",
                                            marginLeft: "0px",
                                            marginTop: "2px",
                                            marginBottom: "-4px",
                                          }}
                                          icon={faFloppyDisk}
                                        />
                                      </div>
                                      <div className="col-9">
                                        <h6
                                          style={{
                                            float: "left",
                                            marginLeft: "-5px",
                                            marginTop: "-1px",
                                            marginBottom: "-7px",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: {
                                                xs: "0.85rem",
                                                sm: "0.85rem",
                                                md: "1rem",
                                              },
                                            }}
                                          >
                                            Submit
                                          </Typography>
                                        </h6>
                                      </div>
                                    </div>
                                  </Button>
                                </Form>
                              </Col>
                              <Col>
                                <Button
                                  onClick={(e) => setOpenSales(3)}
                                  sx={{
                                    width: "150px",
                                    borderColor: "error.main",
                                    fontSize: {
                                      xs: "0.7rem",
                                      sm: "0.85rem",
                                      md: "1rem",
                                    },
                                    padding: {
                                      xs: "3px",
                                      sm: "4px",
                                      md: "5px",
                                      lg: "6px",
                                    },
                                    "&:hover": {
                                      backgroundColor: "error.main",
                                      color: "white",
                                    },
                                  }}
                                  variant="outlined"
                                  color="error"
                                  type="submit"
                                >
                                  <div
                                    className="row"
                                    style={{
                                      marginBottom: "-10px",
                                      marginLeft: "-15px",
                                    }}
                                  >
                                    <div className="col-3">
                                      <FontAwesomeIcon
                                        style={{
                                          float: "left",
                                          marginLeft: "0px",
                                          marginTop: "2px",
                                          marginBottom: "-4px",
                                        }}
                                        icon={faTrashRestore}
                                      />
                                    </div>
                                    <div className="col-9">
                                      <h6
                                        style={{
                                          float: "left",
                                          marginLeft: "-5px",
                                          marginTop: "-1px",
                                          marginBottom: "-7px",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: {
                                              xs: "0.85rem",
                                              sm: "0.85rem",
                                              md: "1rem",
                                            },
                                          }}
                                        >
                                          reject
                                        </Typography>
                                      </h6>
                                    </div>
                                  </div>
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </center>
                      </div>
                    </div>
                  )}
                  {openSales === 0 && (
                    <div>
                      <div className="card-body" style={{ overflow: "scroll" }}>
                        <Box
                          sx={{
                            height: 500,
                            width: {
                              lg: "100%",
                              md: "1000px",
                              sm: "1000px",
                              xs: "1000px",
                            },
                            "& .super-app-theme--header": {
                              backgroundColor: "#1de5ec",
                              color: "black",
                              fontSize: {
                                xs: "13px",
                                sm: "15px", //500
                                md: "17px", //768
                                lg: "18px", //1024
                              },
                            },
                          }}
                        >
                          <DataGrid
                            rows={parseInt(id) === 2 ? rows : allDataRows}
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            sx={{
                              "& .MuiDataGrid-cell": {
                                fontSize: {
                                  xs: "12px",
                                  sm: "14px", //500
                                  md: "16px", //768
                                  lg: "17px", //1024
                                },
                              },
                            }}
                            columns={columns}
                            slots={{ toolbar: GridToolbar }}
                            slotProps={{
                              toolbar: {
                                showQuickFilter: true,
                              },
                            }}
                            initialState={{
                              ...sales.initialState,
                              pagination: {
                                paginationModel: { pageSize: 5 },
                              },
                            }}
                            pageSizeOptions={[5, 10, 15]}
                            getRowId={(row) => row.Id}
                          />
                        </Box>
                      </div>
                    </div>
                  )}

                  {openSales === 2 && (
                    <div style={{ overflow: "scroll" }}>
                      <div className="card-body" style={{ minWidth: "1000px" }}>
                        <Container ref={contentRef}>
                          <div className="row">
                            <div className="col-sm-6 ">
                              <br />
                              <h1
                                style={{
                                  marginLeft: "5vw",
                                  marginBottom: "2vh",
                                }}
                              >
                                Invoice
                              </h1>
                              <i
                                style={{
                                  marginLeft: "7vw",
                                  marginBottom: "2vh",
                                }}
                                className="fas fa-home fa-3x"
                              />
                            </div>

                            <div className="col-sm-6 ">
                              <br />
                              <div
                                style={{
                                  fontSize: 18,
                                  textAlign: "right",
                                }}
                              >
                                <p style={{ margin: "0", fontSize: 24 }}>
                                  <b> Company Name</b>
                                </p>
                                <p style={{ margin: "0" }}>+251987654321</p>
                                <p style={{ margin: "0" }}>email@gmail.com</p>
                                <p style={{ margin: "0" }}>address1</p>
                                <p style={{ margin: "0" }}>address2</p>
                              </div>
                            </div>
                          </div>
                          <h4>Sales</h4>
                          <hr />
                          <Row className="">
                            <Col sm={2}>
                              <p>
                                <b>BILL To</b>
                              </p>
                            </Col>

                            <Col sm={5}>
                              <div>
                                <p>
                                  <b>Customer Name : </b>
                                  {customerValue?.fullName}
                                </p>
                              </div>
                              <div>
                                <p>
                                  <b>Till Number : </b>
                                  {customerValue?.tinNumber}
                                </p>
                              </div>
                              <div>
                                <p>
                                  <b>Customer of : </b>
                                  {
                                    users.find(
                                      (u) => u.Id === customerValue.userId
                                    )?.fullName
                                  }
                                </p>
                              </div>
                              <div>
                                <p>
                                  <b>Phone No : </b>
                                  {customerValue.primaryPhoneNumber}
                                </p>
                              </div>
                              <div>
                                <p>
                                  <b>Fs Number : </b>
                                  {formik.values.fsNumber}
                                </p>
                              </div>
                            </Col>
                            <Col sm={5}>
                              <div>
                                <p>
                                  <b>Bank Name : </b>
                                  {
                                    paymentMethods.find(
                                      (p) =>
                                        p.Id === formik.values.paymentMethodId
                                    )?.name
                                  }
                                </p>
                              </div>
                              {formik.values.Id !== "" && (
                                <div>
                                  <p>
                                    <b>Sale Ref : </b>
                                    {formik.values.Id}
                                  </p>
                                </div>
                              )}
                              <div>
                                <p>
                                  <b>Date : </b>
                                  {`${new Date(formik.values.date).getDate()}/${
                                    new Date(formik.values.date).getMonth() + 1
                                  }/${new Date(
                                    formik.values.date
                                  ).getFullYear()}`}
                                </p>
                              </div>
                              <div>
                                <p>
                                  <b>MRC Number :</b>
                                  {formik.values.acNumber}
                                </p>
                              </div>
                            </Col>
                          </Row>
                          <br />
                          <br />
                          <hr />
                          <br />
                          <br />
                          <br />
                          <Row>
                            <Table
                              className="text-center"
                              style={{ width: "100%" }}
                            >
                              <tr>
                                <th>No</th>
                                <th>Code</th>
                                <th>Desc</th>
                                <th>Model</th>
                                <th>Unit</th>
                                <th>U.Price</th> <th>Qty</th>
                                <th>Discount ( % )</th>
                                <th>T.Price</th>
                                <th>Remark</th>
                              </tr>

                              <tbody>
                                {itemValue.map((request, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>

                                    <td>
                                      {items.map((item) =>
                                        item.Id === parseInt(request.itemId)
                                          ? item.code
                                          : ""
                                      )}
                                    </td>
                                    <td>
                                      {items.map((item) =>
                                        item.Id === parseInt(request.itemId)
                                          ? item.description
                                          : ""
                                      )}
                                    </td>
                                    <td>
                                      {items.map((item) =>
                                        item.Id === parseInt(request.itemId)
                                          ? models.find(
                                              (model) =>
                                                model.Id === item.modelId
                                            )?.modelName
                                          : ""
                                      )}
                                    </td>
                                    <td>
                                      {items.map((item) =>
                                        item.Id === parseInt(request.itemId)
                                          ? units.map((unit) =>
                                              unit.Id === parseInt(item.unitId)
                                                ? unit.unitName
                                                : ""
                                            )
                                          : ""
                                      )}
                                    </td>
                                    <td>{request.price}</td>
                                    <td>{request.quantity}</td>

                                    <td>
                                      {`${(
                                        parseFloat(
                                          (request.discount || 0) /
                                            request.totalPrice
                                        ) * 100
                                      ).toFixed(2)} %`}
                                    </td>
                                    <td>{request.totalPrice}</td>
                                    <td>{request.remark}</td>
                                  </tr>
                                ))}
                                {itemValue.length < 5 ? handelInvoice() : ""}
                              </tbody>
                            </Table>
                          </Row>
                          <Row>
                            <Col sm={8}>
                              <h3>Terms</h3>{" "}
                              {qrCodeUrl ? (
                                <div>
                                  <img
                                    src={qrCodeUrl}
                                    alt="QR Code"
                                    style={{
                                      marginTop: "20px",
                                      width: "256px",
                                      height: "256px",
                                    }}
                                  />
                                </div>
                              ) : (
                                <p>Generating QR code...</p>
                              )}
                            </Col>
                            <Col sm={4}>
                              <Table>
                                <tbody>
                                  <tr style={{}}>
                                    <td>
                                      <b
                                        style={{
                                          float: "right",
                                          fontSize: 18,
                                          width: "100px",
                                          height: "10px",
                                        }}
                                      >
                                        Total Price
                                      </b>
                                    </td>
                                    <td>
                                      <p
                                        style={{
                                          float: "left",
                                          fontSize: 18,
                                          width: "110px",
                                          height: "10px",
                                        }}
                                      >
                                        {price.total.toLocaleString()}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b
                                        style={{
                                          float: "right",
                                          fontSize: 18,
                                          width: "100px",
                                          height: "10px",
                                        }}
                                      >
                                        VAT
                                      </b>
                                    </td>
                                    <td>
                                      <p
                                        style={{
                                          float: "left",
                                          fontSize: 18,
                                          width: "110px",
                                          height: "10px",
                                        }}
                                      >
                                        {price.tax.toLocaleString()}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b
                                        style={{
                                          float: "right",
                                          fontSize: 18,
                                          width: "100px",
                                          height: "10px",
                                        }}
                                      >
                                        WithHold
                                      </b>
                                    </td>
                                    <td>
                                      <p
                                        style={{
                                          float: "left",
                                          fontSize: 18,
                                          width: "110px",
                                          height: "10px",
                                        }}
                                      >
                                        {price.withHold.toLocaleString()}
                                      </p>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      <b
                                        style={{
                                          float: "right",
                                          fontSize: 18,
                                          width: "120px",
                                          height: "10px",
                                        }}
                                      >
                                        Grand Total
                                      </b>
                                    </td>
                                    <td>
                                      <p
                                        style={{
                                          float: "left",
                                          fontSize: 18,
                                          width: "110px",
                                          height: "10px",
                                        }}
                                      >
                                        {price.grandTotal.toLocaleString()}
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                      <div className="card-footer">
                        <Button
                          onClick={() => {
                            reactToPrint();
                            handleBack();
                          }}
                          sx={{
                            width: "100px",
                            borderColor: "primary.main",
                            fontSize: {
                              xs: "0.7rem",
                              sm: "0.85rem",
                              md: "1rem",
                            },
                            padding: {
                              xs: "3px",
                              sm: "4px",
                              md: "5px",
                              lg: "6px",
                            },
                            "&:hover": {
                              backgroundColor: "primary.main",
                              color: "white",
                            },
                          }}
                          variant="outlined"
                          color="primary"
                          className="ml-3 mt-1"
                          style={{ float: "left" }}
                        >
                          <div
                            className="row"
                            style={{
                              marginBottom: "-10px",
                              marginLeft: "-10px",
                            }}
                          >
                            <div className="col">
                              <FontAwesomeIcon
                                style={{
                                  float: "right",
                                  marginRight: "-1px",
                                  marginTop: "2px",
                                  marginBottom: "-4px",
                                }}
                                icon={faPrint}
                              />
                            </div>
                            <div className="col-7">
                              <h6
                                style={{
                                  float: "left",
                                  marginLeft: "-10px",
                                  marginTop: "-1px",
                                  marginBottom: "-7px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: {
                                      xs: "0.85rem",
                                      sm: "0.85rem",
                                      md: "1rem",
                                    },
                                  }}
                                >
                                  Print
                                </Typography>
                              </h6>
                            </div>
                          </div>
                        </Button>
                        <Button
                          onClick={(e) => {
                            handleBack();
                          }}
                          sx={{
                            width: "90px",
                            borderColor: "success.main",
                            fontSize: {
                              xs: "0.7rem",
                              sm: "0.85rem",
                              md: "1rem",
                            },
                            padding: {
                              xs: "3px",
                              sm: "4px",
                              md: "5px",
                              lg: "6px",
                            },
                            "&:hover": {
                              backgroundColor: "success.main",
                              color: "white",
                            },
                          }}
                          variant="outlined"
                          color="success"
                          className="ml-3 mt-1"
                          style={{ float: "left" }}
                        >
                          <div
                            className="row"
                            style={{
                              marginBottom: "-10px",
                              marginLeft: "-10px",
                            }}
                          >
                            <div className="col">
                              <FontAwesomeIcon
                                style={{
                                  float: "right",
                                  marginRight: "-1px",
                                  marginTop: "2px",
                                  marginBottom: "-4px",
                                }}
                                icon={faBackwardStep}
                              />
                            </div>
                            <div className="col-7">
                              <h6
                                style={{
                                  float: "left",
                                  marginLeft: "-10px",
                                  marginTop: "-1px",
                                  marginBottom: "-7px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: {
                                      xs: "0.85rem",
                                      sm: "0.85rem",
                                      md: "1rem",
                                    },
                                  }}
                                >
                                  Back
                                </Typography>
                              </h6>
                            </div>
                          </div>
                        </Button>
                      </div>
                    </div>
                  )}
                </div>

                <Formik
                  initialValues={{
                    remark: "",
                  }}
                  validationSchema={Yup.object().shape({
                    remark: Yup.string()
                      .required("Reason is required.")
                      .min(4, "Too Short!")
                      .max(60, "Too Long"),
                  })}
                  onSubmit={async (values) => {
                    if (isSubmitted) return;
                    setIsSubmitted(true);
                    try {
                      await axios
                        .post(
                          "https://enin.server.highendtradingplc.com/sale/add",
                          {
                            requestId: newRequests.requestId,
                            remark: values.remark,
                            statusId: 3,
                            date: new Date(),
                            userId: userData.Id,
                            customerId: customerValue.Id,
                            totalPrice: price.grandTotal,
                            reciptNumber: "123456",
                            acNumber: "1234",
                            fsNumber: "12345678",
                            refNumber: "1234",
                            transferidId: null,
                            paymentMethodId: null,
                          }
                        )
                        .then(async (res) => {
                          if (newRequests.requestId.includes("QUE")) {
                            await axios.put(
                              "https://enin.server.highendtradingplc.com/queue_order/update/" +
                                newRequests.requestId,
                              {
                                statusId: 5,
                              }
                            );
                          } else if (newRequests.requestId.includes("RES")) {
                            const reserveItem = allDataItems.filter(
                              (all) => all.reserveId === newRequests.requestId
                            );
                            await axios.put(
                              "https://enin.server.highendtradingplc.com/reserve/update/" +
                                newRequests.requestId,
                              {
                                statusId: 5,
                              }
                            );
                            reserveItem.map(async (r) => {
                              const logical = logicals.find(
                                (l) => l.itemId === r.itemId
                              );
                              await axios
                                .post(
                                  "https://enin.server.highendtradingplc.com/transaction/add",
                                  {
                                    quantity: parseInt(r.quantity),
                                    statusId: 1,
                                    date: values.date,
                                  }
                                )
                                .then(async (res1) => {
                                  await axios.post(
                                    "https://enin.server.highendtradingplc.com/logical_stock/add",
                                    {
                                      quantity:
                                        logical.quantity + parseInt(r.quantity),
                                      itemId: r.itemId,
                                      date: values.date,
                                      statusId: 1,
                                      transactionId: res1.data.data.Id,
                                      ADPRS_ID: res.data.data.Id,
                                    }
                                  );
                                });
                              return null;
                            });
                          } else if (newRequests.requestId.includes("QUO")) {
                            await axios.put(
                              "https://enin.server.highendtradingplc.com/request/update/" +
                                newRequests.requestId,
                              {
                                statusId: 5,
                              }
                            );
                            const requestItem = allDataItems.filter(
                              (all) => all.requestId === newRequests.requestId
                            );
                            requestItem.map(async (r) => {
                              const logical = logicals.find(
                                (l) => l.itemId === r.itemId
                              );
                              await axios
                                .post(
                                  "https://enin.server.highendtradingplc.com/transaction/add",
                                  {
                                    quantity: parseInt(r.quantity),
                                    statusId: 1,
                                    date: values.date,
                                  }
                                )
                                .then(async (res1) => {
                                  await axios.post(
                                    "https://enin.server.highendtradingplc.com/logical_stock/add",
                                    {
                                      quantity:
                                        logical.quantity + parseInt(r.quantity),
                                      itemId: r.itemId,
                                      date: values.date,
                                      statusId: 1,
                                      transactionId: res1.data.data.Id,
                                      ADPRS_ID: res.data.data.Id,
                                    }
                                  );
                                });
                              return null;
                            });
                          } else {
                            await axios.put(
                              "https://enin.server.highendtradingplc.com/request/update/" +
                                newRequests.requestId,
                              {
                                statusId: 5,
                              }
                            );
                          }
                        });
                      fetchAllData();
                      setOpenSales(0);
                    } catch (error) {
                      console.log(error);
                    }
                    setIsSubmitted(false);
                  }}
                >
                  {(props) => (
                    <Modal
                      show={openSales === 3}
                      onHide={(e) => setOpenSales(0)}
                      style={{ minWidth: "500px" }}
                    >
                      <Modal.Header closeButton>
                        <span>Sales / Reject </span>
                      </Modal.Header>
                      <Modal.Body>
                        <Form
                          className="m-2 ml-5 pl-5"
                          onSubmit={props.handleSubmit}
                        >
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={8}>
                              <TextField
                                id="outlined-basic"
                                label="Reason"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="remark"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.remark}
                                isInvalid={
                                  props.touched.remark && props.errors.remark
                                }
                                sx={{
                                  width: "250px",
                                  "& .MuiInputBase-root": {
                                    fontSize: {
                                      xs: "0.7rem",
                                      sm: "0.85rem",
                                      md: "0.9rem",
                                    },
                                    padding: {
                                      xs: "1px",
                                      sm: "2px",
                                      md: "3px",
                                      lg: "4px",
                                    },
                                  },
                                }}
                              />

                              {props.touched.remark && props.errors.remark && (
                                <div className="text-danger mt-1">
                                  {props.errors.remark}
                                </div>
                              )}
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={12}>
                              <Button
                                sx={{
                                  width: "250px",
                                  "& .MuiInputBase-root": {
                                    fontSize: {
                                      xs: "0.85rem",
                                      sm: "0.85rem",
                                      md: "0.9rem",
                                    },
                                    padding: {
                                      xs: "4px",
                                      sm: "5px",
                                      md: "6px",
                                      lg: "6px",
                                    },
                                  },
                                }}
                                variant="outlined"
                                color="primary"
                                type="submit"
                              >
                                Submit
                              </Button>
                            </Col>
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                    </Modal>
                  )}
                </Formik>
              </div>
            </center>
          </main>
        </div>
      </Typography>
    </ThemeProvider>
  );
}

export default SalesPage;
