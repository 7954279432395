import axios from "axios";
import React, { useEffect, useState } from "react";
import Header from "../Header";
import { Link, useParams } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  createTheme,
  useMediaQuery,
  ThemeProvider,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBackwardStep,
  faCalendar,
  faEye,
  faFloppyDisk,
  faPenToSquare,
  faStore,
  faTrashCan,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { jwtDecode } from "jwt-decode";

const validationSchema = Yup.object().shape({
  date: Yup.date().required("Date is required!"),
  storeId: Yup.string().required("Store is required!"),
});
const theme = createTheme({
  breakpoints: { values: { xs: 0, sm: 500, md: 769, lg: 1024, xl: 1440 } },
  palette: {
    warning: {
      main: "#ffc107", // Main warning color
      light: "#ffb74d", // Light variation of warning color
      dark: "#f57c00", // Dark variation of warning color
      contrastText: "#000", // Text color on warning
    },
  },
});
const PurchaseItemDelivery = () => {
  const [purchases, setPurchases] = useState([]);
  const [purchaseItems, setPurchaseItems] = useState([]);
  const [newPurchaseItems, setNewPurchaseItems] = useState([]);
  const [items, setItems] = useState([]);
  const [newItems, setNewItems] = useState([]);
  const [itemValues, setItemValues] = useState([]);
  const [selectItem, setSelectItem] = useState(null);
  const [models, setModels] = useState([]);
  const [units, setUnits] = useState([]);
  const [users, setUsers] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [stores, setStores] = useState([]);
  const [storeItems, setStoreItems] = useState([]);
  const [storeSelects, setStoreSelects] = useState(null);
  const [storeDatas, setStoreDatas] = useState([]);
  const [storeDataItems, setStoreDataItems] = useState([]);
  const [open, setOpen] = useState(0);
  const [openStore, setOpenStore] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [physicals, setPhysicals] = useState([]);
  const [openMessage, setOpenMessage] = useState(false);
  const [messages, setMessages] = useState("");
  const [update, setUpdate] = useState({ itemId: "", quantity: "" });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isUnderMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const userData = jwtDecode(sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const { id } = useParams();
  const fetchAllData = async () => {
    try {
      const [
        item,
        unit,
        purchase,
        purchaseItem,
        model,
        user,
        supplier,
        store,
        storeItem,
        storeData,
        storeDataItem,
        physical,
      ] = await Promise.all([
        axios.get("https://enin.server.highendtradingplc.com/item"),
        axios.get("https://enin.server.highendtradingplc.com/unit"),
        axios.get("https://enin.server.highendtradingplc.com/purchase"),
        axios.get("https://enin.server.highendtradingplc.com/purchase_item"),
        axios.get("https://enin.server.highendtradingplc.com/model"),
        axios.get("https://enin.server.highendtradingplc.com/user"),
        axios.get("https://enin.server.highendtradingplc.com/supplier"),
        axios.get("https://enin.server.highendtradingplc.com/store"),
        axios.get("https://enin.server.highendtradingplc.com/store_item"),
        axios.get("https://enin.server.highendtradingplc.com/store_data"),
        axios.get("https://enin.server.highendtradingplc.com/store_data_item"),
        axios.get("https://enin.server.highendtradingplc.com/physical_stock"),
      ]);
      setItems(item.data);
      setUnits(unit.data);
      setModels(model.data);
      setPurchases(purchase.data?.filter((p) => p.statusId === 1));
      setPurchaseItems(purchaseItem.data);
      setUsers(user.data?.filter((user) => user.roleId >= 2));
      setSuppliers(supplier.data);
      setStores(store.data);
      setStoreItems(storeItem.data);
      setStoreDatas(storeData.data?.filter((p) => p.statusId === 1));
      setStoreDataItems(storeDataItem.data);
      setPhysicals(physical.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  const itemData = {
    options: newItems,
    getOptionLabel: (option) => option.description,
  };
  const storeData = {
    options: stores,
    getOptionLabel: (option) => option.storeName,
  };

  const formik = useFormik({
    initialValues: { date: "", storeId: "" },
    validationSchema,
    onSubmit: async (values) => {
      if (isSubmitted) return;
      setIsSubmitted(true);
      try {
        // Get the current time
        const currentTime = new Date();
        const hours = currentTime.getHours();
        const minutes = currentTime.getMinutes();
        const seconds = currentTime.getSeconds();

        // Append the current time to the date value
        values.date = `${values.date} ${hours}:${minutes}:${seconds}`;

        const purchase = newPurchaseItems.find((p) => p.Id > 0);
        const purchaseId = purchases.find((p) => p.Id === purchase.purchaseId);
        let countPurchaseItem = newPurchaseItems.length;
        await axios
          .post("https://enin.server.highendtradingplc.com/store_data/add", {
            date: values.date,
            storeId: values.storeId,
            userId: userData.Id,
            purchaseDeliveryId: purchaseId.Id,
            statusId: 1,
          })
          .then(async (res) => {
            for (let i = 0; i < itemValues.length; ) {
              let counter = 0;
              const purchaseItem = newPurchaseItems.find(
                (p) => p.itemId === itemValues[i].itemId
              );
              const storeItem = storeItems.find(
                (s) =>
                  s.itemId === itemValues[i].itemId &&
                  s.storeId === values.storeId
              );
              await axios
                .post(
                  "https://enin.server.highendtradingplc.com/transaction/add",
                  {
                    quantity: parseInt(itemValues[i].quantity),
                    statusId: 1,
                    date: new Date(),
                  }
                )
                .then(async (res) => {
                  const physical = physicals.find(
                    (p) => p.itemId === itemValues[i].itemId
                  );
                  await axios.post(
                    "https://enin.server.highendtradingplc.com/physical_stock/add",
                    {
                      quantity:
                        parseInt(physical?.quantity || 0) +
                        parseInt(itemValues[i].quantity),
                      itemId: itemValues[i].itemId,
                      storeId: values.storeId,
                      date: values.date,
                      statusId: 1,
                      transactionId: res.data.data.Id,
                      ADPRS_ID: purchaseId.Id,
                    }
                  );
                });
              await axios
                .post(
                  "https://enin.server.highendtradingplc.com/store_data_item/add",
                  {
                    itemId: purchaseItem.itemId,
                    storeDatumId: res.data.data.Id,
                    purchaseDeliveryItemId: purchaseItem.Id,
                    quantity: parseInt(itemValues[i].quantity),
                  }
                )
                .then((ress) => counter++);
              await axios
                .put(
                  "https://enin.server.highendtradingplc.com/store_item/update/" +
                    storeItem.Id,
                  {
                    quantity:
                      parseInt(storeItem?.quantity) +
                      parseInt(itemValues[i].quantity),
                  }
                )
                .then((ress) => counter++);
              await axios
                .put(
                  "https://enin.server.highendtradingplc.com/purchase_item/update/" +
                    purchaseItem.Id,
                  {
                    remainingQuantity:
                      parseInt(purchaseItem?.remainingQuantity) -
                      parseInt(itemValues[i].quantity),
                  }
                )
                .then((ress) => counter++);
              if (
                parseInt(purchaseItem?.remainingQuantity) -
                  parseInt(itemValues[i].quantity) ===
                0
              ) {
                countPurchaseItem -= 1;
              }
              if (counter === 3) {
                i++;
              }
            }
          });
        if (countPurchaseItem === 0) {
          await axios.put(
            "https://enin.server.highendtradingplc.com/purchase/update/" +
              purchaseId.Id,
            { statusId: 2 }
          );
        }
        handleBack();
      } catch (error) {
        console.log(error);
      }
      setIsSubmitted(false);
    },
  });

  const handleOpenPurchaseItem = (row) => {
    if (parseInt(id) === 1) {
      let data = [];
      let dataValue = [];
      const purchaseItem = purchaseItems.filter((p) => {
        const item = items.find((i) => i.Id === p.itemId);
        if (p.purchaseId === row.purchaseId && p.remainingQuantity > 0) {
          data.push(item);
          dataValue.push({ itemId: item.Id, quantity: p.remainingQuantity });
        }
        return p.purchaseId === row.purchaseId && p.remainingQuantity > 0;
      });
      setNewItems(data);
      setItemValues(dataValue);
      setNewPurchaseItems(purchaseItem);
      setOpen(1);
    } else {
      let dataValue = [];
      const purchaseItem = storeDataItems.filter((p) => {
        const item = items.find((i) => i.Id === p.itemId);
        if (p.storeDatumId === row.storeDataId) {
          dataValue.push({ itemId: item.Id, quantity: p.quantity });
        }
        return p.storeDatumId === row.storeDataId;
      });
      formik.values.storeId = row.storeId;

      setItemValues(dataValue);
      setNewPurchaseItems(purchaseItem);
      setOpen(2);
    }
  };

  const handleBack = () => {
    fetchAllData();
    setItemValues([]);
    setSelectItem(null);
    setStoreSelects(null);
    setOpen(0);
    formik.values.date = "";
    formik.values.storeId = "";
  };

  const columnPurchase = [
    {
      field: "Id",
      headerName: "No",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
    },
    {
      field: "purchaseId",
      headerName: "Purchase Id",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "userId",
      headerName: "Purchased By",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      valueGetter: (params) =>
        users.find((user) => user.Id === params)?.fullName || "",
    },
    {
      field: "supplierId",
      headerName: "Supplier Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",

      valueGetter: (params) =>
        suppliers.find((supplier) => supplier.Id === params)?.fullName || "",
    },

    {
      field: "totalPrice",
      headerName: "Total Price",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.toLocaleString(),
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",

      valueGetter: (params) =>
        `${new Date(params).getDate()}/${
          new Date(params).getMonth() + 1
        }/${new Date(params).getFullYear()}`,
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",

      width: 220,
      renderCell: (params) => (
        <>
          <Button
            onClick={() => handleOpenPurchaseItem(params.row)}
            sx={{
              width: "90px",
              borderColor: "primary.main",
              fontSize: {
                xs: "0.8rem",
                sm: "0.8rem",
                md: "1rem",
              },
              padding: {
                xs: "4px",
                sm: "5px",
                md: "6px",
                lg: "6px",
              },
              "&:hover": {
                backgroundColor: "primary.main",
                color: "white",
              },
            }}
            variant="outlined"
            color="primary"
            className="m-1"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{
                    float: "right",
                    marginRight: "-1px",
                    marginTop: "2px",
                    marginBottom: "-4px",
                  }}
                  icon={faEye}
                />
              </div>
              <div className="col-7">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-10px",
                    marginTop: "-1px",
                    marginBottom: "-7px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "0.85rem",
                        sm: "0.85rem",
                        md: "1rem",
                      },
                    }}
                  >
                    View
                  </Typography>
                </h6>
              </div>
            </div>
          </Button>
        </>
      ),
    },
  ];

  const rowPurchase = purchases.map((purchase, index) => {
    return {
      Id: index + 1,
      userId: purchase.userId,
      purchaseId: purchase.Id,
      date: purchase.date,
      totalPrice: purchase.totalPrice,
      supplierId: purchase.supplierId,
    };
  });
  const columnStoreData = [
    {
      field: "Id",
      headerName: "No",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "storeDataId",
      headerName: "Store Data Id",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "userId",
      headerName: "Stored By",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) =>
        users.find((user) => user.Id === params)?.fullName || "",
    },
    {
      field: "storeId",
      headerName: "Store Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) =>
        stores.find((store) => store.Id === params)?.storeName || "",
    },

    {
      field: "total",
      headerName: "Item No",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.toLocaleString(),
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) =>
        `${new Date(params).getDate()}/${
          new Date(params).getMonth() + 1
        }/${new Date(params).getFullYear()}`,
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      width: 220,
      renderCell: (params) => (
        <>
          <Button
            onClick={() => handleOpenPurchaseItem(params.row)}
            sx={{
              width: "90px",
              borderColor: "primary.main",
              fontSize: {
                xs: "0.85rem",
                sm: "0.85rem",
                md: "1rem",
              },
              padding: {
                xs: "4px",
                sm: "5px",
                md: "6px",
                lg: "6px",
              },
              "&:hover": {
                backgroundColor: "primary.main",
                color: "white",
              },
            }}
            variant="outlined"
            color="primary"
            className="m-1"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{
                    float: "right",
                    marginRight: "-1px",
                    marginTop: "2px",
                    marginBottom: "-4px",
                  }}
                  icon={faEye}
                />
              </div>
              <div className="col-6">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-10px",
                    marginTop: "-1px",
                    marginBottom: "-7px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "0.85rem",
                        sm: "0.85rem",
                        md: "1rem",
                      },
                    }}
                  >
                    View
                  </Typography>
                </h6>
              </div>
            </div>
          </Button>
        </>
      ),
    },
  ];
  const rowStoreData = storeDatas.map((storeData, index) => {
    const storeDataItem = storeDataItems.filter(
      (s) => s.storeDatumId === storeData.Id
    );
    return {
      Id: index + 1,
      userId: storeData.userId,
      storeDataId: storeData?.Id,
      date: storeData.date,
      total: storeDataItem.length,
      storeId: storeData.storeId,
    };
  });

  const columnItem = [
    {
      field: "id",
      headerName: "No",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Id",
      headerName: "Item Id",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "description",
      headerName: "description",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "model",
      headerName: "Model",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "code",
      headerName: "Code",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "unit",
      headerName: "Unit",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },

    {
      field: "qty",
      headerName: "Stored Qty",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
  ];

  const rows = newPurchaseItems.map((deliveryItem, index) => {
    const item = items.find((i) => i.Id === deliveryItem.itemId);
    const unit = units.find((u) => u.Id === item.unitId);
    const model = models.find((u) => u.Id === item.modelId);

    return {
      id: index + 1,
      Id: item.Id,
      description: item.description,
      code: item.code,
      model: model?.modelName,
      unit: unit?.unitName,
      qty: deliveryItem.quantity,
    };
  });

  const style = {
    backgroundColor: "white",
    borderRadius: "5px 5px 0 0",
    border: "solid 0.05em rgb(181, 181, 181)",
  };
  return (
    <ThemeProvider theme={theme}>
      <Typography
        sx={{
          fontSize: {
            xs: "12px",
            sm: "12px", //500
            md: "0.85rem", //768
            lg: "17px", //1024
          },
        }}
      >
        <div>
          <Header />
        </div>
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: "13px",
            sm: "14px", //500
            md: "16px", //768
            lg: "18px", //1024
          },
        }}
      >
        <main
          className=""
          style={
            isMobile && !isUnderMobile
              ? { marginTop: "190px" }
              : isUnderMobile
              ? { marginTop: "170px" }
              : isTablet
              ? { marginTop: "133px" }
              : { marginTop: "150px" }
          }
        >
          <center>
            <div
              style={
                isUnderMobile
                  ? {
                      minWidth: "500px",
                      overflow: "scroll",
                      marginBottom: "-5px",
                    }
                  : { maxWidth: "1100px" }
              }
              className="text-left"
            >
              <div
                className="tab-container "
                style={
                  isUnderMobile
                    ? { marginTop: "0", marginBottom: "-17px" }
                    : {
                        marginTop: "-20px",
                        marginBottom: "-17px",
                      }
                }
              >
                <Link className="p-2" to={`/store`}>
                  Store
                </Link>
                <Link
                  className="p-2"
                  onClick={(e) => {
                    window.location.reload();
                  }}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "5px 5px 0 0",
                    border: "solid 0.05em rgb(181, 181, 181)",
                  }}
                >
                  Purchase Item
                </Link>
                <Link className="p-2" to={`/store_transfer`}>
                  Transfer Item
                </Link>

                <Link className="p-2" to={`/delivery_item/1`}>
                  Delivery Item
                </Link>
                <Link className="p-2" to={`/adjustment_delivery/1`}>
                  Adjustment Item
                </Link>
              </div>
              <hr />
              <div
                className="tab-container"
                style={
                  isUnderMobile
                    ? { marginTop: "-12px", marginBottom: "-8px" }
                    : { marginTop: "-12px", marginBottom: "-5px" }
                }
              >
                <Link
                  className="p-2"
                  onClick={(e) => {
                    if (parseInt(id) === 1) window.location.reload();
                    else window.location.replace(`/purchase_delivery/1`);
                  }}
                  style={parseInt(id) === 1 ? style : {}}
                >
                  New Purchase
                </Link>
                <Link
                  className="p-2"
                  onClick={(e) => {
                    if (parseInt(id) === 2) window.location.reload();
                    else window.location.replace(`/purchase_delivery/2`);
                  }}
                  style={parseInt(id) === 2 ? style : {}}
                >
                  Purchase List
                </Link>
              </div>
              <Snackbar
                open={openMessage}
                onClose={(e) => setOpenMessage(false)}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <Alert
                  variant="outlined"
                  severity="error"
                  onClose={(e) => setOpenMessage(false)}
                  sx={{ bgcolor: "background.paper" }}
                >
                  {messages}
                </Alert>
              </Snackbar>
              <div className="card card-info card-outline">
                <div className="card-header">
                  {open > 1 && (
                    <div>
                      <Button
                        onClick={(e) => handleBack()}
                        sx={{
                          width: "100px",
                          borderColor: "success.main",
                          fontSize: {
                            xs: "0.85rem",
                            sm: "0.85rem",
                            md: "1rem",
                          },
                          padding: {
                            xs: "4px",
                            sm: "5px",
                            md: "6px",
                            lg: "6px",
                          },
                          "&:hover": {
                            backgroundColor: "success.main",
                            color: "white",
                          },
                        }}
                        variant="outlined"
                        color="success"
                        className="m-1"
                        style={{ float: "right" }}
                      >
                        <div
                          className="row"
                          style={{
                            marginBottom: "-10px",
                            marginLeft: "-10px",
                          }}
                        >
                          <div className="col">
                            <FontAwesomeIcon
                              style={{
                                float: "right",
                                marginRight: "-1px",
                                marginTop: "2px",
                                marginBottom: "-4px",
                              }}
                              icon={faBackwardStep}
                            />
                          </div>
                          <div className="col-7">
                            <h6
                              style={{
                                float: "left",
                                marginLeft: "-10px",
                                marginTop: "-1px",
                                marginBottom: "-7px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: {
                                    xs: "0.85rem",
                                    sm: "0.85rem",
                                    md: "1rem",
                                  },
                                }}
                              >
                                Back
                              </Typography>
                            </h6>
                          </div>
                        </div>
                      </Button>
                    </div>
                  )}
                </div>
                <div className="card-body">
                  {(open === 0 || open === 2) && (
                    <div
                      style={{
                        overflow: "scroll",
                      }}
                    >
                      <Box
                        sx={{
                          height: 500,

                          width: {
                            lg: "100%",
                            md: "1000px",
                            sm: "1000px",
                            xs: "1000px",
                          },
                          "& .super-app-theme--header": {
                            backgroundColor: "#1de5ec",
                            color: "black",
                            fontSize: {
                              xs: "13px",
                              sm: "15px", //500
                              md: "17px", //768
                              lg: "18px", //1024
                            },
                          },
                        }}
                      >
                        <DataGrid
                          rows={
                            parseInt(id) === 1
                              ? rowPurchase
                              : open === 0
                              ? rowStoreData
                              : rows
                          }
                          disableColumnFilter
                          disableColumnSelector
                          disableDensitySelector
                          columns={
                            parseInt(id) === 1
                              ? columnPurchase
                              : open === 0
                              ? columnStoreData
                              : columnItem
                          }
                          slots={{ toolbar: GridToolbar }}
                          slotProps={{
                            toolbar: {
                              showQuickFilter: true,
                            },
                          }}
                          initialState={
                            parseInt(id) === 1
                              ? {
                                  ...purchases.initialState,
                                  pagination: {
                                    paginationModel: { pageSize: 5 },
                                  },
                                }
                              : open === 0
                              ? {
                                  ...storeData.initialState,
                                  pagination: {
                                    paginationModel: { pageSize: 5 },
                                  },
                                }
                              : {
                                  ...newPurchaseItems.initialState,
                                  pagination: {
                                    paginationModel: { pageSize: 5 },
                                  },
                                }
                          }
                          pageSizeOptions={[5, 10, 15]}
                          getRowId={(row) => row.Id}
                        />
                      </Box>{" "}
                    </div>
                  )}
                  {open === 1 && (
                    <div>
                      <div>
                        <Form>
                          <Form.Group as={Row}>
                            <Col md={7}>
                              {" "}
                              <div
                                className="mb-3 row"
                                style={{
                                  minWidth: "360px",
                                  marginRight: "3px",
                                  maxWidth: "361px",
                                }}
                              >
                                <Autocomplete
                                  {...storeData}
                                  id="controlled-demo"
                                  size="small"
                                  variant="outlined"
                                  name="storeId"
                                  className="col"
                                  value={storeSelects}
                                  onChange={(event, newValue) => {
                                    if (!newValue?.Id) {
                                      setStoreSelects(null);
                                      formik.values.storeId = "";
                                      return;
                                    }
                                    setStoreSelects(newValue);
                                    formik.values.storeId = newValue?.Id;
                                  }}
                                  sx={{
                                    "& .MuiInputBase-root": {
                                      fontSize: {
                                        xs: "0.7rem",
                                        sm: "0.85rem",
                                        md: "1rem",
                                      },
                                      padding: {
                                        xs: "1px",
                                        sm: "2px",
                                        md: "3px",
                                        lg: "4px",
                                      },
                                    },
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      sx={{ width: "250px" }}
                                      error={formik.errors.storeId}
                                      helperText={
                                        formik.errors.storeId
                                          ? `Store is required!`
                                          : ""
                                      }
                                      label={
                                        <div style={{ float: "left" }}>
                                          <FontAwesomeIcon
                                            icon={faStore}
                                            style={{
                                              float: "left",
                                              marginRight: "10px",
                                            }}
                                          />
                                          <span>Store</span>
                                        </div>
                                      }
                                    />
                                  )}
                                />

                                <Button
                                  className="col"
                                  variant="outlined"
                                  color="info"
                                  sx={{
                                    float: "left",

                                    maxWidth: "20px",
                                    height: {
                                      xs: "32px",
                                      sm: "36px",
                                      md: "40px",
                                    },
                                    borderColor: "info.main",
                                    padding: {
                                      xs: "-0",
                                      sm: "0",
                                      md: "1px",
                                      lg: "3px",
                                    },
                                    "&:hover": {
                                      backgroundColor: "info.main",
                                      color: "white",
                                    },
                                  }}
                                  onClick={(e) => setOpenStore(true)}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: {
                                        xs: "2rem",
                                        sm: "2.5rem",
                                        md: "3rem",
                                      },
                                    }}
                                    style={{
                                      marginTop: "12px",
                                      marginLeft: "2px",
                                    }}
                                  >
                                    +
                                  </Typography>
                                </Button>
                              </div>
                            </Col>

                            <Col md={5} className="mb-3">
                              <TextField
                                id="outlined-basic"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.date}
                                type="date"
                                label={
                                  <div
                                    style={{
                                      float: "left",
                                      width: "70px",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faCalendar}
                                      size="1x"
                                      style={{
                                        float: "left",
                                        marginTop: "5px",
                                        marginRight: "5px",
                                      }}
                                    />
                                    <h4>Date</h4>
                                  </div>
                                }
                                size="small"
                                sx={{
                                  width: "250px",
                                  "& .MuiInputBase-root": {
                                    fontSize: {
                                      xs: "0.7rem",
                                      sm: "0.85rem",
                                      md: "0.9rem",
                                    },
                                    padding: {
                                      xs: "1px",
                                      sm: "2px",
                                      md: "3px",
                                      lg: "4px",
                                    },
                                  },
                                }}
                                className="mb-3 "
                                name="date"
                                error={
                                  formik.touched.date && formik.errors.date
                                }
                                helperText={
                                  formik.touched.date && formik.errors.date
                                    ? `${formik.errors.date}`
                                    : ""
                                }
                              />
                            </Col>
                          </Form.Group>
                        </Form>
                      </div>
                      <Formik
                        initialValues={{
                          quantity: "",
                          itemId: "",
                        }}
                        validationSchema={Yup.object().shape({
                          quantity: Yup.number()
                            .required("Quantity is required")
                            .min(1, "Too Low!"),
                          itemId: Yup.string().required("Item is required"),
                        })}
                        onSubmit={(values) => {
                          const checkItem = itemValues.find(
                            (i) => i.itemId === values.itemId
                          );
                          if (!!checkItem) {
                            setMessages("You use the same item!");
                            setOpenMessage(true);
                            return;
                          }
                          const purchaseItem = newPurchaseItems.find(
                            (p) => p.itemId === values.itemId
                          );
                          if (
                            parseFloat(values.quantity) >
                            parseFloat(purchaseItem.remainingQuantity)
                          ) {
                            setMessages(
                              `Only available quantity is ${purchaseItem.remainingQuantity}`
                            );
                            setOpenMessage(true);
                            return;
                          }
                          setItemValues([
                            ...itemValues,
                            {
                              itemId: values.itemId,
                              quantity: values.quantity,
                            },
                          ]);
                          values.itemId = "";
                          values.quantity = "";
                          setSelectItem(null);
                        }}
                      >
                        {(props) => (
                          <div>
                            <hr />
                            <h3>Delivery Item</h3>
                            <hr />
                            {newItems.length !== itemValues.length && (
                              <Form onSubmit={props.handleSubmit}>
                                <Form.Group as={Row}>
                                  <Col lg={4} className="mb-3">
                                    <Autocomplete
                                      {...itemData}
                                      id="controlled-demo"
                                      size="small"
                                      variant="outlined"
                                      name="itemId"
                                      value={selectItem}
                                      onChange={(event, newValue) => {
                                        if (!newValue?.Id) {
                                          setSelectItem(null);
                                          props.values.itemId = "";
                                          return;
                                        }
                                        props.values.itemId = newValue?.Id;
                                        setSelectItem(newValue);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          sx={{
                                            width: "250px",
                                            "& .MuiInputBase-root": {
                                              fontSize: {
                                                xs: "0.7rem",
                                                sm: "0.85rem",
                                                md: "1.1rem",
                                              },
                                              padding: {
                                                xs: "1px",
                                                sm: "2px",
                                                md: "3px",
                                                lg: "4px",
                                              },
                                            },
                                          }}
                                          {...params}
                                          error={props.errors.itemId}
                                          helperText={
                                            props.errors.itemId
                                              ? props.errors.itemId
                                              : ""
                                          }
                                          label={
                                            <div style={{ float: "left" }}>
                                              <span>Select Item</span>
                                            </div>
                                          }
                                        />
                                      )}
                                    />
                                  </Col>
                                  <Col lg={4} className="mb-3 ">
                                    <TextField
                                      id="outlined-basic"
                                      label="Remain Qty"
                                      InputLabelProps={{
                                        shrink: !!selectItem,
                                      }}
                                      disabled
                                      variant="outlined"
                                      type="number"
                                      size="small"
                                      value={
                                        !!selectItem
                                          ? newPurchaseItems.find(
                                              (p) => p.itemId === selectItem.Id
                                            )?.remainingQuantity
                                          : ""
                                      }
                                      sx={{
                                        width: "250px",
                                        "& .MuiInputBase-root": {
                                          fontSize: {
                                            xs: "0.7rem",
                                            sm: "0.85rem",
                                            md: "0.9rem",
                                          },
                                          padding: {
                                            xs: "1px",
                                            sm: "2px",
                                            md: "3px",
                                            lg: "4px",
                                          },
                                        },
                                      }}
                                    />
                                  </Col>
                                  <Col lg={4} className="mb-3 ">
                                    <TextField
                                      id="outlined-basic"
                                      label="Qty"
                                      variant="outlined"
                                      type="number"
                                      size="small"
                                      name="quantity"
                                      onChange={props.handleChange}
                                      value={props.values.quantity}
                                      onBlur={props.handleBlur}
                                      error={
                                        props.touched.quantity &&
                                        props.errors.quantity
                                      }
                                      helperText={
                                        props.touched.quantity &&
                                        props.errors.quantity
                                          ? `${props.errors.quantity}`
                                          : ""
                                      }
                                      sx={{
                                        width: "250px",
                                        "& .MuiInputBase-root": {
                                          fontSize: {
                                            xs: "0.7rem",
                                            sm: "0.85rem",
                                            md: "0.9rem",
                                          },
                                          padding: {
                                            xs: "1px",
                                            sm: "2px",
                                            md: "3px",
                                            lg: "4px",
                                          },
                                        },
                                      }}
                                    />
                                  </Col>

                                  <Col lg={4} className="mb-2">
                                    <Button
                                      sx={{
                                        width: "200px",
                                        borderColor: "primary.main",
                                        fontSize: {
                                          xs: "0.85rem",
                                          sm: "0.85rem",
                                          md: "1rem",
                                        },
                                        padding: {
                                          xs: "4px",
                                          sm: "5px",
                                          md: "6px",
                                          lg: "6px",
                                        },
                                        "&:hover": {
                                          backgroundColor: "primary.main",
                                          color: "white",
                                        },
                                      }}
                                      variant="outlined"
                                      color="primary"
                                      type="submit"
                                    >
                                      <h4
                                        style={{
                                          float: "left",
                                          marginRight: "5px",
                                          marginTop: "-1px",
                                          marginBottom: "-2px",
                                        }}
                                      >
                                        +
                                      </h4>
                                      <strong
                                        style={{
                                          float: "left",
                                          marginTop: "0",
                                          marginBottom: "-2px",
                                        }}
                                      >
                                        Add to list
                                      </strong>
                                    </Button>
                                  </Col>
                                </Form.Group>
                              </Form>
                            )}
                            <div>
                              <div style={{ overflow: "scroll" }}>
                                <table className="summary-table">
                                  <thead className="bg-info">
                                    <tr>
                                      <th>Id</th>
                                      <th>Desc</th>
                                      <th>Model</th>
                                      <th>Code</th>
                                      <th>Unit</th>
                                      <th>Delivery Qty</th>
                                      <th>Remaining Qty</th>
                                      <th>Total Qty</th>
                                      <th style={{ minWidth: "240px" }}>
                                        Action
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {" "}
                                    {itemValues.map((request, index) => {
                                      const item = items.find(
                                        (i) => i.Id === request.itemId
                                      );
                                      const purchaseItem =
                                        newPurchaseItems.find(
                                          (p) => p.itemId === request.itemId
                                        );
                                      return (
                                        <tr key={index}>
                                          <td>{index + 1}</td>

                                          <td>{item.description}</td>
                                          <td>
                                            {
                                              models.find(
                                                (model) =>
                                                  model.Id === item.modelId
                                              )?.modelName
                                            }
                                          </td>
                                          <td>{item.code}</td>
                                          <td>
                                            {
                                              units.find(
                                                (unit) =>
                                                  unit.Id === item.unitId
                                              )?.unitName
                                            }
                                          </td>
                                          <td>
                                            {openUpdate &&
                                            request.itemId === update.itemId ? (
                                              <input
                                                type="number"
                                                className="form-control"
                                                required
                                                style={{ width: "80px" }}
                                                value={update.quantity}
                                                onChange={(e) => {
                                                  if (
                                                    parseInt(e.target.value) <
                                                      1 ||
                                                    parseInt(e.target.value) >
                                                      purchaseItem.remainingQuantity
                                                  ) {
                                                    setUpdate((prev) => ({
                                                      ...prev,
                                                      quantity: 1,
                                                    }));
                                                  } else {
                                                    setUpdate((prev) => ({
                                                      ...prev,
                                                      quantity: parseInt(
                                                        e.target.value
                                                      ),
                                                    }));
                                                  }
                                                }}
                                              />
                                            ) : (
                                              request.quantity
                                            )}
                                          </td>
                                          <td>
                                            {purchaseItem.remainingQuantity -
                                              request.quantity}
                                          </td>
                                          <td>{purchaseItem.quantity}</td>

                                          <td>
                                            {(request.itemId !==
                                              update.itemId ||
                                              !openUpdate) && (
                                              <div>
                                                <Button
                                                  sx={{
                                                    width: "90px",
                                                    borderColor: "warning.main",
                                                    fontSize: {
                                                      xs: "0.7rem",
                                                      sm: "0.85rem",
                                                      md: "1rem",
                                                    },
                                                    padding: {
                                                      xs: "3px",
                                                      sm: "4px",
                                                      md: "5px",
                                                      lg: "6px",
                                                    },
                                                    "&:hover": {
                                                      backgroundColor:
                                                        "warning.main",
                                                      color: "white",
                                                    },
                                                  }}
                                                  variant="outlined"
                                                  color="warning"
                                                  className="mr-3"
                                                  onClick={(e) => {
                                                    setOpenUpdate(true);
                                                    setUpdate({
                                                      itemId: request.itemId,
                                                      quantity:
                                                        request.quantity,
                                                    });
                                                  }}
                                                >
                                                  <div
                                                    className="row "
                                                    style={{
                                                      marginBottom: "-10px",
                                                      marginLeft: "-10px",
                                                    }}
                                                  >
                                                    <div className="col">
                                                      <FontAwesomeIcon
                                                        style={{
                                                          float: "right",
                                                        }}
                                                        icon={faPenToSquare}
                                                      />
                                                    </div>
                                                    <div className="col-6">
                                                      <h6
                                                        style={{
                                                          float: "left",
                                                          marginLeft: "-10px",
                                                        }}
                                                      >
                                                        Edit
                                                      </h6>
                                                    </div>
                                                  </div>
                                                </Button>
                                                <Button
                                                  sx={{
                                                    width: "110px",
                                                    borderColor: "error.main",
                                                    fontSize: {
                                                      xs: "0.7rem",
                                                      sm: "0.85rem",
                                                      md: "1rem",
                                                    },
                                                    padding: {
                                                      xs: "3px",
                                                      sm: "4px",
                                                      md: "5px",
                                                      lg: "6px",
                                                    },
                                                    "&:hover": {
                                                      backgroundColor:
                                                        "error.main",
                                                      color: "white",
                                                    },
                                                  }}
                                                  variant="outlined"
                                                  color="error"
                                                  onClick={(e) => {
                                                    const newItemValue =
                                                      itemValues.filter(
                                                        (i) =>
                                                          parseInt(i.itemId) !==
                                                          parseInt(
                                                            request.itemId
                                                          )
                                                      );
                                                    setItemValues(newItemValue);
                                                  }}
                                                >
                                                  <div
                                                    className="row"
                                                    style={{
                                                      marginBottom: "-10px",
                                                      marginLeft: "-10px",
                                                    }}
                                                  >
                                                    <div className="col">
                                                      <FontAwesomeIcon
                                                        style={{
                                                          float: "right",
                                                          marginRight: "-5px",
                                                        }}
                                                        icon={faTrashCan}
                                                      />
                                                    </div>
                                                    <div className="col-8">
                                                      <h6
                                                        style={{
                                                          float: "left",
                                                          marginLeft: "-3px",
                                                        }}
                                                      >
                                                        Delete
                                                      </h6>
                                                    </div>
                                                  </div>
                                                </Button>
                                              </div>
                                            )}

                                            {openUpdate &&
                                              request.itemId ===
                                                update.itemId && (
                                                <div>
                                                  <Button
                                                    sx={{
                                                      width: "90px",
                                                      borderColor:
                                                        "success.main",
                                                      fontSize: {
                                                        xs: "0.7rem",
                                                        sm: "0.85rem",
                                                        md: "1rem",
                                                      },
                                                      padding: {
                                                        xs: "3px",
                                                        sm: "4px",
                                                        md: "5px",
                                                        lg: "6px",
                                                      },
                                                      "&:hover": {
                                                        backgroundColor:
                                                          "success.main",
                                                        color: "white",
                                                      },
                                                    }}
                                                    variant="outlined"
                                                    color="success"
                                                    className="mr-3"
                                                    onClick={(e) => {
                                                      const purchaseItem =
                                                        newPurchaseItems.find(
                                                          (i) =>
                                                            i.itemId ===
                                                            update.itemId
                                                        );

                                                      if (
                                                        parseInt(
                                                          purchaseItem.remainingQuantity
                                                        ) < update.quantity
                                                      ) {
                                                        setMessages(
                                                          `Only available quantity is ${purchaseItem.remainingQuantity}`
                                                        );
                                                        setOpenMessage(true);
                                                        return;
                                                      }

                                                      itemValues[
                                                        index
                                                      ].quantity =
                                                        update.quantity;
                                                      setItemValues([
                                                        ...itemValues,
                                                      ]);
                                                      update.itemId = "";
                                                      update.quantity = "";
                                                      setOpenUpdate(false);
                                                    }}
                                                  >
                                                    <div
                                                      className="row "
                                                      style={{
                                                        marginBottom: "-10px",
                                                        marginLeft: "-20px",
                                                      }}
                                                    >
                                                      <div className="col">
                                                        <FontAwesomeIcon
                                                          style={{
                                                            float: "right",
                                                          }}
                                                          className="fa-regular"
                                                          icon={faFloppyDisk}
                                                        />
                                                      </div>
                                                      <div className="col-6">
                                                        <h6
                                                          style={{
                                                            float: "left",
                                                            marginLeft: "-10px",
                                                          }}
                                                        >
                                                          Save
                                                        </h6>
                                                      </div>
                                                    </div>
                                                  </Button>
                                                  <Button
                                                    sx={{
                                                      width: "100px",
                                                      borderColor: "error.main",
                                                      fontSize: {
                                                        xs: "0.7rem",
                                                        sm: "0.85rem",
                                                        md: "1rem",
                                                      },
                                                      padding: {
                                                        xs: "3px",
                                                        sm: "4px",
                                                        md: "5px",
                                                        lg: "6px",
                                                      },
                                                      "&:hover": {
                                                        backgroundColor:
                                                          "error.main",
                                                        color: "white",
                                                      },
                                                    }}
                                                    variant="outlined"
                                                    color="error"
                                                    onClick={(e) => {
                                                      setOpenUpdate(false);
                                                      setUpdate({
                                                        itemId: "",
                                                        price: "",
                                                        quantity: "",
                                                      });
                                                    }}
                                                  >
                                                    <div
                                                      className="row"
                                                      style={{
                                                        marginBottom: "-10px",
                                                        marginLeft: "-20px",
                                                      }}
                                                    >
                                                      <div className="col">
                                                        <FontAwesomeIcon
                                                          style={{
                                                            float: "right",
                                                            marginRight: "-5px",
                                                          }}
                                                          icon={faXmark}
                                                        />
                                                      </div>
                                                      <div className="col-8">
                                                        <h6
                                                          style={{
                                                            float: "left",
                                                            marginLeft: "-3px",
                                                          }}
                                                        >
                                                          Cancle
                                                        </h6>
                                                      </div>
                                                    </div>
                                                  </Button>
                                                </div>
                                              )}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        )}
                      </Formik>
                      <center className="mt-3">
                        <Row className="mb-3">
                          <Col>
                            <Button
                              sx={{
                                width: "150px",
                                borderColor: "primary.main",
                                fontSize: {
                                  xs: "0.7rem",
                                  sm: "0.85rem",
                                  md: "1rem",
                                },
                                padding: {
                                  xs: "3px",
                                  sm: "4px",
                                  md: "5px",
                                  lg: "6px",
                                },
                                "&:hover": {
                                  backgroundColor: "primary.main",
                                  color: "white",
                                },
                              }}
                              variant="outlined"
                              color="primary"
                              onClick={(e) => setItemValues([])}
                            >
                              <div
                                className="row"
                                style={{
                                  marginBottom: "-10px",
                                  marginLeft: "-15px",
                                }}
                              >
                                <div className="col-2">
                                  <FontAwesomeIcon
                                    style={{
                                      float: "left",
                                      marginLeft: "0px",
                                      marginTop: "2px",
                                      marginBottom: "-4px",
                                    }}
                                    icon={faBackwardStep}
                                  />
                                </div>
                                <div className="col-10">
                                  <h6
                                    style={{
                                      float: "left",
                                      marginLeft: "-5px",
                                      marginTop: "-1px",
                                      marginBottom: "-7px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: {
                                          xs: "0.85rem",
                                          sm: "0.85rem",
                                          md: "1rem",
                                        },
                                      }}
                                    >
                                      Back to list
                                    </Typography>
                                  </h6>
                                </div>
                              </div>
                            </Button>
                          </Col>
                          <Col>
                            <Form onSubmit={formik.handleSubmit}>
                              <Button
                                sx={{
                                  width: "150px",
                                  borderColor: "primary.main",
                                  fontSize: {
                                    xs: "0.7rem",
                                    sm: "0.85rem",
                                    md: "1rem",
                                  },
                                  padding: {
                                    xs: "3px",
                                    sm: "4px",
                                    md: "5px",
                                    lg: "6px",
                                  },
                                  "&:hover": {
                                    backgroundColor: "primary.main",
                                    color: "white",
                                  },
                                }}
                                variant="outlined"
                                color="primary"
                                type="submit"
                              >
                                <div
                                  className="row"
                                  style={{
                                    marginBottom: "-10px",
                                    marginLeft: "-15px",
                                  }}
                                >
                                  <div className="col-3">
                                    <FontAwesomeIcon
                                      style={{
                                        float: "left",
                                        marginLeft: "0px",
                                        marginTop: "2px",
                                        marginBottom: "-4px",
                                      }}
                                      icon={faFloppyDisk}
                                    />
                                  </div>
                                  <div className="col-9">
                                    <h6
                                      style={{
                                        float: "left",
                                        marginLeft: "-5px",
                                        marginTop: "-1px",
                                        marginBottom: "-7px",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: {
                                            xs: "0.85rem",
                                            sm: "0.85rem",
                                            md: "1rem",
                                          },
                                        }}
                                      >
                                        Submit
                                      </Typography>
                                    </h6>
                                  </div>
                                </div>
                              </Button>
                            </Form>
                          </Col>
                        </Row>
                      </center>
                    </div>
                  )}
                </div>
              </div>
              {/* {open === 2 && (
              <div className="card card-info card-outline">
                <div className="card-body">
                  <Container ref={componentRef}>
                    <div className="row">
                      <div className="col-sm-6 ">
                        <br />
                        <h1 style={{ marginLeft: "5vw", marginBottom: "2vh" }}>
                          Invoice
                        </h1>
                        <i
                          style={{ marginLeft: "7vw", marginBottom: "2vh" }}
                          className="fas fa-home fa-3x"
                        />
                      </div>

                      <div className="col-sm-6 ">
                        <br />
                        <div
                          style={{
                            fontSize: 18,
                            textAlign: "right",
                          }}
                        >
                          <p style={{ margin: "0", fontSize: 24 }}>
                            <b> Company Name</b>
                          </p>
                          <p style={{ margin: "0" }}>+251987654321</p>
                          <p style={{ margin: "0" }}>email@gmail.com</p>
                          <p style={{ margin: "0" }}>address1</p>
                          <p style={{ margin: "0" }}>address2</p>
                        </div>
                      </div>
                    </div>
                    <h4>Item Store</h4>
                    <hr />
                    <Row className="">
                      <Col sm={2}>
                        <div>
                          <p>
                            <b>BILL To</b>
                          </p>
                        </div>
                      </Col>

                      <Col sm={5}>
                        <div>
                          <p>
                            <b>Store Name : </b>
                            {
                              stores.find((s) => s.Id === formik.values.storeId)
                                ?.storeName
                            }
                          </p>
                        </div>
                        <div>
                          <p>
                            <b>Store Address : </b>
                            {
                              stores.find((s) => s.Id === formik.values.storeId)
                                ?.address
                            }
                          </p>
                        </div>
                      </Col>
                      <Col sm={5}>
                        <div>
                          <p>
                            <b>RefId : </b>
                            {`1111111`}
                          </p>
                        </div>

                        <div>
                          <p>
                            <b>Date : </b>
                            {`${new Date().getDate()}/${
                              new Date().getMonth() + 1
                            }/${new Date().getFullYear()}`}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <br />
                    <br />
                    <hr />
                    <br />
                    <br />
                    <br />
                    <Row>
                      <Table className="text-center" style={{ width: "100%" }}>
                        <tr>
                          <th>No</th>
                          <th>Desc</th>
                          <th>Model</th>
                          <th>Code</th>
                          <th>Unit</th>
                          <th>Delivery Qty</th>
                          {parseInt(id) === 1 && <th>Remaining Qty</th>}
                          <th>Total Qty</th>
                        </tr>

                        <tbody>
                          {itemValues.map((request, index) => {
                            const item = items.find(
                              (i) => i.Id === request.itemId
                            );
                            let purchaseItem, storeDataItem;
                            if (parseInt(id) === 1) {
                              purchaseItem = newPurchaseItems.find(
                                (p) => p.itemId === request.itemId
                              );
                            }
                            if (parseInt(id) === 2) {
                              storeDataItem = newPurchaseItems.find(
                                (p) => p.itemId === request.itemId
                              );
                              purchaseItem = purchaseItems.find(
                                (p) =>
                                  p.Id === storeDataItem.purchaseDeliveryItemId
                              );
                            }

                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>

                                <td>{item.description}</td>
                                <td>
                                  {
                                    models.find(
                                      (model) => model.Id === item.modelId
                                    )?.modelName
                                  }
                                </td>
                                <td>{item.code}</td>
                                <td>
                                  {
                                    units.find(
                                      (unit) => unit.Id === item.unitId
                                    )?.unitName
                                  }
                                </td>
                                <td>{request.quantity}</td>
                                {parseInt(id) === 1 && (
                                  <td>
                                    {purchaseItem.remainingQuantity -
                                      request.quantity}
                                  </td>
                                )}
                                <td>{purchaseItem.quantity}</td>
                              </tr>
                            );
                          })}
                          {itemValues.length < 5 ? handelInvoice() : ""}
                        </tbody>
                      </Table>
                    </Row>
                    <Row>
                      <Col sm={8}>
                        <h3>Terms</h3>
                      </Col>
                    </Row>
                  </Container>
                </div>
                <div className="card-footer">
                  <ReactToPrint
                    onAfterPrint={(e) => handleBack()}
                    trigger={() => (
                      <Button
                        onClick={(e) => handleBack()}
                        variant="outline-primary"
                        className="ml-3 mt-1"
                        style={{ float: "left" }}
                      >
                        <div
                          className="row"
                          style={{
                            marginBottom: "-10px",
                            marginLeft: "-10px",
                          }}
                        >
                          <div className="col">
                            <FontAwesomeIcon
                              style={{
                                float: "right",
                                marginRight: "-1px",
                              }}
                              icon={faPrint}
                            />
                          </div>
                          <div className="col-6">
                            <h6
                              style={{
                                float: "left",
                                marginLeft: "-10px",
                              }}
                            >
                              Print
                            </h6>
                          </div>
                        </div>
                      </Button>
                    )}
                    content={() => componentRef.current}
                  />
                  <Button
                    onClick={(e) => handleBack()}
                    variant="outline-success"
                    className="ml-3 mt-1"
                    style={{ float: "left" }}
                  >
                    <div
                      className="row"
                      style={{
                        marginBottom: "-10px",
                        marginLeft: "-10px",
                      }}
                    >
                      <div className="col">
                        <FontAwesomeIcon
                          style={{ float: "right", marginRight: "-1px" }}
                          icon={faBackwardStep}
                        />
                      </div>
                      <div className="col-7">
                        <h6
                          style={{
                            float: "left",
                            marginLeft: "-10px",
                          }}
                        >
                          Back
                        </h6>
                      </div>
                    </div>
                  </Button>
                </div>
              </div>
            )} */}
            </div>
            <Formik
              initialValues={{
                storeName: "",
                address: "",
              }}
              validationSchema={Yup.object().shape({
                storeName: Yup.string()
                  .required("Full name is required.")
                  .min(3, "Too Short!")
                  .max(60, "Too Long"),

                address: Yup.string()
                  .required("Address is required")
                  .min(3, "Too Short!")
                  .max(60, "Too Long"),
              })}
              onSubmit={async (values) => {
                if (itemValues.length === 0) return;
                try {
                  if (isSubmitted) return;
                    setIsSubmitted(true);
                  await axios
                    .post(
                      "https://enin.server.highendtradingplc.com/store/add",
                      values
                    )
                    .then(async (res) => {
                      for (let i = 0; i < items.length; i++) {
                        await axios.post(
                          "https://enin.server.highendtradingplc.com/store_item/add",
                          {
                            itemId: items[i].Id,
                            storeId: res.data.data.Id,
                            quantity: 0,
                          }
                        );
                      }
                    });
                    setIsSubmitted(false);
                  fetchAllData();
                  setOpenStore(false);
                  values.storeName = "";
                  values.address = "";
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              {(props) => (
                <Modal
                  show={openStore}
                  onHide={(e) => setOpenStore(false)}
                  style={{ minWidth: "700px" }}
                >
                  <Modal.Header closeButton>
                    <span>Purchase Delivery / Store / Create</span>
                  </Modal.Header>
                  <Modal.Body>
                    <Form
                      className="m-2 ml-5 pl-5"
                      onSubmit={props.handleSubmit}
                    >
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formHorizontalUsername"
                      >
                        <Col sm={8}>
                          <TextField
                            id="outlined-basic"
                            sx={{
                              width: "250px",
                              "& .MuiInputBase-root": {
                                fontSize: {
                                  xs: "0.7rem",
                                  sm: "0.85rem",
                                  md: "0.9rem",
                                },
                                padding: {
                                  xs: "1px",
                                  sm: "2px",
                                  md: "3px",
                                  lg: "4px",
                                },
                              },
                            }}
                            variant="outlined"
                            label="Store Name"
                            size="small"
                            type="text"
                            name="storeName"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.storeName}
                            error={
                              props.touched.storeName && props.errors.storeName
                            }
                            helperText={
                              props.touched.storeName && props.errors.storeName
                                ? `${props.errors.storeName}`
                                : ""
                            }
                            style={{ maxWidth: "300px" }}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formHorizontalUsername"
                      >
                        <Col sm={8}>
                          <TextField
                            id="outlined-basic"
                            sx={{
                              width: "250px",
                              "& .MuiInputBase-root": {
                                fontSize: {
                                  xs: "0.7rem",
                                  sm: "0.85rem",
                                  md: "0.9rem",
                                },
                                padding: {
                                  xs: "1px",
                                  sm: "2px",
                                  md: "3px",
                                  lg: "4px",
                                },
                              },
                            }}
                            label="Address"
                            variant="outlined"
                            size="small"
                            type="text"
                            name="address"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.address}
                            error={
                              props.touched.address && props.errors.address
                            }
                            helperText={
                              props.touched.address && props.errors.address
                                ? `${props.errors.address}`
                                : ""
                            }
                            style={{ maxWidth: "300px" }}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formHorizontalUsername"
                      >
                        <Col sm={12}>
                          <Button
                            sx={{
                              width: "250px",
                              borderColor: "success.main",
                              fontSize: {
                                xs: "0.85rem",
                                sm: "0.85rem",
                                md: "1rem",
                              },
                              padding: {
                                xs: "4px",
                                sm: "5px",
                                md: "6px",
                                lg: "6px",
                              },
                              "&:hover": {
                                backgroundColor: "success.main",
                                color: "white",
                              },
                            }}
                            variant="outlined"
                            color="success"
                            type="submit"
                          >
                            Create
                          </Button>
                        </Col>
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                </Modal>
              )}
            </Formik>
          </center>
        </main>
      </Typography>
    </ThemeProvider>
  );
};

export default PurchaseItemDelivery;
