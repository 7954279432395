/** @format */
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Modal, Form, Col, Row } from "react-bootstrap";
import { useFormik, Formik } from "formik";
import TextField from "@mui/material/TextField";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Header from "../Header";
import {
  faBackwardStep,
  faEye,
  faFileExport,
} from "@fortawesome/free-solid-svg-icons";
import {
  Alert,
  AlertTitle,
  MenuItem,
  Stack,
  Button,
  createTheme,
  useMediaQuery,
  ThemeProvider,
  Typography,
  Box,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Snackbar,
} from "@mui/material";
import axios from "axios";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QRCode from "qrcode";

import * as XLSX from "xlsx";
import HexCharacterManipulator from "./AllDataFetch";
import { jwtDecode } from "jwt-decode";
import { Close } from "@mui/icons-material";
const theme = createTheme({
  breakpoints: { values: { xs: 0, sm: 500, md: 769, lg: 1024, xl: 1440 } },
});
const ItemPage = () => {
  const [open, setOpen] = useState(false);
  const [logicals, setLogicals] = useState([]);
  const [physicals, setPhysicals] = useState([]);
  const [progressOpen, setProgressOpen] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [starts, setStarts] = useState(false);
  const [check, setCheck] = useState(false);
  const [units, setUnits] = useState([]);
  const [models, setModels] = useState([]);
  const [taxs, setTaxs] = useState([]);
  const [items, setItems] = useState([]);
  const [storeItems, setStoreItems] = useState([]);
  const [popup, setPopup] = useState(false);
  const [popupM, setPopupM] = useState(false);
  const [popupT, setPopupT] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const [qrItem, setQRItem] = useState(null);
  const [show, setShow] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [idvalue, setidvalue] = useState({
    idValue: 0,
  });
  const [users, setUser] = useState({
    password: "",
    id: "",
  });
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const [stores, setStores] = useState(null);
  const [warnings, setWarings] = useState([0, 0]);
  const [handle, setHandle] = useState({
    item: true,
    createItem: false,
    createModel: false,
    addType: false,
    addModel: false,
    addUnit: false,
    addTax: false,
  });
  const userDatas = jwtDecode(sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isUnderMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const handlePopup = () => {
    setPopup(false);
  };
  const handlePopupCheck = () => {
    setCheck(false);
  };
  const handlePopupM = () => {
    setPopupM(false);
  };
  const handleOpen = () => {
    setStarts(false);
  };
  const handleItem = () => {
    setHandle((prev) => ({ item: true }));
    clear();
  };

  const handleUnit = () => {
    setPopup(true);
    setHandle((prev) => ({ addUnit: true, createItem: true }));
  };
  const handleCheck = () => {
    setCheck(true);
  };
  const handleModel = () => {
    setPopupM(true);
    setHandle((prev) => ({ addModel: true, createItem: true }));
  };
  const handleTax = () => {
    setPopupT(true);
    setHandle((prev) => ({ addTax: true, createItem: true }));
  };
  const createItem = () => {
    if (userDatas.roleId !== 2) {
      setOpen(true);
      return;
    }
    setHandle((prev) => ({ createItem: true }));
  };
  const fetchAllData = async () => {
    try {
      const [unit, item, model, tax, store, sotreitem, logical, physical] =
        await Promise.all([
          axios.get("https://enin.server.highendtradingplc.com/unit"),
          axios.get("https://enin.server.highendtradingplc.com/item"),
          axios.get("https://enin.server.highendtradingplc.com/model"),
          axios.get("https://enin.server.highendtradingplc.com/tax"),
          axios.get("https://enin.server.highendtradingplc.com/store"),
          axios.get("https://enin.server.highendtradingplc.com/store_item"),
          axios.get("https://enin.server.highendtradingplc.com/logical_stock"),
          axios.get("https://enin.server.highendtradingplc.com/physical_stock"),
        ]);
      setUnits(unit.data);
      setModels(model.data);
      setItems(item.data);
      setTaxs(tax.data);
      setStores(store.data);
      setStoreItems(sotreitem.data);
      setLogicals(logical.data);
      setPhysicals(physical.data);
    } catch (error) {
      console.log(error);
    }
  };
  const descriptionElementRef = useRef(null);
  useEffect(() => {
    fetchAllData();
    if (error) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [error]);

  const validationSchema = Yup.object().shape({
    description: Yup.string()
      .matches(
        /^[A-Za-z0-9 ]{3,30}$/,
        "Description should be 3-30 alphanumeric characters."
      )
      .required("Name is required"),
    code: Yup.string()
      .matches(
        /^[A-Za-z0-9 ]{3,20}$/,
        "Code should be 3-20 alphanumeric characters."
      )
      .required("Code is required"),
    modelId: Yup.string().required("Model is required"),
    taxId: Yup.string().required("Tax is required"),
    statusId: Yup.string().required("Item Type is required"),
    unitId: Yup.string().required("Unit is required"),
    price: Yup.number()
      .min(1, "Too Low!")
      .max(100000, "Too High!")
      .required("Price is required"),
  });
  const formik = useFormik({
    initialValues: {
      description: "",
      modelId: "",
      taxId: "",
      code: "",
      unitId: "",
      price: "",
      statusId: "",
      id: "",
    },

    validationSchema,
    onSubmit: async (values) => {
      if (isSubmitted) return;
      setIsSubmitted(true);
      try {
        formik.setSubmitting(true);
        const { description } = values;
        const userExists = items.some(
          (user) => user.description === description
        );
        if (values.id === "") {
          if (userExists) {
            formik.setErrors({
              description: "Item already exists.",
            });
            setIsSubmitted(false);
            return;
          }
          setProgressOpen(true);
          await axios
            .post(`https://enin.server.highendtradingplc.com/item/add`, values)
            .then(async (res) => {
              for (let i = 0; i < stores.length; i++) {
                await axios.post(
                  "https://enin.server.highendtradingplc.com/store_item/add",
                  {
                    itemId: res.data.data.Id,
                    storeId: stores[i].Id,
                    quantity: 0,
                  }
                );
              }
            });
          setHandle((prev) => ({ ...prev, item: true, createItem: false }));
        } else if (idvalue.idValue !== 0) {
          const { description } = values;
          const userExists = items.some(
            (user) => user.Id !== values.id && user.description === description
          );

          if (userExists) {
            formik.setErrors({
              description: "Item already exists.",
            });
            setIsSubmitted(false);
            return;
          } else {
            if (warnings[0] === 0) {
              warnings[0] = 2;
              warnings[1] = values.id;
              setWarings([...warnings]);
              setIsSubmitted(false);
              return;
            }
            setProgressOpen(true);
            await axios.put(
              "https://enin.server.highendtradingplc.com/item/update/" +
                values.id,
              values
            );
            setWarings([0, 0]);
            handle.createItem = false;
            handle.item = true;
          }
        }
        clear();
        fetchAllData();
      } catch (error) {
        console.log(error);
      }
      setIsSubmitted(false);
      setProgressOpen(false);
      formik.setSubmitting(false);
    },
  });
  const clear = () => {
    formik.values.modelId = "";
    formik.values.unitId = "";
    formik.values.taxId = "";
    formik.values.description = "";
    formik.values.code = "";
    formik.values.price = "";
    formik.values.id = "";
    formik.values.statusId = "";
  };
  const generateQRCode = (text) => {
    QRCode.toDataURL(text)
      .then((url) => {
        setQrCodeUrl(url); // Set the QR code image URL
      })
      .catch((err) => console.error("Error generating QR code:", err));
  };

  const ends = async () => {
    setProgressOpen(true);
    try {
      let data = [];
      let id = [];
      storeItems.map((store) => {
        const i = items.find((item) => item.Id === store.itemId);
        const s = stores.find((st) => st.Id === store.storeId);
        const newData = {
          storeName: s.storeName,
          description: i.description,
          address: s.address,
          code: i.code,
          model: models.find((t) => t.Id === i.modelId)?.modelName,
          unit: units.find((t) => t.Id === i.unitId)?.unitName,
          taxType: taxs.find((t) => t.Id === i.taxId)?.taxType,
          taxValue: taxs.find((t) => t.Id === i.taxId)?.taxValue,
          price: i.price,
          quantity: store.quantity,
          itemType: i.statusId === 1 ? "Service" : "Consumable",
        };
        const itemId = {
          Id: i.Id,
        };
        data.push(newData);
        id.push(itemId);
        return null;
      });

      // Create a new workbook and a new worksheet
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      // Write to file and trigger download
      XLSX.writeFile(wb, "item_data.xlsx");

      setProgressOpen(false);
      setCheck(false);
      users.password = "";
      setUser(users);
    } catch (error) {
      console.log(error);
    }
  };
  const start = () => {
    setStarts(true);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const workbook = XLSX.read(event.target.result, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet);
      
      setData(sheetData);
    };
    reader.readAsBinaryString(file);
  };
  const handleCSVSubmit = async (e) => {
    setProgressOpen(true);
    e.preventDefault();
    setShow([]);
    let checkError = true;
    try {
      let model = [],
        unit = [],
        taxValue = [],
        storeEror = [],
        description = [],
        storeName = [];
      for (let i = 0; i < data.length; i = i + 1) {
        model.push(data[i].model);
        unit.push(data[i].unit);
        description.push(data[i].description);
        storeName.push(data[i].storeName);

        if (!taxValue.includes(data[i].taxType)) {
          taxValue.push(data[i].taxType);
        }
        if (
          data[i].itemType.toLowerCase().at(0) !== "s" &&
          data[i].itemType.toLowerCase().at(0) !== "c"
        ) {
          let ee = [];
          ee = {
            name: "Invalid Item Type",
            line: data[i].itemType,
          };
          storeEror.push(ee);
          checkError = false;
        }
      }
      model = [...new Set(model)];
      unit = [...new Set(unit)];
      description = [...new Set(description)];
      storeName = [...new Set(storeName)];
      const checkDescription = new Set([
        ...items.map((i) => i.description),
        ...description,
      ]);
      // const checkStoreName = new Set([
      //   ...stores.map((i) => i.storeName),
      //   ...storeName,
      // ]);
      if (checkDescription.size !== items.length + description.length) {
        storeEror.push({
          name: "Duplicate previous description",
          line: "Check the description",
        });
        checkError = false;
      }
      // if (checkStoreName.size !== stores.length + storeName.length) {
      //   storeEror.push({
      //     name: "Duplicate previous store name",
      //     line: "Check the store name",
      //   });
      //   checkError = false;
      // }
      setShow(storeEror);
      if (checkError) {
        let insertDataUnit = [],
          insertDataModel = [],
          insertDataTax = [],
          insertDataItem = [],
          insertDataStore = [],
          firstItem = { Id: "", description: "" };
        for (let i = 0; i < model.length; ) {
          const checkModel = models?.find((m) => m.modelName === model[i]);
          if (!checkModel) {
            await axios
              .post(`https://enin.server.highendtradingplc.com/model/add`, {
                modelName: model[i],
              })
              .then((res) => {
                insertDataModel.push({
                  Id: res.data.data.Id,
                  modelName: model[i],
                });
                i = i + 1;
              });
          } else {
            insertDataModel.push({
              Id: checkModel.Id,
              modelName: checkModel.modelName,
            });
            i = i + 1;
          }
        }
        for (let i = 0; i < unit.length; ) {
          const checkUnit = units?.find((u) => u.unitName === unit[i]);
          if (!checkUnit) {
            await axios
              .post(`https://enin.server.highendtradingplc.com/unit/add`, {
                unitName: unit[i],
              })
              .then((res) => {
                insertDataUnit.push({
                  Id: res.data.data.Id,
                  unitName: unit[i],
                });
                i = i + 1;
              });
          } else {
            insertDataUnit.push({
              Id: checkUnit.Id,
              unitName: checkUnit.unitName,
            });
            i = i + 1;
          }
        }
        for (let i = 0; i < taxValue.length; ) {
          const checkTax = taxs?.find((t) => t.taxType === taxValue[i]);
          console.log(taxs);
          if (!checkTax) {
            await axios
              .post(`https://enin.server.highendtradingplc.com/tax/add`, {
                taxType: taxValue[i],
                taxValue: parseFloat(taxValue[i + 1]),
              })
              .then((res) => {
                insertDataTax.push({
                  Id: res.data.data.Id,
                  taxType: taxValue[i],
                });
                i = i + 2;
              });
          } else {
            insertDataTax.push({ Id: checkTax.Id, taxType: checkTax.taxType });
            i = i + 2;
          }
        }

        description.map(async (desc, index) => {
          const quantity = data
            .map((d) => {
              return d.description === desc ? d.quantity : 0;
            })
            .reduce((acc, crr) => acc + crr, 0);
          const d = data.find((da) => da.description === desc);
          let values = {
            description: d.description,
            code: d.code,
            price: d.price,
            statusId: d.itemType.toLowerCase().at(0) === "s" ? 1 : 2,
            modelId: insertDataModel.find((t) => t.modelName === d.model)?.Id,
            taxId: insertDataTax.find(
              (t) => t.taxType === d.taxType || t?.taxValue === d.taxValue
            )?.Id,
            unitId: insertDataUnit.find((t) => t.unitName === d.unit)?.Id,
          };
          await axios
            .post(`https://enin.server.highendtradingplc.com/item/add`, values)
            .then(async (res) => {
              insertDataItem.push(res.data.data);
              if (index === 0) {
                firstItem.Id = res.data.data.Id;
                firstItem.description = res.data.data.description;
              }
              await axios
                .post(
                  "https://enin.server.highendtradingplc.com/transaction/add",
                  {
                    quantity: quantity,
                    date: new Date(),
                    statusId: 3,
                  }
                )
                .then(async (res12) => {
                  await axios.post(
                    "https://enin.server.highendtradingplc.com/logical_stock/add",
                    {
                      itemId: res.data.data.Id,
                      quantity: quantity,
                      date: new Date(),
                      transactionId: res12.data.data.Id,
                      statusId: 1,
                      ADPRS_ID: null,
                    }
                  );
                  await axios.post(
                    "https://enin.server.highendtradingplc.com/physical_stock/add",
                    {
                      itemId: res.data.data.Id,
                      quantity: quantity,
                      date: new Date(),
                      transactionId: res12.data.data.Id,
                      statusId: 1,
                      ADPRS_ID: null,
                    }
                  );
                });
            });
          return null;
        });
        storeName.map(async (s) => {
          const d = data.find((da) => da.storeName === s);
          const store = stores.find((ss) => ss.storeName === s);
          if (!store) {
            await axios
              .post("https://enin.server.highendtradingplc.com/store/add", {
                storeName: d.storeName,
                address: d.address,
              })
              .then((res) => {
                insertDataStore.push(res.data.data);
              });
          } else {
            insertDataStore.push(store);
          }
          return 0;
        });
        fetchAllData()
          .then(async () => {
            const storeLength = stores.length;
            for (let i = 0; i < storeLength * description.length; ) {
              const store = stores.find(
                (s, index) => index === i % storeLength
              );
              const item =
                i === 0
                  ? firstItem
                  : insertDataItem.find(
                      (d) =>
                        d.description === description[i - (i % storeLength)]
                    );
              const quantity =
                data.find(
                  (d) =>
                    d.storeName === store?.storeName &&
                    d.description === item?.description
                )?.quantity || 0;
              await axios
                .post(
                  "https://enin.server.highendtradingplc.com/store_item/add",
                  {
                    storeId: store?.Id,
                    itemId: item?.Id,
                    quantity: quantity,
                  }
                )
                .then((res) => (i = i + 1));
            }
          })
          .then(() => {
            //setProgressOpen(false);
            window.location.reload();
          });
      } else {
        setProgressOpen(false);
        setStarts(false);
        setError(true);
      }

      setStarts(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handelchange = (e) => {
    setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handelclick = async () => {
    users.id = userDatas.Id;
    if (isSubmitted) return;
    setIsSubmitted(true);
    await axios
      .post("https://enin.server.highendtradingplc.com/check", users)
      .then((res) => {
        console.log(res.data.Login);
        if (res.data.Login) {
          ends();
        } else {
          setPasswordError(true);
        }
      })
      .catch((err) => console.log(err));
    setIsSubmitted(false);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `https://enin.server.highendtradingplc.com/item/delete/${id}`
      );
      setItems(items.filter((item) => item.Id !== id));
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdate = async (id) => {
    const item = items.find((i) => i.Id === id);
    formik.values.modelId = item.modelId;
    formik.values.price = item.price;
    formik.values.unitId = item.unitId;
    formik.values.taxId = item.taxId;
    formik.values.description = item.description;
    formik.values.code = item.code;
    formik.values.physicalQuantity = physicals.find(
      (l) => l.itemId === item.Id
    )?.quantity;
    formik.values.logicalQuantity = logicals.find(
      (l) => l.itemId === item.Id
    )?.quantity;
    formik.values.statusId = item.statusId;
    formik.values.id = id;
    setidvalue(id);
    createItem();
    formik.setErrors([]);
  };
  const columnItem = [
    {
      field: "id",
      headerName: "No",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Id",
      headerName: "Item Id",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "description",
      headerName: "description",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "model",
      headerName: "Model",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "code",
      headerName: "Code",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "unit",
      headerName: "Unit",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "qty",
      headerName: "Available Qty",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "action",
      headerName: "Action",
      width: 350,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <Button
            onClick={(e) => {
              const hexCharacterManipulator = new HexCharacterManipulator();
              let charTohexa = hexCharacterManipulator.charTohexa(
                [params.row.pQty],
                params.row.qty
              );
              const data = `
           Item ID : ${charTohexa},
            Description : ${params.row.description},
            Code : ${params.row.code},
            Model : ${params.row.model},
            Unit : ${params.row.unit},
            Available Qty : ${params.row.qty}`;
              const qrData = {
                Id: params.row.Id,
                description: params.row.description,
                code: params.row.code,
                model: params.row.model,
                unit: params.row.unit,
                qty: params.row.qty,
              };
              setQRItem(qrData);
              generateQRCode(data);
            }}
            sx={{
              width: "90px",
              borderColor: "primary.main",
              fontSize: {
                xs: "0.7rem",
                sm: "0.85rem",
                md: "1rem",
              },
              padding: {
                xs: "3px",
                sm: "4px",
                md: "5px",
                lg: "6px",
              },
              "&:hover": {
                backgroundColor: "primary.main",
                color: "white",
              },
            }}
            variant="outlined"
            color="primary"
            className="m-1"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-7px" }}
                  icon={faEye}
                />
              </div>
              <div className="col-7">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-3px",
                  }}
                >
                  View
                </h6>
              </div>
            </div>
          </Button>
          <Button
            onClick={() => {
              if (userDatas.roleId !== 2) {
                setOpen(true);
                return;
              }
              handleUpdate(params.row.Id);
            }}
            sx={{
              width: "90px",
              borderColor: "warning.main",
              fontSize: {
                xs: "0.7rem",
                sm: "0.85rem",
                md: "1rem",
              },
              padding: {
                xs: "3px",
                sm: "4px",
                md: "5px",
                lg: "6px",
              },
              "&:hover": {
                backgroundColor: "warning.main",
                color: "white",
              },
            }}
            variant="outlined"
            color="warning"
            className="m-1"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-7px" }}
                  icon={faPenToSquare}
                />
              </div>
              <div className="col-7">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-3px",
                  }}
                >
                  Edit
                </h6>
              </div>
            </div>
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              if (userDatas.roleId !== 2) {
                setOpen(true);
                return;
              }
              warnings[1] = params.row.Id;
              warnings[0] = 1;
              setWarings([...warnings]);
            }}
            sx={{
              width: "110px",
              borderColor: "error.main",
              fontSize: {
                xs: "0.7rem",
                sm: "0.85rem",
                md: "1rem",
              },
              padding: {
                xs: "3px",
                sm: "4px",
                md: "5px",
                lg: "6px",
              },
              "&:hover": {
                backgroundColor: "error.main",
                color: "white",
              },
            }}
            variant="outlined"
            color="error"
            className=""
          >
            <div
              className="row "
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-5px" }}
                  icon={faTrashCan}
                />
              </div>
              <div className="col-8">
                <h6 style={{ float: "left", marginLeft: "-3px" }}>Delete</h6>
              </div>
            </div>
          </Button>
        </>
      ),
    },
  ];

  const rows = items.map((item, index) => {
    const unit = units.find((u) => u.Id === item.unitId);
    const model = models.find((u) => u.Id === item.modelId);
    const logical = logicals.find((l) => l.itemId === item.Id);
    const physical = physicals.find((p) => p.itemId === item.Id);
    return {
      id: index + 1,
      Id: item.Id,
      description: item.description,
      code: item.code,
      model: model?.modelName,
      unit: unit?.unitName,
      price: item.price,
      qty: logical?.quantity || 0,
      pQty: physical?.quantity || 0,
    };
  });

  return (
    <ThemeProvider theme={theme}>
      <Typography
        sx={{
          fontSize: {
            xs: "12px",
            sm: "12px", //500
            md: "0.85rem", //768
            lg: "17px", //1024
          },
        }}
      >
        <div>
          <Header />
        </div>
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: "13px",
            sm: "14px", //500
            md: "16px", //768
            lg: "18px", //1024
          },
        }}
      >
        <main
          className="inventory-page"
          style={
            isMobile && !isUnderMobile
              ? { marginTop: "190px" }
              : isUnderMobile
              ? { marginTop: "170px" }
              : isTablet
              ? { marginTop: "133px" }
              : { marginTop: "150px" }
          }
        >
          <center>
            <div
              style={
                isUnderMobile
                  ? {
                      minWidth: "500px",
                      overflow: "scroll",
                      marginBottom: "-5px",
                    }
                  : { maxWidth: "1100px" }
              }
              className="text-left"
            >
              <div
                className="tab-container "
                style={{
                  marginTop: "-20px",
                  marginBottom: "-5px",
                }}
              >
                <Link
                  className="p-2"
                  onClick={(e) => {
                    window.location.reload();
                  }}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "5px 5px 0 0",
                    border: "solid 0.05em rgb(181, 181, 181)",
                  }}
                >
                  Item
                </Link>
                <Link className="p-2" to={`/unit`}>
                  Unit
                </Link>
                <Link className="p-2" to={`/model`}>
                  Model
                </Link>
                <Link className="p-2" to={`/tax`}>
                  Tax
                </Link>
              </div>
              <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={(e) => setOpen(false)}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <Alert
                  severity="error"
                  onClose={(e) => setOpen(false)}
                  variant="outlined"
                  sx={{ bgcolor: "background.paper" }}
                >
                  <AlertTitle>Access Denied</AlertTitle>
                  You do not have permission to access this page.
                </Alert>
              </Snackbar>
              <Backdrop
                sx={(theme) => ({
                  color: "#fff",
                  zIndex: theme.zIndex.drawer + 1,
                })}
                open={progressOpen}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <div className="card card-info card-outline">
                <div className="card-header">
                  {qrCodeUrl === null && (
                    <>
                      {" "}
                      {!handle.createItem && (
                        <Button
                          sx={{
                            width: "150px",
                            borderColor: "info.main",
                            fontSize: {
                              xs: "0.7rem",
                              sm: "0.85rem",
                              md: "1rem",
                            },
                            padding: {
                              xs: "0",
                              sm: "0",
                              md: "1px",
                              lg: "3px",
                            },
                            "&:hover": {
                              backgroundColor: "info.main",
                              color: "white",
                            },
                          }}
                          variant="outlined"
                          color="info"
                          onClick={createItem}
                        >
                          <h4
                            style={{
                              float: "left",
                              marginTop: "-1px",
                              marginRight: "5px",
                              marginBottom: "-2px",
                            }}
                          >
                            +
                          </h4>
                          <strong
                            style={{
                              float: "left",
                              marginTop: "0",
                              marginBottom: "-2px",
                            }}
                          >
                            {" "}
                            Create New
                          </strong>
                        </Button>
                      )}
                      {!handle.createItem && items?.length > 0 && (
                        <Button
                          className="ml-2"
                          sx={{
                            width: "150px",
                            borderColor: "info.main",
                            fontSize: {
                              xs: "0.7rem",
                              sm: "0.85rem",
                              md: "1rem",
                            },
                            padding: {
                              xs: "0",
                              sm: "0",
                              md: "1px",
                              lg: "3px",
                            },
                            "&:hover": {
                              backgroundColor: "info.main",
                              color: "white",
                            },
                          }}
                          variant="outlined"
                          color="info"
                          style={{
                            width: "120px",
                            height: "35px",
                            float: "right",
                          }}
                          onClick={handleCheck}
                        >
                          <div
                            className="row"
                            style={{
                              marginBottom: "-10px",
                              marginLeft: "-10px",
                            }}
                          >
                            <div className="col">
                              <FontAwesomeIcon
                                style={{ float: "right", marginRight: "-7px" }}
                                icon={faFileExport}
                              />
                            </div>
                            <div className="col-7">
                              <h6
                                style={{
                                  float: "left",
                                  marginLeft: "-3px",
                                }}
                              >
                                <strong>End</strong>
                              </h6>
                            </div>
                          </div>
                        </Button>
                      )}
                      {!handle.createItem && (
                        <>
                          <Button
                            className="mr-2"
                            sx={{
                              width: "150px",
                              borderColor: "info.main",
                              fontSize: {
                                xs: "0.7rem",
                                sm: "0.85rem",
                                md: "1rem",
                              },
                              padding: {
                                xs: "0",
                                sm: "0",
                                md: "1px",
                                lg: "3px",
                              },
                              "&:hover": {
                                backgroundColor: "info.main",
                                color: "white",
                              },
                            }}
                            variant="outlined"
                            color="info"
                            style={{
                              width: "100px",
                              height: "35px",
                              float: "right",
                            }}
                            onClick={start}
                          >
                            <h3
                              style={{
                                float: "left",
                                marginTop: "5px",
                                marginRight: "5px",
                              }}
                            >
                              <strong>+</strong>
                            </h3>
                            <h6 style={{ float: "left", marginTop: "7px" }}>
                              <strong>Begin</strong>
                            </h6>
                          </Button>
                        </>
                      )}
                      {handle.createItem && (
                        <div>
                          <h5 style={{ float: "left" }}>
                            <Typography
                              sx={{
                                fontSize: {
                                  xs: "1.3rem",
                                  sm: "1.3rem",
                                  md: "1.5rem",
                                },
                              }}
                            >
                              {formik.values.id === ""
                                ? "Create New"
                                : "Update"}{" "}
                              Item
                            </Typography>
                          </h5>
                          <Button
                            onClick={handleItem}
                            sx={{
                              width: "100px",
                              borderColor: "success.main",
                              fontSize: {
                                xs: "0.85rem",
                                sm: "0.85rem",
                                md: "1rem",
                              },
                              padding: {
                                xs: "4px",
                                sm: "5px",
                                md: "6px",
                                lg: "6px",
                              },
                              "&:hover": {
                                backgroundColor: "success.main",
                                color: "white",
                              },
                            }}
                            variant="outlined"
                            color="success"
                            className="m-1"
                            style={{ float: "right" }}
                          >
                            <div
                              className="row"
                              style={{
                                marginBottom: "-10px",
                                marginLeft: "-10px",
                              }}
                            >
                              <div className="col">
                                <FontAwesomeIcon
                                  style={{
                                    float: "right",
                                    marginRight: "-1px",
                                    marginTop: "4px",
                                  }}
                                  icon={faBackwardStep}
                                />
                              </div>
                              <div className="col-7">
                                <h6
                                  style={{
                                    float: "left",
                                    marginLeft: "-10px",
                                    marginTop: "1px",
                                    marginBottom: "-3px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: {
                                        xs: "0.85rem",
                                        sm: "0.85rem",
                                        md: "1rem",
                                      },
                                    }}
                                  >
                                    Back
                                  </Typography>
                                </h6>
                              </div>
                            </div>
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                  {qrCodeUrl !== null && (
                    <div>
                      <Button
                        onClick={(e) => {
                          setQRItem(null);
                          setQrCodeUrl(null);
                        }}
                        sx={{
                          width: "100px",
                          borderColor: "success.main",
                          fontSize: {
                            xs: "0.85rem",
                            sm: "0.85rem",
                            md: "1rem",
                          },
                          padding: {
                            xs: "4px",
                            sm: "5px",
                            md: "6px",
                            lg: "6px",
                          },
                          "&:hover": {
                            backgroundColor: "success.main",
                            color: "white",
                          },
                        }}
                        variant="outlined"
                        color="success"
                        className="m-1"
                        style={{ float: "right" }}
                      >
                        <div
                          className="row"
                          style={{
                            marginBottom: "-10px",
                            marginLeft: "-10px",
                          }}
                        >
                          <div className="col">
                            <FontAwesomeIcon
                              style={{
                                float: "right",
                                marginRight: "-1px",
                                marginTop: "4px",
                              }}
                              icon={faBackwardStep}
                            />
                          </div>
                          <div className="col-7">
                            <h6
                              style={{
                                float: "left",
                                marginLeft: "-10px",
                                marginTop: "1px",
                                marginBottom: "-3px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: {
                                    xs: "0.85rem",
                                    sm: "0.85rem",
                                    md: "1rem",
                                  },
                                }}
                              >
                                Back
                              </Typography>
                            </h6>
                          </div>
                        </div>
                      </Button>
                    </div>
                  )}
                </div>
                <div className="card-body">
                  {qrCodeUrl === null && (
                    <>
                      {" "}
                      {handle.createItem && (
                        <div>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: "0.85rem",
                                sm: "0.85rem",
                                md: "1rem",
                              },
                            }}
                          >
                            {" "}
                            <div
                              className="nav-bra pl-3 ml-1 p-2"
                              style={{
                                backgroundColor: "rgb(235, 235, 235)",
                              }}
                            >
                              <span>
                                Home / Item /{" "}
                                {formik.values.id === "" ? "Create" : "Update"}
                              </span>
                            </div>
                            <hr />
                          </Typography>
                        </div>
                      )}
                      {handle.item && (
                        <div>
                          <div
                            className="nav-bra pl-3 ml-1 p-2"
                            style={{
                              backgroundColor: "rgb(235, 235, 235)",
                            }}
                          >
                            <span>Item / Home</span>
                          </div>
                          <hr />
                        </div>
                      )}
                      {handle.item && (
                        <div
                          style={{
                            overflow: "scroll",
                          }}
                        >
                          <Box
                            sx={{
                              height: 500,

                              width: {
                                lg: "100%",
                                md: "1000px",
                                sm: "1000px",
                                xs: "1000px",
                              },
                              "& .super-app-theme--header": {
                                backgroundColor: "#1de5ec",
                                color: "black",
                                fontSize: {
                                  xs: "13px",
                                  sm: "15px", //500
                                  md: "17px", //768
                                  lg: "18px", //1024
                                },
                              },
                            }}
                          >
                            <DataGrid
                              rows={rows}
                              disableColumnFilter
                              disableColumnSelector
                              disableDensitySelector
                              sx={{
                                "& .MuiDataGrid-cell": {
                                  fontSize: {
                                    xs: "12px",
                                    sm: "14px", //500
                                    md: "16px", //768
                                    lg: "17px", //1024
                                  },
                                },
                              }}
                              columns={columnItem}
                              slots={{ toolbar: GridToolbar }}
                              slotProps={{
                                toolbar: {
                                  showQuickFilter: true,
                                },
                              }}
                              initialState={{
                                ...items.initialState,
                                pagination: {
                                  paginationModel: { pageSize: 5 },
                                },
                              }}
                              pageSizeOptions={[5, 10, 15]}
                              getRowId={(row) => row.Id}
                            />
                          </Box>
                        </div>
                      )}
                      {handle.createItem && (
                        <div>
                          <center>
                            <div>
                              <Form
                                lassName="ml-5 pl-5"
                                onSubmit={formik.handleSubmit}
                              >
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  controlId="formHorizontalUsername"
                                >
                                  <Col sm={12}>
                                    <TextField
                                      id="outlined-basic"
                                      sx={{
                                        width: "250px",
                                        "& .MuiInputBase-root": {
                                          fontSize: {
                                            xs: "0.7rem",
                                            sm: "0.85rem",
                                            md: "0.9rem",
                                          },
                                          padding: {
                                            xs: "1px",
                                            sm: "2px",
                                            md: "3px",
                                            lg: "4px",
                                          },
                                        },
                                      }}
                                      label="description"
                                      variant="outlined"
                                      type="text"
                                      size="small"
                                      name="description"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.description}
                                      error={
                                        formik.touched.description &&
                                        formik.errors.description
                                      }
                                      helperText={
                                        formik.touched.description &&
                                        formik.errors.description
                                          ? `${formik.errors.description}`
                                          : ""
                                      }
                                      style={{ width: "300px" }}
                                    />
                                  </Col>
                                </Form.Group>

                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  controlId="formHorizontalUsername"
                                >
                                  <Col sm={12}>
                                    <TextField
                                      id="outlined-basic"
                                      sx={{
                                        width: "250px",
                                        "& .MuiInputBase-root": {
                                          fontSize: {
                                            xs: "0.7rem",
                                            sm: "0.85rem",
                                            md: "0.9rem",
                                          },
                                          padding: {
                                            xs: "1px",
                                            sm: "2px",
                                            md: "3px",
                                            lg: "4px",
                                          },
                                        },
                                      }}
                                      label="Price"
                                      variant="outlined"
                                      size="small"
                                      name="price"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.price}
                                      error={
                                        formik.touched.price &&
                                        formik.errors.price
                                      }
                                      helperText={
                                        formik.touched.price &&
                                        formik.errors.price
                                          ? `${formik.errors.price}`
                                          : ""
                                      }
                                      style={{ width: "300px" }}
                                    />
                                  </Col>
                                </Form.Group>

                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  controlId="formHorizontalUsername"
                                >
                                  <Col sm={12}>
                                    <TextField
                                      id="outlined-basic"
                                      sx={{
                                        width: "250px",
                                        "& .MuiInputBase-root": {
                                          fontSize: {
                                            xs: "0.7rem",
                                            sm: "0.85rem",
                                            md: "0.9rem",
                                          },
                                          padding: {
                                            xs: "1px",
                                            sm: "2px",
                                            md: "3px",
                                            lg: "4px",
                                          },
                                        },
                                      }}
                                      label="Code"
                                      variant="outlined"
                                      type="text"
                                      name="code"
                                      size="small"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.code}
                                      error={
                                        formik.touched.code &&
                                        formik.errors.code
                                      }
                                      helperText={
                                        formik.touched.code &&
                                        formik.errors.code
                                          ? `${formik.errors.code}`
                                          : ""
                                      }
                                      style={{ width: "300px" }}
                                    />
                                  </Col>
                                </Form.Group>
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  controlId="formHorizontalUsername"
                                >
                                  <Col sm={12}>
                                    <TextField
                                      id="outlined-basic"
                                      sx={{
                                        width: "250px",
                                        "& .MuiInputBase-root": {
                                          fontSize: {
                                            xs: "0.7rem",
                                            sm: "0.85rem",
                                            md: "1rem",
                                          },
                                          padding: {
                                            xs: "1px",
                                            sm: "2px",
                                            md: "3px",
                                            lg: "4px",
                                          },
                                        },
                                      }}
                                      label="Select Model"
                                      variant="outlined"
                                      select
                                      size="small"
                                      name="modelId"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.modelId}
                                      error={
                                        formik.touched.modelId &&
                                        formik.errors.modelId
                                      }
                                      helperText={
                                        formik.touched.modelId &&
                                        formik.errors.modelId
                                          ? `${formik.errors.modelId}`
                                          : ""
                                      }
                                      style={{ width: "220px" }}
                                    >
                                      <MenuItem key={null} value={null}>
                                        None
                                      </MenuItem>
                                      {models.map((model) => {
                                        return (
                                          <MenuItem
                                            key={model.Id}
                                            value={model.Id}
                                          >
                                            {model.modelName}
                                          </MenuItem>
                                        );
                                      })}
                                    </TextField>
                                    <Button
                                      sx={{
                                        width: "90px",
                                        borderColor: "info.main",
                                        fontSize: {
                                          xs: "0.7rem",
                                          sm: "0.85rem",
                                          md: "1rem",
                                        },
                                        padding: {
                                          xs: "3px",
                                          sm: "4px",
                                          md: "5px",
                                          lg: "6px",
                                        },
                                        "&:hover": {
                                          backgroundColor: "info.main",
                                          color: "white",
                                        },
                                      }}
                                      variant="outlined"
                                      //onClick={openAddModal}
                                      style={{
                                        width: "30px",
                                        height: "40px",
                                        marginLeft: "19px",
                                        marginTop: "5px",
                                      }}
                                      onClick={handleModel}
                                    >
                                      <h2
                                        style={{
                                          marginTop: "8px",
                                          marginLeft: "-7px",
                                        }}
                                      >
                                        +
                                      </h2>
                                    </Button>
                                  </Col>
                                </Form.Group>
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  controlId="formHorizontalUsername"
                                >
                                  <Col sm={12}>
                                    <TextField
                                      id="outlined-basic"
                                      sx={{
                                        width: "250px",
                                        "& .MuiInputBase-root": {
                                          fontSize: {
                                            xs: "0.7rem",
                                            sm: "0.85rem",
                                            md: "1rem",
                                          },
                                          padding: {
                                            xs: "1px",
                                            sm: "2px",
                                            md: "3px",
                                            lg: "4px",
                                          },
                                        },
                                      }}
                                      label="Select Unit"
                                      variant="outlined"
                                      select
                                      size="small"
                                      name="unitId"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.unitId}
                                      error={
                                        formik.touched.unitId &&
                                        formik.errors.unitId
                                      }
                                      helperText={
                                        formik.touched.unitId &&
                                        formik.errors.unitId
                                          ? `${formik.errors.unitId}`
                                          : ""
                                      }
                                      style={{ width: "220px" }}
                                    >
                                      <MenuItem key={null} value={null}>
                                        None
                                      </MenuItem>
                                      {units.map((unit) => {
                                        return (
                                          <MenuItem
                                            key={unit.Id}
                                            value={unit.Id}
                                          >
                                            {unit.unitName}
                                          </MenuItem>
                                        );
                                      })}
                                    </TextField>
                                    <Button
                                      sx={{
                                        width: "90px",
                                        borderColor: "info.main",
                                        fontSize: {
                                          xs: "0.7rem",
                                          sm: "0.85rem",
                                          md: "1rem",
                                        },
                                        padding: {
                                          xs: "3px",
                                          sm: "4px",
                                          md: "5px",
                                          lg: "6px",
                                        },
                                        "&:hover": {
                                          backgroundColor: "info.main",
                                          color: "white",
                                        },
                                      }}
                                      variant="outlined"
                                      //onClick={openAddModal}
                                      style={{
                                        width: "30px",
                                        height: "40px",
                                        marginLeft: "19px",
                                        marginTop: "5px",
                                      }}
                                      onClick={handleUnit}
                                    >
                                      <h2
                                        style={{
                                          marginTop: "8px",
                                          marginLeft: "-7px",
                                        }}
                                      >
                                        +
                                      </h2>
                                    </Button>
                                  </Col>
                                </Form.Group>
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  controlId="formHorizontalUsername"
                                >
                                  <Col sm={12}>
                                    <TextField
                                      id="outlined-basic"
                                      sx={{
                                        width: "250px",
                                        "& .MuiInputBase-root": {
                                          fontSize: {
                                            xs: "0.7rem",
                                            sm: "0.85rem",
                                            md: "1rem",
                                          },
                                          padding: {
                                            xs: "1px",
                                            sm: "2px",
                                            md: "3px",
                                            lg: "4px",
                                          },
                                        },
                                      }}
                                      label="Select Tax Type"
                                      variant="outlined"
                                      select
                                      size="small"
                                      name="taxId"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.taxId}
                                      error={
                                        formik.touched.taxId &&
                                        formik.errors.taxId
                                      }
                                      helperText={
                                        formik.touched.taxId &&
                                        formik.errors.taxId
                                          ? `${formik.errors.taxId}`
                                          : ""
                                      }
                                      style={{ width: "220px" }}
                                    >
                                      <MenuItem key={null} value={null}>
                                        None
                                      </MenuItem>
                                      {taxs.map((tax) => {
                                        return (
                                          <MenuItem key={tax.Id} value={tax.Id}>
                                            <span className="row text-center">
                                              <div
                                                className="col-6"
                                                style={{ float: "left" }}
                                              >
                                                {tax.taxType}
                                              </div>
                                              <div
                                                className="col-6"
                                                style={{ float: "right" }}
                                              >
                                                {`${tax.taxValue} %`}
                                              </div>
                                            </span>
                                          </MenuItem>
                                        );
                                      })}
                                    </TextField>
                                    <Button
                                      sx={{
                                        width: "90px",
                                        borderColor: "info.main",
                                        fontSize: {
                                          xs: "0.7rem",
                                          sm: "0.85rem",
                                          md: "1rem",
                                        },
                                        padding: {
                                          xs: "3px",
                                          sm: "4px",
                                          md: "5px",
                                          lg: "6px",
                                        },
                                        "&:hover": {
                                          backgroundColor: "info.main",
                                          color: "white",
                                        },
                                      }}
                                      variant="outlined"
                                      color="info"
                                      //onClick={openAddModal}
                                      style={{
                                        width: "30px",
                                        height: "40px",
                                        marginLeft: "19px",
                                        marginTop: "5px",
                                      }}
                                      onClick={handleTax}
                                    >
                                      <h2
                                        style={{
                                          marginTop: "8px",
                                          marginLeft: "-7px",
                                        }}
                                      >
                                        +
                                      </h2>
                                    </Button>
                                  </Col>
                                </Form.Group>
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  controlId="formHorizontalUsername"
                                >
                                  <Col sm={12}>
                                    <TextField
                                      id="outlined-basic"
                                      sx={{
                                        width: "200px",
                                        "& .MuiInputBase-root": {
                                          fontSize: {
                                            xs: "0.7rem",
                                            sm: "0.85rem",
                                            md: "1rem",
                                          },
                                          padding: {
                                            xs: "1px",
                                            sm: "2px",
                                            md: "3px",
                                            lg: "4px",
                                          },
                                        },
                                      }}
                                      label="Select Item Type"
                                      variant="outlined"
                                      select
                                      size="small"
                                      name="statusId"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.statusId}
                                      error={
                                        formik.touched.statusId &&
                                        formik.errors.statusId
                                      }
                                      helperText={
                                        formik.touched.statusId &&
                                        formik.errors.statusId
                                          ? `${formik.errors.statusId}`
                                          : ""
                                      }
                                      style={{ width: "300px" }}
                                    >
                                      <MenuItem key={null} value={null}>
                                        None
                                      </MenuItem>
                                      <MenuItem key={1} value={1}>
                                        Service
                                      </MenuItem>
                                      <MenuItem key={2} value={2}>
                                        Consumable
                                      </MenuItem>
                                    </TextField>
                                  </Col>
                                </Form.Group>
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  controlId="formHorizontalUsername"
                                >
                                  <Col sm={12}>
                                    <Button
                                      style={{ width: "300px" }}
                                      sx={{
                                        width: "250px",
                                        borderColor: "success.main",
                                        fontSize: {
                                          xs: "0.85rem",
                                          sm: "0.85rem",
                                          md: "1rem",
                                        },
                                        padding: {
                                          xs: "4px",
                                          sm: "5px",
                                          md: "6px",
                                          lg: "6px",
                                        },
                                        "&:hover": {
                                          backgroundColor: "success.main",
                                          color: "white",
                                        },
                                      }}
                                      variant="outlined"
                                      color="success"
                                      type="submit"
                                      disabled={formik.isSubmitting}
                                    >
                                      {formik.values.id === ""
                                        ? "Create"
                                        : "Update"}
                                    </Button>
                                  </Col>
                                </Form.Group>
                              </Form>
                            </div>
                          </center>
                        </div>
                      )}
                    </>
                  )}
                  {qrCodeUrl !== null && (
                    <div>
                      <div className="row text-center">
                        <div className="col-md-6" style={{ minWidth: "200px" }}>
                          <table>
                            <tr>
                              <th>Id</th>
                              <td>{qrItem?.Id}</td>
                            </tr>
                            <tr>
                              <th>Description</th>
                              <td>{qrItem?.description}</td>
                            </tr>
                            <tr>
                              <th>Code</th>
                              <td>{qrItem?.code}</td>
                            </tr>
                            <tr>
                              <th>Model</th>
                              <td>{qrItem?.model}</td>
                            </tr>
                            <tr>
                              <th>Unit</th>
                              <td>{qrItem?.unit}</td>
                            </tr>
                            <tr>
                              <th>Available Qty</th>
                              <td>{qrItem?.qty}</td>
                            </tr>
                          </table>
                        </div>
                        <div className="col-md-6" style={{ minWidth: "200px" }}>
                          {qrCodeUrl ? (
                            <div>
                              <img
                                src={qrCodeUrl}
                                alt="QR Code"
                                style={{
                                  marginTop: "20px",
                                  width: "256px",
                                  height: "256px",
                                }}
                              />
                            </div>
                          ) : (
                            <p>Generating QR code...</p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <Modal
                style={{ marginTop: "80px" }}
                show={starts}
                onHide={handleOpen}
              >
                <Modal.Header closeButton>
                  <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {" "}
                  <Form
                    className="text-center"
                    onSubmit={handleCSVSubmit}
                    encType="multipart/form-data"
                  >
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalUsername"
                    >
                      <Col className="mb-3" md={9}>
                        <TextField
                          label="Import File"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          type="file"
                          sx={{
                            width: "300px",
                            "& .MuiInputBase-input": {
                              fontSize: {
                                xs: "0.6rem",
                                sm: "0.95rem",
                                md: "1.1rem",
                              },
                              padding: {
                                xs: "4px",
                                sm: "6px",
                                md: "7px",
                                lg: "12px",
                              },
                            },
                          }}
                          placeholder="Class Name"
                          name="file"
                          onChange={handleFileUpload}
                        />
                      </Col>

                      <Col md={3}>
                        <Button
                          sx={{
                            width: "100px",
                            borderColor: "success.main",
                            fontSize: {
                              xs: "0.65rem",
                              sm: "0.75rem",
                              md: "1rem",
                            },
                            padding: {
                              xs: "3px",
                              sm: "4px",
                              md: "5x",
                              lg: "7px",
                            },
                            "&:hover": {
                              backgroundColor: "success.main",
                              color: "white",
                            },
                          }}
                          style={{ marginLeft: "-20px" }}
                          variant="outlined"
                          color="success"
                          type="submit"
                        >
                          Submit
                        </Button>
                      </Col>
                    </Form.Group>
                  </Form>
                </Modal.Body>
              </Modal>
              <Modal
                style={{ marginTop: "80px" }}
                show={check}
                onHide={handlePopupCheck}
              >
                <Modal.Header closeButton>
                  {passwordError && (
                    <Stack sx={{ width: "100%" }} spacing={2}>
                      <Alert severity="error">
                        <AlertTitle>Try Again</AlertTitle>
                        You Use Invalid Password !!!
                      </Alert>
                    </Stack>
                  )}
                </Modal.Header>
                <Modal.Body>
                  <Form id="formAuthentication" className="mb-2">
                    <div className="">
                      <Form.Group className="mb-3 row form-password-toggle text-center">
                        <Col className="mb-3" md={9}>
                          <TextField
                            type="password"
                            id="password"
                            sx={{
                              width: "250px",
                              "& .MuiInputBase-root": {
                                fontSize: {
                                  xs: "0.7rem",
                                  sm: "0.85rem",
                                  md: "0.9rem",
                                },
                                padding: {
                                  xs: "1px",
                                  sm: "2px",
                                  md: "3px",
                                  lg: "4px",
                                },
                              },
                            }}
                            className="form-control"
                            name="password"
                            size="small"
                            value={users.password}
                            label="password"
                            aria-describedby="password"
                            onChange={handelchange}
                            style={{ width: "350px" }}
                          />
                        </Col>
                        <Col md={3}>
                          <Button
                            onClick={handelclick}
                            sx={{
                              width: "110px",
                              borderColor: "success.main",
                              fontSize: {
                                xs: "0.85rem",
                                sm: "0.85rem",
                                md: "1rem",
                              },
                              padding: {
                                xs: "4px",
                                sm: "5px",
                                md: "6x",
                                lg: "6px",
                              },
                              "&:hover": {
                                backgroundColor: "success.main",
                                color: "white",
                              },
                            }}
                            style={{ marginLeft: "-5px" }}
                            variant="outlined"
                            color="success"
                          >
                            Submit
                          </Button>
                        </Col>
                      </Form.Group>
                    </div>
                  </Form>
                </Modal.Body>
              </Modal>

              <Modal
                show={popup}
                onHide={handlePopup}
                style={{ minWidth: "700px" }}
              >
                {" "}
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <Formik
                    initialValues={{
                      unitName: "",
                    }}
                    validationSchema={Yup.object().shape({
                      unitName: Yup.string()
                        .required("Unit is required")
                        .min(2, "Too Short!!!")
                        .max(20, "Too High!!!"),
                    })}
                    onSubmit={async (values) => {
                      if (isSubmitted) return;
                      setIsSubmitted(true);
                      try {
                        await axios.post(
                          `https://enin.server.highendtradingplc.com/unit/add`,
                          values
                        );
                        setPopup(false);
                      } catch (error) {
                        console.log(error);
                      } finally {
                        fetchAllData();
                      }
                      setIsSubmitted(false);
                    }}
                  >
                    {(props) => (
                      <Form
                        className="m-2 ml-5 pl-5"
                        onSubmit={props.handleSubmit}
                      >
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalUsername"
                        >
                          <Col sm={12}>
                            <TextField
                              id="outlined-basic"
                              sx={{
                                width: "250px",
                                "& .MuiInputBase-root": {
                                  fontSize: {
                                    xs: "0.7rem",
                                    sm: "0.85rem",
                                    md: "0.9rem",
                                  },
                                  padding: {
                                    xs: "1px",
                                    sm: "2px",
                                    md: "3px",
                                    lg: "4px",
                                  },
                                },
                              }}
                              label="Unit Name"
                              variant="outlined"
                              type="text"
                              name="unitName"
                              size="small"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.unitName}
                              error={
                                props.touched.unitName && props.errors.unitName
                              }
                              helperText={
                                props.touched.unitName && props.errors.unitName
                                  ? `${props.errors.unitName}`
                                  : ""
                              }
                              style={{ maxWidth: "300px" }}
                            />
                          </Col>
                        </Form.Group>

                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalUsername"
                        >
                          <Col sm={12}>
                            <Button
                              sx={{
                                width: "250px",
                                borderColor: "success.main",
                                fontSize: {
                                  xs: "0.85rem",
                                  sm: "0.85rem",
                                  md: "1rem",
                                },
                                padding: {
                                  xs: "4px",
                                  sm: "5px",
                                  md: "6px",
                                  lg: "6px",
                                },
                                "&:hover": {
                                  backgroundColor: "success.main",
                                  color: "white",
                                },
                              }}
                              variant="outlined"
                              color="success"
                              type="submit"
                            >
                              Create
                            </Button>
                          </Col>
                        </Form.Group>
                      </Form>
                    )}
                  </Formik>
                </Modal.Body>
              </Modal>
              <Modal
                show={popupM}
                onHide={handlePopupM}
                style={{ minWidth: "700px" }}
              >
                {" "}
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <Formik
                    initialValues={{
                      modelName: "",
                    }}
                    validationSchema={Yup.object().shape({
                      modelName: Yup.string()
                        .required("Unit is required")
                        .min(2, "Too Short!!!")
                        .max(20, "Too High!!!"),
                    })}
                    onSubmit={async (values) => {
                      if (isSubmitted) return;
                      setIsSubmitted(true);
                      try {
                        await axios.post(
                          `https://enin.server.highendtradingplc.com/model/add`,
                          values
                        );
                        setPopupM(false);
                      } catch (error) {
                        console.log(error);
                      } finally {
                        fetchAllData();
                      }
                      setIsSubmitted(false);
                    }}
                  >
                    {(props) => (
                      <Form
                        className="m-2 ml-5 pl-5"
                        onSubmit={props.handleSubmit}
                      >
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalUsername"
                        >
                          <Col sm={12}>
                            <TextField
                              id="outlined-basic"
                              sx={{
                                width: "250px",
                                "& .MuiInputBase-root": {
                                  fontSize: {
                                    xs: "0.7rem",
                                    sm: "0.85rem",
                                    md: "0.9rem",
                                  },
                                  padding: {
                                    xs: "1px",
                                    sm: "2px",
                                    md: "3px",
                                    lg: "4px",
                                  },
                                },
                              }}
                              label="Model Name"
                              variant="outlined"
                              type="text"
                              name="modelName"
                              size="small"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.modelName}
                              error={
                                props.touched.modelName &&
                                props.errors.modelName
                              }
                              helperText={
                                props.touched.modelName &&
                                props.errors.modelName
                                  ? `${props.errors.modelName}`
                                  : ""
                              }
                              style={{ maxWidth: "300px" }}
                            />
                          </Col>
                        </Form.Group>

                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalUsername"
                        >
                          <Col sm={12}>
                            <Button
                              sx={{
                                width: "250px",
                                borderColor: "success.main",
                                fontSize: {
                                  xs: "0.85rem",
                                  sm: "0.85rem",
                                  md: "1rem",
                                },
                                padding: {
                                  xs: "4px",
                                  sm: "5px",
                                  md: "6px",
                                  lg: "6px",
                                },
                                "&:hover": {
                                  backgroundColor: "success.main",
                                  color: "white",
                                },
                              }}
                              variant="outlined"
                              color="success"
                              type="submit"
                            >
                              Create
                            </Button>
                          </Col>
                        </Form.Group>
                      </Form>
                    )}
                  </Formik>
                </Modal.Body>
              </Modal>
              <Modal
                show={popupT}
                onHide={(e) => setPopupT(false)}
                style={{ minWidth: "700px" }}
              >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <Formik
                    initialValues={{
                      taxType: "",
                      taxValue: "",
                    }}
                    validationSchema={Yup.object().shape({
                      taxType: Yup.string()
                        .matches(
                          /^[A-Za-z ]{1,20}$/,
                          "Tax Name should be 1-20 alpha characters."
                        )
                        .required("Tax Name is required"),
                      taxValue: Yup.number()
                        .required("Tax Value is required")
                        .min(0, "Too Low!")
                        .max(50, "Too High!"),
                    })}
                    onSubmit={async (values) => {
                      if (isSubmitted) return;
                      setIsSubmitted(true);
                      try {
                        await axios.post(
                          `https://enin.server.highendtradingplc.com/tax/add`,
                          values
                        );
                        setPopupT(false);
                      } catch (error) {
                        console.log(error);
                      } finally {
                        fetchAllData();
                      }
                      setIsSubmitted(false);
                    }}
                  >
                    {(props) => (
                      <Form
                        className="m-2 ml-5 pl-5"
                        onSubmit={props.handleSubmit}
                      >
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalUsername"
                        >
                          <Col sm={12}>
                            <TextField
                              id="outlined-basic"
                              sx={{
                                width: "250px",
                                "& .MuiInputBase-root": {
                                  fontSize: {
                                    xs: "0.7rem",
                                    sm: "0.85rem",
                                    md: "0.9rem",
                                  },
                                  padding: {
                                    xs: "1px",
                                    sm: "2px",
                                    md: "3px",
                                    lg: "4px",
                                  },
                                },
                              }}
                              label="Tax Name"
                              variant="outlined"
                              type="text"
                              name="taxType"
                              size="small"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.taxType}
                              error={
                                props.touched.taxType && props.errors.taxType
                              }
                              helperText={
                                props.touched.taxType && props.errors.taxType
                                  ? `${props.errors.taxType}`
                                  : ""
                              }
                              style={{ maxWidth: "300px" }}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalUsername"
                        >
                          <Col sm={12}>
                            <TextField
                              id="outlined-basic"
                              sx={{
                                width: "250px",
                                "& .MuiInputBase-root": {
                                  fontSize: {
                                    xs: "0.7rem",
                                    sm: "0.85rem",
                                    md: "0.9rem",
                                  },
                                  padding: {
                                    xs: "1px",
                                    sm: "2px",
                                    md: "3px",
                                    lg: "4px",
                                  },
                                },
                              }}
                              label="Tax Value % "
                              variant="outlined"
                              type="text"
                              size="small"
                              name="taxValue"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.taxValue}
                              error={
                                props.touched.taxValue && props.errors.taxValue
                              }
                              helperText={
                                props.touched.taxValue && props.errors.taxValue
                                  ? `${props.errors.taxValue}`
                                  : ""
                              }
                              style={{ maxWidth: "300px" }}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalUsername"
                        >
                          <Col sm={12}>
                            <Button
                              sx={{
                                width: "250px",
                                borderColor: "success.main",
                                fontSize: {
                                  xs: "0.8rem",
                                  sm: "0.8rem",
                                  md: "1rem",
                                },
                                padding: {
                                  xs: "3px",
                                  sm: "4px",
                                  md: "6px",
                                  lg: "6px",
                                },
                                "&:hover": {
                                  backgroundColor: "success.main",
                                  color: "white",
                                },
                              }}
                              variant="outlined"
                              color="success"
                              type="submit"
                            >
                              Create
                            </Button>
                          </Col>
                        </Form.Group>
                      </Form>
                    )}
                  </Formik>
                </Modal.Body>
              </Modal>
              <Modal show={warnings[0] !== 0} onHide={() => setWarings([0, 0])}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <Typography variant="h6">
                    Are you sure you want to{" "}
                    {warnings[0] === 1 ? "delete" : "update"} this item?
                  </Typography>
                  <Row>
                    <center>
                      <Button
                        className="col mr-4"
                        style={{ maxWidth: "100px" }}
                        variant="contained"
                        color="error"
                        onClick={() => {
                          if (warnings[0] === 1) {
                            handleDelete(warnings[1]);
                            setWarings([0, 0]);
                          } else if (warnings[0] === 2) {
                            formik.handleSubmit();
                          }
                        }}
                      >
                        Confirm
                      </Button>
                      <Button
                        className="col ml-4"
                        style={{ maxWidth: "100px" }}
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          warnings[0] = 0;
                          setWarings([...warnings]);
                        }}
                      >
                        Cancel
                      </Button>
                    </center>
                  </Row>
                </Modal.Body>
              </Modal>
              <Fragment>
                {" "}
                <Dialog
                  fullWidth={true}
                  maxWidth="sm"
                  open={error}
                  onClose={(e) => setError(false)}
                  scroll="paper"
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                >
                  <DialogTitle id="scroll-dialog-title" color="red">
                    Error
                  </DialogTitle>
                  <IconButton
                    aria-label="close"
                    onClick={(e) => setError(false)}
                    sx={(themes) => ({
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: themes.palette.grey[500],
                    })}
                  >
                    <Close />
                  </IconButton>
                  <DialogContent dividers={true}>
                    <DialogContentText
                      id="scroll-dialog-description"
                      ref={descriptionElementRef}
                      tabIndex={-1}
                    >
                      {show.map((s, i) => {
                        console.log(s);
                        return (
                          <p>
                            {i + 1}. {s.name}{" "}
                            {s.line === -1 ? (
                              ""
                            ) : (
                              <span style={{ color: "red" }}>{s.line}</span>
                            )}{" "}
                          </p>
                        );
                      })}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={(e) => setError(false)}>Cancel</Button>
                  </DialogActions>
                </Dialog>
              </Fragment>
            </div>
          </center>
        </main>
      </Typography>
    </ThemeProvider>
  );
};

export default ItemPage;
