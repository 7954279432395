class HexCharacterManipulator {
  hexaTochar = (hexaData, realValue) => {
    let allData = [];
    const hexaChange = hexaData.split("Å");
    for (let i = 0; i < hexaChange.length; i++) {
      allData.push("");
      for (let char of hexaChange[i]) {
        allData[i] += (
          Number(char.codePointAt(0)) === 198
            ? "11"
            : Number(char.codePointAt(0)) > 150
            ? Number(char.codePointAt(0)) - 161
            : Number(char.codePointAt(0)) - 33 > 9
            ? Number(char.codePointAt(0)) - 33
            : "0" + (Number(char.codePointAt(0)) - 33)
        ).toString();
      }
    }
    return allData;
  };
  charTohexa = (hexaData, reaValue) => {
    let allData = "";
    for (let i = 0; i < hexaData.length; i++) {
      allData +=
        Math.floor(hexaData[i])
          .toString()
          .match(/.{1,2}/g) // Split into pairs of two characters
          .map((hex) => {
            if (hex === "11") return String.fromCodePoint(198);
            if (hex.length === 1)
              return String.fromCodePoint(Number(hex) + 161);
            return String.fromCodePoint(Number(hex) + 33);
          })
          .join("") + (i + 1 === hexaData.length ? "" : "Å");
    }
    return allData;
  };
}

export default HexCharacterManipulator;
