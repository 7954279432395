/** @format */

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../Header";
import {
  createTheme,
  useMediaQuery,
  ThemeProvider,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackwardStep, faEye } from "@fortawesome/free-solid-svg-icons";
const theme = createTheme({
  breakpoints: { values: { xs: 0, sm: 500, md: 769, lg: 1024, xl: 1440 } },
  palette: {
    warning: {
      main: "#ffc107", // Main warning color
      light: "#ffb74d", // Light variation of warning color
      dark: "#f57c00", // Dark variation of warning color
      contrastText: "#000", // Text color on warning
    },
  },
});

const StockAvailable = () => {
  const [units, setUnits] = useState([]);
  const [models, setModels] = useState([]);
  const [items, setItems] = useState([]);
  const [stores, setStores] = useState([]);
  const [storeItems, setStoreItems] = useState([]);
  const [storeValues, setStoreValues] = useState([]);
  const [logicals, setLogicals] = useState([]);
  const [physicals, setPhysiacls] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const { id } = useParams();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isUnderMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const fetchAllData = async () => {
    const [
      unit,
      item,
      storeItem,
      store,
      model,
      logical,
      physical,
      transaction,
    ] = await Promise.all([
      axios.get("https://enin.server.highendtradingplc.com/unit"),
      axios.get("https://enin.server.highendtradingplc.com/item"),
      axios.get("https://enin.server.highendtradingplc.com/store_item"),
      axios.get("https://enin.server.highendtradingplc.com/store"),
      axios.get("https://enin.server.highendtradingplc.com/model"),
      axios.get("https://enin.server.highendtradingplc.com/logical_stock"),
      axios.get("https://enin.server.highendtradingplc.com/physical_stock"),
      axios.get("https://enin.server.highendtradingplc.com/transaction"),
    ]);
    setUnits(unit.data);
    setItems(item.data.filter((i) => i.statusId === 2));
    setStoreItems(storeItem.data);
    setStores(store.data);
    setModels(model.data);
    setLogicals(logical.data);
    setPhysiacls(physical.data);
    setTransactions(transaction.data);
  };
  useEffect(() => {
    fetchAllData().then(() => {});
  }, []);

  const mergeData = (leftData, rightData) => {
    let mergedData = [];
    let i = 0,
      j = 0;
    while (i < leftData.length && j < rightData.length) {
      if (leftData[i]?.quantity >= rightData[j]?.quantity) {
        mergedData.push(leftData[i]);
        i++;
      } else {
        mergedData.push(rightData[j]);
        j++;
      }
    }
    let leftDataTail = leftData.filter((d, l) => l >= i);
    let rightDataTail = rightData.filter((d, r) => r >= j);
    return mergedData.concat(leftDataTail?.concat(rightDataTail));
  };

  const sortData = (data) => {
    if (data.length < 2) return data;
    let midValue = Math.round(data.length / 2);
    let leftData = data.filter((d, i) => i < midValue);
    let rightData = data.filter((d, i) => i >= midValue);
    let leftSortData = sortData(leftData);
    let rightSortData = sortData(rightData);
    let sortedData = mergeData(leftSortData, rightSortData);
    return sortedData;
  };
  const handleStoreItemView = (row) => {
    const storeItem = storeItems.filter((s) => s.itemId === row.itemId);
    const newValue = sortData(storeItem);
    setStoreValues(newValue);
  };

  const columns = [
    {
      field: "Id",
      headerName: "No",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "description",
      headerName: "Description",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "modelId",
      headerName: "Model",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        models.find((model) => model.Id === params)?.modelName || "",
    },
    {
      field: "code",
      headerName: "Code",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "unitId",
      headerName: "Unit",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        units.find((unit) => unit.Id === params)?.unitName || "",
    },
    {
      field: "qty",
      headerName: "Available Qty",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    parseInt(id) === 2
      ? {
          field: "action",
          headerName: "Action",
          headerAlign: "center",
          align: "center",
          headerClassName: "super-app-theme--header",
          width: 150,
          renderCell: (params) => (
            <>
              <Button
                onClick={() => handleStoreItemView(params.row)}
                sx={{
                  width: "90px",
                  borderColor: "primary.main",
                  fontSize: {
                    xs: "0.7rem",
                    sm: "0.85rem",
                    md: "1rem",
                  },
                  padding: {
                    xs: "3px",
                    sm: "4px",
                    md: "5px",
                    lg: "6px",
                  },
                  "&:hover": {
                    backgroundColor: "primary.main",
                    color: "white",
                  },
                }}
                variant="outlined"
                color="primary"
                className="m-1"
              >
                <div
                  className="row"
                  style={{
                    marginBottom: "-10px",
                    marginLeft: "-10px",
                  }}
                >
                  <div className="col">
                    <FontAwesomeIcon
                      style={{ float: "right", marginRight: "-7px" }}
                      icon={faEye}
                    />
                  </div>
                  <div className="col-7">
                    <h6
                      style={{
                        float: "left",
                        marginLeft: "-3px",
                      }}
                    >
                      View
                    </h6>
                  </div>
                </div>
              </Button>
            </>
          ),
        }
      : { width: 1, headerClassName: "super-app-theme--header" },
  ];
  const columnStores = [
    {
      field: "Id",
      headerName: "No",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "description",
      headerName: "Description",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "modelId",
      headerName: "Model",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        models.find((model) => model.Id === params)?.modelName || "",
    },
    {
      field: "storeName",
      headerName: "Store Name",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "address",
      headerName: "Address",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "qty",
      headerName: "Available Qty",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ];
  const rows = items.map((item, index) => {
    const logical = logicals.find((l) => l.itemId === item.Id);
    const physical = physicals.find((p) => p.itemId === item.Id);
    return {
      Id: index + 1,
      itemId: item.Id,
      description: item.description,
      modelId: item.modelId,
      code: item.code,
      unitId: item.unitId,
      qty:
        parseInt(id) === 1 ? logical?.quantity || 0 : physical?.quantity || 0,
    };
  });
  const rowStore = storeValues.map((storeItem, index) => {
    const item = items.find((i) => i.Id === storeItem.itemId);
    const store = stores.find((s) => s.Id === storeItem.storeId);
    return {
      Id: index + 1,
      storeItemId: storeItem.Id,
      description: item.description,
      modelId: item.modelId,
      storeName: store.storeName,
      address: store.address,
      qty: storeItem.quantity,
    };
  });
  const style = {
    backgroundColor: "white",
    borderRadius: "5px 5px 0 0",
    border: "solid 0.05em rgb(181, 181, 181)",
  };
  return (
    <ThemeProvider theme={theme}>
      <Typography
        sx={{
          fontSize: {
            xs: "12px",
            sm: "12px", //500
            md: "0.85rem", //768
            lg: "17px", //1024
          },
        }}
      >
        <div>
          <Header />
        </div>
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: "13px",
            sm: "14px", //500
            md: "16px", //768
            lg: "18px", //1024
          },
        }}
      >
        {" "}
        <div>
          {" "}
          <main
            className=""
            style={
              isMobile && !isUnderMobile
                ? { marginTop: "190px" }
                : isUnderMobile
                ? { marginTop: "170px" }
                : isTablet
                ? { marginTop: "133px" }
                : { marginTop: "150px" }
            }
          >
            <center>
              <div
                style={
                  isUnderMobile
                    ? {
                        minWidth: "500px",
                        overflow: "scroll",
                        marginBottom: "-5px",
                      }
                    : { maxWidth: "1100px" }
                }
                className="text-left"
              >
                <div
                  className="tab-container "
                  style={
                    isUnderMobile
                      ? { marginTop: "0", marginBottom: "-17px" }
                      : {
                          marginTop: "-20px",
                          marginBottom: "-17px",
                        }
                  }
                >
                  <Link className="p-2" to={`/purchase`}>
                    Purchase
                  </Link>
                  <Link
                    className="p-2"
                    onClick={(e) => {
                      window.location.reload();
                    }}
                    style={{
                      backgroundColor: "white",
                      borderRadius: "5px 5px 0 0",
                      border: "solid 0.05em rgb(181, 181, 181)",
                    }}
                  >
                    Stock Available
                  </Link>

                  <Link className="p-2" to={`/adjustment`}>
                    Adjustment
                  </Link>
                  <Link className="p-2" to={`/payment`}>
                    Bank
                  </Link>
                </div>
                <hr />
                <div
                  className="tab-container"
                  style={
                    isUnderMobile
                      ? {
                          marginTop: "-12px",
                          marginBottom: "-8px",
                        }
                      : {
                          marginTop: "-12px",
                          marginBottom: "-5px",
                        }
                  }
                >
                  <Link
                    className="p-2"
                    to={`/stock_available/1`}
                    onClick={(e) => {
                      if (parseInt(id) === 1) window.location.reload();
                      else window.location.replace(`/stock_available/1`);
                    }}
                    style={parseInt(id) === 1 ? style : {}}
                  >
                    Logical Quantity
                  </Link>
                  <Link
                    className="p-2"
                    to={`/stock_available/2`}
                    onClick={(e) => {
                      if (parseInt(id) === 2) window.location.reload();
                      else window.location.replace(`/stock_available/2`);
                    }}
                    style={parseInt(id) === 2 ? style : {}}
                  >
                    Physical Quantity
                  </Link>
                </div>
                <div className="card card-info card-outline">
                  <div className="card-header">
                    {storeValues.length === 0 && (
                      <center>
                        <div>
                          <h4>
                            <Typography
                              sx={{
                                fontSize: {
                                  xs: "1.3rem",
                                  sm: "1.3rem",
                                  md: "1.5rem",
                                  lg: "2rem",
                                },
                              }}
                            >
                              System Stock Balance
                            </Typography>
                          </h4>
                        </div>
                      </center>
                    )}
                    {storeValues.length > 0 && (
                      <Button
                        onClick={(e) => setStoreValues([])}
                        sx={{
                          width: "100px",
                          borderColor: "success.main",
                          fontSize: {
                            xs: "0.85rem",
                            sm: "0.85rem",
                            md: "1rem",
                          },
                          padding: {
                            xs: "4px",
                            sm: "5px",
                            md: "6px",
                            lg: "6px",
                          },
                          "&:hover": {
                            backgroundColor: "success.main",
                            color: "white",
                          },
                        }}
                        variant="outlined"
                        color="success"
                        type="submit"
                        className="m-1"
                        style={{ float: "right" }}
                      >
                        <div
                          className="row"
                          style={{
                            marginBottom: "-10px",
                            marginLeft: "-10px",
                          }}
                        >
                          <div className="col">
                            <FontAwesomeIcon
                              style={{
                                float: "right",
                                marginRight: "-1px",
                                marginTop: "2px",
                                marginBottom: "-4px",
                              }}
                              icon={faBackwardStep}
                            />
                          </div>
                          <div className="col-7">
                            <h6
                              style={{
                                float: "left",
                                marginLeft: "-10px",
                                marginTop: "-1px",
                                marginBottom: "-7px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: {
                                    xs: "0.85rem",
                                    sm: "0.85rem",
                                    md: "1rem",
                                  },
                                }}
                              >
                                Back
                              </Typography>
                            </h6>
                          </div>
                        </div>
                      </Button>
                    )}
                  </div>
                  <div className="card-body">
                    <div style={{ overflow: "scroll" }}>
                      <Box
                        sx={{
                          height: 500,
                          width: {
                            lg: "100%",
                            md: "1000px",
                            sm: "1000px",
                            xs: "1000px",
                          },
                          "& .super-app-theme--header": {
                            backgroundColor: "#1de5ec",
                            color: "black",
                            fontSize: {
                              xs: "13px",
                              sm: "15px", //500
                              md: "17px", //768
                              lg: "18px", //1024
                            },
                          },
                        }}
                      >
                        <DataGrid
                          rows={storeValues.length > 0 ? rowStore : rows}
                          disableColumnFilter
                          disableColumnSelector
                          disableDensitySelector
                          sx={{
                            "& .MuiDataGrid-cell": {
                              fontSize: {
                                xs: "12px",
                                sm: "14px", //500
                                md: "16px", //768
                                lg: "17px", //1024
                              },
                            },
                          }}
                          columns={
                            storeValues.length > 0 ? columnStores : columns
                          }
                          slots={{ toolbar: GridToolbar }}
                          slotProps={{
                            toolbar: {
                              showQuickFilter: true,
                            },
                          }}
                          initialState={
                            storeValues.length > 0
                              ? {
                                  ...storeValues.initialState,
                                  pagination: {
                                    paginationModel: { pageSize: 5 },
                                  },
                                }
                              : {
                                  ...items.initialState,
                                  pagination: {
                                    paginationModel: { pageSize: 5 },
                                  },
                                }
                          }
                          pageSizeOptions={[5, 10, 15]}
                          getRowId={(row) => row.Id}
                        />
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </center>
          </main>
        </div>
      </Typography>
    </ThemeProvider>
  );
};
export default StockAvailable;
