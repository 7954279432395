/** @format */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../Header";
import { Col, Form, Row } from "react-bootstrap";
import {
  Autocomplete,
  Box,
  createTheme,
  useMediaQuery,
  ThemeProvider,
  Button,
  Typography,
  TextField,
} from "@mui/material";
import { faBackwardStep } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const theme = createTheme({
  breakpoints: { values: { xs: 0, sm: 500, md: 769, lg: 1024, xl: 1440 } },
  palette: {
    warning: {
      main: "#ffc107", // Main warning color
      light: "#ffb74d", // Light variation of warning color
      dark: "#f57c00", // Dark variation of warning color
      contrastText: "#000", // Text color on warning
    },
  },
});

const StockReport = () => {
  const [stores, setStores] = useState([]);
  const [storeDatas, setStoreDatas] = useState([]);
  const [allStoreDataItems, setAllStoreDataItems] = useState([]);
  const [allStoreDatas, setAllStoreDatas] = useState([]);
  const [storeDataItems, setStoreDataItems] = useState([]);
  const [oneItems, setOneItems] = useState(null);
  const [users, setUsers] = useState([]);
  const [items, setItems] = useState([]);
  const [units, setUnits] = useState([]);
  const [models, setModels] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [deliverys, setDeliverys] = useState([]);
  const [adjustment, setAdjustments] = useState([]);
  const [status, setStatus] = useState(0);
  const [open, setOpen] = useState(0);
  const [storeDatasDate, setStoreDatasDate] = useState({
    start: new Date(new Date().setDate(new Date().getDate() - 1)),
    end: new Date(new Date().setHours(new Date().getHours() + 7)),
  });
  const [checkDate, setCheckDate] = useState({
    start: new Date(),
    end: new Date(),
  });
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isUnderMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const fetchAllData = async () => {
    const [
      store_data,
      user,
      item,
      unit,
      model,
      store_data_item,
      store,
      purchase,
      delivery,
      adjustment,
    ] = await Promise.all([
      axios.get("https://enin.server.highendtradingplc.com/store_data"),
      axios.get("https://enin.server.highendtradingplc.com/user"),
      axios.get("https://enin.server.highendtradingplc.com/item"),
      axios.get("https://enin.server.highendtradingplc.com/unit"),
      axios.get("https://enin.server.highendtradingplc.com/model"),
      axios.get("https://enin.server.highendtradingplc.com/store_data_item"),
      axios.get("https://enin.server.highendtradingplc.com/store"),
      axios.get("https://enin.server.highendtradingplc.com/purchase_item"),
      axios.get("https://enin.server.highendtradingplc.com/delivery_item"),
      axios.get("https://enin.server.highendtradingplc.com/damege_item"),
    ]);
    setAllStoreDataItems(store_data_item.data);
    setAllStoreDatas(store_data.data);
    setUsers(user.data?.filter((user) => user.roleId >= 2));
    setItems(item.data);
    setUnits(unit.data);
    setModels(model.data);
    setStores(store.data);
    setPurchases(purchase.data);
    setDeliverys(delivery.data);
    setAdjustments(adjustment.data);
    let data = [];
    store_data.data?.map((qua) => {
      const date = new Date(qua.date);
      const startDate = (date - storeDatasDate.start) / 86400000;
      const endDate = (storeDatasDate.end - date) / 86400000;
      if (startDate >= 0 && endDate >= 0) {
        data.push(qua);
      }

      setStoreDatas(data);
    });
  };
  useEffect(() => {
    fetchAllData();
  }, []);
  const defaultProps = {
    options: stores,
    getOptionLabel: (option) => option.storeName,
  };

  const handleView = (row) => {
    const storeItem = allStoreDataItems.filter(
      (s) => s.storeDatumId === row.id
    );
    if (row.statusId === 3) setStatus(3);
    else if (row.statusId === 2) setStatus(2);
    else setStatus(1);
    setStoreDataItems(storeItem);
    setOpen(1);
  };

  const columns = [
    {
      field: "Id",
      headerClassName: "super-app-theme--header",
      headerName: "No",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
    },
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "Store data Id",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
    },

    {
      field: "adpID",
      headerClassName: "super-app-theme--header",
      headerName: "ADP ID",
      headerAlign: "center",
      align: "center",
      flex: 0.6,
    },
    {
      field: "userId",
      headerClassName: "super-app-theme--header",
      headerName: "Applicant User",
      headerAlign: "center",
      align: "center",
      flex: 1.1,
      valueGetter: (params) =>
        users.find((user) => user.Id === params)?.fullName || "",
    },
    {
      field: "storeName",
      headerClassName: "super-app-theme--header",
      headerName: "Store Name",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },

    {
      field: "status",
      headerClassName: "super-app-theme--header",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      flex: 0.8,
    },

    {
      field: "date",
      headerClassName: "super-app-theme--header",
      headerName: "Date",
      headerAlign: "center",
      align: "center",
      flex: 0.7,
      valueGetter: (params) =>
        `${new Date(params).getUTCDate()}/${
          new Date(params).getUTCMonth() + 1
        }/${new Date(params).getUTCFullYear()}`,
    },
  ];
  const columnItems = [
    {
      field: "Id",
      headerClassName: "super-app-theme--header",
      headerName: "No",
      headerAlign: "center",
      align: "center",
      flex: 0.7,
    },
    {
      field: "description",
      headerClassName: "super-app-theme--header",
      headerName: "Description",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "code",
      headerClassName: "super-app-theme--header",
      headerName: "Code",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "modelId",
      headerClassName: "super-app-theme--header",
      headerName: "Model",
      headerAlign: "center",
      align: "center",
      flex: 1,
      valueGetter: (params) =>
        models.find((model) => model.Id === params)?.modelName || "",
    },
    {
      field: "unitId",
      headerClassName: "super-app-theme--header",
      headerName: "Unit",
      headerAlign: "center",
      align: "center",
      flex: 1,
      valueGetter: (params) =>
        units.find((unit) => unit.Id === params)?.unitName || "",
    },
    {
      field: "qty",
      headerClassName: "super-app-theme--header",
      headerName: "Qty",
      headerAlign: "center",
      align: "center",
      flex: 0.7,
    },
    {
      field: "status",
      headerClassName: "super-app-theme--header",
      headerName: status < 3 ? "U. Price" : "Status",
      headerAlign: "center",
      align: "center",
      flex: 0.7,
      cellClassName: (params) => {
        if (params.row.status === "Remove") {
          return "super-app-theme--cell-reject";
        } else if (params.row.status === "Add") {
          return "super-app-theme--cell-approve";
        }
      },
    },
  ];

  const rows = storeDatas?.map((store_data, index) => {
    const store = stores.find((s) => s.Id === store_data.storeId);
    return {
      Id: index + 1,
      id: store_data.Id,
      adpID: store_data.purchaseDeliveryId,
      userId: store_data.userId,
      storeName: store.storeName,
      statusId: store_data.statusId,
      status:
        store_data.statusId === 1
          ? "Purchase"
          : store_data.statusId === 2
          ? "Delivery"
          : "Adjustment",

      date: store_data.date,
    };
  });
  const rowDelivery = storeDataItems?.map((storeItem, index) => {
    const item = items.find((s) => s.Id === storeItem.itemId);
    return {
      Id: index + 1,
      description: item.description,
      modelId: item.modelId,
      unitId: item.unitId,
      code: item.code,
      qty: storeItem.quantity,
      status:
        status === 1
          ? purchases.find((d) => d.Id === storeItem.purchaseDeliveryItemId)
              ?.price
          : status === 2
          ? deliverys.find((d) => d.Id === storeItem.purchaseDeliveryItemId)
              ?.unitPrice
          : adjustment.find((d) => d.Id === storeItem.purchaseDeliveryItemId)
              ?.statusId === 1
          ? "Add"
          : "Remove",
    };
  });

  return (
    <ThemeProvider theme={theme}>
      <Typography
        sx={{
          fontSize: {
            xs: "12px",
            sm: "12px", //500
            md: "0.85rem", //768
            lg: "17px", //1024
          },
        }}
      >
        <div>
          <Header />
        </div>
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: "13px",
            sm: "14px", //500
            md: "16px", //768
            lg: "18px", //1024
          },
        }}
      >
        <main
          className=""
          style={
            isMobile && !isUnderMobile
              ? { marginTop: "190px" }
              : isUnderMobile
              ? { marginTop: "170px" }
              : isTablet
              ? { marginTop: "133px" }
              : { marginTop: "150px" }
          }
        >
          <center>
            <div
              style={
                isUnderMobile
                  ? {
                      minWidth: "500px",
                      overflow: "scroll",
                      marginBottom: "-5px",
                    }
                  : { maxWidth: "1100px" }
              }
              className="text-left"
            >
              <div
                className="tab-container "
                style={
                  isUnderMobile
                    ? { marginTop: "0", marginBottom: "-5px" }
                    : {
                        marginTop: "-20px",
                        marginBottom: "-5px",
                      }
                }
              >
                <Link className="p-2" to={`/purchase_report`}>
                  Purchase Report
                </Link>
                <Link className="p-2" to={`/sales_report`}>
                  Sales Report
                </Link>
                <Link className="p-2" to={`/delivery_report`}>
                  Deliverys Report
                </Link>
                <Link
                  className="p-2"
                  onClick={(e) => {
                    window.location.reload();
                  }}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "5px 5px 0 0",
                    border: "solid 0.05em rgb(181, 181, 181)",
                  }}
                >
                  Stock Report
                </Link>
                <Link className="p-2" to={`/quantity_report`}>
                  Item Report
                </Link>
              </div>
              <div className="card card-info card-outline">
                {/* <div className="card-header">
            <center>
              <div>
                <h4>System Stock Balance</h4>
              </div>
            </center>
          </div> */}
                <div className="card-body">
                  <div
                    className="nav-bra pl-3 ml-1 p-2 row"
                    style={{
                      backgroundColor: "rgb(235, 235, 235)",
                    }}
                  >
                    <span>
                      Home / Stock Report
                      {open === 1 && (
                        <Button
                          onClick={(e) => {
                            setOpen(0);
                          }}
                          sx={{
                            width: "100px",
                            borderColor: "success.main",
                            fontSize: {
                              xs: "0.85rem",
                              sm: "0.85rem",
                              md: "1rem",
                            },
                            padding: {
                              xs: "4px",
                              sm: "5px",
                              md: "6px",
                              lg: "6px",
                            },
                            "&:hover": {
                              backgroundColor: "success.main",
                              color: "white",
                            },
                          }}
                          variant="outlined"
                          color="success"
                          type="submit"
                          className="m-1"
                          style={{ float: "right" }}
                        >
                          <div
                            className="row"
                            style={{
                              marginBottom: "-10px",
                              marginLeft: "-10px",
                            }}
                          >
                            <div className="col">
                              <FontAwesomeIcon
                                style={{
                                  float: "right",
                                  marginRight: "-1px",
                                  marginTop: "2px",
                                  marginBottom: "-4px",
                                }}
                                icon={faBackwardStep}
                              />
                            </div>
                            <div className="col-7">
                              <h6
                                style={{
                                  float: "left",
                                  marginLeft: "-10px",
                                  marginTop: "-1px",
                                  marginBottom: "-7px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: {
                                      xs: "0.85rem",
                                      sm: "0.85rem",
                                      md: "1rem",
                                    },
                                  }}
                                >
                                  Back
                                </Typography>
                              </h6>
                            </div>
                          </div>
                        </Button>
                      )}
                    </span>
                  </div>
                  <br />
                  <div style={{ border: "solid 1px #ffb71b" }}></div>
                  <br />
                  {open === 0 && (
                    <Form>
                      <Form.Group as={Row}>
                        <Col lg={4} className="mb-2">
                          <Autocomplete
                            {...defaultProps}
                            id="controlled-demo"
                            size="small"
                            value={oneItems}
                            onChange={(event, newValue) => {
                              setOneItems(newValue);
                            }}
                            sx={{
                              "& .MuiInputBase-root": {
                                fontSize: {
                                  xs: "0.7rem",
                                  sm: "0.85rem",
                                  md: "1rem",
                                },
                                padding: {
                                  xs: "1px",
                                  sm: "2px",
                                  md: "3px",
                                  lg: "4px",
                                },
                              },
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{ width: "200px" }}
                                label="Select Store"
                              />
                            )}
                          />
                        </Col>
                        <Col lg={4} className="mb-2">
                          <Form.Label
                            style={{
                              margin: "7px 5px 0 0",
                            }}
                          >
                            From
                          </Form.Label>
                          <TextField
                            type="date"
                            size="small"
                            sx={{
                              width: "200px",
                              "& .MuiInputBase-root": {
                                fontSize: {
                                  xs: "0.7rem",
                                  sm: "0.85rem",
                                  md: "0.9rem",
                                },
                                padding: {
                                  xs: "1px",
                                  sm: "2px",
                                  md: "3px",
                                  lg: "4px",
                                },
                              },
                            }}
                            onChange={(event) => {
                              if (
                                new Date() >= new Date(event.target.value) &&
                                new Date(storeDatasDate.end) >=
                                  new Date(event.target.value)
                              ) {
                                storeDatasDate.start = new Date(
                                  event.target.value
                                );
                                setStoreDatasDate(storeDatasDate);
                              }
                              checkDate.start = new Date(event.target.value);
                              setCheckDate(checkDate);
                            }}
                            error={
                              new Date() < new Date(checkDate.start) ||
                              new Date(checkDate.end) <
                                new Date(checkDate.start)
                            }
                          />
                        </Col>
                        <Col lg={4} className="mb-2 ">
                          <Form.Label
                            style={{
                              margin: "7px 27px 0 0",
                            }}
                          >
                            To
                          </Form.Label>
                          <TextField
                            size="small"
                            type="date"
                            sx={{
                              width: "200px",
                              "& .MuiInputBase-root": {
                                fontSize: {
                                  xs: "0.7rem",
                                  sm: "0.85rem",
                                  md: "0.9rem",
                                },
                                padding: {
                                  xs: "1px",
                                  sm: "2px",
                                  md: "3px",
                                  lg: "4px",
                                },
                              },
                            }}
                            onChange={(event) => {
                              if (
                                new Date() >= new Date(event.target.value) &&
                                new Date(event.target.value) >=
                                  new Date(storeDatasDate.start)
                              ) {
                                storeDatasDate.end = new Date(
                                  event.target.value
                                );
                                setStoreDatasDate(storeDatasDate);
                              }
                              checkDate.end = new Date(event.target.value);
                              setCheckDate(checkDate);
                            }}
                            error={
                              new Date() < new Date(checkDate.end) ||
                              new Date(checkDate.end) <
                                new Date(checkDate.start)
                            }
                          />
                        </Col>
                        <Col lg={1}>
                          <>
                            <Button
                              onClick={(e) => {
                                let data = [];
                                if (oneItems === null) {
                                  allStoreDatas?.map((qua) => {
                                    const date = new Date(qua.date);
                                    const startDate =
                                      (date - storeDatasDate.start) / 86400000;
                                    const endDate =
                                      (storeDatasDate.end - date) / 86400000;
                                    if (startDate >= 0 && endDate >= 0) {
                                      data.push(qua);
                                    }
                                  });
                                } else {
                                  allStoreDatas?.map((qua) => {
                                    const date = new Date(qua.date);
                                    const startDate =
                                      (date - storeDatasDate.start) / 86400000;
                                    const endDate =
                                      (storeDatasDate.end - date) / 86400000;
                                    if (
                                      startDate >= 0 &&
                                      endDate >= 0 &&
                                      qua.storeId === oneItems.Id
                                    ) {
                                      data.push(qua);
                                    }
                                  });
                                }

                                setStoreDatas(data);
                              }}
                              style={{ marginLeft: "50px" }}
                              sx={{
                                borderColor: "info.main",
                                fontSize: {
                                  xs: "11px",
                                  sm: "12px",
                                  md: "0.9rem",
                                },
                                padding: {
                                  xs: "3px",
                                  sm: "3px",
                                  md: "4px",
                                  lg: "5px",
                                },
                                "&:hover": {
                                  backgroundColor: "info.main",
                                  color: "white",
                                },
                              }}
                              variant="outlined"
                              color="info"
                            >
                              Search
                            </Button>
                          </>
                        </Col>
                      </Form.Group>
                      <hr />
                    </Form>
                  )}
                  <div style={{ overflow: "scroll" }}>
                    {" "}
                    <Box
                      sx={{
                        height: 500,
                        width: {
                          lg: "100%",
                          md: "1000px",
                          sm: "1000px",
                          xs: "1000px",
                        },
                        "& .super-app-theme--header": {
                          backgroundColor: "#1de5ec",
                          color: "black",
                          fontSize: {
                            xs: "13px",
                            sm: "15px", //500
                            md: "17px", //768
                            lg: "18px", //1024
                          },
                        },
                      }}
                    >
                      <DataGrid
                        rows={open === 0 ? rows : rowDelivery}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        sx={{
                          "& .MuiDataGrid-cell": {
                            fontSize: {
                              xs: "12px",
                              sm: "14px", //500
                              md: "16px", //768
                              lg: "17px", //1024
                            },
                          },
                          "& .super-app-theme--cell-reject": {
                            color: "red",
                          },
                          "& .super-app-theme--cell-approve": {
                            color: "#06a603",
                          },
                        }}
                        columns={open === 0 ? columns : columnItems}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                          },
                        }}
                        initialState={
                          open === 0
                            ? {
                                ...storeDatas.initialState,
                                pagination: {
                                  paginationModel: { pageSize: 5 },
                                },
                              }
                            : {
                                ...storeDataItems.initialState,
                                pagination: {
                                  paginationModel: { pageSize: 5 },
                                },
                              }
                        }
                        onCellClick={(row) => {
                          if (open === 0) handleView(row.row);
                        }}
                        pageSizeOptions={[5, 10, 15]}
                        getRowId={(row) => row.Id}
                      />
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </center>
        </main>
      </Typography>
    </ThemeProvider>
  );
};
export default StockReport;
