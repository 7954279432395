/** @format */
import React, { useEffect, useRef, useState } from "react";
import Header from "../Header";

import { Modal, Form, Col, Row, Table } from "react-bootstrap";
import {
  Button,
  Autocomplete,
  Box,
  Container,
  TextField,
  createTheme,
  useMediaQuery,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  faUser,
  faBackwardStep,
  faFloppyDisk,
  faCode,
  faCalendar,
  faPenToSquare,
  faTrashCan,
  faXmark,
  faEye,
  faPrint,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import QRCode from "qrcode";
import HexCharacterManipulator from "./AllDataFetch";
import { jwtDecode } from "jwt-decode";
const validationSchema = Yup.object().shape({
  date: Yup.date().required("Date is required!"),
  supplierId: Yup.string().required("Supplier is required"),
  fsNumber: Yup.string()
    .matches(
      /^[0-9]{0,20}$/,
      "FS number must be less than 20 numeric characters."
    )
    .required("FS number is required."),
  acNumber: Yup.string()
    .matches(
      /^[0-9]{0,20}$/,
      "MRC number must be less than 20 numeric characters."
    )
    .required("MRC number is required."),
  refNumber: Yup.string()
    .matches(
      /^[A-Za-z0-9-]{3,20}$/,
      "Ref number should be 3-20 alphanumeric characters."
    )
    .required("Ref number is required."),
});
const theme = createTheme({
  breakpoints: { values: { xs: 0, sm: 500, md: 769, lg: 1024, xl: 1440 } },
  palette: {
    warning: {
      main: "#ffc107", // Main warning color
      light: "#ffb74d", // Light variation of warning color
      dark: "#f57c00", // Dark variation of warning color
      contrastText: "#000", // Text color on warning
    },
  },
});
function PurchasePage() {
  const [open, setOpen] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [openSupplier, setOpenSupplier] = useState(false);
  const [items, setItems] = useState([]);
  const [units, setUnits] = useState([]);
  const [models, setModels] = useState([]);
  const [itemId, setItemId] = useState(null);
  const [itemCheck, setItemCheck] = useState(null);
  const [supplierCheck, setSupplierCheck] = useState(null);
  const [supplierValue, setSupplierValue] = useState(null);
  const [itemValue, setItemValue] = useState([]);
  const [valids, setValid] = useState([0, 0, 0]);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [taxs, setTaxs] = useState([]);
  const [users, setUsers] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [purchaseItems, setPurchaseItems] = useState([]);
  const [openPurchaseItem, setOpenPurchaseItem] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [supplierErrorTin, setSupplierErrorTin] = useState(false);
  const [supplierErrorPhone, setSupplierErrorPhone] = useState(false);
  const [logicals, setLogicals] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [update, setUpdate] = useState({
    itemId: "",
    quantity: "",
    price: "",
  });

  const [price, setPrice] = useState({
    total: 0,
    vat: 0,
    grandTotal: 0,
  });
  const userData = jwtDecode(sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const contentRef = useRef();
  const reactToPrint = useReactToPrint({ contentRef });
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isUnderMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const fetchAllData = async () => {
    try {
      const [
        supplier,
        item,
        unit,
        purchase,
        purchaseItem,
        user,
        model,
        tax,
        logical,
        transaction,
      ] = await Promise.all([
        axios.get("https://enin.server.highendtradingplc.com/supplier"),
        axios.get("https://enin.server.highendtradingplc.com/item"),
        axios.get("https://enin.server.highendtradingplc.com/unit"),
        axios.get("https://enin.server.highendtradingplc.com/purchase"),
        axios.get("https://enin.server.highendtradingplc.com/purchase_item"),
        axios.get("https://enin.server.highendtradingplc.com/user"),
        axios.get("https://enin.server.highendtradingplc.com/model"),
        axios.get("https://enin.server.highendtradingplc.com/tax"),
        axios.get("https://enin.server.highendtradingplc.com/logical_stock"),
        axios.get("https://enin.server.highendtradingplc.com/transaction"),
      ]);
      setSuppliers(supplier.data);
      setItems(item.data.filter((i) => i.statusId === 2));
      setUnits(unit.data);
      setModels(model.data);
      setPurchases(purchase.data);
      setPurchaseItems(purchaseItem.data);
      setUsers(user.data?.filter((user) => user.roleId >= 2));
      setTaxs(tax.data);
      setLogicals(logical.data);
      setTransactions(transaction.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  const handleOpenSupplier = () => {
    setOpenSupplier(!openSupplier);
  };

  const handleOpenPurchaseItem = (row) => {
    const purchaseItem = purchaseItems.filter(
      (i) => i.purchaseId === row.purchaseId
    );
    const data = purchaseItem.map((p, index) => {
      const item = items.find((i) => i.Id === p.itemId);
      const tax = taxs.find((t) => t.Id === item.taxId);
      const logical = logicals.find((l) => l.itemId === item.Id);
      const transaction = transactions.find(
        (t) => t.Id === logical.transactionId
      );
      price.vat += parseFloat((p.totalPrice * tax.taxValue) / 100);
      price.total += p.totalPrice;
      return {
        itemId: item.Id,
        unitId: item.unitId,
        quantity: transaction.quantity,
        price: p.price,
        totalPrice: p.totalPrice,
      };
    });
    formik.values.supplierId = row.supplierId;
    formik.values.acNumber = row.acNumber;
    formik.values.fsNumber = row.fsNumber;
    formik.values.refNumber = row.refNumber;
    formik.values.date = row.date;
    price.grandTotal = price.vat + price.total;
    formik.values.Id = row.purchaseId;
    const supplier = suppliers.find((c) => c.Id === row.supplierId);
    const hexCharacterManipulator = new HexCharacterManipulator();
    let charTohexa = hexCharacterManipulator.charTohexa(
      [price.total, price.vat],
      price.grandTotal
    );
    const dataValue = `
            Purchase ID : ${charTohexa}
            Supplier Name : ${supplier.fullName},
            Supplier Phone : ${supplier.phoneNumber},
            Grand Price : ${price.grandTotal}.`;
    generateQRCode(dataValue);
    setItemValue(data);
    setOpenPurchaseItem(true);
  };
  const supplierData = {
    options: suppliers,
    getOptionLabel: (option) => option.fullName,
  };

  const itemData = {
    options: items,
    getOptionLabel: (option) => option.description,
  };
  const formik = useFormik({
    initialValues: {
      date: "",
      supplierId: "",
      fsNumber: "",
      acNumber: "",
      refNumber: "",
      Id: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      if (itemValue.length === 0) return;
      if (isSubmitted) return;
      setIsSubmitted(true);
      // Get the current time
      const currentTime = new Date();
      const hours = currentTime.getHours();
      const minutes = currentTime.getMinutes();
      const seconds = currentTime.getSeconds();

      // Append the current time to the date value
      values.date = `${values.date} ${hours}:${minutes}:${seconds}`;

      const supplier = suppliers.find((c) => c.Id === values.supplierId);
      const hexCharacterManipulator = new HexCharacterManipulator();
      let charTohexa = hexCharacterManipulator.charTohexa(
        [price.total, price.vat],
        price.grandTotal
      );
      const dataValue = `
            Purchase ID : ${charTohexa}
            Supplier Name : ${supplier.fullName},
            Supplier Phone : ${supplier.phoneNumber},
            Grand Price : ${price.grandTotal}.`;
      generateQRCode(dataValue);
      setOpenPurchaseItem(true);
      try {
        await axios
          .post("https://enin.server.highendtradingplc.com/purchase/add", {
            date: values.date,
            userId: userData.Id,
            supplierId: values.supplierId,
            totalPrice: parseFloat(price.grandTotal),
            statusId: 1,
            fsNumber: values.fsNumber,
            acNumber: values.acNumber,
            refNumber: values.refNumber,
          })
          .then(async (res) => {
            for (let i = 0; i <= itemValue.length - 1; ) {
              let count = 0;
              const logical = logicals?.find(
                (l) => l.itemId === itemValue[i].itemId
              ) || { quantity: 0 };
              await axios
                .post(
                  "https://enin.server.highendtradingplc.com/transaction/add",
                  {
                    quantity: parseInt(itemValue[i].quantity),
                    statusId: 1,
                    date: values.date,
                  }
                )
                .then(async (res1) => {
                  await axios.post(
                    "https://enin.server.highendtradingplc.com/logical_stock/add",
                    {
                      quantity:
                        logical.quantity + parseInt(itemValue[i].quantity),
                      itemId: itemValue[i].itemId,
                      date: values.date,
                      statusId: 1,
                      transactionId: res1.data.data.Id,
                      ADPRS_ID: res.data.data.Id,
                    }
                  );
                  count += 1;
                });
              await axios
                .post(
                  "https://enin.server.highendtradingplc.com/purchase_item/add",
                  {
                    itemId: itemValue[i].itemId,
                    purchaseId: res.data.data.Id,
                    quantity: itemValue[i].quantity,
                    remainingQuantity: itemValue[i].quantity,
                    unitId: itemValue[i].unitId,
                    price: itemValue[i].price,
                    totalPrice: itemValue[i].totalPrice,
                  }
                )
                .then((res) => (count += 1));
              if (count === 2) i = i + 1;
            }
          });
      } catch (error) {
        console.log(error);
      }
      setIsSubmitted(false);
    },
  });
  const handleBack = () => {
    fetchAllData();
    formik.values.date = "";
    formik.values.supplierId = "";
    formik.values.Id = "";
    formik.values.acNumber = "";
    formik.values.fsNumber = "";
    formik.values.refNumber = "";
    price.total = 0;
    setValid([0, 0, 0]);
    setItemCheck(null);
    setItemId(null);
    price.vat = 0;
    price.grandTotal = 0;
    setSupplierCheck(null);
    setSupplierValue(null);
    setItemValue([]);
    setOpen(false);
    setOpenPurchaseItem(false);
  };
  const clearData = () => {
    price.total = 0;
    price.vat = 0;
    price.grandTotal = 0;
    setItemValue([]);
  };

  const generateQRCode = (text) => {
    QRCode.toDataURL(text)
      .then((url) => {
        setQrCodeUrl(url); // Set the QR code image URL
      })
      .catch((err) => console.error("Error generating QR code:", err));
  };
  const handleValidity = (i) => {
    valids[i] = 1;
    setValid([...valids]);
  };
  const columnPurchase = [
    {
      field: "Id",
      headerName: "No",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "purchaseId",
      headerName: "Purchase Id",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "userId",
      headerName: "Purchased By",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        users.find((user) => user.Id === params)?.fullName || "",
    },
    {
      field: "fullName",
      headerName: "Supplier Name",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "phoneNo",
      headerName: "Phone Number",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "date",
      headerName: "Date",
      headerAlign: "center",
      align: "center",
      flex: 0.75,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        `${new Date(params).getDate()}/${
          new Date(params).getMonth() + 1
        }/${new Date(params).getFullYear()}`,
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 150,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <>
          <Button
            onClick={() => handleOpenPurchaseItem(params.row)}
            sx={{
              width: "90px",
              borderColor: "primary.main",
              fontSize: {
                xs: "0.85rem",
                sm: "0.85rem",
                md: "1rem",
              },
              padding: {
                xs: "4px",
                sm: "5px",
                md: "6px",
                lg: "6px",
              },
              "&:hover": {
                backgroundColor: "primary.main",
                color: "white",
              },
            }}
            variant="outlined"
            color="primary"
            className="m-1"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{
                    float: "right",
                    marginRight: "-1px",
                    marginTop: "2px",
                    marginBottom: "-4px",
                  }}
                  icon={faEye}
                />
              </div>
              <div className="col-6">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-10px",
                    marginTop: "-1px",
                    marginBottom: "-7px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "0.85rem",
                        sm: "0.85rem",
                        md: "1rem",
                      },
                    }}
                  >
                    View
                  </Typography>
                </h6>
              </div>
            </div>
          </Button>
        </>
      ),
    },
  ];

  const rowPurchase = purchases.map((purchase, index) => {
    const supplier = suppliers.find(
      (supplier) => supplier.Id === purchase.supplierId
    );
    return {
      Id: index + 1,
      userId: purchase.userId,
      purchaseId: purchase.Id,
      date: purchase.date,
      totalPrice: purchase.totalPrice,
      fullName: supplier.fullName,
      phoneNo: supplier.phoneNumber,
      fsNumber: purchase.fsNumber,
      acNumber: purchase.acNumber,
      refNumber: purchase.refNumber,
      supplierId: supplier.Id,
    };
  });

  const handelInvoice = () => {
    let data = [];
    for (let index = itemValue.length; index < 5; index++) {
      data.push(
        <tr key={index}>
          <td>{index + 1}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      );
    }
    return data;
  };
  return (
    <ThemeProvider theme={theme}>
      <Typography
        sx={{
          fontSize: {
            xs: "12px",
            sm: "12px", //500
            md: "0.85rem", //768
            lg: "17px", //1024
          },
        }}
      >
        <div>
          <Header />
        </div>
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: "13px",
            sm: "14px", //500
            md: "16px", //768
            lg: "18px", //1024
          },
        }}
      >
        <div>
          <main
            className=""
            style={
              isMobile && !isUnderMobile
                ? { marginTop: "190px" }
                : isUnderMobile
                ? { marginTop: "170px" }
                : isTablet
                ? { marginTop: "133px" }
                : { marginTop: "150px" }
            }
          >
            <center>
              <div
                style={
                  isUnderMobile
                    ? {
                        minWidth: "500px",
                        overflow: "scroll",
                        marginBottom: "-5px",
                      }
                    : { maxWidth: "1100px" }
                }
                className="text-left"
              >
                <div
                  className="tab-container "
                  style={
                    isUnderMobile
                      ? { marginTop: "0", marginBottom: "-5px" }
                      : {
                          marginTop: "-20px",
                          marginBottom: "-5px",
                        }
                  }
                >
                  <Link
                    className="p-2"
                    onClick={(e) => {
                      window.location.reload();
                    }}
                    style={{
                      backgroundColor: "white",
                      borderRadius: "5px 5px 0 0",
                      border: "solid 0.05em rgb(181, 181, 181)",
                    }}
                  >
                    Purchase
                  </Link>
                  <Link className="p-2" to={`/stock_available/1`}>
                    Stock Available
                  </Link>

                  <Link className="p-2" to={`/adjustment`}>
                    Adjustment
                  </Link>
                  <Link className="p-2" to={`/payment`}>
                    Bank
                  </Link>
                </div>
                <div className="card card-info card-outline">
                  {!openPurchaseItem && (
                    <div>
                      <div className="card-header">
                        {!open && (
                          <Button
                            sx={{
                              width: "150px",
                              borderColor: "info.main",
                              fontSize: {
                                xs: "0.7rem",
                                sm: "0.85rem",
                                md: "1rem",
                              },
                              padding: {
                                xs: "0",
                                sm: "0",
                                md: "1px",
                                lg: "3px",
                              },
                              "&:hover": {
                                backgroundColor: "info.main",
                                color: "white",
                              },
                            }}
                            variant="outlined"
                            color="info"
                            onClick={(e) => setOpen(true)}
                          >
                            <h4
                              style={{
                                float: "left",
                                marginRight: "5px",
                                marginTop: "-1",
                                marginBottom: "-2px",
                              }}
                            >
                              +
                            </h4>
                            <strong
                              style={{
                                float: "left",
                                marginTop: "0",
                                marginBottom: "-2px",
                              }}
                            >
                              Create New
                            </strong>
                          </Button>
                        )}
                        {open && (
                          <div>
                            <h5 style={{ float: "left" }}>
                              <Typography
                                sx={{
                                  fontSize: {
                                    xs: "1.3rem",
                                    sm: "1.3rem",
                                    md: "1.5rem",
                                  },
                                }}
                              >
                                New Purchase Entry
                              </Typography>
                            </h5>
                            <Button
                              onClick={handleBack}
                              sx={{
                                width: "100px",
                                borderColor: "success.main",
                                fontSize: {
                                  xs: "0.85rem",
                                  sm: "0.85rem",
                                  md: "1rem",
                                },
                                padding: {
                                  xs: "4px",
                                  sm: "5px",
                                  md: "6px",
                                  lg: "6px",
                                },
                                "&:hover": {
                                  backgroundColor: "success.main",
                                  color: "white",
                                },
                              }}
                              variant="outlined"
                              color="success"
                              type="submit"
                              className="m-1"
                              style={{ float: "right" }}
                            >
                              <div
                                className="row"
                                style={{
                                  marginBottom: "-10px",
                                  marginLeft: "-10px",
                                }}
                              >
                                <div className="col">
                                  <FontAwesomeIcon
                                    style={{
                                      float: "right",
                                      marginRight: "-1px",
                                      marginTop: "2px",
                                      marginBottom: "-4px",
                                    }}
                                    icon={faBackwardStep}
                                  />
                                </div>
                                <div className="col-7">
                                  <h6
                                    style={{
                                      float: "left",
                                      marginLeft: "-10px",
                                      marginTop: "-1px",
                                      marginBottom: "-7px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: {
                                          xs: "0.85rem",
                                          sm: "0.85rem",
                                          md: "1rem",
                                        },
                                      }}
                                    >
                                      Back
                                    </Typography>
                                  </h6>
                                </div>
                              </div>
                            </Button>
                          </div>
                        )}
                      </div>
                      <div className="card-body">
                        {open && (
                          <div>
                            <Typography
                              sx={{
                                fontSize: {
                                  xs: "0.85rem",
                                  sm: "0.85rem",
                                  md: "1rem",
                                },
                              }}
                            >
                              <div
                                className="nav-bra pl-3 ml-1 p-2 "
                                style={{
                                  backgroundColor: "rgb(235, 235, 235)",
                                }}
                              >
                                <span>Purchase / Create</span>
                              </div>
                            </Typography>

                            <hr />
                            <div>
                              <div>
                                <Form className="">
                                  <Form.Group
                                    as={Row}
                                    controlId="formHorizontalUsername"
                                  >
                                    <Col
                                      lg={4}
                                      className="mb-3 row"
                                      style={{
                                        minWidth: "360px",
                                        marginRight: "3px",
                                        maxWidth: "361px",
                                      }}
                                    >
                                      <Autocomplete
                                        {...supplierData}
                                        className="col"
                                        id="controlled-demo"
                                        size="small"
                                        variant="outlined"
                                        name="supplierId"
                                        onBlur={(e) => handleValidity(1)}
                                        value={supplierValue}
                                        onChange={(event, newValue) => {
                                          if (!newValue?.Id) {
                                            setSupplierCheck(null);
                                            setSupplierValue(null);
                                            formik.values.supplierId = "";
                                            return;
                                          }
                                          setSupplierValue(newValue);
                                          formik.values.supplierId =
                                            newValue?.Id;
                                          setSupplierCheck(5);
                                        }}
                                        sx={{
                                          "& .MuiInputBase-root": {
                                            fontSize: {
                                              xs: "0.7rem",
                                              sm: "0.85rem",
                                              md: "1rem",
                                            },
                                            padding: {
                                              xs: "1px",
                                              sm: "2px",
                                              md: "3px",
                                              lg: "4px",
                                            },
                                          },
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            sx={{ width: "250px" }}
                                            error={
                                              (valids[1] === 1 &&
                                                supplierCheck === null) ||
                                              formik.errors.supplierId
                                            }
                                            helperText={
                                              (valids[1] === 1 &&
                                                supplierCheck === null) ||
                                              formik.errors.supplierId
                                                ? `Supplier is required!`
                                                : ""
                                            }
                                            label={
                                              <div style={{ float: "left" }}>
                                                <FontAwesomeIcon
                                                  icon={faUser}
                                                  style={{
                                                    float: "left",
                                                    marginRight: "10px",
                                                  }}
                                                />
                                                <span>Supplier</span>
                                              </div>
                                            }
                                          />
                                        )}
                                      />

                                      <Button
                                        className="col"
                                        variant="outlined"
                                        color="info"
                                        sx={{
                                          float: "left",

                                          maxWidth: "20px",
                                          height: {
                                            xs: "32px",
                                            sm: "36px",
                                            md: "40px",
                                          },
                                          borderColor: "info.main",
                                          padding: {
                                            xs: "-0",
                                            sm: "0",
                                            md: "1px",
                                            lg: "3px",
                                          },
                                          "&:hover": {
                                            backgroundColor: "info.main",
                                            color: "white",
                                          },
                                        }}
                                        onClick={handleOpenSupplier}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: {
                                              xs: "2rem",
                                              sm: "2.5rem",
                                              md: "3rem",
                                            },
                                          }}
                                          style={{
                                            marginTop: "12px",
                                          }}
                                        >
                                          +
                                        </Typography>
                                      </Button>
                                    </Col>

                                    <Col lg={4} className="mb-3">
                                      <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        disabled
                                        value={null}
                                        type="text"
                                        label={
                                          <div style={{ float: "left" }}>
                                            <FontAwesomeIcon
                                              icon={faPhone}
                                              size="1x"
                                              style={{
                                                float: "left",
                                                marginRight: "5px",
                                              }}
                                            />
                                            <span>
                                              {!!supplierValue
                                                ? supplierValue?.phoneNumber
                                                : "Phone"}
                                            </span>
                                          </div>
                                        }
                                        size="small"
                                        className="mb-3 "
                                        sx={{
                                          width: "250px",
                                          "& .MuiInputBase-root": {
                                            fontSize: {
                                              xs: "0.7rem",
                                              sm: "0.85rem",
                                              md: "0.9rem",
                                            },
                                            padding: {
                                              xs: "1px",
                                              sm: "2px",
                                              md: "3px",
                                              lg: "4px",
                                            },
                                          },
                                        }}
                                      />
                                    </Col>

                                    <Col lg={4} className="mb-3">
                                      <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.date}
                                        type="date"
                                        label={
                                          <div
                                            style={{
                                              float: "left",
                                              width: "70px",
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faCalendar}
                                              size="1x"
                                              style={{
                                                float: "left",
                                                marginTop: "5px",
                                                marginRight: "5px",
                                              }}
                                            />
                                            <h4>Date</h4>
                                          </div>
                                        }
                                        size="small"
                                        name="date"
                                        error={
                                          formik.touched.date &&
                                          formik.errors.date
                                        }
                                        helperText={
                                          formik.touched.date &&
                                          formik.errors.date
                                            ? `${formik.errors.date}`
                                            : ""
                                        }
                                        sx={{
                                          width: "250px",
                                          "& .MuiInputBase-root": {
                                            fontSize: {
                                              xs: "0.7rem",
                                              sm: "0.85rem",
                                              md: "0.9rem",
                                            },
                                            padding: {
                                              xs: "1px",
                                              sm: "2px",
                                              md: "3px",
                                              lg: "4px",
                                            },
                                          },
                                        }}
                                      />
                                    </Col>
                                  </Form.Group>
                                  <Form.Group as={Row}>
                                    <Col lg={4}>
                                      <TextField
                                        id="outlined-basic"
                                        className="mb-3"
                                        label={
                                          <div style={{ float: "left" }}>
                                            <FontAwesomeIcon
                                              icon={faCode}
                                              style={{
                                                float: "left",
                                                marginRight: "10px",
                                              }}
                                            />
                                            <span> Fs Number</span>
                                          </div>
                                        }
                                        variant="outlined"
                                        type="text"
                                        size="small"
                                        name="fsNumber"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.fsNumber}
                                        error={
                                          formik.touched.fsNumber &&
                                          formik.errors.fsNumber
                                        }
                                        helperText={
                                          formik.touched.fsNumber &&
                                          formik.errors.fsNumber
                                            ? `${formik.errors.fsNumber}`
                                            : ""
                                        }
                                        sx={{
                                          width: "250px",
                                          "& .MuiInputBase-root": {
                                            fontSize: {
                                              xs: "0.7rem",
                                              sm: "0.85rem",
                                              md: "0.9rem",
                                            },
                                            padding: {
                                              xs: "1px",
                                              sm: "2px",
                                              md: "3px",
                                              lg: "4px",
                                            },
                                          },
                                        }}
                                      />
                                    </Col>
                                    <Col lg={4}>
                                      <TextField
                                        id="outlined-basic"
                                        className="mb-3"
                                        label={
                                          <div style={{ float: "left" }}>
                                            <FontAwesomeIcon
                                              icon={faCode}
                                              style={{
                                                float: "left",
                                                marginRight: "10px",
                                              }}
                                            />
                                            <span> MRC Number</span>
                                          </div>
                                        }
                                        variant="outlined"
                                        type="text"
                                        size="small"
                                        name="acNumber"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.acNumber}
                                        error={
                                          formik.touched.acNumber &&
                                          formik.errors.acNumber
                                        }
                                        helperText={
                                          formik.touched.acNumber &&
                                          formik.errors.acNumber
                                            ? `${formik.errors.acNumber}`
                                            : ""
                                        }
                                        sx={{
                                          width: "250px",
                                          "& .MuiInputBase-root": {
                                            fontSize: {
                                              xs: "0.7rem",
                                              sm: "0.85rem",
                                              md: "0.9rem",
                                            },
                                            padding: {
                                              xs: "1px",
                                              sm: "2px",
                                              md: "3px",
                                              lg: "4px",
                                            },
                                          },
                                        }}
                                      />
                                    </Col>
                                    <Col lg={4}>
                                      <TextField
                                        id="outlined-basic"
                                        className="mb-3"
                                        label={
                                          <div style={{ float: "left" }}>
                                            <FontAwesomeIcon
                                              icon={faCode}
                                              style={{
                                                float: "left",
                                                marginRight: "10px",
                                              }}
                                            />
                                            <span> REF Number</span>
                                          </div>
                                        }
                                        variant="outlined"
                                        type="text"
                                        size="small"
                                        name="refNumber"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.refNumber}
                                        error={
                                          formik.touched.refNumber &&
                                          formik.errors.refNumber
                                        }
                                        helperText={
                                          formik.touched.refNumber &&
                                          formik.errors.refNumber
                                            ? `${formik.errors.refNumber}`
                                            : ""
                                        }
                                        sx={{
                                          width: "250px",
                                          "& .MuiInputBase-root": {
                                            fontSize: {
                                              xs: "0.7rem",
                                              sm: "0.85rem",
                                              md: "0.9rem",
                                            },
                                            padding: {
                                              xs: "1px",
                                              sm: "2px",
                                              md: "3px",
                                              lg: "4px",
                                            },
                                          },
                                        }}
                                      />
                                    </Col>
                                  </Form.Group>
                                </Form>
                              </div>
                              <Formik
                                initialValues={{
                                  itemId: "",
                                  quantity: "",
                                  unitId: "",
                                  price: "",
                                  taxId: "",
                                  totalPrice: "",
                                }}
                                validationSchema={Yup.object().shape({
                                  quantity: Yup.number()
                                    .min(1, "Too Low!")
                                    .max(10000, "Too High!")
                                    .required("Quantity is required"),
                                  price: Yup.number(1, "Too Low")
                                    .max(10000000, "Too High")
                                    .required("Price is required"),
                                })}
                                onSubmit={async (values) => {
                                  try {
                                    if (itemCheck === null) {
                                      return;
                                    }
                                    const checkItem = itemValue?.find(
                                      (i) => i?.itemId === itemId.Id
                                    );
                                    if (!!checkItem) {
                                      //enin.server.highendtradingplc.com"You use the same item!");
                                      https: return;
                                    }
                                    values.totalPrice =
                                      values.quantity * values.price;
                                    let tax = taxs.find(
                                      (tt) => tt.Id === itemId.taxId
                                    );
                                    price.vat =
                                      parseFloat(price.vat) +
                                      parseFloat(values.totalPrice) *
                                        parseFloat(tax.taxValue / 100);
                                    price.total =
                                      parseFloat(price.total) +
                                      parseFloat(values.totalPrice);
                                    price.grandTotal = price.total + price.vat;
                                    setItemId(null);
                                    setItemValue([
                                      ...itemValue,
                                      {
                                        taxId: values.taxId,
                                        unitId: values.unitId,
                                        itemId: values.itemId,
                                        quantity: values.quantity,
                                        price: values.price,
                                        totalPrice: values.totalPrice,
                                      },
                                    ]);
                                  } catch (error) {
                                    console.log(error);
                                  } finally {
                                    values.quantity = "";
                                    values.unitId = "";
                                    values.price = "";
                                    values.taxId = "";
                                  }
                                }}
                              >
                                {(props) => (
                                  <div className="">
                                    <hr />
                                    <h5>Purchase Item</h5>
                                    <hr />
                                    <Form onSubmit={props.handleSubmit}>
                                      <Form.Group
                                        as={Row}
                                        controlId="formHorizontalUsername"
                                      >
                                        <Col lg={4} className="mb-3">
                                          <Autocomplete
                                            {...itemData}
                                            id="controlled-demo"
                                            size="small"
                                            name="itemId"
                                            value={itemId}
                                            onBlur={(e) => handleValidity(2)}
                                            onChange={(event, newValue) => {
                                              if (!newValue?.Id) {
                                                setItemCheck(null);
                                                setItemId(null);
                                                props.values.unitId = "";
                                                props.values.itemId = "";
                                                props.values.taxId = "";
                                                return;
                                              }
                                              setItemId(newValue);
                                              props.values.unitId =
                                                newValue?.unitId;
                                              props.values.taxId =
                                                newValue?.taxId;
                                              props.values.itemId =
                                                newValue?.Id;
                                              setItemCheck(5);
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                sx={{
                                                  width: "250px",
                                                  "& .MuiInputBase-root": {
                                                    fontSize: {
                                                      xs: "0.7rem",
                                                      sm: "0.85rem",
                                                      md: "1.1rem",
                                                    },
                                                    padding: {
                                                      xs: "1px",
                                                      sm: "2px",
                                                      md: "3px",
                                                      lg: "4px",
                                                    },
                                                  },
                                                }}
                                                label={
                                                  <div
                                                    style={{ float: "left" }}
                                                  >
                                                    <span>Select Item</span>
                                                  </div>
                                                }
                                                error={
                                                  valids[2] === 1 &&
                                                  itemCheck === null
                                                }
                                              />
                                            )}
                                          />
                                        </Col>
                                        <Col lg={4} className="mb-3">
                                          <TextField
                                            id="outlined-basic"
                                            label="Qty"
                                            variant="outlined"
                                            type="text"
                                            size="small"
                                            name="quantity"
                                            onChange={props.handleChange}
                                            value={props.values.quantity}
                                            onBlur={props.handleBlur}
                                            error={
                                              props.touched.quantity &&
                                              props.errors.quantity
                                            }
                                            helperText={
                                              props.touched.quantity &&
                                              props.errors.quantity
                                                ? `${props.errors.quantity}`
                                                : ""
                                            }
                                            sx={{
                                              width: "250px",
                                              "& .MuiInputBase-root": {
                                                fontSize: {
                                                  xs: "0.7rem",
                                                  sm: "0.85rem",
                                                  md: "0.9rem",
                                                },
                                                padding: {
                                                  xs: "1px",
                                                  sm: "2px",
                                                  md: "3px",
                                                  lg: "4px",
                                                },
                                              },
                                            }}
                                          />
                                        </Col>
                                        <Col lg={4} className="mb-3">
                                          <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            label="Price"
                                            type="text"
                                            size="small"
                                            name="price"
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.price}
                                            error={
                                              props.touched.price &&
                                              props.errors.price
                                            }
                                            helperText={
                                              props.touched.price &&
                                              props.errors.price
                                                ? `${props.errors.price}`
                                                : ""
                                            }
                                            sx={{
                                              width: "250px",
                                              "& .MuiInputBase-root": {
                                                fontSize: {
                                                  xs: "0.7rem",
                                                  sm: "0.85rem",
                                                  md: "0.9rem",
                                                },
                                                padding: {
                                                  xs: "1px",
                                                  sm: "2px",
                                                  md: "3px",
                                                  lg: "4px",
                                                },
                                              },
                                            }}
                                          />
                                        </Col>
                                      </Form.Group>
                                      <Form.Group as={Row}>
                                        <Col md={2} className="mb-3">
                                          <Button
                                            sx={{
                                              width: "200px",
                                              borderColor: "primary.main",
                                              fontSize: {
                                                xs: "0.85rem",
                                                sm: "0.85rem",
                                                md: "1rem",
                                              },
                                              padding: {
                                                xs: "4px",
                                                sm: "5px",
                                                md: "6px",
                                                lg: "6px",
                                              },
                                              "&:hover": {
                                                backgroundColor: "primary.main",
                                                color: "white",
                                              },
                                            }}
                                            variant="outlined"
                                            color="primary"
                                            type="submit"
                                          >
                                            <h4
                                              style={{
                                                float: "left",
                                                marginRight: "5px",
                                                marginTop: "-1px",
                                                marginBottom: "-2px",
                                              }}
                                            >
                                              +
                                            </h4>
                                            <strong
                                              style={{
                                                float: "left",
                                                marginTop: "0",
                                                marginBottom: "-2px",
                                              }}
                                            >
                                              Add to list
                                            </strong>
                                          </Button>
                                        </Col>
                                      </Form.Group>
                                    </Form>
                                    <div style={{ overflow: "scroll" }}>
                                      <div
                                        style={{
                                          minWidth: "1000px",
                                        }}
                                      >
                                        <table className="summary-table ">
                                          <thead className="bg-info">
                                            <tr>
                                              <th>Id</th>
                                              <th>Code</th>
                                              <th>Desc</th>
                                              <th>Model</th>
                                              <th>Unit</th>
                                              <th>U.Price</th> <th>Qty</th>
                                              <th>T.Price</th>
                                              <th style={{ minWidth: "230px" }}>
                                                Action
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {itemValue.map((request, index) => (
                                              <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                  {items.map((item) =>
                                                    item.Id ===
                                                    parseInt(request.itemId)
                                                      ? item.code
                                                      : ""
                                                  )}
                                                </td>
                                                <td>
                                                  {items.map((item) =>
                                                    item.Id ===
                                                    parseInt(request.itemId)
                                                      ? item.description
                                                      : ""
                                                  )}
                                                </td>
                                                <td>
                                                  {items.map((item) =>
                                                    item.Id ===
                                                    parseInt(request.itemId)
                                                      ? item.modelId
                                                      : ""
                                                  )}
                                                </td>
                                                <td>
                                                  {
                                                    units.find(
                                                      (unit) =>
                                                        unit.Id ===
                                                        request.unitId
                                                    )?.unitName
                                                  }
                                                </td>
                                                <td>
                                                  {openUpdate &&
                                                  request.itemId ===
                                                    update.itemId ? (
                                                    <input
                                                      type="number"
                                                      className={
                                                        parseFloat(
                                                          update.price
                                                        ) < 50
                                                          ? "form-control is-invalid"
                                                          : "form-control"
                                                      }
                                                      minLength={100}
                                                      maxLength={1000000}
                                                      required
                                                      value={update.price}
                                                      style={{
                                                        margin: "0 ",
                                                        width: "120px",
                                                      }}
                                                      onChange={(e) => {
                                                        if (
                                                          parseFloat(
                                                            e.target.value
                                                          ) < 1 ||
                                                          parseFloat(
                                                            e.target.value
                                                          ) > 1000000
                                                        ) {
                                                          setUpdate((prev) => ({
                                                            ...prev,
                                                            price: 1,
                                                          }));
                                                        } else {
                                                          setUpdate((prev) => ({
                                                            ...prev,
                                                            price: parseFloat(
                                                              e.target.value
                                                            ),
                                                          }));
                                                        }

                                                        setItemValue([
                                                          ...itemValue,
                                                        ]);
                                                      }}
                                                    />
                                                  ) : (
                                                    request.price.toLocaleString()
                                                  )}
                                                </td>{" "}
                                                <td>
                                                  {openUpdate &&
                                                  request.itemId ===
                                                    update.itemId ? (
                                                    <input
                                                      type="number"
                                                      minLength={1}
                                                      maxLength={20}
                                                      className="form-control"
                                                      required
                                                      style={{ width: "100px" }}
                                                      value={update.quantity}
                                                      onChange={(e) => {
                                                        if (
                                                          parseInt(
                                                            e.target.value
                                                          ) < 1 ||
                                                          parseInt(
                                                            e.target.value
                                                          ) > 10000
                                                        ) {
                                                          setUpdate((prev) => ({
                                                            ...prev,
                                                            quantity: 1,
                                                          }));
                                                        } else {
                                                          setUpdate((prev) => ({
                                                            ...prev,
                                                            quantity: parseInt(
                                                              e.target.value
                                                            ),
                                                          }));
                                                        }
                                                      }}
                                                    />
                                                  ) : (
                                                    request.quantity.toLocaleString()
                                                  )}
                                                </td>
                                                <td>
                                                  {request.totalPrice.toLocaleString()}
                                                </td>
                                                <td>
                                                  {(request.itemId !==
                                                    update.itemId ||
                                                    !openUpdate) && (
                                                    <div>
                                                      <Button
                                                        sx={{
                                                          width: "90px",
                                                          borderColor:
                                                            "warning.main",
                                                          fontSize: {
                                                            xs: "0.7rem",
                                                            sm: "0.85rem",
                                                            md: "1rem",
                                                          },
                                                          padding: {
                                                            xs: "3px",
                                                            sm: "4px",
                                                            md: "5px",
                                                            lg: "6px",
                                                          },
                                                          "&:hover": {
                                                            backgroundColor:
                                                              "warning.main",
                                                            color: "white",
                                                          },
                                                        }}
                                                        variant="outlined"
                                                        color="warning"
                                                        className="mr-3"
                                                        onClick={(e) => {
                                                          setOpenUpdate(true);
                                                          setUpdate({
                                                            itemId:
                                                              request.itemId,
                                                            quantity:
                                                              request.quantity,
                                                            price:
                                                              request.price,
                                                          });
                                                        }}
                                                      >
                                                        <div
                                                          className="row "
                                                          style={{
                                                            marginBottom:
                                                              "-10px",
                                                            marginLeft: "-10px",
                                                          }}
                                                        >
                                                          <div className="col">
                                                            <FontAwesomeIcon
                                                              style={{
                                                                float: "right",
                                                              }}
                                                              icon={
                                                                faPenToSquare
                                                              }
                                                            />
                                                          </div>
                                                          <div className="col-6">
                                                            <h6
                                                              style={{
                                                                float: "left",
                                                                marginLeft:
                                                                  "-10px",
                                                              }}
                                                            >
                                                              Edit
                                                            </h6>
                                                          </div>
                                                        </div>
                                                      </Button>
                                                      <Button
                                                        sx={{
                                                          width: "110px",
                                                          borderColor:
                                                            "error.main",
                                                          fontSize: {
                                                            xs: "0.7rem",
                                                            sm: "0.85rem",
                                                            md: "1rem",
                                                          },
                                                          padding: {
                                                            xs: "3px",
                                                            sm: "4px",
                                                            md: "5px",
                                                            lg: "6px",
                                                          },
                                                          "&:hover": {
                                                            backgroundColor:
                                                              "error.main",
                                                            color: "white",
                                                          },
                                                        }}
                                                        variant="outlined"
                                                        color="error"
                                                        onClick={(e) => {
                                                          const newItem =
                                                            itemValue.find(
                                                              (i) =>
                                                                parseInt(
                                                                  i.itemId
                                                                ) ===
                                                                parseInt(
                                                                  request.itemId
                                                                )
                                                            );
                                                          const tax = taxs.find(
                                                            (t) =>
                                                              t.Id ===
                                                              newItem?.taxId
                                                          );
                                                          const priceVat =
                                                            newItem.quantity *
                                                            parseFloat(
                                                              newItem.price
                                                            ) *
                                                            parseFloat(
                                                              tax.taxValue / 100
                                                            );

                                                          const newItemValue =
                                                            itemValue?.filter(
                                                              (i) =>
                                                                parseInt(
                                                                  i.itemId
                                                                ) !==
                                                                parseInt(
                                                                  request.itemId
                                                                )
                                                            );
                                                          setItemValue(
                                                            newItemValue
                                                          );
                                                          price.vat =
                                                            price.vat -
                                                            priceVat;
                                                          price.total =
                                                            parseFloat(
                                                              price.total
                                                            ) -
                                                            parseFloat(
                                                              newItem.price
                                                            ) *
                                                              newItem.quantity;
                                                          price.grandTotal =
                                                            price.total;
                                                        }}
                                                      >
                                                        <div
                                                          className="row"
                                                          style={{
                                                            marginBottom:
                                                              "-10px",
                                                            marginLeft: "-10px",
                                                          }}
                                                        >
                                                          <div className="col">
                                                            <FontAwesomeIcon
                                                              style={{
                                                                float: "right",
                                                                marginRight:
                                                                  "-5px",
                                                              }}
                                                              icon={faTrashCan}
                                                            />
                                                          </div>
                                                          <div className="col-8">
                                                            <h6
                                                              style={{
                                                                float: "left",
                                                                marginLeft:
                                                                  "-3px",
                                                              }}
                                                            >
                                                              Delete
                                                            </h6>
                                                          </div>
                                                        </div>
                                                      </Button>
                                                    </div>
                                                  )}

                                                  {openUpdate &&
                                                    request.itemId ===
                                                      update.itemId && (
                                                      <div>
                                                        <Button
                                                          sx={{
                                                            width: "90px",
                                                            borderColor:
                                                              "success.main",
                                                            fontSize: {
                                                              xs: "0.7rem",
                                                              sm: "0.85rem",
                                                              md: "1rem",
                                                            },
                                                            padding: {
                                                              xs: "3px",
                                                              sm: "4px",
                                                              md: "5px",
                                                              lg: "6px",
                                                            },
                                                            "&:hover": {
                                                              backgroundColor:
                                                                "success.main",
                                                              color: "white",
                                                            },
                                                          }}
                                                          variant="outlined"
                                                          color="success"
                                                          className="mr-3"
                                                          onClick={(e) => {
                                                            if (
                                                              parseFloat(
                                                                update.price
                                                              ) < 50 ||
                                                              parseFloat(
                                                                update.price
                                                              ) > 1000000
                                                            ) {
                                                              return;
                                                            }
                                                            price.grandTotal = 0;
                                                            price.total = 0;
                                                            price.vat = 0;
                                                            itemValue[
                                                              index
                                                            ].price =
                                                              update.price;

                                                            itemValue[
                                                              index
                                                            ].quantity =
                                                              update.quantity;

                                                            itemValue[
                                                              index
                                                            ].totalPrice =
                                                              itemValue[index]
                                                                .price *
                                                              itemValue[index]
                                                                .quantity;
                                                            setItemValue([
                                                              ...itemValue,
                                                            ]);
                                                            update.itemId = "";
                                                            update.price = "";
                                                            update.quantity =
                                                              "";
                                                            itemValue.map(
                                                              (i) => {
                                                                const tax =
                                                                  taxs.find(
                                                                    (t) =>
                                                                      t.Id ===
                                                                      i.taxId
                                                                  );
                                                                price.total =
                                                                  parseFloat(
                                                                    price.total
                                                                  ) +
                                                                  parseFloat(
                                                                    i.price
                                                                  ) *
                                                                    i.quantity;
                                                                price.vat =
                                                                  parseFloat(
                                                                    price.vat
                                                                  ) +
                                                                  (parseFloat(
                                                                    i.totalPrice
                                                                  ) *
                                                                    tax.taxValue) /
                                                                    100;
                                                                return 0;
                                                              }
                                                            );
                                                            price.grandTotal =
                                                              price.total +
                                                              price.vat;
                                                          }}
                                                        >
                                                          <div
                                                            className="row "
                                                            style={{
                                                              marginBottom:
                                                                "-10px",
                                                              marginLeft:
                                                                "-20px",
                                                            }}
                                                          >
                                                            <div className="col">
                                                              <FontAwesomeIcon
                                                                style={{
                                                                  float:
                                                                    "right",
                                                                }}
                                                                className="fa-regular"
                                                                icon={
                                                                  faFloppyDisk
                                                                }
                                                              />
                                                            </div>
                                                            <div className="col-6">
                                                              <h6
                                                                style={{
                                                                  float: "left",
                                                                  marginLeft:
                                                                    "-10px",
                                                                }}
                                                              >
                                                                Save
                                                              </h6>
                                                            </div>
                                                          </div>
                                                        </Button>
                                                        <Button
                                                          sx={{
                                                            width: "100px",
                                                            borderColor:
                                                              "error.main",
                                                            fontSize: {
                                                              xs: "0.7rem",
                                                              sm: "0.85rem",
                                                              md: "1rem",
                                                            },
                                                            padding: {
                                                              xs: "3px",
                                                              sm: "4px",
                                                              md: "5px",
                                                              lg: "6px",
                                                            },
                                                            "&:hover": {
                                                              backgroundColor:
                                                                "error.main",
                                                              color: "white",
                                                            },
                                                          }}
                                                          variant="outlined"
                                                          color="error"
                                                          onClick={(e) => {
                                                            setOpenUpdate(
                                                              false
                                                            );
                                                            setUpdate({
                                                              itemId: "",
                                                              price: "",
                                                              quantity: "",
                                                            });
                                                          }}
                                                        >
                                                          <div
                                                            className="row"
                                                            style={{
                                                              marginBottom:
                                                                "-10px",
                                                              marginLeft:
                                                                "-20px",
                                                            }}
                                                          >
                                                            <div className="col">
                                                              <FontAwesomeIcon
                                                                style={{
                                                                  float:
                                                                    "right",
                                                                  marginRight:
                                                                    "-5px",
                                                                }}
                                                                icon={faXmark}
                                                              />
                                                            </div>
                                                            <div className="col-8">
                                                              <h6
                                                                style={{
                                                                  float: "left",
                                                                  marginLeft:
                                                                    "-3px",
                                                                }}
                                                              >
                                                                Cancel
                                                              </h6>
                                                            </div>
                                                          </div>
                                                        </Button>
                                                      </div>
                                                    )}
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                        <div className="row">
                                          <div className="col"></div>
                                          <div
                                            className="col mt-1"
                                            style={{
                                              textAlign: "right",
                                              marginBottom: "20px",
                                              float: "right",
                                            }}
                                          >
                                            <Form.Group
                                              as={Row}
                                              controlId="formHorizontalUsername"
                                              style={{ width: "500px" }}
                                            >
                                              <Col>
                                                <Form.Label>
                                                  Total :{" "}
                                                </Form.Label>
                                              </Col>
                                              <Col>
                                                <Form.Control
                                                  id="outlined-basic"
                                                  disabled
                                                  label="Qty"
                                                  variant="outlined"
                                                  type="text"
                                                  size="small"
                                                  name="qty"
                                                  value={price.total.toLocaleString()}
                                                  style={{ width: "150px" }}
                                                />
                                              </Col>
                                            </Form.Group>
                                            <Form.Group
                                              as={Row}
                                              controlId="formHorizontalUsername"
                                              style={{ width: "500px" }}
                                            >
                                              <Col>
                                                <Form.Label>VAT : </Form.Label>
                                              </Col>
                                              <Col>
                                                <Form.Control
                                                  id="outlined-basic"
                                                  disabled
                                                  label="Qty"
                                                  variant="outlined"
                                                  type="text"
                                                  size="small"
                                                  name="qty"
                                                  value={price.vat.toLocaleString()}
                                                  style={{ width: "150px" }}
                                                />
                                              </Col>
                                            </Form.Group>
                                            <Form.Group
                                              as={Row}
                                              controlId="formHorizontalUsername"
                                              style={{ width: "500px" }}
                                            >
                                              <Col>
                                                <Form.Label>
                                                  Grand Total :{" "}
                                                </Form.Label>
                                              </Col>
                                              <Col>
                                                <Form.Control
                                                  id="outlined-basic"
                                                  disabled
                                                  label="Qty"
                                                  variant="outlined"
                                                  type="text"
                                                  size="small"
                                                  name="qty"
                                                  value={price.grandTotal.toLocaleString()}
                                                  style={{ width: "150px" }}
                                                />
                                              </Col>
                                            </Form.Group>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Formik>
                            </div>
                            <center className="mt-3">
                              <Row className="mb-3">
                                <Col>
                                  <Button
                                    sx={{
                                      width: "150px",
                                      borderColor: "primary.main",
                                      fontSize: {
                                        xs: "0.7rem",
                                        sm: "0.85rem",
                                        md: "1rem",
                                      },
                                      padding: {
                                        xs: "3px",
                                        sm: "4px",
                                        md: "5px",
                                        lg: "6px",
                                      },
                                      "&:hover": {
                                        backgroundColor: "primary.main",
                                        color: "white",
                                      },
                                    }}
                                    variant="outlined"
                                    color="primary"
                                    onClick={clearData}
                                  >
                                    <div
                                      className="row"
                                      style={{
                                        marginBottom: "-10px",
                                        marginLeft: "-15px",
                                      }}
                                    >
                                      <div className="col-2">
                                        <FontAwesomeIcon
                                          style={{
                                            float: "left",
                                            marginLeft: "0px",
                                            marginTop: "2px",
                                            marginBottom: "-4px",
                                          }}
                                          icon={faBackwardStep}
                                        />
                                      </div>
                                      <div className="col-10">
                                        <h6
                                          style={{
                                            float: "left",
                                            marginLeft: "-5px",
                                            marginTop: "-1px",
                                            marginBottom: "-7px",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: {
                                                xs: "0.85rem",
                                                sm: "0.85rem",
                                                md: "1rem",
                                              },
                                            }}
                                          >
                                            Back to list
                                          </Typography>
                                        </h6>
                                      </div>
                                    </div>
                                  </Button>
                                </Col>
                                <Col>
                                  <Form onSubmit={formik.handleSubmit}>
                                    <Button
                                      sx={{
                                        width: "150px",
                                        borderColor: "primary.main",
                                        fontSize: {
                                          xs: "0.7rem",
                                          sm: "0.85rem",
                                          md: "1rem",
                                        },
                                        padding: {
                                          xs: "3px",
                                          sm: "4px",
                                          md: "5px",
                                          lg: "6px",
                                        },
                                        "&:hover": {
                                          backgroundColor: "primary.main",
                                          color: "white",
                                        },
                                      }}
                                      variant="outlined"
                                      color="primary"
                                      type="submit"
                                    >
                                      <div
                                        className="row"
                                        style={{
                                          marginBottom: "-10px",
                                          marginLeft: "-15px",
                                        }}
                                      >
                                        <div className="col-3">
                                          <FontAwesomeIcon
                                            style={{
                                              float: "left",
                                              marginLeft: "0px",
                                              marginTop: "2px",
                                              marginBottom: "-4px",
                                            }}
                                            icon={faFloppyDisk}
                                          />
                                        </div>
                                        <div className="col-9">
                                          <h6
                                            style={{
                                              float: "left",
                                              marginLeft: "-5px",
                                              marginTop: "-1px",
                                              marginBottom: "-7px",
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: {
                                                  xs: "0.85rem",
                                                  sm: "0.85rem",
                                                  md: "1rem",
                                                },
                                              }}
                                            >
                                              Submit
                                            </Typography>
                                          </h6>
                                        </div>
                                      </div>
                                    </Button>
                                  </Form>
                                </Col>
                              </Row>
                            </center>
                          </div>
                        )}
                        {!open && (
                          <div style={{ overflow: "scroll" }}>
                            <Box
                              sx={{
                                height: 500,
                                width: {
                                  lg: "100%",
                                  md: "1000px",
                                  sm: "1000px",
                                  xs: "1000px",
                                },
                                "& .super-app-theme--header": {
                                  backgroundColor: "#1de5ec",
                                  color: "black",
                                  fontSize: {
                                    xs: "13px",
                                    sm: "15px", //500
                                    md: "17px", //768
                                    lg: "18px", //1024
                                  },
                                },
                              }}
                            >
                              <DataGrid
                                rows={rowPurchase}
                                disableColumnFilter
                                disableColumnSelector
                                disableDensitySelector
                                columns={columnPurchase}
                                sx={{
                                  "& .MuiDataGrid-cell": {
                                    fontSize: {
                                      xs: "12px",
                                      sm: "14px", //500
                                      md: "16px", //768
                                      lg: "17px", //1024
                                    },
                                  },
                                }}
                                slots={{ toolbar: GridToolbar }}
                                slotProps={{
                                  toolbar: {
                                    showQuickFilter: true,
                                  },
                                }}
                                initialState={{
                                  ...purchases.initialState,
                                  pagination: {
                                    paginationModel: { pageSize: 5 },
                                  },
                                }}
                                pageSizeOptions={[5, 10, 15]}
                                getRowId={(row) => row.Id}
                              />
                            </Box>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {openPurchaseItem && (
                    <div style={{ overflow: "scroll" }}>
                      <div className="card-body" style={{ minWidth: "1000px" }}>
                        <Container ref={contentRef}>
                          <div className="row">
                            <div className="col-sm-6 ">
                              <br />
                              <h1
                                style={{
                                  marginLeft: "5vw",
                                  marginBottom: "2vh",
                                }}
                              >
                                Invoice
                              </h1>
                              <i
                                style={{
                                  marginLeft: "7vw",
                                  marginBottom: "2vh",
                                }}
                                className="fas fa-home fa-3x"
                              />
                            </div>

                            <div className="col-sm-6 ">
                              <br />
                              <div
                                style={{
                                  fontSize: 18,
                                  textAlign: "right",
                                }}
                              >
                                <p style={{ margin: "0", fontSize: 24 }}>
                                  <b> Company Name</b>
                                </p>
                                <p style={{ margin: "0" }}>+251987654321</p>
                                <p style={{ margin: "0" }}>email@gmail.com</p>
                                <p style={{ margin: "0" }}>address1</p>
                                <p style={{ margin: "0" }}>address2</p>
                              </div>
                            </div>
                          </div>
                          <h4>Purchase</h4>
                          <hr />
                          <Row className="">
                            <Col sm={2}>
                              <div>
                                <p>
                                  <b>BILL To</b>
                                </p>
                              </div>
                            </Col>

                            <Col sm={5}>
                              <div>
                                <p>
                                  <b>Supplier Name : </b>
                                  {
                                    suppliers.find(
                                      (s) => s.Id === formik.values.supplierId
                                    )?.fullName
                                  }
                                </p>
                              </div>
                              <div>
                                <p>
                                  <b>Phone No : </b>
                                  {
                                    suppliers.find(
                                      (s) => s.Id === formik.values.supplierId
                                    )?.phoneNumber
                                  }
                                </p>
                              </div>
                              <div>
                                <p>
                                  <b>Tin Number : </b>
                                  {
                                    suppliers.find(
                                      (s) => s.Id === formik.values.supplierId
                                    )?.tinNumber
                                  }
                                </p>
                              </div>
                            </Col>
                            <Col sm={5}>
                              {formik.values.Id !== "" && (
                                <div>
                                  <p>
                                    <b>Purchase Ref : </b>
                                    {formik.values.Id}
                                  </p>
                                </div>
                              )}

                              <div>
                                <p>
                                  <b>Date : </b>
                                  {`${new Date(formik.values.date).getDate()}/${
                                    new Date(formik.values.date).getMonth() + 1
                                  }/${new Date(
                                    formik.values.date
                                  ).getFullYear()}`}
                                </p>
                              </div>
                            </Col>
                          </Row>
                          <br />
                          <br />
                          <hr />
                          <br />
                          <br />
                          <br />
                          <Row>
                            <Table
                              className="text-center"
                              style={{ width: "100%" }}
                            >
                              <tr>
                                <th>No</th>
                                <th>Code</th>
                                <th>Desc</th>
                                <th>Model</th>
                                <th>Unit</th>
                                <th>U.Price</th>
                                <th>Qty</th>
                                <th>T.Price</th>
                              </tr>

                              <tbody>
                                {itemValue.map((request, index) => {
                                  const item = items.find(
                                    (i) => i.Id === request.itemId
                                  );
                                  return (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{item.code}</td>
                                      <td>{item.description}</td>
                                      <td>
                                        {models.map((model) =>
                                          model.Id === parseInt(item.modelId)
                                            ? model.modelName
                                            : ""
                                        )}
                                      </td>
                                      <td>
                                        {units.map((unit) =>
                                          unit.Id === parseInt(request.unitId)
                                            ? unit.unitName
                                            : ""
                                        )}
                                      </td>
                                      <td>
                                        {parseFloat(
                                          request.price
                                        ).toLocaleString()}
                                      </td>
                                      <td>{request.quantity}</td>

                                      <td>
                                        {parseFloat(
                                          request.totalPrice
                                        ).toLocaleString()}
                                      </td>
                                    </tr>
                                  );
                                })}
                                {itemValue.length < 5 ? handelInvoice() : ""}
                              </tbody>
                            </Table>
                          </Row>
                          <Row>
                            <Col sm={8}>
                              <h3>Terms</h3>{" "}
                              {qrCodeUrl ? (
                                <div>
                                  <img
                                    src={qrCodeUrl}
                                    alt="QR Code"
                                    style={{
                                      marginTop: "20px",
                                      width: "256px",
                                      height: "256px",
                                    }}
                                  />
                                </div>
                              ) : (
                                <p>Generating QR code...</p>
                              )}
                            </Col>
                            <Col sm={4}>
                              <Table>
                                <tbody>
                                  <tr>
                                    <td>
                                      <b
                                        style={{
                                          float: "right",
                                          fontSize: 18,
                                          width: "100px",
                                          height: "10px",
                                        }}
                                      >
                                        Total Price
                                      </b>
                                    </td>
                                    <td>
                                      <p
                                        style={{
                                          float: "left",
                                          fontSize: 18,
                                          width: "110px",
                                          height: "10px",
                                        }}
                                      >
                                        {price.total.toLocaleString()}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b
                                        style={{
                                          float: "right",
                                          fontSize: 18,
                                          width: "100px",
                                          height: "10px",
                                        }}
                                      >
                                        VAT
                                      </b>
                                    </td>
                                    <td>
                                      <p
                                        style={{
                                          float: "left",
                                          fontSize: 18,
                                          width: "110px",
                                          height: "10px",
                                        }}
                                      >
                                        {price.vat.toLocaleString()}
                                      </p>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      <b
                                        style={{
                                          float: "right",
                                          fontSize: 18,
                                          width: "120px",
                                          height: "10px",
                                        }}
                                      >
                                        Grand Total
                                      </b>
                                    </td>
                                    <td>
                                      <p
                                        style={{
                                          float: "left",
                                          fontSize: 18,
                                          width: "110px",
                                          height: "10px",
                                        }}
                                      >
                                        {price.grandTotal.toLocaleString()}
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                      <div className="card-footer">
                        <Button
                          onClick={() => {
                            reactToPrint();
                            handleBack();
                          }}
                          sx={{
                            width: "100px",
                            borderColor: "primary.main",
                            fontSize: {
                              xs: "0.7rem",
                              sm: "0.85rem",
                              md: "1rem",
                            },
                            padding: {
                              xs: "3px",
                              sm: "4px",
                              md: "5px",
                              lg: "6px",
                            },
                            "&:hover": {
                              backgroundColor: "primary.main",
                              color: "white",
                            },
                          }}
                          variant="outlined"
                          color="primary"
                          className="ml-3 mt-1"
                          style={{ float: "left" }}
                        >
                          <div
                            className="row"
                            style={{
                              marginBottom: "-10px",
                              marginLeft: "-10px",
                            }}
                          >
                            <div className="col">
                              <FontAwesomeIcon
                                style={{
                                  float: "right",
                                  marginRight: "-1px",
                                  marginTop: "2px",
                                  marginBottom: "-4px",
                                }}
                                icon={faPrint}
                              />
                            </div>
                            <div className="col-7">
                              <h6
                                style={{
                                  float: "left",
                                  marginLeft: "-10px",
                                  marginTop: "-1px",
                                  marginBottom: "-7px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: {
                                      xs: "0.85rem",
                                      sm: "0.85rem",
                                      md: "1rem",
                                    },
                                  }}
                                >
                                  Print
                                </Typography>
                              </h6>
                            </div>
                          </div>
                        </Button>
                        <Button
                          onClick={(e) => {
                            handleBack();
                          }}
                          sx={{
                            width: "90px",
                            borderColor: "success.main",
                            fontSize: {
                              xs: "0.7rem",
                              sm: "0.85rem",
                              md: "1rem",
                            },
                            padding: {
                              xs: "3px",
                              sm: "4px",
                              md: "5px",
                              lg: "6px",
                            },
                            "&:hover": {
                              backgroundColor: "success.main",
                              color: "white",
                            },
                          }}
                          variant="outlined"
                          color="success"
                          className="ml-3 mt-1"
                          style={{ float: "left" }}
                        >
                          <div
                            className="row"
                            style={{
                              marginBottom: "-10px",
                              marginLeft: "-10px",
                            }}
                          >
                            <div className="col">
                              <FontAwesomeIcon
                                style={{
                                  float: "right",
                                  marginRight: "-1px",
                                  marginTop: "2px",
                                  marginBottom: "-4px",
                                }}
                                icon={faBackwardStep}
                              />
                            </div>
                            <div className="col-7">
                              <h6
                                style={{
                                  float: "left",
                                  marginLeft: "-10px",
                                  marginTop: "-1px",
                                  marginBottom: "-7px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: {
                                      xs: "0.85rem",
                                      sm: "0.85rem",
                                      md: "1rem",
                                    },
                                  }}
                                >
                                  Back
                                </Typography>
                              </h6>
                            </div>
                          </div>
                        </Button>
                      </div>
                    </div>
                  )}
                </div>

                <Formik
                  initialValues={{
                    fullName: "",
                    tinNumber: "",
                    address: "",
                    phoneNumber: "",
                  }}
                  validationSchema={Yup.object().shape({
                    fullName: Yup.string().required("Full name is required."),
                    phoneNumber: Yup.string()
                      .required("Phone Number is required")
                      .min(9, "Too Short!")
                      .max(10, "Too Long"),
                    address: Yup.string().required("Address is required"),
                    tinNumber: Yup.string().matches(
                      /^\d{10}$/,
                      "Tin number must be 10 digits."
                    ),
                  })}
                  onSubmit={async (values) => {
                    try {
                      setSupplierErrorTin(false);
                      setSupplierErrorPhone(false);
                      const { phoneNumber, tinNumber } = values;
                      const userExists = suppliers.find(
                        (supplier) => supplier?.phoneNumber === phoneNumber
                      );
                      const tinExists = suppliers.find(
                        (supplier) => supplier?.tinNumber === tinNumber
                      );
                      if (userExists) {
                        setSupplierErrorPhone(true);

                        return;
                      }
                      if (tinExists) {
                        setSupplierErrorTin(true);

                        return;
                      }
                      if (isSubmitted) return;
                      setIsSubmitted(true);
                      await axios.post(
                        "https://enin.server.highendtradingplc.com/supplier/add",
                        values
                      );

                      values.tinNumber = "";
                      values.phoneNumber = "";
                      values.fullName = "";
                      values.address = "";
                      fetchAllData();
                      setOpenSupplier(false);
                    } catch (error) {
                      console.log(error);
                    }
                    setIsSubmitted(false);
                  }}
                >
                  {(props) => (
                    <Modal
                      show={openSupplier}
                      onHide={handleOpenSupplier}
                      style={{ minWidth: "700px" }}
                    >
                      <Modal.Header closeButton>
                        <span>Purchase / Supplier / Create</span>
                      </Modal.Header>
                      <Modal.Body>
                        <Form
                          className="m-2 ml-5 pl-5"
                          onSubmit={props.handleSubmit}
                        >
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={8}>
                              <TextField
                                id="outlined-basic"
                                label="Full Name"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="fullName"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.fullName}
                                isInvalid={
                                  props.touched.fullName &&
                                  props.errors.fullName
                                }
                                sx={{
                                  width: "250px",
                                  "& .MuiInputBase-root": {
                                    fontSize: {
                                      xs: "0.7rem",
                                      sm: "0.85rem",
                                      md: "0.9rem",
                                    },
                                    padding: {
                                      xs: "1px",
                                      sm: "2px",
                                      md: "3px",
                                      lg: "4px",
                                    },
                                  },
                                }}
                              />

                              {props.touched.fullName &&
                                props.errors.fullName && (
                                  <div className="text-danger mt-1">
                                    {props.errors.fullName}
                                  </div>
                                )}
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={8}>
                              <TextField
                                id="outlined-basic"
                                label="Till Number"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="tinNumber"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.tinNumber}
                                isInvalid={
                                  (props.touched.tinNumber &&
                                    props.errors.tinNumber) ||
                                  supplierErrorTin
                                }
                                sx={{
                                  width: "250px",
                                  "& .MuiInputBase-root": {
                                    fontSize: {
                                      xs: "0.7rem",
                                      sm: "0.85rem",
                                      md: "0.9rem",
                                    },
                                    padding: {
                                      xs: "1px",
                                      sm: "2px",
                                      md: "3px",
                                      lg: "4px",
                                    },
                                  },
                                }}
                              />
                              {supplierErrorTin && (
                                <div className="text-danger mt-1">
                                  Tin number already exists.
                                </div>
                              )}
                              {props.touched.tinNumber &&
                                props.errors.tinNumber && (
                                  <div className="text-danger mt-1">
                                    {props.errors.tinNumber}
                                  </div>
                                )}
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={8}>
                              <TextField
                                id="outlined-basic"
                                label="Phone Number"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="phoneNumber"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.phoneNumber}
                                isInvalid={
                                  (props.touched.phoneNumber &&
                                    props.errors.phoneNumber) ||
                                  supplierErrorPhone
                                }
                                sx={{
                                  width: "250px",
                                  "& .MuiInputBase-root": {
                                    fontSize: {
                                      xs: "0.7rem",
                                      sm: "0.85rem",
                                      md: "0.9rem",
                                    },
                                    padding: {
                                      xs: "1px",
                                      sm: "2px",
                                      md: "3px",
                                      lg: "4px",
                                    },
                                  },
                                }}
                              />
                              {supplierErrorPhone && (
                                <div className="text-danger mt-1">
                                  phone number already exists.
                                </div>
                              )}
                              {props.touched.phoneNumber &&
                                props.errors.phoneNumber && (
                                  <div className="text-danger mt-1">
                                    {props.errors.phoneNumber}
                                  </div>
                                )}
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={8}>
                              <TextField
                                id="outlined-basic"
                                label="Address"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="address"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.address}
                                isInvalid={
                                  props.touched.address && props.errors.address
                                }
                                sx={{
                                  width: "250px",
                                  "& .MuiInputBase-root": {
                                    fontSize: {
                                      xs: "0.7rem",
                                      sm: "0.85rem",
                                      md: "0.9rem",
                                    },
                                    padding: {
                                      xs: "1px",
                                      sm: "2px",
                                      md: "3px",
                                      lg: "4px",
                                    },
                                  },
                                }}
                              />

                              {props.touched.address &&
                                props.errors.address && (
                                  <div className="text-danger mt-1">
                                    {props.errors.address}
                                  </div>
                                )}
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={12}>
                              <Button
                                sx={{
                                  width: "250px",
                                  borderColor: "success.main",
                                  fontSize: {
                                    xs: "0.7rem",
                                    sm: "0.85rem",
                                    md: "1rem",
                                  },
                                  padding: {
                                    xs: "3px",
                                    sm: "4px",
                                    md: "5px",
                                    lg: "6px",
                                  },
                                  "&:hover": {
                                    backgroundColor: "success.main",
                                    color: "white",
                                  },
                                }}
                                variant="outlined"
                                color="success"
                                type="submit"
                              >
                                Create
                              </Button>
                            </Col>
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                    </Modal>
                  )}
                </Formik>
              </div>
            </center>
          </main>
        </div>
      </Typography>
    </ThemeProvider>
  );
}

export default PurchasePage;
