/** @format */
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Modal, Form, Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as XLSX from "xlsx";
import {
  TextField,
  Autocomplete,
  createTheme,
  useMediaQuery,
  ThemeProvider,
  Typography,
  Button,
  Box,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Alert,
  AlertTitle,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as Yup from "yup";
import axios from "axios";
import Header from "../Header";
import {
  faBackwardStep,
  faFileExport,
  faPenToSquare,
  faTrashCan,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { jwtDecode } from "jwt-decode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Close } from "@mui/icons-material";
const theme = createTheme({
  breakpoints: { values: { xs: 0, sm: 500, md: 769, lg: 1024, xl: 1440 } },
  palette: {
    warning: {
      main: "#ffc107", // Main warning color
      light: "#ffb74d", // Light variation of warning color
      dark: "#f57c00", // Dark variation of warning color
      contrastText: "#000", // Text color on warning
    },
  },
});
const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Full name should be character.")
    .required("Full name is required.")
    .min(3, "Too Short!")
    .max(30, "Too Long"),
  phoneNumber: Yup.string()
    .required("Phone Number is required")
    .matches(/^\d{10}$/, "Phone number must be 10 digits."),
  address: Yup.string().required("Address is required"),
  tinNumber: Yup.string().matches(/^\d{10}$/, "Tin number must be 10 digits."),
});
const SupplierPage = () => {
  const [progressOpen, setProgressOpen] = useState(false);
  const [show, setShow] = useState([]);
  const [error, setError] = useState(false);
  const [start, setStart] = useState(false);
  const [check, setCheck] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [valids, setValid] = useState([0]);
  const [warnings, setWarings] = useState([0, 0]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [userDatas, setUserDatas] = useState({
    password: "",
    id: "",
  });
  const user = jwtDecode(sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const descriptionElementRef = useRef(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isUnderMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const fetchAllData = async () => {
    try {
      const [supplier] = await Promise.all([
        axios.get("https://enin.server.highendtradingplc.com/supplier"),
      ]);
      setSuppliers(supplier.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  const formik = useFormik({
    initialValues: {
      fullName: "",
      tinNumber: "",
      address: "",
      phoneNumber: "",
      id: "",
    },

    validationSchema,
    onSubmit: async (values) => {
      if (isSubmitted) return;
      setIsSubmitted(true);
      try {
        if (values.id === "") {
          const { phoneNumber, tinNumber } = values;
          const userExists = suppliers.find(
            (supplier) => supplier?.phoneNumber === phoneNumber
          );
          const tinExists = suppliers.find(
            (supplier) => supplier?.tinNumber === tinNumber
          );
          if (userExists) {
            formik.setErrors({
              phoneNumber: "phone number already exists.",
            });
            setIsSubmitted(false);
            return;
          }
          if (tinExists) {
            formik.setErrors({
              tinNumber: "tin number already exists.",
            });
            setIsSubmitted(false);
            return;
          }
          if (
            !(values.tinNumber.length === 10 || values.tinNumber.length === 0)
          ) {
            formik.setErrors({
              tinNumber: "Till number must be empty or exactly 10 digits.",
            });
            setIsSubmitted(false);
            return;
          }
          await axios.post(
            "https://enin.server.highendtradingplc.com/supplier/add",
            values
          );
          setOpen(false);
        } else {
          const { phoneNumber, tinNumber } = values;
          const userExists = suppliers.find(
            (supplier) =>
              supplier.Id !== values.id && supplier?.phoneNumber === phoneNumber
          );
          const tinExists = suppliers.find(
            (supplier) =>
              supplier.Id !== values.id && supplier?.tinNumber === tinNumber
          );
          if (userExists) {
            formik.setErrors({
              phoneNumber: "phone number already exists.",
            });
            setIsSubmitted(false);
            return;
          }
          if (tinExists) {
            formik.setErrors({
              tinNumber: "tin number already exists.",
            });
            setIsSubmitted(false);
            return;
          }
          if (
            !(values.tinNumber.length === 10 || values.tinNumber.length === 0)
          ) {
            formik.setErrors({
              tinNumber: "Till number must be empty or exactly 10 digits.",
            });
            setIsSubmitted(false);
            return;
          }
          if (warnings[0] === 0) {
            warnings[0] = 2;
            warnings[1] = values.id;
            setWarings([...warnings]);
            setIsSubmitted(false);
            return;
          }
          await axios.put(
            "https://enin.server.highendtradingplc.com/supplier/update/" +
              values.id,
            values
          );
          setWarings([0, 0]);
          setOpen(false);
        }
        fetchAllData();
        setValid([0]);
      } catch (error) {
        console.log(error);
      }
      setIsSubmitted(false);
    },
  });
  const handleAdd = () => {
    formik.values.address = "";
    formik.values.fullName = "";
    formik.values.phoneNumber = "";
    formik.values.tinNumber = "";
    formik.values.id = "";
    setOpen(true);
    formik.setErrors([]);
  };
  const handleUpdate = (id) => {
    const supplier = suppliers.find((sup) => sup.Id === parseInt(id));
    formik.values.address = supplier.address;
    formik.values.fullName = supplier.fullName;
    formik.values.phoneNumber = supplier.phoneNumber;
    formik.values.tinNumber = supplier.tinNumber;
    formik.values.id = parseInt(id);
    setOpen(true);
    formik.setErrors([]);
  };
  const handleDelete = async (id) => {
    try {
      await axios.delete(
        "https://enin.server.highendtradingplc.com/supplier/delete/" + id
      );
      fetchAllData();
    } catch (error) {}
  };

  const handleOpen = () => {
    setOpen(false);
  };

  const ends = async () => {
    setProgressOpen(true);
    try {
      let data = [];
      suppliers.map((cust) => {
        const newData = {
          fullName: cust.fullName,
          tinNumber: cust?.tinNumber || "",
          phoneNumber: cust.phoneNumber,
          address: cust.address,
        };
        data.push(newData);
        return null;
      });

      // Create a new workbook and a new worksheet
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      // Write to file and trigger download
      XLSX.writeFile(wb, "supplier_data.xlsx");

      setProgressOpen(false);
      setCheck(false);
      userDatas.password = "";
      setUserDatas(userDatas);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const workbook = XLSX.read(event.target.result, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet);
      setData(sheetData);
    };
    reader.readAsBinaryString(file);
  };

  const handleCSVSubmit = async (e) => {
    setProgressOpen(true);
    e.preventDefault();
    setShow([]);
    let checkError = true;
    try {
      let phone1 = [],
        storeEror = [],
        tinNumber = [];
      const checkSupplierByPhoneOne = new Set(
        suppliers.map((cust) => cust.phoneNumber)
      );
      const checkSupplierByTinNumber = new Set(
        suppliers.map((cust) => cust.tinNumber)
      );
      for (let i = 0; i < data.length; i = i + 1) {
        checkSupplierByPhoneOne.add(data[i].phoneNumber);
        if (
          data[i].fullName.length < 4 ||
          /^[A-Za-z]+$/.test(data[i].fullName)
        ) {
          storeEror.push({
            name: "Full Name must be greater than 3 alpha-character",
            line: data[i].fullName,
          });
          checkError = false;
        }
        if (data[i].address.length < 4 || /^[A-Za-z]+$/.test(data[i].address)) {
          storeEror.push({
            name: "Address must be greater than 3 alpha-character",
            line: data[i].address,
          });
          checkError = false;
        }
        if (phone1.includes(data[i].phoneNumber)) {
          storeEror.push({
            name: "Duplicate Phone Number",
            line: data[i].phoneNumber,
          });
          checkError = false;
        }

        if (tinNumber.includes(data[i].tinNumber)) {
          storeEror.push({
            name: "Duplicate Tin Number",
            line: data[i].tinNumber,
          });
          checkError = false;
        }
        if (
          (data[i].phoneNumber + "").length !== 10 ||
          isNaN(Number(data[i].phoneNumber))
        ) {
          storeEror.push({
            name: "Phone Number must be 10 digits",
            line: data[i].phoneNumber,
          });
          checkError = false;
        }

        if (
          data[i].tinNumber === ""
            ? false
            : (data[i].tinNumber + "").length !== 10 ||
              isNaN(Number(data[i].tinNumber))
        ) {
          storeEror.push({
            name: "Tin Number must be 0 or 10 digits",
            line: data[i].tinNumber,
          });
          checkError = false;
        }
        if (checkSupplierByPhoneOne.size !== suppliers.length + i + 1) {
          storeEror.push({
            name: "Duplicate previous phone numbers found",
            line: data[i].phoneNumber,
          });
          checkError = false;
        }

        if (
          checkSupplierByTinNumber.has(data[i].tinNumber) &&
          data[i].tinNumber !== ""
        ) {
          storeEror.push({
            name: "Duplicate previous tin numbers found",
            line: data[i].tinNumber,
          });
          checkError = false;
        } else {
          checkSupplierByTinNumber.add(data[i].tinNumber);
        }
        phone1.push(data[i].phoneNumber);
        tinNumber.push(data[i].tinNumber);
      }

      setShow(storeEror);
      if (checkError) {
        setProgressOpen(true);
        for (let i = 0; i < data.length; ) {
          await axios
            .post(`https://enin.server.highendtradingplc.com/supplier/add`, {
              fullName: data[i].fullName,
              phoneNumber: data[i].phoneNumber,
              tinNumber: data[i].tinNumber,
              address: data[i].address,
            })
            .then((res) => {
              i = i + 1;
            });
        }
        setStart(false);
      } else {
        setError(true);
      }

      fetchAllData().then(() => {
        setProgressOpen(false);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handelclick = async () => {
    userDatas.id = user.Id;
    await axios
      .post("https://enin.server.highendtradingplc.com/check", userDatas)
      .then((res) => {
        if (res.data.Login) {
          ends();
        } else {
          setPasswordError(true);
        }
      })
      .catch((err) => console.log(err));
  };
  const handelchange = (e) => {
    setUserDatas((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const columns = [
    {
      field: "id",
      headerName: "No",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Id",
      headerAlign: "center",
      align: "center",
      headerName: "Supplier Id",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
    },
    {
      field: "fullName",
      headerAlign: "center",
      align: "center",
      headerName: "Supplier Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "tinNumber",
      headerAlign: "center",
      align: "center",
      headerName: "Tin Number",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "phoneNumber",
      headerAlign: "center",
      align: "center",
      headerName: "Phone Number",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "address",
      headerAlign: "center",
      align: "center",
      headerName: "Address",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "action",
      headerAlign: "center",
      align: "center",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      width: 230,

      renderCell: (params) => (
        <>
          <Button
            onClick={() => handleUpdate(params.row.Id)}
            sx={{
              width: "90px",
              borderColor: "warning.main",
              fontSize: {
                xs: "0.7rem",
                sm: "0.85rem",
                md: "1rem",
              },
              padding: {
                xs: "3px",
                sm: "4px",
                md: "5px",
                lg: "6px",
              },
              "&:hover": {
                backgroundColor: "warning.main",
                color: "white",
              },
            }}
            variant="outlined"
            color="warning"
            className="m-1"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-7px" }}
                  icon={faPenToSquare}
                />
              </div>
              <div className="col-7">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-3px",
                  }}
                >
                  Edit
                </h6>
              </div>
            </div>
          </Button>
          <Button
            onClick={(e) => {
              warnings[1] = params.row.Id;
              warnings[0] = 1;
              setWarings([...warnings]);
            }}
            sx={{
              width: "110px",
              borderColor: "error.main",
              fontSize: {
                xs: "0.7rem",
                sm: "0.85rem",
                md: "1rem",
              },
              padding: {
                xs: "3px",
                sm: "4px",
                md: "5px",
                lg: "6px",
              },
              "&:hover": {
                backgroundColor: "error.main",
                color: "white",
              },
            }}
            variant="outlined"
            color="error"
            className=""
          >
            <div
              className="row "
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-5px" }}
                  icon={faTrashCan}
                />
              </div>
              <div className="col-8">
                <h6 style={{ float: "left", marginLeft: "-3px" }}>Delete</h6>
              </div>
            </div>
          </Button>
        </>
      ),
    },
  ];
  const rows = suppliers.map((sup, index) => {
    let supp = sup;
    supp.id = index + 1;
    return supp;
  });
  return (
    <ThemeProvider theme={theme}>
      <Typography
        sx={{
          fontSize: {
            xs: "12px",
            sm: "12px", //500
            md: "0.85rem", //768
            lg: "17px", //1024
          },
        }}
      >
        <div>
          <Header />
        </div>
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: "13px",
            sm: "14px", //500
            md: "16px", //768
            lg: "18px", //1024
          },
        }}
      >
        <div>
          <main
            className=""
            style={
              isMobile && !isUnderMobile
                ? { marginTop: "170px" }
                : isUnderMobile
                ? { marginTop: "170px" }
                : isTablet
                ? { marginTop: "115px" }
                : { marginTop: "130px" }
            }
          >
            <center>
              <div
                style={
                  isUnderMobile
                    ? {
                        minWidth: "500px",
                        overflow: "scroll",
                        marginBottom: "-5px",
                      }
                    : { maxWidth: "1100px" }
                }
                className="text-left"
              >
                <div className="">
                  <Backdrop
                    sx={(theme) => ({
                      color: "#fff",
                      zIndex: theme.zIndex.drawer + 1,
                    })}
                    open={progressOpen}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                  <div className="card card-info card-outline">
                    <div className="card-header pl-2">
                      {!open && (
                        <>
                          {" "}
                          <Button
                            sx={{
                              width: "150px",
                              borderColor: "info.main",
                              fontSize: {
                                xs: "0.7rem",
                                sm: "0.85rem",
                                md: "1rem",
                              },
                              padding: {
                                xs: "0",
                                sm: "0",
                                md: "1px",
                                lg: "3px",
                              },
                              "&:hover": {
                                backgroundColor: "info.main",
                                color: "white",
                              },
                            }}
                            variant="outlined"
                            color="info"
                            onClick={handleAdd}
                          >
                            <h4
                              style={{
                                float: "left",
                                marginTop: "-1px",
                                marginRight: "5px",
                                marginBottom: "-2px",
                              }}
                            >
                              +
                            </h4>
                            <strong
                              style={{
                                float: "left",
                                marginTop: "0",
                                marginBottom: "-2px",
                              }}
                            >
                              {" "}
                              Create New
                            </strong>
                          </Button>
                          {suppliers?.length > 0 && (
                            <Button
                              className="ml-2"
                              sx={{
                                width: "150px",
                                borderColor: "info.main",
                                fontSize: {
                                  xs: "0.7rem",
                                  sm: "0.85rem",
                                  md: "1rem",
                                },
                                padding: {
                                  xs: "0",
                                  sm: "0",
                                  md: "1px",
                                  lg: "3px",
                                },
                                "&:hover": {
                                  backgroundColor: "info.main",
                                  color: "white",
                                },
                              }}
                              variant="outlined"
                              color="info"
                              style={{
                                width: "120px",
                                height: "35px",
                                float: "right",
                              }}
                              onClick={(e) => setCheck(true)}
                            >
                              <div
                                className="row"
                                style={{
                                  marginBottom: "-10px",
                                  marginLeft: "-10px",
                                }}
                              >
                                <div className="col">
                                  <FontAwesomeIcon
                                    style={{
                                      float: "right",
                                      marginRight: "-7px",
                                    }}
                                    icon={faFileExport}
                                  />
                                </div>
                                <div className="col-9">
                                  <h6
                                    style={{
                                      float: "left",
                                      marginLeft: "-3px",
                                    }}
                                  >
                                    <strong>Export</strong>
                                  </h6>
                                </div>
                              </div>
                            </Button>
                          )}
                          <Button
                            className="mr-2"
                            sx={{
                              width: "150px",
                              borderColor: "info.main",
                              fontSize: {
                                xs: "0.7rem",
                                sm: "0.85rem",
                                md: "1rem",
                              },
                              padding: {
                                xs: "0",
                                sm: "0",
                                md: "1px",
                                lg: "3px",
                              },
                              "&:hover": {
                                backgroundColor: "info.main",
                                color: "white",
                              },
                            }}
                            variant="outlined"
                            color="info"
                            style={{
                              width: "100px",
                              height: "35px",
                              float: "right",
                            }}
                            onClick={(e) => setStart(true)}
                          >
                            <h3
                              style={{
                                float: "left",
                                marginTop: "5px",
                                marginRight: "5px",
                              }}
                            >
                              <strong>+</strong>
                            </h3>
                            <h6 style={{ float: "left", marginTop: "7px" }}>
                              <strong>Import</strong>
                            </h6>
                          </Button>
                        </>
                      )}
                      {open && (
                        <div>
                          <h5 style={{ float: "left" }}>
                            <Typography
                              sx={{
                                fontSize: {
                                  xs: "1.3rem",
                                  sm: "1.3rem",
                                  md: "1.5rem",
                                },
                              }}
                            >
                              {formik.values.id === ""
                                ? "Create New"
                                : "Update"}{" "}
                              Supplier
                            </Typography>
                          </h5>
                          <Button
                            onClick={handleOpen}
                            sx={{
                              width: "100px",
                              borderColor: "success.main",
                              fontSize: {
                                xs: "0.85rem",
                                sm: "0.85rem",
                                md: "1rem",
                              },
                              padding: {
                                xs: "4px",
                                sm: "5px",
                                md: "6px",
                                lg: "6px",
                              },
                              "&:hover": {
                                backgroundColor: "success.main",
                                color: "white",
                              },
                            }}
                            variant="outlined"
                            color="success"
                            type="submit"
                            className="m-1"
                            style={{ float: "right" }}
                          >
                            <div
                              className="row"
                              style={{
                                marginBottom: "-10px",
                                marginLeft: "-10px",
                              }}
                            >
                              <div className="col">
                                <FontAwesomeIcon
                                  style={{
                                    float: "right",
                                    marginRight: "-1px",
                                    marginTop: "4px",
                                  }}
                                  icon={faBackwardStep}
                                />
                              </div>
                              <div className="col-7">
                                <h6
                                  style={{
                                    float: "left",
                                    marginLeft: "-10px",
                                    marginTop: "1px",
                                    marginBottom: "-3px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: {
                                        xs: "0.85rem",
                                        sm: "0.85rem",
                                        md: "1rem",
                                      },
                                    }}
                                  >
                                    Back
                                  </Typography>
                                </h6>
                              </div>
                            </div>
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className="card-body">
                      <div>
                        {!open && (
                          <div>
                            <Typography
                              sx={{
                                fontSize: {
                                  xs: "0.85rem",
                                  sm: "0.85rem",
                                  md: "1rem",
                                },
                              }}
                            >
                              <div
                                className="nav-bra pl-3 ml-1 p-2"
                                style={{
                                  backgroundColor: "rgb(235, 235, 235)",
                                  height: "40px",
                                }}
                              >
                                <span style={{ float: "left" }}>
                                  Home / Supplier{" "}
                                </span>
                              </div>
                            </Typography>

                            <hr />
                          </div>
                        )}
                        {open && (
                          <div>
                            <Typography
                              sx={{
                                fontSize: {
                                  xs: "0.85rem",
                                  sm: "0.85rem",
                                  md: "1rem",
                                },
                              }}
                            >
                              <div
                                className="nav-bra pl-3 ml-1 p-2"
                                style={{
                                  backgroundColor: "rgb(235, 235, 235)",
                                }}
                              >
                                <span>
                                  Home / Supplier /{" "}
                                  {formik.values.id === ""
                                    ? "Create"
                                    : "Update"}{" "}
                                </span>
                              </div>
                            </Typography>

                            <hr />
                          </div>
                        )}
                        {!open && (
                          <div
                            style={{
                              overflow: "scroll",
                            }}
                          >
                            <Box
                              sx={{
                                height: 500,

                                width: {
                                  lg: "100%",
                                  md: "1000px",
                                  sm: "1000px",
                                  xs: "1000px",
                                },
                                "& .super-app-theme--header": {
                                  backgroundColor: "#1de5ec",
                                  color: "black",
                                  fontSize: {
                                    xs: "13px",
                                    sm: "15px", //500
                                    md: "17px", //768
                                    lg: "18px", //1024
                                  },
                                },
                              }}
                            >
                              <DataGrid
                                rows={rows}
                                disableColumnFilter
                                disableColumnSelector
                                disableDensitySelector
                                sx={{
                                  "& .MuiDataGrid-cell": {
                                    fontSize: {
                                      xs: "12px",
                                      sm: "14px", //500
                                      md: "16px", //768
                                      lg: "17px", //1024
                                    },
                                  },
                                }}
                                columns={columns}
                                slots={{ toolbar: GridToolbar }}
                                slotProps={{
                                  toolbar: {
                                    showQuickFilter: true,
                                  },
                                }}
                                initialState={{
                                  ...suppliers.initialState,
                                  pagination: {
                                    paginationModel: { pageSize: 5 },
                                  },
                                }}
                                pageSizeOptions={[5, 10, 15]}
                                getRowId={(row) => row.Id}
                              />
                            </Box>
                          </div>
                        )}
                      </div>
                    </div>
                    {open && (
                      <center>
                        <div>
                          <Form
                            className=" ml-5 pl-5"
                            onSubmit={formik.handleSubmit}
                          >
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formHorizontalUsername"
                            >
                              <Col sm={12}>
                                <TextField
                                  id="outlined-basic"
                                  sx={{
                                    width: "250px",
                                    "& .MuiInputBase-root": {
                                      fontSize: {
                                        xs: "0.7rem",
                                        sm: "0.85rem",
                                        md: "0.9rem",
                                      },
                                      padding: {
                                        xs: "1px",
                                        sm: "2px",
                                        md: "3px",
                                        lg: "4px",
                                      },
                                    },
                                  }}
                                  label="Full Name"
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  name="fullName"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.fullName}
                                  error={
                                    formik.touched.fullName &&
                                    formik.errors.fullName
                                  }
                                  helperText={
                                    formik.touched.fullName &&
                                    formik.errors.fullName
                                      ? `${formik.errors.fullName}`
                                      : ""
                                  }
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formHorizontalUsername"
                            >
                              <Col sm={12}>
                                <TextField
                                  id="outlined-basic"
                                  sx={{
                                    width: "250px",
                                    "& .MuiInputBase-root": {
                                      fontSize: {
                                        xs: "0.7rem",
                                        sm: "0.85rem",
                                        md: "0.9rem",
                                      },
                                      padding: {
                                        xs: "1px",
                                        sm: "2px",
                                        md: "3px",
                                        lg: "4px",
                                      },
                                    },
                                  }}
                                  label="Tin Number"
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  name="tinNumber"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.tinNumber}
                                  error={
                                    formik.touched.tinNumber &&
                                    formik.errors.tinNumber
                                  }
                                  helperText={
                                    formik.touched.tinNumber &&
                                    formik.errors.tinNumber
                                      ? `${formik.errors.tinNumber}`
                                      : ""
                                  }
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formHorizontalUsername"
                            >
                              <Col sm={12}>
                                <TextField
                                  id="outlined-basic"
                                  sx={{
                                    width: "250px",
                                    "& .MuiInputBase-root": {
                                      fontSize: {
                                        xs: "0.7rem",
                                        sm: "0.85rem",
                                        md: "0.9rem",
                                      },
                                      padding: {
                                        xs: "1px",
                                        sm: "2px",
                                        md: "3px",
                                        lg: "4px",
                                      },
                                    },
                                  }}
                                  label="phone Number"
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  name="phoneNumber"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.phoneNumber}
                                  error={
                                    formik.touched.phoneNumber &&
                                    formik.errors.phoneNumber
                                  }
                                  helperText={
                                    formik.touched.phoneNumber &&
                                    formik.errors.phoneNumber
                                      ? `${formik.errors.phoneNumber}`
                                      : ""
                                  }
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formHorizontalUsername"
                            >
                              <Col sm={12}>
                                <TextField
                                  id="outlined-basic"
                                  sx={{
                                    width: "250px",
                                    "& .MuiInputBase-root": {
                                      fontSize: {
                                        xs: "0.7rem",
                                        sm: "0.85rem",
                                        md: "0.9rem",
                                      },
                                      padding: {
                                        xs: "1px",
                                        sm: "2px",
                                        md: "3px",
                                        lg: "4px",
                                      },
                                    },
                                  }}
                                  label="Address"
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  name="address"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.address}
                                  error={
                                    formik.touched.address &&
                                    formik.errors.address
                                  }
                                  helperText={
                                    formik.touched.address &&
                                    formik.errors.address
                                      ? `${formik.errors.address}`
                                      : ""
                                  }
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formHorizontalUsername"
                            >
                              <Col sm={12}>
                                <Button
                                  sx={{
                                    width: "250px",
                                    borderColor: "success.main",
                                    fontSize: {
                                      xs: "0.85rem",
                                      sm: "0.85rem",
                                      md: "1rem",
                                    },
                                    padding: {
                                      xs: "4px",
                                      sm: "5px",
                                      md: "6px",
                                      lg: "6px",
                                    },
                                    "&:hover": {
                                      backgroundColor: "success.main",
                                      color: "white",
                                    },
                                  }}
                                  variant="outlined"
                                  color="success"
                                  type="submit"
                                >
                                  {formik.values.id === ""
                                    ? "Create"
                                    : "Update"}
                                </Button>
                              </Col>{" "}
                            </Form.Group>
                          </Form>
                        </div>
                      </center>
                    )}
                  </div>
                </div>
                <Fragment>
                  <Dialog
                    fullWidth={true}
                    maxWidth="sm"
                    open={error}
                    onClose={(e) => setError(false)}
                    scroll="paper"
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                  >
                    <DialogTitle id="scroll-dialog-title" color="red">
                      Error
                    </DialogTitle>
                    <IconButton
                      aria-label="close"
                      onClick={(e) => setError(false)}
                      sx={(themes) => ({
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: themes.palette.grey[500],
                      })}
                    >
                      <Close />
                    </IconButton>
                    <DialogContent dividers={true}>
                      <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                      >
                        {show.map((s, i) => {
                          console.log(s);
                          return (
                            <p>
                              {i + 1}. {s.name}{" "}
                              {s.line === -1 ? (
                                ""
                              ) : (
                                <span style={{ color: "red" }}>{s.line}</span>
                              )}{" "}
                            </p>
                          );
                        })}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={(e) => setError(false)}>Cancel</Button>
                    </DialogActions>
                  </Dialog>
                </Fragment>
              </div>
              <Modal
                style={{ marginTop: "80px" }}
                show={check}
                onHide={(e) => {
                  setCheck(false);
                }}
              >
                <Modal.Header closeButton>
                  {passwordError && (
                    <Stack sx={{ width: "100%" }} spacing={2}>
                      <Alert severity="error">
                        <AlertTitle>Try Again</AlertTitle>
                        You Use Invalid Password !!!
                      </Alert>
                    </Stack>
                  )}
                </Modal.Header>
                <Modal.Body>
                  <Form id="formAuthentication" className="mb-2">
                    <div className="">
                      <Form.Group className="mb-3 row form-password-toggle text-center">
                        <Col className="mb-3" md={9}>
                          <TextField
                            type="password"
                            id="password"
                            sx={{
                              width: "250px",
                              "& .MuiInputBase-root": {
                                fontSize: {
                                  xs: "0.7rem",
                                  sm: "0.85rem",
                                  md: "0.9rem",
                                },
                                padding: {
                                  xs: "1px",
                                  sm: "2px",
                                  md: "3px",
                                  lg: "4px",
                                },
                              },
                            }}
                            className="form-control"
                            name="password"
                            size="small"
                            value={userDatas.password}
                            label="password"
                            aria-describedby="password"
                            onChange={handelchange}
                            style={{ width: "350px" }}
                          />
                        </Col>
                        <Col md={3}>
                          <Button
                            onClick={handelclick}
                            sx={{
                              width: "110px",
                              borderColor: "success.main",
                              fontSize: {
                                xs: "0.85rem",
                                sm: "0.85rem",
                                md: "1rem",
                              },
                              padding: {
                                xs: "4px",
                                sm: "5px",
                                md: "6x",
                                lg: "6px",
                              },
                              "&:hover": {
                                backgroundColor: "success.main",
                                color: "white",
                              },
                            }}
                            style={{ marginLeft: "-5px" }}
                            variant="outlined"
                            color="success"
                          >
                            Submit
                          </Button>
                        </Col>
                      </Form.Group>
                    </div>
                  </Form>
                </Modal.Body>
              </Modal>
              <Modal
                style={{ marginTop: "80px" }}
                show={start}
                onHide={(e) => {
                  setStart(false);
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {" "}
                  <Form
                    className="text-center"
                    onSubmit={handleCSVSubmit}
                    encType="multipart/form-data"
                  >
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalUsername"
                    >
                      <Col className="mb-3" md={9}>
                        <TextField
                          label="Import File"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          type="file"
                          sx={{
                            width: "300px",
                            "& .MuiInputBase-input": {
                              fontSize: {
                                xs: "0.6rem",
                                sm: "0.95rem",
                                md: "1.1rem",
                              },
                              padding: {
                                xs: "4px",
                                sm: "6px",
                                md: "7px",
                                lg: "12px",
                              },
                            },
                          }}
                          placeholder="Class Name"
                          name="file"
                          onChange={handleFileUpload}
                        />
                      </Col>

                      <Col md={3}>
                        <Button
                          sx={{
                            width: "100px",
                            borderColor: "success.main",
                            fontSize: {
                              xs: "0.65rem",
                              sm: "0.75rem",
                              md: "1rem",
                            },
                            padding: {
                              xs: "3px",
                              sm: "4px",
                              md: "5x",
                              lg: "7px",
                            },
                            "&:hover": {
                              backgroundColor: "success.main",
                              color: "white",
                            },
                          }}
                          style={{ marginLeft: "-20px" }}
                          variant="outlined"
                          color="success"
                          type="submit"
                        >
                          Submit
                        </Button>
                      </Col>
                    </Form.Group>
                  </Form>
                </Modal.Body>
              </Modal>
              <Modal show={warnings[0] !== 0} onHide={() => setWarings([0, 0])}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <Typography variant="h6">
                    Are you sure you want to{" "}
                    {warnings[0] === 1 ? "delete" : "update"} this supplier?
                  </Typography>
                  <Row>
                    <center>
                      <Button
                        className="col mr-4"
                        style={{ maxWidth: "100px" }}
                        variant="contained"
                        color="error"
                        onClick={() => {
                          if (warnings[0] === 1) {
                            handleDelete(warnings[1]);
                            setWarings([0, 0]);
                          } else if (warnings[0] === 2) {
                            formik.handleSubmit();
                          }
                        }}
                      >
                        Confirm
                      </Button>
                      <Button
                        className="col ml-4"
                        style={{ maxWidth: "100px" }}
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          warnings[0] = 0;
                          setWarings([...warnings]);
                        }}
                      >
                        Cancel
                      </Button>
                    </center>
                  </Row>
                </Modal.Body>
              </Modal>
            </center>
          </main>
        </div>
      </Typography>
    </ThemeProvider>
  );
};

export default SupplierPage;
