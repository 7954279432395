/** @format */

import React, { Fragment, useEffect, useRef, useState } from "react";
import { Modal, Form, Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import {
  TextField,
  createTheme,
  useMediaQuery,
  ThemeProvider,
  Typography,
  Button,
  Box,
  Backdrop,
  CircularProgress,
  AlertTitle,
  Alert,
  Stack,
  DialogActions,
  DialogContentText,
  DialogContent,
  IconButton,
  DialogTitle,
  Dialog,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as Yup from "yup";
import axios from "axios";
import Header from "../Header";
import {
  faBackwardStep,
  faFileExport,
  faPenToSquare,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem"; // Import MenuItem for the role selection
import { jwtDecode } from "jwt-decode";
import * as XLSX from "xlsx";
import { Close } from "@mui/icons-material";
const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .matches(
      /^[A-Za-z]+\s[A-Za-z]+$/,
      "Full name should be at least two words."
    )
    .required("Full name is required.")
    .min(8, "Too Short!")
    .max(60, "Too Long"),
  userName: Yup.string()
    .matches(
      /^[A-Za-z0-9]{3,15}$/,
      "Username must be 3-15 alphanumeric characters."
    )
    .required("Username is required."),
  email: Yup.string()
    .email("Invalid email address.")
    .required("Email is required."),

  phoneNumber: Yup.string()
    .matches(/^\d{9,10}$/, "Phone number must be 9 or 10 digits.")
    .required("Phone number is required."),
  roleId: Yup.string().required("Role is required"),
});
const theme = createTheme({
  breakpoints: { values: { xs: 0, sm: 500, md: 769, lg: 1024, xl: 1440 } },
  palette: {
    warning: {
      main: "#ffc107", // Main warning color
      light: "#ffb74d", // Light variation of warning color
      dark: "#f57c00", // Dark variation of warning color
      contrastText: "#000", // Text color on warning
    },
  },
});
const UserMng = () => {
  const [progressOpen, setProgressOpen] = useState(false);
  const [show, setShow] = useState([]);
  const [error, setError] = useState(false);
  const [start, setStart] = useState(false);
  const [check, setCheck] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [warnings, setWarings] = useState([0, 0]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [userDatas, setUserDatas] = useState({
    password: "",
    id: "",
  });
  const userData = jwtDecode(sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isUnderMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const descriptionElementRef = useRef();
  const fetchAllData = async () => {
    try {
      const [user, role] = await Promise.all([
        axios.get("https://enin.server.highendtradingplc.com/user"),
        axios.get("https://enin.server.highendtradingplc.com/role"),
      ]);
      if (userData.roleId === 1) {
        setUsers(user.data.filter((usr) => userData.roleId + 1 >= usr.roleId));
      } else {
        setUsers(user.data.filter((usr) => userData.roleId <= usr.roleId));
      }

      if (userData.roleId === 1) {
        setRoles(role.data.filter((usr) => userData.roleId + 1 >= usr.Id));
      } else {
        setRoles(role.data.filter((usr) => userData.roleId <= usr.Id));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const formik = useFormik({
    initialValues: {
      fullName: "",
      userName: "",
      passwordHash: "",
      phoneNumber: "",
      roleId: "",
      email: "",
      id: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      if (isSubmitted) return;
      setIsSubmitted(true);
      try {
        const { userName } = values;
        const userExists = users.find(
          (user) => user.userName === userName && user.Id !== values.id
        );

        if (userExists) {
          formik.setErrors({
            userName: "User name already exists.",
          });
          setIsSubmitted(false);
          return;
        }
        if (values.id === "") {
          await axios
            .post("https://enin.server.highendtradingplc.com/user/add", values)
            .then(() => {
              window.location.reload();
            });
        } else {
          if (warnings[0] === 0) {
            warnings[0] = 2;
            warnings[1] = values.id;
            setWarings([...warnings]);
            setIsSubmitted(false);
            return;
          }
          setWarings([0, 0]);
          values.userName = users.find((usr) => usr.Id === values.id).userName;
          await axios
            .put(
              `https://enin.server.highendtradingplc.com/user/update/${values.id}`,
              {
                fullName: values.fullName,
                userName: values.userName,
                phoneNumber: values.phoneNumber,
                roleId: values.roleId,
                email: values.email,
                check: 5,
              }
            )
            .then(() => {
              fetchAllData();
              setOpen(false);
            });
        }
      } catch (error) {
        console.error(error);
      }
      setIsSubmitted(false);
    },
  });

  const handleAdd = () => {
    formik.resetForm();
    setOpen(true);
  };

  const handleUpdate = (id) => {
    const user = users.find((usr) => usr.Id === parseInt(id));
    formik.setValues({
      fullName: user.fullName,
      userName: user.userName,
      phoneNumber: user.phoneNumber,
      roleId: user.roleId,
      email: user.email,
      passwordHash: "12345",
      id: parseInt(id),
    });
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `https://enin.server.highendtradingplc.com/user/delete/${id}`
      );
      fetchAllData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpen = () => {
    setOpen(false);
  };

  const ends = async () => {
    setProgressOpen(true);
    try {
      let data = [];
      users.map((cust) => {
        const role = roles.find((r) => r.Id === cust.roleId);
        const newData = {
          fullName: cust.fullName,
          userName: cust?.userName,
          phoneNumber: cust.phoneNumber,
          email: cust?.email,
          role: role.roleName,
        };
        data.push(newData);
        return null;
      });

      // Create a new workbook and a new worksheet
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      // Write to file and trigger download
      XLSX.writeFile(wb, "user_data.xlsx");

      setProgressOpen(false);
      setCheck(false);
      userDatas.password = "";
      setUserDatas(userDatas);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const workbook = XLSX.read(event.target.result, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet);
      setData(sheetData);
    };
    reader.readAsBinaryString(file);
  };

  const handleCSVSubmit = async (e) => {
    setProgressOpen(true);
    e.preventDefault();
    setShow([]);
    let checkError = true;
    try {
      let phone = [],
        userName = [],
        email = [],
        userError = [];
      const checkUserByPhone = new Set(users.map((cust) => cust.phoneNumber));
      const checkUserByUsername = new Set(users.map((cust) => cust.userName));
      const checkUserByEmail = new Set(users.map((cust) => cust.email));
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Email validation regex
      const userNameRegex = /^[A-Za-z0-9]{4,}$/; // Username validation rege
      for (let i = 0; i < data.length; i = i + 1) {
        checkUserByPhone.add(data[i].phoneNumber);
        if (
          data[i].fullName.length < 4 ||
          /^[A-Za-z]+$/.test(data[i].fullName)
        ) {
          userError.push({
            name: "Full Name must be greater than 3 alpha-character",
            line: data[i].fullName,
          });
          checkError = false;
        }
        if (!userNameRegex.test(data[i].userName)) {
          userError.push({
            name: "Username must be at least 4 alphanumeric characters",
            line: data[i].userName,
          });
          checkError = false;
        }

        if (!emailRegex.test(data[i].email)) {
          userError.push({
            name: "Invalid email format",
            line: data[i].email,
          });
          checkError = false;
        }
        const role = roles.find((r) => r.roleName === data[i].role);
        if (role.Id === 1) {
          userError.push({
            name: "Invalid role input ",
            line: data[i].role,
          });
          checkError = false;
        }
        if (!role) {
          userError.push({
            name: "Invalid role input ",
            line: data[i].role,
          });
          checkError = false;
        }
        if (data[i]?.password?.length < 4) {
          userError.push({
            name: "Password must be at least 4 characters",
            line: data[i]?.password,
          });
          checkError = false;
        }
        if (phone.includes(data[i].phoneNumber)) {
          userError.push({
            name: "Duplicate Phone Number",
            line: data[i].phoneNumber,
          });
          checkError = false;
        }
        if (userName.includes(data[i].userName)) {
          userError.push({
            name: "Duplicate Username",
            line: data[i].userName,
          });
          checkError = false;
        }
        if (email.includes(data[i].email)) {
          userError.push({
            name: "Duplicate email",
            line: data[i].email,
          });
          checkError = false;
        }
        if (
          (data[i].phoneNumber + "").length !== 10 ||
          isNaN(Number(data[i].phoneNumber))
        ) {
          userError.push({
            name: "Phone Number must be 10 digits",
            line: data[i].phoneNumber,
          });
          checkError = false;
        }
        if (checkUserByPhone.size !== users.length + i + 1) {
          userError.push({
            name: "Duplicate previous phone numbers found",
            line: data[i].phoneNumber,
          });
          checkError = false;
        }
        if (
          checkUserByUsername.has(data[i].userName) &&
          data[i].userName !== ""
        ) {
          userError.push({
            name: "Duplicate previous username found",
            line: data[i].userName,
          });
          checkError = false;
        } else {
          checkUserByUsername.add(data[i].userName);
        }
        if (checkUserByEmail.has(data[i].email) && data[i].email !== "") {
          userError.push({
            name: "Duplicate previous email found",
            line: data[i].email,
          });
          checkError = false;
        } else {
          checkUserByEmail.add(data[i].email);
        }
        phone.push(data[i].phoneNumber);
        userName.push(data[i].userName);
        email.push(data[i].email);
      }
      console.log(userError);
      setShow(userError);
      if (checkError) {
        setProgressOpen(true);
        // for (let i = 0; i < data.length; ) {
        //   const role = roles.find((r) => r.roleName === data[i].role);
        //   await axios
        //     .post(`https://enin.server.highendtradingplc.com/user/add`, {
        //       fullName: data[i].fullName,
        //       phoneNumber: data[i].phoneNumber,
        //       userName: data[i].userName,
        //       email: data[i].email,
        //       roleId: role.Id,
        //       passwordHash: data[i]?.password || "test",
        //     })
        //     .then((res) => {
        //       i = i + 1;
        //     });
        // }
      } else {
        setError(true);
      }

      fetchAllData().then(() => {
        setProgressOpen(false);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handelclick = async () => {
    if (isSubmitted) return;
    setIsSubmitted(true);
    userDatas.id = userData.Id;
    await axios
      .post("https://enin.server.highendtradingplc.com/check", userDatas)
      .then((res) => {
        if (res.data.Login) {
          ends();
        } else {
          setPasswordError(true);
        }
      })
      .catch((err) => console.log(err));
    setIsSubmitted(false);
  };
  const handelchange = (e) => {
    setUserDatas((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const columns = [
    {
      field: "id",
      headerName: "No",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Id",
      headerName: "User Id",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
    },
    {
      field: "fullName",
      headerName: "Full Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "userName",
      headerName: "User Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "roleId",
      headerName: "Role ID",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) =>
        roles.find((role) => role.Id === params)?.roleName || "",
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",

      minWidth: 230,
      renderCell: (params) => (
        <>
          <Button
            onClick={() =>
              userData.roleId === params.row.roleId
                ? ""
                : handleUpdate(params.row.Id)
            }
            sx={{
              width: "90px",
              borderColor: "warning.main",
              fontSize: {
                xs: "0.7rem",
                sm: "0.85rem",
                md: "1rem",
              },
              padding: {
                xs: "3px",
                sm: "4px",
                md: "5px",
                lg: "6px",
              },
              "&:hover": {
                backgroundColor: "warning.main",
                color: "white",
              },
            }}
            variant="outlined"
            color="warning"
            className="m-1"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-7px" }}
                  icon={faPenToSquare}
                />
              </div>
              <div className="col-7">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-3px",
                  }}
                >
                  Edit
                </h6>
              </div>
            </div>
          </Button>
          <Button
            onClick={(e) => {
              if (userData.roleId === params.row.roleId) return;
              warnings[1] = params.row.Id;
              warnings[0] = 1;
              setWarings([...warnings]);
            }}
            sx={{
              width: "110px",
              borderColor: "error.main",
              fontSize: {
                xs: "0.7rem",
                sm: "0.85rem",
                md: "1rem",
              },
              padding: {
                xs: "3px",
                sm: "4px",
                md: "5px",
                lg: "6px",
              },
              "&:hover": {
                backgroundColor: "error.main",
                color: "white",
              },
            }}
            variant="outlined"
            color="error"
            className=""
          >
            <div
              className="row "
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-5px" }}
                  icon={faTrashCan}
                />
              </div>
              <div className="col-8">
                <h6 style={{ float: "left", marginLeft: "-3px" }}>Delete</h6>
              </div>
            </div>
          </Button>
        </>
      ),
    },
  ];
  const rows = users.map((user, index) => {
    let newUser = user;
    newUser.id = index + 1;
    return newUser;
  });
  return (
    <ThemeProvider theme={theme}>
      <Typography
        sx={{
          fontSize: {
            xs: "12px",
            sm: "12px", //500
            md: "0.85rem", //768
            lg: "17px", //1024
          },
        }}
      >
        <div>
          <Header />
        </div>
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: "13px",
            sm: "14px", //500
            md: "16px", //768
            lg: "18px", //1024
          },
        }}
      >
        <div>
          <main
            style={
              isMobile && !isUnderMobile
                ? { marginTop: "190px" }
                : isUnderMobile
                ? { marginTop: "170px" }
                : isTablet
                ? { marginTop: "133px" }
                : { marginTop: "150px" }
            }
          >
            <center>
              <div
                style={
                  isUnderMobile
                    ? {
                        minWidth: "500px",
                        overflow: "scroll",
                        marginBottom: "-5px",
                      }
                    : { maxWidth: "1100px" }
                }
                className="text-left"
              >
                <div
                  className="tab-container"
                  style={
                    isUnderMobile
                      ? { marginTop: "0", marginBottom: "-5px" }
                      : {
                          marginTop: "-20px",
                          marginBottom: "-5px",
                        }
                  }
                >
                  <Link
                    className="p-2"
                    onClick={(e) => {
                      window.location.reload();
                    }}
                    style={{
                      backgroundColor: "white",
                      borderRadius: "5px 5px 0 0",
                      border: "solid 0.05em rgb(181, 181, 181)",
                    }}
                  >
                    User Mgt
                  </Link>
                  <Link className="p-2" to={`/role_mgt`}>
                    Role Mgt
                  </Link>
                  {/* <Link className="p-2" to={`/account_mgt`}>
                    Account Mgt
                  </Link> */}
                  <Link className="p-2" to={`/backup`}>
                    Backup Data
                  </Link>
                </div>

                {/* User Management Section */}
                <div className="card card-info card-outline">
                  <Backdrop
                    sx={(theme) => ({
                      color: "#fff",
                      zIndex: theme.zIndex.drawer + 1,
                    })}
                    open={progressOpen}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                  <div className="card-header pl-2">
                    {!open && (
                      <>
                        {" "}
                        <Button
                          sx={{
                            width: "150px",
                            borderColor: "info.main",
                            fontSize: {
                              xs: "0.7rem",
                              sm: "0.85rem",
                              md: "1rem",
                            },
                            padding: {
                              xs: "0",
                              sm: "0",
                              md: "1px",
                              lg: "3px",
                            },
                            "&:hover": {
                              backgroundColor: "info.main",
                              color: "white",
                            },
                          }}
                          variant="outlined"
                          color="info"
                          onClick={handleAdd}
                        >
                          <h4
                            style={{
                              float: "left",
                              marginTop: "-1px",
                              marginRight: "5px",
                              marginBottom: "-2px",
                            }}
                          >
                            +
                          </h4>
                          <strong
                            style={{
                              float: "left",
                              marginTop: "0",
                              marginBottom: "-2px",
                            }}
                          >
                            {" "}
                            Create New
                          </strong>
                        </Button>
                        {users?.length > 0 && (
                          <Button
                            className="ml-2"
                            sx={{
                              width: "150px",
                              borderColor: "info.main",
                              fontSize: {
                                xs: "0.7rem",
                                sm: "0.85rem",
                                md: "1rem",
                              },
                              padding: {
                                xs: "0",
                                sm: "0",
                                md: "1px",
                                lg: "3px",
                              },
                              "&:hover": {
                                backgroundColor: "info.main",
                                color: "white",
                              },
                            }}
                            variant="outlined"
                            color="info"
                            style={{
                              width: "120px",
                              height: "35px",
                              float: "right",
                            }}
                            onClick={(e) => setCheck(true)}
                          >
                            <div
                              className="row"
                              style={{
                                marginBottom: "-10px",
                                marginLeft: "-10px",
                              }}
                            >
                              <div className="col">
                                <FontAwesomeIcon
                                  style={{
                                    float: "right",
                                    marginRight: "-7px",
                                  }}
                                  icon={faFileExport}
                                />
                              </div>
                              <div className="col-9">
                                <h6
                                  style={{
                                    float: "left",
                                    marginLeft: "-3px",
                                  }}
                                >
                                  <strong>Export</strong>
                                </h6>
                              </div>
                            </div>
                          </Button>
                        )}
                        <Button
                          className="mr-2"
                          sx={{
                            width: "150px",
                            borderColor: "info.main",
                            fontSize: {
                              xs: "0.7rem",
                              sm: "0.85rem",
                              md: "1rem",
                            },
                            padding: {
                              xs: "0",
                              sm: "0",
                              md: "1px",
                              lg: "3px",
                            },
                            "&:hover": {
                              backgroundColor: "info.main",
                              color: "white",
                            },
                          }}
                          variant="outlined"
                          color="info"
                          style={{
                            width: "100px",
                            height: "35px",
                            float: "right",
                          }}
                          onClick={(e) => setStart(true)}
                        >
                          <h3
                            style={{
                              float: "left",
                              marginTop: "5px",
                              marginRight: "5px",
                            }}
                          >
                            <strong>+</strong>
                          </h3>
                          <h6 style={{ float: "left", marginTop: "7px" }}>
                            <strong>Import</strong>
                          </h6>
                        </Button>
                      </>
                    )}
                    {open && (
                      <div>
                        <h5 style={{ float: "left" }}>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: "1.3rem",
                                sm: "1.3rem",
                                md: "1.5rem",
                              },
                            }}
                          >
                            {formik.values.id === "" ? "Create New" : "Update"}{" "}
                            User
                          </Typography>
                        </h5>
                        <Button
                          onClick={handleOpen}
                          sx={{
                            width: "100px",
                            borderColor: "success.main",
                            fontSize: {
                              xs: "0.85rem",
                              sm: "0.85rem",
                              md: "1rem",
                            },
                            padding: {
                              xs: "4px",
                              sm: "5px",
                              md: "6px",
                              lg: "6px",
                            },
                            "&:hover": {
                              backgroundColor: "success.main",
                              color: "white",
                            },
                          }}
                          variant="outlined"
                          color="success"
                          type="submit"
                          className="m-1"
                          style={{ float: "right" }}
                        >
                          <div
                            className="row"
                            style={{
                              marginBottom: "-10px",
                              marginLeft: "-10px",
                            }}
                          >
                            <div className="col">
                              <FontAwesomeIcon
                                style={{
                                  float: "right",
                                  marginRight: "-1px",
                                  marginTop: "4px",
                                }}
                                icon={faBackwardStep}
                              />
                            </div>
                            <div className="col-7">
                              <h6
                                style={{
                                  float: "left",
                                  marginLeft: "-10px",
                                  marginTop: "1px",
                                  marginBottom: "-3px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: {
                                      xs: "0.85rem",
                                      sm: "0.85rem",
                                      md: "1rem",
                                    },
                                  }}
                                >
                                  Back
                                </Typography>
                              </h6>
                            </div>
                          </div>
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className="card-body">
                    <div>
                      {!open && (
                        <div>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: "0.85rem",
                                sm: "0.85rem",
                                md: "1rem",
                              },
                            }}
                          >
                            <div
                              className="nav-bra pl-3 ml-1 p-2"
                              style={{
                                backgroundColor: "rgb(235, 235, 235)",
                                height: "40px",
                              }}
                            >
                              <span style={{ float: "left" }}>
                                Home / User{" "}
                              </span>
                            </div>
                          </Typography>

                          <hr />
                        </div>
                      )}
                      {open && (
                        <div>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: "0.85rem",
                                sm: "0.85rem",
                                md: "1rem",
                              },
                            }}
                          >
                            <div
                              className="nav-bra pl-3 ml-1 p-2"
                              style={{
                                backgroundColor: "rgb(235, 235, 235)",
                              }}
                            >
                              <span>
                                Home / User /{" "}
                                {formik.values.id === "" ? "Create" : "Update"}{" "}
                              </span>
                            </div>
                          </Typography>

                          <hr />
                        </div>
                      )}
                      {/* Data Grid */}
                      {!open && (
                        <div
                          style={{
                            overflow: "scroll",
                          }}
                        >
                          <Box
                            sx={{
                              height: 500,

                              width: {
                                lg: "100%",
                                md: "1000px",
                                sm: "1000px",
                                xs: "1000px",
                              },
                              "& .super-app-theme--header": {
                                backgroundColor: "#1de5ec",
                                color: "black",
                                fontSize: {
                                  xs: "13px",
                                  sm: "15px", //500
                                  md: "17px", //768
                                  lg: "18px", //1024
                                },
                              },
                            }}
                          >
                            <DataGrid
                              rows={rows}
                              columns={columns}
                              disableColumnFilter
                              disableColumnSelector
                              disableDensitySelector
                              components={{
                                Toolbar: GridToolbar,
                              }}
                              initialState={{
                                ...users.initialState,
                                pagination: {
                                  paginationModel: { pageSize: 5 },
                                },
                              }}
                              pageSizeOptions={[5, 10, 15]}
                              getRowId={(row) => row.Id}
                            />
                          </Box>
                        </div>
                      )}
                    </div>
                    {open && (
                      <center>
                        <div>
                          <Form
                            className=" ml-5 pl-5"
                            onSubmit={formik.handleSubmit}
                          >
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formHorizontalFullName"
                            >
                              <Col sm={12}>
                                <TextField
                                  label="Full Name"
                                  sx={{
                                    width: "250px",
                                    "& .MuiInputBase-root": {
                                      fontSize: {
                                        xs: "0.7rem",
                                        sm: "0.85rem",
                                        md: "0.9rem",
                                      },
                                      padding: {
                                        xs: "1px",
                                        sm: "2px",
                                        md: "3px",
                                        lg: "4px",
                                      },
                                    },
                                  }}
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  name="fullName"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.fullName}
                                  error={
                                    formik.touched.fullName &&
                                    formik.errors.fullName
                                  }
                                  helperText={
                                    formik.touched.fullName &&
                                    formik.errors.fullName
                                  }
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formHorizontalUserName"
                            >
                              <Col sm={12}>
                                <TextField
                                  label="User Name"
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  name="userName"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.userName}
                                  error={
                                    formik.touched.userName &&
                                    formik.errors.userName
                                  }
                                  helperText={
                                    formik.touched.userName &&
                                    formik.errors.userName
                                  }
                                  sx={{
                                    width: "250px",
                                    "& .MuiInputBase-root": {
                                      fontSize: {
                                        xs: "0.7rem",
                                        sm: "0.85rem",
                                        md: "0.9rem",
                                      },
                                      padding: {
                                        xs: "1px",
                                        sm: "2px",
                                        md: "3px",
                                        lg: "4px",
                                      },
                                    },
                                  }}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formHorizontalUserName"
                            >
                              <Col sm={12}>
                                <TextField
                                  label="Email"
                                  variant="outlined"
                                  size="small"
                                  type="email"
                                  name="email"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.email}
                                  error={
                                    formik.touched.email && formik.errors.email
                                  }
                                  helperText={
                                    formik.touched.email && formik.errors.email
                                  }
                                  sx={{
                                    width: "250px",
                                    "& .MuiInputBase-root": {
                                      fontSize: {
                                        xs: "0.7rem",
                                        sm: "0.85rem",
                                        md: "0.9rem",
                                      },
                                      padding: {
                                        xs: "1px",
                                        sm: "2px",
                                        md: "3px",
                                        lg: "4px",
                                      },
                                    },
                                  }}
                                />
                              </Col>
                            </Form.Group>
                            {formik.values.id === "" && (
                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formHorizontalPasswordHash"
                              >
                                <Col sm={12}>
                                  <TextField
                                    label="Password"
                                    variant="outlined"
                                    size="small"
                                    type="password"
                                    name="passwordHash"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.passwordHash}
                                    error={
                                      formik.touched.passwordHash &&
                                      formik.errors.passwordHash
                                    }
                                    helperText={
                                      formik.touched.passwordHash &&
                                      formik.errors.passwordHash
                                    }
                                    sx={{
                                      width: "250px",
                                      "& .MuiInputBase-root": {
                                        fontSize: {
                                          xs: "0.7rem",
                                          sm: "0.85rem",
                                          md: "0.9rem",
                                        },
                                        padding: {
                                          xs: "1px",
                                          sm: "2px",
                                          md: "3px",
                                          lg: "4px",
                                        },
                                      },
                                    }}
                                  />
                                </Col>
                              </Form.Group>
                            )}
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formHorizontalPhoneNumber"
                            >
                              <Col sm={12}>
                                <TextField
                                  label="Phone Number"
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  name="phoneNumber"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.phoneNumber}
                                  error={
                                    formik.touched.phoneNumber &&
                                    formik.errors.phoneNumber
                                  }
                                  helperText={
                                    formik.touched.phoneNumber &&
                                    formik.errors.phoneNumber
                                  }
                                  sx={{
                                    width: "250px",
                                    "& .MuiInputBase-root": {
                                      fontSize: {
                                        xs: "0.7rem",
                                        sm: "0.85rem",
                                        md: "0.9rem",
                                      },
                                      padding: {
                                        xs: "1px",
                                        sm: "2px",
                                        md: "3px",
                                        lg: "4px",
                                      },
                                    },
                                  }}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formHorizontalRoleId"
                            >
                              <Col sm={12}>
                                <TextField
                                  label="Role"
                                  variant="outlined"
                                  size="small"
                                  select
                                  name="roleId"
                                  fullWidth
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.roleId}
                                  error={
                                    formik.touched.roleId &&
                                    !!formik.errors.roleId
                                  }
                                  helperText={
                                    formik.touched.roleId &&
                                    formik.errors.roleId
                                  }
                                  sx={{
                                    width: "250px",
                                    "& .MuiInputBase-root": {
                                      fontSize: {
                                        xs: "0.7rem",
                                        sm: "0.85rem",
                                        md: "0.9rem",
                                      },
                                      padding: {
                                        xs: "1px",
                                        sm: "2px",
                                        md: "3px",
                                        lg: "4px",
                                      },
                                    },
                                  }}
                                >
                                  <MenuItem value="">Select Role</MenuItem>
                                  {roles.map((role) => (
                                    <MenuItem key={role.Id} value={role.Id}>
                                      {role.roleName}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Col>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formHorizontalActions"
                            >
                              <Col sm={12}>
                                <Button
                                  type="submit"
                                  sx={{
                                    width: "250px",
                                    borderColor: "success.main",
                                    fontSize: {
                                      xs: "0.85rem",
                                      sm: "0.85rem",
                                      md: "1rem",
                                    },
                                    padding: {
                                      xs: "4px",
                                      sm: "5px",
                                      md: "6px",
                                      lg: "6px",
                                    },
                                    "&:hover": {
                                      backgroundColor: "success.main",
                                      color: "white",
                                    },
                                  }}
                                  variant="outlined"
                                  color="success"
                                >
                                  {formik.values.id === ""
                                    ? "Create"
                                    : "Update"}
                                </Button>
                              </Col>
                            </Form.Group>
                          </Form>
                        </div>
                      </center>
                    )}
                  </div>
                  <Fragment>
                    <Dialog
                      fullWidth={true}
                      maxWidth="sm"
                      open={error}
                      onClose={(e) => setError(false)}
                      scroll="paper"
                      aria-labelledby="scroll-dialog-title"
                      aria-describedby="scroll-dialog-description"
                    >
                      <DialogTitle id="scroll-dialog-title" color="red">
                        Error
                      </DialogTitle>
                      <IconButton
                        aria-label="close"
                        onClick={(e) => setError(false)}
                        sx={(themes) => ({
                          position: "absolute",
                          right: 8,
                          top: 8,
                          color: themes.palette.grey[500],
                        })}
                      >
                        <Close />
                      </IconButton>
                      <DialogContent dividers={true}>
                        <DialogContentText
                          id="scroll-dialog-description"
                          ref={descriptionElementRef}
                          tabIndex={-1}
                        >
                          {show.map((s, i) => {
                            console.log(s);
                            return (
                              <p>
                                {i + 1}. {s.name}{" "}
                                {s.line === -1 ? (
                                  ""
                                ) : (
                                  <span style={{ color: "red" }}>{s.line}</span>
                                )}{" "}
                              </p>
                            );
                          })}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={(e) => setError(false)}>Cancel</Button>
                      </DialogActions>
                    </Dialog>
                  </Fragment>
                </div>
              </div>
              <Modal
                style={{ marginTop: "80px" }}
                show={check}
                onHide={(e) => {
                  setCheck(false);
                }}
              >
                <Modal.Header closeButton>
                  {passwordError && (
                    <Stack sx={{ width: "100%" }} spacing={2}>
                      <Alert severity="error">
                        <AlertTitle>Try Again</AlertTitle>
                        You Use Invalid Password !!!
                      </Alert>
                    </Stack>
                  )}
                </Modal.Header>
                <Modal.Body>
                  <Form id="formAuthentication" className="mb-2">
                    <div className="">
                      <Form.Group className="mb-3 row form-password-toggle text-center">
                        <Col className="mb-3" md={9}>
                          <TextField
                            type="password"
                            id="password"
                            sx={{
                              width: "250px",
                              "& .MuiInputBase-root": {
                                fontSize: {
                                  xs: "0.7rem",
                                  sm: "0.85rem",
                                  md: "0.9rem",
                                },
                                padding: {
                                  xs: "1px",
                                  sm: "2px",
                                  md: "3px",
                                  lg: "4px",
                                },
                              },
                            }}
                            className="form-control"
                            name="password"
                            size="small"
                            value={userDatas.password}
                            label="password"
                            aria-describedby="password"
                            onChange={handelchange}
                            style={{ width: "350px" }}
                          />
                        </Col>
                        <Col md={3}>
                          <Button
                            onClick={handelclick}
                            sx={{
                              width: "110px",
                              borderColor: "success.main",
                              fontSize: {
                                xs: "0.85rem",
                                sm: "0.85rem",
                                md: "1rem",
                              },
                              padding: {
                                xs: "4px",
                                sm: "5px",
                                md: "6x",
                                lg: "6px",
                              },
                              "&:hover": {
                                backgroundColor: "success.main",
                                color: "white",
                              },
                            }}
                            style={{ marginLeft: "-5px" }}
                            variant="outlined"
                            color="success"
                          >
                            Submit
                          </Button>
                        </Col>
                      </Form.Group>
                    </div>
                  </Form>
                </Modal.Body>
              </Modal>
              <Modal
                style={{ marginTop: "80px" }}
                show={start}
                onHide={(e) => {
                  setStart(false);
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {" "}
                  <Form
                    className="text-center"
                    onSubmit={handleCSVSubmit}
                    encType="multipart/form-data"
                  >
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalUsername"
                    >
                      <Col className="mb-3" md={9}>
                        <TextField
                          label="Import File"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          type="file"
                          sx={{
                            width: "300px",
                            "& .MuiInputBase-input": {
                              fontSize: {
                                xs: "0.6rem",
                                sm: "0.95rem",
                                md: "1.1rem",
                              },
                              padding: {
                                xs: "4px",
                                sm: "6px",
                                md: "7px",
                                lg: "12px",
                              },
                            },
                          }}
                          placeholder="Class Name"
                          name="file"
                          onChange={handleFileUpload}
                        />
                      </Col>

                      <Col md={3}>
                        <Button
                          sx={{
                            width: "100px",
                            borderColor: "success.main",
                            fontSize: {
                              xs: "0.65rem",
                              sm: "0.75rem",
                              md: "1rem",
                            },
                            padding: {
                              xs: "3px",
                              sm: "4px",
                              md: "5x",
                              lg: "7px",
                            },
                            "&:hover": {
                              backgroundColor: "success.main",
                              color: "white",
                            },
                          }}
                          style={{ marginLeft: "-20px" }}
                          variant="outlined"
                          color="success"
                          type="submit"
                        >
                          Submit
                        </Button>
                      </Col>
                    </Form.Group>
                  </Form>
                </Modal.Body>
              </Modal>
              <Modal show={warnings[0] !== 0} onHide={() => setWarings([0, 0])}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <Typography variant="h6">
                    Are you sure you want to{" "}
                    {warnings[0] === 1 ? "delete" : "update"} this user?
                  </Typography>
                  <Row>
                    <center>
                      <Button
                        className="col mr-4"
                        style={{ maxWidth: "100px" }}
                        variant="contained"
                        color="error"
                        onClick={() => {
                          if (warnings[0] === 1) {
                            handleDelete(warnings[1]);
                            setWarings([0, 0]);
                          } else if (warnings[0] === 2) {
                            formik.handleSubmit();
                          }
                        }}
                      >
                        Confirm
                      </Button>
                      <Button
                        className="col ml-4"
                        style={{ maxWidth: "100px" }}
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          warnings[0] = 0;
                          setWarings([...warnings]);
                        }}
                      >
                        Cancel
                      </Button>
                    </center>
                  </Row>
                </Modal.Body>
              </Modal>
            </center>
          </main>
        </div>
      </Typography>
    </ThemeProvider>
  );
};

export default UserMng;
