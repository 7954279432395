import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  createTheme,
  useMediaQuery,
  ThemeProvider,
  Typography,
  Box,
} from "@mui/material";
import Header from "../Header";
import { Link } from "react-router-dom";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
const theme = createTheme({
  breakpoints: { values: { xs: 0, sm: 500, md: 769, lg: 1024, xl: 1440 } },
  palette: {
    warning: {
      main: "#ffc107", // Main warning color
      light: "#ffb74d", // Light variation of warning color
      dark: "#f57c00", // Dark variation of warning color
      contrastText: "#000", // Text color on warning
    },
  },
});
const BackUp = () => {
  const [files, setFiles] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isUnderMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const fetchAllData = async () => {
    try {
      const file = await axios.get(
        "https://enin.server.highendtradingplc.com/files"
      );
      setFiles(file.data.reverse());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);
  const handleDownload = (file) => {
    if (isSubmitted) return;
    setIsSubmitted(true);
    const link = document.createElement("a"); // Create an <a> element
    link.href = `https://enin.server.highendtradingplc.com/backups/${file}`; // Set the href to the file URL
    link.download = file; // Set the download attribute to suggest the file name
    document.body.appendChild(link); // Append the link to the document body
    link.click(); // Simulate a click on the link
    document.body.removeChild(link); // Remove the link from the document
    setIsSubmitted(false);
  };

  const columns = [
    {
      field: "Id",
      headerName: "No",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        `${new Date(params).getDate()}/${
          new Date(params).getMonth() + 1
        }/${new Date(params).getFullYear()}`,
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 170,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <div>
          <Button
            onClick={() => handleDownload(params.row.file)}
            sx={{
              width: "140px",
              borderColor: "primary.main",
              fontSize: {
                xs: "0.85rem",
                sm: "0.85rem",
                md: "1rem",
              },
              padding: {
                xs: "4px",
                sm: "5px",
                md: "6px",
                lg: "6px",
              },
              "&:hover": {
                backgroundColor: "primary.main",
                color: "white",
              },
            }}
            variant="outlined"
            color="primary"
            className="m-1"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{
                    float: "right",
                    marginRight: "-1px",
                    marginTop: "2px",
                    marginBottom: "-4px",
                  }}
                  icon={faFileDownload}
                />
              </div>
              <div className="col-9">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-10px",
                    marginTop: "-1px",
                    marginBottom: "-7px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "0.85rem",
                        sm: "0.85rem",
                        md: "1rem",
                      },
                    }}
                  >
                    Download
                  </Typography>
                </h6>
              </div>
            </div>
          </Button>
        </div>
      ),
    },
  ];
  const formatDate = (dateString) => {
    // Parse the date string
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);

    // Create a Date object
    const date = new Date(`${year}-${month}-${day}`);

    // Format the date using Intl.DateTimeFormat
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    }).format(date);
  };

  const rows = files.map((file, index) => {
    const name = file.split(".")[0];
    const date = name.split("_")[3];

    return {
      Id: index + 1,
      name: name,
      date: formatDate(date),
      file: file,
    };
  });
  return (
    <ThemeProvider theme={theme}>
      <Typography
        sx={{
          fontSize: {
            xs: "12px",
            sm: "12px", //500
            md: "0.85rem", //768
            lg: "17px", //1024
          },
        }}
      >
        <div>
          <Header />
        </div>
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: "13px",
            sm: "14px", //500
            md: "16px", //768
            lg: "18px", //1024
          },
        }}
      >
        <main
          style={
            isMobile && !isUnderMobile
              ? { marginTop: "190px" }
              : isUnderMobile
              ? { marginTop: "170px" }
              : isTablet
              ? { marginTop: "133px" }
              : { marginTop: "150px" }
          }
        >
          <center>
            <div
              style={
                isUnderMobile
                  ? {
                      minWidth: "500px",
                      overflow: "scroll",
                      marginBottom: "-5px",
                    }
                  : { maxWidth: "1100px" }
              }
              className="text-left"
            >
              <div
                className="tab-container "
                style={
                  isUnderMobile
                    ? { marginTop: "0", marginBottom: "-5px" }
                    : {
                        marginTop: "-20px",
                        marginBottom: "-5px",
                      }
                }
              >
                <Link className="p-2" to={`/user_mgt`}>
                  User Mgt
                </Link>
                <Link className="p-2" to={`/role_mgt`}>
                  Role Mgt
                </Link>
                {/* <Link className="p-2" to={`/account_mgt`}>
                  Account Mgt
                </Link> */}
                <Link
                  className="p-2"
                  onClick={(e) => window.location.reload()}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "5px 5px 0 0",
                    border: "solid 0.05em rgb(181, 181, 181)",
                  }}
                >
                  Backup Data
                </Link>
              </div>
              <div className="card card-info card-outline">
                <div className="card-body">
                  <div
                    className="nav-bra pl-3 ml-1 p-2 "
                    style={{
                      backgroundColor: "rgb(235, 235, 235)",
                    }}
                  >
                    <span>Home / Backup Data</span>
                    <div style={{ overflow: "scroll" }}>
                      <Box
                        sx={{
                          height: 500,

                          width: {
                            lg: "100%",
                            md: "1000px",
                            sm: "1000px",
                            xs: "1000px",
                          },
                          "& .super-app-theme--header": {
                            backgroundColor: "#1de5ec",
                            color: "black",
                            fontSize: {
                              xs: "13px",
                              sm: "15px", //500
                              md: "17px", //768
                              lg: "18px", //1024
                            },
                          },
                        }}
                      >
                        <DataGrid
                          rows={rows}
                          disableColumnFilter
                          disableColumnSelector
                          disableDensitySelector
                          sx={{
                            "& .MuiDataGrid-cell": {
                              fontSize: {
                                xs: "12px",
                                sm: "14px", //500
                                md: "16px", //768
                                lg: "17px", //1024
                              },
                            },
                          }}
                          columns={columns}
                          slots={{ toolbar: GridToolbar }}
                          slotProps={{
                            toolbar: {
                              showQuickFilter: true,
                            },
                          }}
                          initialState={{
                            ...files.initialState,
                            pagination: {
                              paginationModel: { pageSize: 5 },
                            },
                          }}
                          pageSizeOptions={[5, 10, 15]}
                          getRowId={(row) => row.Id}
                        />
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </center>
        </main>
      </Typography>
    </ThemeProvider>
  );
};

export default BackUp;
