import React, { useEffect, useRef, useState } from "react";
import Header from "../Header";
import { PropagateLoader, RiseLoader } from "react-spinners";
import {
  Modal,
  Form,
  Col,
  Row,
  Label,
  Container,
  Table,
} from "react-bootstrap";
import {
  Autocomplete,
  Button,
  createTheme,
  useMediaQuery,
  ThemeProvider,
  Typography,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  faUser,
  faBackwardStep,
  faFloppyDisk,
  faCode,
  faCalendar,
  faPenToSquare,
  faTrashCan,
  faXmark,
  faEye,
  faPrint,
  faWallet,
  faMoneyBillTransfer,
  faCreditCard,
  faMoneyBill1,
  faPaperPlane,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import {useReactToPrint} from "react-to-print";
import QRCode from "qrcode";
import HexCharacterManipulator from "./AllDataFetch";
import { jwtDecode } from "jwt-decode";
const validationSchema = Yup.object().shape({
  date: Yup.date().required("Date is required!"),
  untilDate: Yup.date().required("Date is required!"),
  customerId: Yup.string().required("Customer is required!"),
  paymentMethodId: Yup.string().required("Payment Method is required!"),
  transferidId: Yup.string().matches(
    /^[A-Za-z0-9-]{3,20}$/,
    "Transfer ID should be 3-20 alphanumeric characters."
  ),
  firstPayment: Yup.number().max(10000000, "Too High"),
});
const theme = createTheme({
  breakpoints: { values: { xs: 0, sm: 500, md: 769, lg: 1024, xl: 1440 } },
  palette: {
    warning: {
      main: "#ffc107", // Main warning color
      light: "#ffb74d", // Light variation of warning color
      dark: "#f57c00", // Dark variation of warning color
      contrastText: "#000", // Text color on warning
    },
  },
});

export default function ReservePage() {
  const [open, setOpen] = useState(0);
  const [openCustomer, setOpenCustomer] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [items, setItems] = useState([]);
  const [units, setUnits] = useState([]);
  const [models, setModels] = useState([]);
  const [itemId, setItemId] = useState(null);
  const [itemCheck, setItemCheck] = useState(null);
  const [customerCheck, setCustomerCheck] = useState(null);
  const [customerValue, setCustomerValue] = useState(null);
  const [userCheck, setUserCheck] = useState(null);
  const [userValue, setUserValue] = useState(null);
  const [itemValue, setItemValue] = useState([]);
  const [valids, setValid] = useState([0, 0, 0]);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [taxs, setTaxs] = useState([]);
  const [users, setUsers] = useState([]);
  const [reserves, setReserves] = useState([]);
  const [reservesList, setReservesList] = useState([]);
  const [reserveItems, setReserveItems] = useState([]);
  const [discountValue, setDiscountValue] = useState(null);
  const [paymentValue, setPaymentValue] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [isError, setisError] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [newReserveItems, setNewReserverItems] = useState([]);
  const [customerErrorTin, setCustomerErrorTin] = useState(false);
  const [customerErrorPhone1, setCustomerErrorPhone1] = useState(false);
  const [customerErrorPhone2, setCustomerErrorPhone2] = useState(false);
  const [logicals, setLogicals] = useState([]);
  const [warnings, setWarings] = useState([0, 0]);
  const [openMessage, setOpenMessage] = useState(false);
  const [messages, setMessages] = useState("");
  const [isSubmitted,setIsSubmitted] = useState(false)
  const [update, setUpdate] = useState({
    itemId: "",
    quantity: "",
    price: "",
    discount: "",
    totalPrice: "",
  });
  const [price, setPrice] = useState({
    total: 0,
    grandTotal: 0,
    tax: 0,
    withHold: 0,
  });
  const userDatas = jwtDecode(sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const contentRef = useRef();
  const reactToPrint = useReactToPrint({contentRef})
  const { id } = useParams();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isUnderMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const fetchAllData = async () => {
    setisError(false);
    setisLoading(true);
    try {
      const [
        customer,
        item,
        unit,
        reserve,
        reserveItem,
        user,
        model,
        tax,
        paymentMethod,
        logical,
      ] = await Promise.all([
        axios.get("https://enin.server.highendtradingplc.com/customer"),
        axios.get("https://enin.server.highendtradingplc.com/item"),
        axios.get("https://enin.server.highendtradingplc.com/unit"),
        axios.get("https://enin.server.highendtradingplc.com/reserve"),
        axios.get("https://enin.server.highendtradingplc.com/reserve_item"),
        axios.get("https://enin.server.highendtradingplc.com/user"),
        axios.get("https://enin.server.highendtradingplc.com/model"),
        axios.get("https://enin.server.highendtradingplc.com/tax"),
        axios.get("https://enin.server.highendtradingplc.com/payment_method"),
        axios.get("https://enin.server.highendtradingplc.com/logical_stock"),
      ]);
      setCustomers(customer.data);
      setItems(item.data);
      setUnits(unit.data);
      setReservesList(reserve.data.filter((r) => r.statusId === 2));
      setReserves(reserve.data.filter((r) => r.statusId === 1));
      setReserveItems(reserveItem.data);
      setUsers(user.data?.filter((user) => user.roleId >= 2));
      setModels(model.data);
      setTaxs(tax.data);
      setPaymentMethods(paymentMethod.data);
      setLogicals(logical.data);
    } catch (error) {
      setisError(true);
    }
    setisLoading(false);
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const handleOpenCustomer = () => {
    setOpenCustomer(!openCustomer);
  };

  const customerData = {
    options: customers,
    getOptionLabel: (option) => option.fullName,
  };
  const itemData = {
    options: items,
    getOptionLabel: (option) => option.description,
  };
  const paymentData = {
    options: paymentMethods,
    getOptionLabel: (option) => option.name,
  };
  const userData = {
    options: users,
    getOptionLabel: (option) => option.fullName,
  };
  const formik = useFormik({
    initialValues: {
      customerId: "",
      date: "",
      untilDate: "",
      paymentMethodId: "",
      transferidId: "",
      firstPayment: "",
      reserveId: "",
      Id: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      if (itemValue.length === 0) return;
      if (isSubmitted) return;
                    setIsSubmitted(true);
      if (values.firstPayment > price.grandTotal) {
        setMessages(
          `Max Value of the price is ${price.grandTotal.toLocaleString()} !!!`
        );
        setOpenMessage(true);
        setIsSubmitted(false);
        return;
      }
      // Get the current time
      const currentTime = new Date();
      const hours = currentTime.getHours();
      const minutes = currentTime.getMinutes();
      const seconds = currentTime.getSeconds();

      // Append the current time to the date value
      values.date = `${values.date} ${hours}:${minutes}:${seconds}`;

      const customer = customers.find((c) => c.Id === values.customerId);
      const hexCharacterManipulator = new HexCharacterManipulator();
      let charTohexa = hexCharacterManipulator.charTohexa(
        [price.total, price.tax, price.withHold],
        price.grandTotal
      );
      const data = `
                    Reserve ID : ${charTohexa},
                    Customer Name : ${customer.fullName},
                    Customer Phone : ${customer.primaryPhoneNumber},
                    Grand Price : ${price.grandTotal}`;
      generateQRCode(data);

      try {
        if (values.reserveId === "") {
          setOpen(2);
          await axios
            .post("https://enin.server.highendtradingplc.com/reserve/add", {
              customerId: values.customerId,
              date: values.date,
              untilDate: values.untilDate,
              userId: userDatas.Id,
              statusId: 1,
              paymentMethodId: values.paymentMethodId,
              transferidId: values.transferidId,
              firstPayment: values.firstPayment,
            })
            .then(async (res) => {
              for (let i = 0; i <= itemValue.length - 1; ) {
                await axios.post(
                  "https://enin.server.highendtradingplc.com/reserve_item/add",
                  {
                    itemId: itemValue[i].itemId,
                    reserveId: res.data.data.Id,
                    quantity: itemValue[i].quantity,
                    unitPrice: itemValue[i].price,
                    totalPrice: itemValue[i].totalPrice,
                    discount: itemValue[i].discount,
                  }
                );
                let newItem = items.find((ii) => ii.Id === itemValue[i].itemId);
                if (newItem.statusId === 2) {
                  await axios
                    .post(
                      "https://enin.server.highendtradingplc.com/transaction/add",
                      {
                        quantity: parseInt(itemValue[i].quantity),
                        statusId: 2,
                        date: values.date,
                      }
                    )
                    .then(async (res1) => {
                      const logical = logicals.find(
                        (l) => l.itemId === itemValue[i].itemId
                      );
                      await axios.post(
                        "https://enin.server.highendtradingplc.com/logical_stock/add",
                        {
                          quantity:
                            parseInt(logical?.quantity || 0) -
                            itemValue[i].quantity,
                          itemId: itemValue.itemId,
                          date: values.date,
                          statusId: 2,
                          transactionId: res1.data.data.Id,
                          ADPRS_ID: res.data.data.Id,
                        }
                      );
                    });
                }
                i = i + 1;
              }
            });
        } else {
          if (warnings[0] === 0) {
            warnings[0] = 2;
            warnings[1] = values.id;
            setWarings([...warnings]);
            setIsSubmitted(false);
            return;
          }
          setWarings([0, 0]);
          setOpen(2);
          await axios.put(
            "https://enin.server.highendtradingplc.com/reserve/update/" +
              values.reserveId,
            {
              customerId: values.customerId,
              date: values.date,
              untilDate: values.untilDate,
              paymentMethodId: values.paymentMethodId,
              transferidId: values.transferidId,
              firstPayment: values.firstPayment,
            }
          );
          let counter = 0;
          newReserveItems.filter(async (r) => {
            counter++;
            const newItemValue = itemValue.find(
              (item) => item.itemId === r.itemId
            );
            let newItem = items.find((ii) => ii.Id === r.itemId);
            if (newItem.statusId === 2) {
              const reserveItem = reserveItems.find((rq) => rq.Id === r.Id);
              if (reserveItem.quantity !== newItemValue.quantity) {
                await axios
                  .post(
                    "https://enin.server.highendtradingplc.com/transaction/add",
                    {
                      quantity: !!newItemValue
                        ? Math.abs(
                            parseInt(reserveItem.quantity) -
                              parseInt(newItemValue.quantity)
                          )
                        : parseInt(newItemValue.quantity),
                      statusId: 2,
                      date: values.date,
                    }
                  )
                  .then(async (res1) => {
                    const logical = logicals.find(
                      (l) => l.itemId === newItem.Id
                    );
                    await axios.post(
                      "https://enin.server.highendtradingplc.com/logical_stock/add",
                      {
                        quantity:
                          parseInt(logical?.quantity || 0) +
                          (!!newItemValue
                            ? parseInt(reserveItem.quantity) -
                              parseInt(newItemValue.quantity)
                            : parseInt(newItemValue.quantity)),
                        itemId: newItem.Id,
                        date: values.date,
                        statusId: !!newItemValue
                          ? reserveItem.quantity > newItemValue.quantity
                            ? 1
                            : 2
                          : 1,
                        transactionId: res1.data.data.Id,
                        ADPRS_ID: values.reserveId,
                      }
                    );
                  });
              }
            }
            if (!!newItemValue) {
              await axios.put(
                "https://enin.server.highendtradingplc.com/reserve_item/update/" +
                  r.Id,
                {
                  quantity: newItemValue.quantity,
                  unitPrice: newItemValue.price,
                  totalPrice: newItemValue.totalPrice,
                  discount: newItemValue.discount,
                }
              );
            } else {
              await axios.delete(
                "https://enin.server.highendtradingplc.com/reserve_item/delete/" +
                  r.Id
              );
            }
            return true;
          });

          if (counter === newReserveItems.length) {
            for (let i = 0; i <= itemValue.length - 1; ) {
              const checkItem = newReserveItems.find(
                (n) => n.itemId === itemValue[i].itemId
              );
              if (!!!checkItem) {
                await axios.post(
                  "https://enin.server.highendtradingplc.com/reserve_item/add",
                  {
                    itemId: itemValue[i].itemId,
                    reserveId: values.reserveId,
                    quantity: itemValue[i].quantity,
                    unitPrice: itemValue[i].price,
                    totalPrice: itemValue[i].totalPrice,
                    discount: itemValue[i].discount,
                  }
                );
                let newItem = items.find((ii) => ii.Id === itemValue[i].itemId);
                if (newItem.statusId === 2) {
                  await axios
                    .post(
                      "https://enin.server.highendtradingplc.com/transaction/add",
                      {
                        quantity: parseInt(itemValue[i].quantity),
                        statusId: 2,
                        date: values.date,
                      }
                    )
                    .then(async (res1) => {
                      const logical = logicals.find(
                        (l) => l.itemId === itemValue[i].itemId
                      );
                      await axios.post(
                        "https://enin.server.highendtradingplc.com/logical_stock/add",
                        {
                          quantity:
                            parseInt(logical?.quantity || 0) -
                            itemValue[i].quantity,
                          itemId: itemValue.itemId,
                          date: values.date,
                          statusId: 2,
                          transactionId: res1.data.data.Id,
                          ADPRS_ID: values.reserveId,
                        }
                      );
                    });
                }
              }

              i = i + 1;
            }
          }
        }
        fetchAllData();
      } catch (error) {
        setisError(true);
      }
      setIsSubmitted(false);
    },
  });
  const clearData = () => {
    price.total = 0;
    price.grandTotal = 0;
    price.tax = 0;
    price.withHold = 0;
    setItemValue([]);
  };
  const handleBack = () => {
    fetchAllData();
    formik.values.paymentMethodId = "";
    formik.values.transferidId = "";
    formik.values.firstPayment = "";
    formik.values.customerId = "";
    formik.values.date = "";
    formik.values.untilDate = "";
    formik.values.reserveId = "";
    formik.values.Id = "";
    setCustomerCheck(null);
    setCustomerValue(null);
    setPaymentValue(null);
    setItemValue([]);
    setItemCheck(null);
    setItemId(null);
    setValid([0, 0, 0]);
    price.total = 0;
    price.tax = 0;
    price.withHold = 0;
    price.grandTotal = 0;
    setOpen(0);
  };

  const generateQRCode = (text) => {
    QRCode.toDataURL(text)
      .then((url) => {
        setQrCodeUrl(url); // Set the QR code image URL
      })
      .catch((err) => console.error("Error generating QR code:", err));
  };

  const handleValidity = (i) => {
    valids[i] = 1;
    setValid([...valids]);
  };
  const hand = async (row) => {
    if (isSubmitted) return;
                    setIsSubmitted(true);
    try {
      // let reserve = reserveItems.filter((ii) => row.Id === ii.reserveId);
      // await axios
      //   // .post("https://enin.server.highendtradingplc.com/request/add", {
      //   //   customerId: row.customerId,
      //   //   date: new Date(),
      //   //   userId: userDatas.Id,
      //   //   statusId: 1,
      //   //   untilDate: row.untilDate,
      //   // })
      //   // .then(async (res) => {
      //   //   for (let rr = 0; rr < reserve.length; rr++) {
      //   //     let values = {
      //   //       itemId: reserve[rr].itemId,
      //   //       requestId: res.data.data.Id,
      //   //       quantity: reserve[rr].quantity,
      //   //       unitPrice: reserve[rr].unitPrice,
      //   //       totalPrice: reserve[rr].totalPrice,
      //   //       discount: reserve[rr].discount,
      //   //     };
      //   //     await axios.post(
      //   //       "https://enin.server.highendtradingplc.com/request_item/add",
      //   //       values
      //   //     );
      //   //     await axios
      //   //       .post(
      //   //         "https://enin.server.highendtradingplc.com/transaction/add",
      //   //         {
      //   //           quantity: parseInt(reserve[rr].quantity),
      //   //           statusId: 2,
      //   //           date: new Date(),
      //   //         }
      //   //       )
      //   //       .then(async (res1) => {
      //   //         const logical = logicals.find(
      //   //           (l) => l.itemId === reserve[rr].itemId
      //   //         );
      //   //         await axios.post(
      //   //           "https://enin.server.highendtradingplc.com/logical_stock/add",
      //   //           {
      //   //             quantity: parseInt(logical.quantity) + reserve[rr].quantity,
      //   //             itemId: reserve.itemId,
      //   //             date: new Date(),
      //   //             statusId: 1,
      //   //             transactionId: res1.data.data.Id,
      //   //             ADPRS_ID: "Reserve_ID-" + reserve[rr].reserveId,
      //   //           }
      //   //         );
      //   //       });
      //   //   }

      //   //   // await axios.post(
      //   //   //   "https://enin.server.highendtradingplc.com/sale/add",
      //   //   //   {
      //   //   //     requestId: res.data.data.Id,
      //   //   //     statusId: 10,
      //   //   //     customerId: row.customerId,
      //   //   //     date: new Date(),
      //   //   //     totalPrice: row.firstPayment,
      //   //   //   }
      //   //   // );
      //   // });
      await axios.put(
        "https://enin.server.highendtradingplc.com/reserve/update/" + row.Id,
        {
          statusId: 2,
          date: new Date(),
        }
      );

      fetchAllData();
      setOpen(2);
    } catch (error) {
      console.log(error);
    }
    setIsSubmitted(false);
  };
  const handleUpdate = (row) => {
    const cust = customers.find((c) => c.Id === row.customerId);
    const payment = paymentMethods.find((p) => p.Id === row.paymentMethodId);
    formik.values.reserveId = row.Id;
    formik.values.customerId = row.customerId;
    formik.values.date = row.date;
    formik.values.untilDate = row.untilDate;
    formik.values.paymentMethodId = row.paymentMethodId;
    formik.values.firstPayment = row.firstPayment;
    formik.values.transferidId = row.transferidId;
    const reserveItem = reserveItems.map((r) => {
      if (r.reserveId === row.Id) {
        price.total += r.totalPrice - r.discount;
        const item = items.find((i) => i.Id === r.itemId);
        const tax = taxs.find((t) => t.Id === item.taxId);
        price.tax += ((r.totalPrice - r.discount) * tax.taxValue) / 100;
        price.withHold +=
          item.statusId === 2 && r.unitPrice >= 10000
            ? 0.02 * r.totalPrice
            : item.statusId === 1 && r.unitPrice >= 3000
            ? 0.02 * r.totalPrice
            : 0;
        return {
          itemId: r.itemId,
          quantity: r.quantity,
          price: r.unitPrice,
          totalPrice: r.totalPrice,
          discount: r.discount,
          unitId: item.unitId,
          taxId: item.taxId,
          statusId: item.statusId,
        };
      }
    });
    price.grandTotal = price.tax + price.withHold + price.total;
    setItemValue(reserveItem.filter((r) => !!r));
    setNewReserverItems(reserveItems.filter((r) => r.reserveId === row.Id));
    setCustomerValue(cust);
    setPaymentValue(payment);
    setCustomerCheck(5);
    setOpen(1);
  };

  const columnreserve = [
    {
      field: "id",
      headerName: "No",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Id",
      headerName: "Reserve Id",
      headerAlign: "center",
      align: "center",
      flex: 0.7,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "userId",
      headerName: "Reserved By",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        users.find((user) => user.Id === params)?.fullName || "",
    },
    {
      field: "fullName",
      headerName: "Customer Name",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "phoneNumber",
      headerName: "Phone No",
      headerAlign: "center",
      align: "center",
      flex: 0.9,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "date",
      headerName: "Date",
      headerAlign: "center",
      align: "center",
      flex: 0.7,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        `${new Date(params).getDate()}/${
          new Date(params).getMonth() + 1
        }/${new Date(params).getFullYear()}`,
    },
    {
      field: "untilDate",
      headerName: "Unitl Date",
      headerAlign: "center",
      align: "center",
      flex: 0.75,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        `${new Date(params).getDate()}/${
          new Date(params).getMonth() + 1
        }/${new Date(params).getFullYear()}`,
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      width: parseInt(id) === 1 ? 350 : 130,
      renderCell: (params) => (
        <>
          {parseInt(id) === 1 ? (
            <div>
              <Button
                onClick={(e) => {
                  const reserveItem = reserveItems.map((r) => {
                    if (r.reserveId === params.row.Id) {
                      price.total += r.totalPrice - r.discount;
                      const item = items.find((i) => i.Id === r.itemId);
                      const tax = taxs.find((t) => t.Id === item.taxId);
                      price.tax +=
                        ((r.totalPrice - r.discount) * tax.taxValue) / 100;
                      price.withHold +=
                        item.statusId === 2 && r.unitPrice >= 10000
                          ? 0.02 * r.totalPrice
                          : item.statusId === 1 && r.unitPrice >= 3000
                          ? 0.02 * r.totalPrice
                          : 0;
                      return {
                        itemId: r.itemId,
                        quantity: r.quantity,
                        price: r.unitPrice,
                        totalPrice: r.totalPrice,
                        discount: r.discount,
                        unitId: item.unitId,
                      };
                    }
                  });
                  price.grandTotal = price.tax + price.withHold + price.total;
                  formik.values.firstPayment = params.row.firstPayment;
                  formik.values.transferidId = params.row.transferidId;
                  formik.values.paymentMethodId = params.row.paymentMethodId;
                  setItemValue(reserveItem.filter((r) => !!r));
                  const cust = customers.find(
                    (c) => c.Id === params.row.customerId
                  );
                  setCustomerValue(cust);

                  hand(params.row);

                  return 0;
                }}
                sx={{
                  width: "90px",
                  borderColor: "primary.main",
                  fontSize: {
                    xs: "0.85rem",
                    sm: "0.85rem",
                    md: "1rem",
                  },
                  padding: {
                    xs: "4px",
                    sm: "5px",
                    md: "6px",
                    lg: "6px",
                  },
                  "&:hover": {
                    backgroundColor: "primary.main",
                    color: "white",
                  },
                }}
                variant="outlined"
                color="primary"
                className="m-1"
              >
                <div
                  className="row"
                  style={{
                    marginBottom: "-10px",
                    marginLeft: "-10px",
                  }}
                >
                  <div className="col">
                    <FontAwesomeIcon
                      style={{
                        float: "right",
                        marginRight: "-1px",
                        marginTop: "2px",
                        marginBottom: "-4px",
                      }}
                      icon={faPaperPlane}
                    />
                  </div>
                  <div className="col-6">
                    <h6
                      style={{
                        float: "left",
                        marginLeft: "-10px",
                        marginTop: "-1px",
                        marginBottom: "-7px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "0.85rem",
                            sm: "0.85rem",
                            md: "1rem",
                          },
                        }}
                      >
                        Send
                      </Typography>
                    </h6>
                  </div>
                </div>
              </Button>
              <Button
                onClick={() => handleUpdate(params.row)}
                sx={{
                  width: "90px",
                  borderColor: "warning.main",
                  fontSize: {
                    xs: "0.7rem",
                    sm: "0.85rem",
                    md: "1rem",
                  },
                  padding: {
                    xs: "3px",
                    sm: "4px",
                    md: "5px",
                    lg: "6px",
                  },
                  "&:hover": {
                    backgroundColor: "warning.main",
                    color: "white",
                  },
                }}
                variant="outlined"
                color="warning"
                className="m-1"
              >
                <div
                  className="row"
                  style={{
                    marginBottom: "-10px",
                    marginLeft: "-10px",
                  }}
                >
                  <div className="col">
                    <FontAwesomeIcon
                      style={{
                        float: "right",
                        marginRight: "-1px",
                        marginTop: "2px",
                        marginBottom: "-4px",
                      }}
                      icon={faPenToSquare}
                    />
                  </div>
                  <div className="col-6">
                    <h6
                      style={{
                        float: "left",
                        marginLeft: "-10px",
                        marginTop: "-1px",
                        marginBottom: "-7px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "0.85rem",
                            sm: "0.85rem",
                            md: "1rem",
                          },
                        }}
                      >
                        Edit
                      </Typography>
                    </h6>
                  </div>
                </div>
              </Button>
              <Button
                onClick={(e) => {
                  warnings[1] = params.row.Id;
                  warnings[0] = 1;
                  setWarings([...warnings]);
                }}
                sx={{
                  width: "110px",
                  borderColor: "error.main",
                  fontSize: {
                    xs: "0.7rem",
                    sm: "0.85rem",
                    md: "1rem",
                  },
                  padding: {
                    xs: "3px",
                    sm: "4px",
                    md: "5px",
                    lg: "6px",
                  },
                  "&:hover": {
                    backgroundColor: "error.main",
                    color: "white",
                  },
                }}
                variant="outlined"
                color="error"
                className=""
              >
                <div
                  className="row "
                  style={{
                    marginBottom: "-10px",
                    marginLeft: "-10px",
                  }}
                >
                  <div className="col">
                    <FontAwesomeIcon
                      style={{
                        float: "right",
                        marginRight: "-1px",
                        marginTop: "2px",
                        marginBottom: "-4px",
                      }}
                      icon={faTrashCan}
                    />
                  </div>
                  <div className="col-8">
                    <h6
                      style={{
                        float: "left",
                        marginLeft: "-10px",
                        marginTop: "-1px",
                        marginBottom: "-7px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "0.85rem",
                            sm: "0.85rem",
                            md: "1rem",
                          },
                        }}
                      >
                        delete
                      </Typography>
                    </h6>
                  </div>
                </div>
              </Button>
            </div>
          ) : (
            <Button
              onClick={(e) => {
                const reserveItem = reserveItems.map((r) => {
                  if (r.reserveId === params.row.Id) {
                    price.total += r.totalPrice - r.discount;
                    const item = items.find((i) => i.Id === r.itemId);
                    const tax = taxs.find((t) => t.Id === item.taxId);
                    price.tax +=
                      ((r.totalPrice - r.discount) * tax.taxValue) / 100;
                    price.withHold +=
                      item.statusId === 2 && r.unitPrice >= 10000
                        ? 0.02 * r.totalPrice
                        : item.statusId === 1 && r.unitPrice >= 3000
                        ? 0.02 * r.totalPrice
                        : 0;
                    return {
                      itemId: r.itemId,
                      quantity: r.quantity,
                      price: r.unitPrice,
                      totalPrice: r.totalPrice,
                      discount: r.discount,
                    };
                  }
                });
                price.grandTotal = price.tax + price.withHold + price.total;
                formik.values.firstPayment = params.row.firstPayment;
                formik.values.transferidId = params.row.transferidId;
                formik.values.paymentMethodId = params.row.paymentMethodId;
                formik.values.Id = params.row.Id;
                setItemValue(reserveItem.filter((r) => !!r));
                const cust = customers.find(
                  (c) => c.Id === params.row.customerId
                );
                formik.values.date = params.row.date;
                formik.values.untilDate = params.row.untilDate;
                const hexCharacterManipulator = new HexCharacterManipulator();
                let charTohexa = hexCharacterManipulator.charTohexa(
                  [price.total, price.tax, price.withHold],
                  price.grandTotal
                );
                const data = `
                    Reserve ID : ${charTohexa},
                    Customer Name : ${cust.fullName},
                    Customer Phone : ${cust.primaryPhoneNumber},
                    Grand Price : ${price.grandTotal}`;
                generateQRCode(data);
                setCustomerValue(cust);
                setOpen(2);
                return 0;
              }}
              sx={{
                width: "90px",
                borderColor: "primary.main",
                fontSize: {
                  xs: "0.85rem",
                  sm: "0.85rem",
                  md: "1rem",
                },
                padding: {
                  xs: "4px",
                  sm: "5px",
                  md: "6px",
                  lg: "6px",
                },
                "&:hover": {
                  backgroundColor: "primary.main",
                  color: "white",
                },
              }}
              variant="outlined"
              color="primary"
              className="m-1"
            >
              <div
                className="row"
                style={{
                  marginBottom: "-10px",
                  marginLeft: "-10px",
                }}
              >
                <div className="col">
                  <FontAwesomeIcon
                    style={{
                      float: "right",
                      marginRight: "-1px",
                      marginTop: "2px",
                      marginBottom: "-4px",
                    }}
                    icon={faEye}
                  />
                </div>
                <div className="col-6">
                  <h6
                    style={{
                      float: "left",
                      marginLeft: "-10px",
                      marginTop: "-1px",
                      marginBottom: "-7px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "0.85rem",
                          sm: "0.85rem",
                          md: "1rem",
                        },
                      }}
                    >
                      View
                    </Typography>
                  </h6>
                </div>
              </div>
            </Button>
          )}
        </>
      ),
    },
  ];
  const rows = reserves.map((reserve, index) => {
    const customer = customers.find((c) => c.Id === reserve.customerId);

    return {
      id: index + 1,
      Id: reserve.Id,
      customerId: reserve.customerId,
      userId: reserve.userId,
      date: reserve.date,
      untilDate: reserve.untilDate,
      phoneNumber: customer.primaryPhoneNumber,
      fullName: customer.fullName,
      paymentMethodId: reserve.paymentMethodId,
      transferidId: reserve.transferidId,
      firstPayment: reserve.firstPayment,
    };
  });
  const rowsList = reservesList.map((reserve, index) => {
    const customer = customers.find((c) => c.Id === reserve.customerId);
    return {
      id: index + 1,
      Id: reserve.Id,
      customerId: reserve.customerId,
      userId: reserve.userId,
      date: reserve.date,
      untilDate: reserve.untilDate,
      phoneNumber: customer.primaryPhoneNumber,
      paymentMethodId: reserve.paymentMethodId,
      transferidId: reserve.transferidId,
      firstPayment: reserve.firstPayment,
      fullName: customer.fullName,
    };
  });
  const color = "#02f7f7";
  // if (isError) return <div>Error Try again!</div>;
  // if (isLoading)
  //   return (
  //     <div>
  //       <center>
  //         <div>
  //           <h3> Enin</h3>
  //           <PropagateLoader
  //             color={color}
  //             loading={isLoading}
  //             cssOverride={{
  //               display: "block",
  //               alignItems: "center",
  //             }}
  //             size={30}
  //             aria-label="Loading Spinner"
  //             data-testid="loader"
  //           />
  //         </div>
  //       </center>
  //     </div>
  //   );

  const handelInvoice = () => {
    let data = [];
    for (let index = itemValue.length; index < 5; index++) {
      data.push(
        <tr key={index}>
          <td>{index + 1}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      );
    }
    return data;
  };
  return (
    <ThemeProvider theme={theme}>
      <Typography
        sx={{
          fontSize: {
            xs: "12px",
            sm: "12px", //500
            md: "0.85rem", //768
            lg: "17px", //1024
          },
        }}
      >
        <div>
          <Header />
        </div>
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: "13px",
            sm: "14px", //500
            md: "16px", //768
            lg: "18px", //1024
          },
        }}
      >
        {" "}
        <div>
          <main
            className=""
            style={
              isMobile && !isUnderMobile
                ? { marginTop: "190px" }
                : isUnderMobile
                ? { marginTop: "170px" }
                : isTablet
                ? { marginTop: "133px" }
                : { marginTop: "150px" }
            }
          >
            <center>
              <div
                style={
                  isUnderMobile
                    ? {
                        minWidth: "500px",
                        overflow: "scroll",
                        marginBottom: "-5px",
                      }
                    : { maxWidth: "1100px" }
                }
                className="text-left"
              >
                <div
                  className="tab-container "
                  style={
                    isUnderMobile
                      ? { marginTop: "0", marginBottom: "-17px" }
                      : {
                          marginTop: "-20px",
                          marginBottom: "-17px",
                        }
                  }
                >
                  <Link
                    className="p-2"
                    onClick={(e) => {
                      window.location.reload();
                    }}
                    style={{
                      backgroundColor: "white",
                      borderRadius: "5px 5px 0 0",
                      border: "solid 0.05em rgb(181, 181, 181)",
                    }}
                  >
                    Reserve
                  </Link>

                  <Link className="p-2" to={`/queue_order/1`}>
                    Queue Order
                  </Link>
                </div>
                <hr />
                <div
                  className="tab-container"
                  style={
                    isUnderMobile
                      ? {
                          marginTop: "-12px",
                          marginBottom: "-8px",
                        }
                      : {
                          marginTop: "-12px",
                          marginBottom: "-5px",
                        }
                  }
                >
                  <Link
                    style={
                      parseInt(id) === 1
                        ? {
                            backgroundColor: "white",
                            borderRadius: "5px 5px 0 0",
                            border: "solid 0.05em rgb(181, 181, 181)",
                          }
                        : {}
                    }
                    className="p-2"
                    onClick={(e) => {
                      if (parseInt(id) === 1) window.location.reload();
                      else window.location.replace(`/reserve/1`);
                    }}
                  >
                    New Reserve
                  </Link>
                  <Link
                    className="p-2"
                    style={
                      parseInt(id) === 1
                        ? {}
                        : {
                            backgroundColor: "white",
                            borderRadius: "5px 5px 0 0",
                            border: "solid 0.05em rgb(181, 181, 181)",
                          }
                    }
                    onClick={(e) => {
                      if (parseInt(id) === 2) window.location.reload();
                      else window.location.replace(`/reserve/2`);
                    }}
                  >
                    Reserve List
                  </Link>
                </div>
                <Snackbar
                  open={openMessage}
                  onClose={(e) => setOpenMessage(false)}
                  autoHideDuration={6000}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  <Alert
                    variant="outlined"
                    severity="error"
                    onClose={(e) => setOpenMessage(false)}
                    sx={{ bgcolor: "background.paper" }}
                  >
                    {messages}
                  </Alert>
                </Snackbar>
                <div className="card card-info card-outline">
                  {(open === 1 || open === 0) && (
                    <>
                      <div className="card-header">
                        {open === 0 && parseInt(id) === 1 && (
                          <Button
                            sx={{
                              width: "150px",
                              borderColor: "info.main",
                              fontSize: {
                                xs: "0.7rem",
                                sm: "0.85rem",
                                md: "1rem",
                              },
                              padding: {
                                xs: "0",
                                sm: "0",
                                md: "1px",
                                lg: "3px",
                              },
                              "&:hover": {
                                backgroundColor: "info.main",
                                color: "white",
                              },
                            }}
                            variant="outlined"
                            color="info"
                            onClick={(e) => setOpen(1)}
                          >
                            <h4
                              style={{
                                float: "left",
                                marginRight: "5px",
                                marginTop: "-1",
                                marginBottom: "-2px",
                              }}
                            >
                              +
                            </h4>
                            <strong
                              style={{
                                float: "left",
                                marginTop: "0",
                                marginBottom: "-2px",
                              }}
                            >
                              Create New
                            </strong>
                          </Button>
                        )}
                        {open === 1 && (
                          <div>
                            <h5 style={{ float: "left" }}>
                              <Typography
                                sx={{
                                  fontSize: {
                                    xs: "1.3rem",
                                    sm: "1.3rem",
                                    md: "1.5rem",
                                  },
                                }}
                              >
                                New Reserve Entry
                              </Typography>
                            </h5>
                            <Button
                              onClick={handleBack}
                              sx={{
                                width: "100px",
                                borderColor: "success.main",
                                fontSize: {
                                  xs: "0.85rem",
                                  sm: "0.85rem",
                                  md: "1rem",
                                },
                                padding: {
                                  xs: "4px",
                                  sm: "5px",
                                  md: "6px",
                                  lg: "6px",
                                },
                                "&:hover": {
                                  backgroundColor: "success.main",
                                  color: "white",
                                },
                              }}
                              variant="outlined"
                              color="success"
                              type="submit"
                              className="m-1"
                              style={{ float: "right" }}
                            >
                              <div
                                className="row"
                                style={{
                                  marginBottom: "-10px",
                                  marginLeft: "-10px",
                                }}
                              >
                                <div className="col">
                                  <FontAwesomeIcon
                                    style={{
                                      float: "right",
                                      marginRight: "-1px",
                                      marginTop: "2px",
                                      marginBottom: "-4px",
                                    }}
                                    icon={faBackwardStep}
                                  />
                                </div>
                                <div className="col-7">
                                  <h6
                                    style={{
                                      float: "left",
                                      marginLeft: "-10px",
                                      marginTop: "-1px",
                                      marginBottom: "-7px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: {
                                          xs: "0.85rem",
                                          sm: "0.85rem",
                                          md: "1rem",
                                        },
                                      }}
                                    >
                                      Back
                                    </Typography>
                                  </h6>
                                </div>
                              </div>
                            </Button>
                          </div>
                        )}
                      </div>
                      <div className="card-body">
                        {open === 1 && (
                          <div>
                            <Typography
                              sx={{
                                fontSize: {
                                  xs: "0.85rem",
                                  sm: "0.85rem",
                                  md: "1rem",
                                },
                              }}
                            >
                              <div
                                className="nav-bra pl-3 ml-1 p-2 "
                                style={{
                                  backgroundColor: "rgb(235, 235, 235)",
                                }}
                              >
                                <span>Reserve / Create</span>
                              </div>
                            </Typography>
                            <hr />
                            <div>
                              <div>
                                <Form className="">
                                  <Form.Group
                                    as={Row}
                                    controlId="formHorizontalUsername"
                                  >
                                    <Col
                                      lg={4}
                                      className="mb-3 row"
                                      style={{
                                        minWidth: "360px",
                                        marginRight: "3px",
                                        maxWidth: "361px",
                                      }}
                                    >
                                      <Autocomplete
                                        {...customerData}
                                        className="col"
                                        id="controlled-demo"
                                        size="small"
                                        variant="outlined"
                                        name="customerId"
                                        onBlur={(e) => handleValidity(1)}
                                        value={customerValue}
                                        onChange={(event, newValue) => {
                                          if (!newValue?.Id) {
                                            setCustomerCheck(null);
                                            setCustomerValue(null);
                                            formik.values.customerId = "";
                                            return;
                                          }
                                          setCustomerValue(newValue);
                                          formik.values.customerId =
                                            newValue?.Id;
                                          setCustomerCheck(5);
                                        }}
                                        sx={{
                                          "& .MuiInputBase-root": {
                                            fontSize: {
                                              xs: "0.7rem",
                                              sm: "0.85rem",
                                              md: "1rem",
                                            },
                                            padding: {
                                              xs: "1px",
                                              sm: "2px",
                                              md: "3px",
                                              lg: "4px",
                                            },
                                          },
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            sx={{ width: "250px" }}
                                            error={
                                              (valids[1] === 1 &&
                                                customerCheck === null) ||
                                              formik.errors.customerId
                                            }
                                            helperText={
                                              (valids[1] === 1 &&
                                                customerCheck === null) ||
                                              formik.errors.customerId
                                                ? `Customer is required!`
                                                : ""
                                            }
                                            label={
                                              <div style={{ float: "left" }}>
                                                <FontAwesomeIcon
                                                  icon={faUser}
                                                  style={{
                                                    float: "left",
                                                    marginRight: "10px",
                                                  }}
                                                />
                                                <span>Customer</span>
                                              </div>
                                            }
                                          />
                                        )}
                                      />

                                      <Button
                                        className="col"
                                        variant="outlined"
                                        color="info"
                                        sx={{
                                          float: "left",

                                          maxWidth: "20px",
                                          height: {
                                            xs: "32px",
                                            sm: "36px",
                                            md: "40px",
                                          },
                                          borderColor: "info.main",
                                          padding: {
                                            xs: "-0",
                                            sm: "0",
                                            md: "1px",
                                            lg: "3px",
                                          },
                                          "&:hover": {
                                            backgroundColor: "info.main",
                                            color: "white",
                                          },
                                        }}
                                        onClick={handleOpenCustomer}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: {
                                              xs: "2rem",
                                              sm: "2.5rem",
                                              md: "3rem",
                                            },
                                          }}
                                          style={{
                                            marginTop: "12px",
                                          }}
                                        >
                                          +
                                        </Typography>
                                      </Button>
                                    </Col>

                                    <Col
                                      lg={3}
                                      style={{
                                        marginRight: "25px",
                                        marginLeft: "-5px",
                                      }}
                                      className=" mb-3 "
                                    >
                                      <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        disabled
                                        value={null}
                                        type="text"
                                        label={
                                          <div style={{ float: "left" }}>
                                            <FontAwesomeIcon
                                              icon={faPhone}
                                              size="1x"
                                              style={{
                                                float: "left",
                                                marginRight: "5px",
                                              }}
                                            />
                                            <span>
                                              {!!customerValue
                                                ? customerValue?.primaryPhoneNumber
                                                : "Phone"}
                                            </span>
                                          </div>
                                        }
                                        size="small"
                                        sx={{
                                          width: "250px",
                                          "& .MuiInputBase-root": {
                                            fontSize: {
                                              xs: "0.7rem",
                                              sm: "0.85rem",
                                              md: "0.9rem",
                                            },
                                            padding: {
                                              xs: "1px",
                                              sm: "2px",
                                              md: "3px",
                                              lg: "4px",
                                            },
                                          },
                                        }}
                                      />
                                    </Col>

                                    <Col lg={4} className="mb-3">
                                      <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.date}
                                        type="date"
                                        label={
                                          <div
                                            style={{
                                              float: "left",
                                              width: "70px",
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faCalendar}
                                              size="1x"
                                              style={{
                                                float: "left",
                                                marginTop: "5px",
                                                marginRight: "5px",
                                              }}
                                            />
                                            <h4>Date</h4>
                                          </div>
                                        }
                                        size="small"
                                        name="date"
                                        error={
                                          formik.touched.date &&
                                          formik.errors.date
                                        }
                                        helperText={
                                          formik.touched.date &&
                                          formik.errors.date
                                            ? `${formik.errors.date}`
                                            : ""
                                        }
                                        sx={{
                                          width: "250px",
                                          "& .MuiInputBase-root": {
                                            fontSize: {
                                              xs: "0.7rem",
                                              sm: "0.85rem",
                                              md: "0.9rem",
                                            },
                                            padding: {
                                              xs: "1px",
                                              sm: "2px",
                                              md: "3px",
                                              lg: "4px",
                                            },
                                          },
                                        }}
                                      />
                                    </Col>
                                    <Col lg={4} className="mb-3">
                                      <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.untilDate}
                                        type="date"
                                        label={
                                          <div
                                            style={{
                                              float: "left",
                                              width: "140px",
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faCalendar}
                                              size="1x"
                                              style={{
                                                float: "left",
                                                marginTop: "5px",
                                                marginRight: "5px",
                                              }}
                                            />
                                            <h4>Until Date</h4>
                                          </div>
                                        }
                                        size="small"
                                        name="untilDate"
                                        error={
                                          formik.touched.untilDate &&
                                          formik.errors.untilDate
                                        }
                                        helperText={
                                          formik.touched.untilDate &&
                                          formik.errors.untilDate
                                            ? `${formik.errors.untilDate}`
                                            : ""
                                        }
                                        sx={{
                                          width: "250px",
                                          "& .MuiInputBase-root": {
                                            fontSize: {
                                              xs: "0.7rem",
                                              sm: "0.85rem",
                                              md: "0.9rem",
                                            },
                                            padding: {
                                              xs: "1px",
                                              sm: "2px",
                                              md: "3px",
                                              lg: "4px",
                                            },
                                          },
                                        }}
                                      />
                                    </Col>
                                  </Form.Group>
                                </Form>
                              </div>
                              <Formik
                                initialValues={{
                                  itemId: "",
                                  quantity: "",
                                  price: "",
                                  discount: "",
                                  taxId: "",
                                  withHold: "",
                                  unitId: "",
                                  statusId: "",
                                }}
                                validationSchema={Yup.object().shape({
                                  quantity: Yup.number()
                                    .required("Quantity is required")
                                    .min(1, "Too Low!")
                                    .max(10000, "Too Hihg"),
                                  price: Yup.number()
                                    .required("Price is required")
                                    .min(1, "Too Low!")
                                    .max(1000000, "Too Hihg"),
                                })}
                                onSubmit={async (values) => {
                                  try {
                                    if (itemCheck === null) {
                                      return;
                                    }
                                    const checkItem = itemValue?.find(
                                      (i) => i?.itemId === itemId.Id
                                    );

                                    if (!!checkItem) {
                                      setMessages("You use the same item!");
                                      setOpenMessage(true);
                                      return;
                                    }
                                    const item = items.find(
                                      (i) => i.Id === values.itemId
                                    );
                                    if (
                                      parseInt(
                                        logicals.find(
                                          (l) => l.itemId === item.Id
                                        )?.quantity
                                      ) < values.quantity
                                    ) {
                                      setMessages(
                                        `Only available quantity is ${
                                          logicals.find(
                                            (l) => l.itemId === item.Id
                                          )?.quantity
                                        }`
                                      );
                                      setOpenMessage(true);
                                      return;
                                    }

                                    values.totalPrice =
                                      values.quantity * values.price;
                                    let tax = taxs.find(
                                      (tt) => tt.Id === itemId.taxId
                                    );
                                    price.tax =
                                      parseFloat(price.tax) +
                                      (parseFloat(values.totalPrice) -
                                        parseFloat(
                                          discountValue * values.totalPrice
                                        ) /
                                          100) *
                                        parseFloat(tax.taxValue / 100);
                                    price.total =
                                      parseFloat(price.total) +
                                      (parseFloat(values.totalPrice) -
                                        parseFloat(
                                          discountValue * values.totalPrice
                                        ) /
                                          100);

                                    setItemValue([
                                      ...itemValue,
                                      {
                                        unitId: values.unitId,
                                        itemId: values.itemId,
                                        taxId: values.taxId,
                                        quantity: values.quantity,
                                        statusId: values.statusId,
                                        price: values.price,
                                        totalPrice:
                                          values.price * values.quantity,
                                        discount:
                                          (discountValue *
                                            values.quantity *
                                            values.price) /
                                          100,
                                      },
                                    ]);
                                    let service = 0,
                                      good = 0;

                                    itemValue?.map((iv) => {
                                      if (
                                        iv.statusId === 1 &&
                                        parseFloat(iv.price) >= 3000
                                      ) {
                                        service =
                                          service +
                                          parseFloat(
                                            0.02 *
                                              parseFloat(iv.price) *
                                              parseInt(iv.quantity)
                                          );
                                      }
                                      if (
                                        iv.statusId === 2 &&
                                        parseFloat(iv.price) >= 10000
                                      ) {
                                        good =
                                          good +
                                          parseFloat(
                                            0.02 *
                                              parseFloat(iv.price) *
                                              parseInt(iv.quantity)
                                          );
                                      }
                                      return 0;
                                    });
                                    service += items.find(
                                      (i) =>
                                        i.Id === values.itemId &&
                                        i.statusId === 1 &&
                                        parseFloat(values.price) >= 3000
                                    )
                                      ? parseFloat(
                                          0.02 * parseFloat(values.price)
                                        ) * parseInt(values.quantity)
                                      : 0;
                                    good += items.find(
                                      (i) =>
                                        i.Id === values.itemId &&
                                        i.statusId === 2 &&
                                        parseFloat(values.price) >= 10000
                                    )
                                      ? parseFloat(
                                          0.02 * parseFloat(values.price)
                                        ) * parseInt(values.quantity)
                                      : 0;
                                    price.withHold = service + good;
                                    price.grandTotal =
                                      price.total + price.tax + price.withHold;
                                    setItemId(null);
                                  } catch (error) {
                                    console.log(error);
                                  } finally {
                                    values.quantity = "";
                                    values.unitId = "";
                                    values.price = "";
                                    values.untilDate = "";
                                    setDiscountValue(null);
                                  }
                                }}
                              >
                                {(props) => (
                                  <div className="">
                                    <hr />
                                    <h3>
                                      <Typography
                                        sx={{
                                          fontSize: {
                                            xs: "1.3rem",
                                            sm: "1.3rem",
                                            md: "1.5rem",
                                            lg: "2rem",
                                          },
                                        }}
                                      >
                                        Reserve Item
                                      </Typography>{" "}
                                    </h3>
                                    <hr />
                                    <Form onSubmit={props.handleSubmit}>
                                      <Form.Group
                                        as={Row}
                                        controlId="formHorizontalUsername"
                                      >
                                        <Col lg={4} className="mb-3">
                                          <Autocomplete
                                            {...itemData}
                                            id="controlled-demo"
                                            size="small"
                                            name="itemId"
                                            value={itemId}
                                            onBlur={(e) => handleValidity(1)}
                                            onChange={(event, newValue) => {
                                              if (!newValue?.Id) {
                                                setItemCheck(null);
                                                setItemId(null);
                                                props.values.unitId = "";
                                                props.values.itemId = "";
                                                props.values.taxId = "";
                                                props.values.statusId = "";
                                                return;
                                              }
                                              setItemId(newValue);
                                              props.values.unitId =
                                                newValue?.unitId;
                                              props.values.itemId =
                                                newValue?.Id;
                                              props.values.taxId =
                                                newValue.taxId;
                                              props.values.statusId =
                                                newValue.statusId;
                                              setItemCheck(5);
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                sx={{
                                                  width: "250px",
                                                  "& .MuiInputBase-root": {
                                                    fontSize: {
                                                      xs: "0.7rem",
                                                      sm: "0.85rem",
                                                      md: "0.9rem",
                                                    },
                                                    padding: {
                                                      xs: "1px",
                                                      sm: "2px",
                                                      md: "3px",
                                                      lg: "4px",
                                                    },
                                                  },
                                                }}
                                                label={
                                                  <div
                                                    style={{ float: "left" }}
                                                  >
                                                    <span>Select Item</span>
                                                  </div>
                                                }
                                                error={
                                                  valids[1] === 1 &&
                                                  itemCheck === null
                                                }
                                              />
                                            )}
                                          />
                                        </Col>
                                        <Col lg={4} className="mb-3">
                                          <TextField
                                            id="outlined-basic"
                                            label="Qty"
                                            variant="outlined"
                                            type="text"
                                            size="small"
                                            name="quantity"
                                            onChange={props.handleChange}
                                            value={props.values.quantity}
                                            onBlur={props.handleBlur}
                                            error={
                                              props.touched.quantity &&
                                              props.errors.quantity
                                            }
                                            helperText={
                                              props.touched.quantity &&
                                              props.errors.quantity
                                                ? `${props.errors.quantity}`
                                                : ""
                                            }
                                            sx={{
                                              width: "250px",
                                              "& .MuiInputBase-root": {
                                                fontSize: {
                                                  xs: "0.7rem",
                                                  sm: "0.85rem",
                                                  md: "0.9rem",
                                                },
                                                padding: {
                                                  xs: "1px",
                                                  sm: "2px",
                                                  md: "3px",
                                                  lg: "4px",
                                                },
                                              },
                                            }}
                                          />
                                        </Col>
                                        <Col lg={4} className="mb-3">
                                          <TextField
                                            id="outlined-basic"
                                            label="Price"
                                            variant="outlined"
                                            type="text"
                                            size="small"
                                            name="price"
                                            onChange={props.handleChange}
                                            value={props.values.price}
                                            onBlur={props.handleBlur}
                                            error={
                                              props.touched.price &&
                                              props.errors.price
                                            }
                                            helperText={
                                              props.touched.price &&
                                              props.errors.price
                                                ? `${props.errors.price}`
                                                : ""
                                            }
                                            sx={{
                                              width: "250px",
                                              "& .MuiInputBase-root": {
                                                fontSize: {
                                                  xs: "0.7rem",
                                                  sm: "0.85rem",
                                                  md: "0.9rem",
                                                },
                                                padding: {
                                                  xs: "1px",
                                                  sm: "2px",
                                                  md: "3px",
                                                  lg: "4px",
                                                },
                                              },
                                            }}
                                          />
                                        </Col>
                                        <Col lg={4} className="mb-3">
                                          <TextField
                                            id="outlined-basic"
                                            label="Discount (%)"
                                            variant="outlined"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            type="text"
                                            size="small"
                                            name="discount"
                                            onChange={(event) => {
                                              if (event.target.value === "") {
                                                setDiscountValue(null);
                                                return;
                                              }

                                              if (
                                                parseFloat(event.target.value) <
                                                  0 ||
                                                parseFloat(event.target.value) >
                                                  20
                                              ) {
                                                setDiscountValue(null);
                                                return;
                                              }
                                              setDiscountValue(
                                                event.target.value
                                              );
                                            }}
                                            value={
                                              discountValue === null
                                                ? ""
                                                : discountValue
                                            }
                                            onBlur={props.handleBlur}
                                            error={
                                              props.touched.discount &&
                                              props.errors.discount
                                            }
                                            helperText={
                                              props.touched.discount &&
                                              props.errors.discount
                                                ? `${props.errors.discount}`
                                                : ""
                                            }
                                            sx={{
                                              width: "250px",
                                              "& .MuiInputBase-root": {
                                                fontSize: {
                                                  xs: "0.7rem",
                                                  sm: "0.85rem",
                                                  md: "0.9rem",
                                                },
                                                padding: {
                                                  xs: "1px",
                                                  sm: "2px",
                                                  md: "3px",
                                                  lg: "4px",
                                                },
                                              },
                                            }}
                                          />
                                        </Col>
                                        <Col lg={4} className="mb-3">
                                          <TextField
                                            id="outlined-basic"
                                            label="Discount (Birr)"
                                            variant="outlined"
                                            type="number"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            size="small"
                                            name="discount"
                                            onChange={(event) => {
                                              if (event.target.value === "") {
                                                setDiscountValue(null);
                                                return;
                                              }
                                              let count =
                                                (parseFloat(
                                                  event.target.value
                                                ) *
                                                  100) /
                                                (props.values.quantity *
                                                  props.values.price);
                                              if (count < 0 || count > 20) {
                                                setDiscountValue(null);
                                                return;
                                              }
                                              setDiscountValue(count);
                                            }}
                                            value={
                                              discountValue === null
                                                ? ""
                                                : (
                                                    (discountValue *
                                                      props.values.quantity *
                                                      props.values.price) /
                                                    100
                                                  ).toFixed(1)
                                            }
                                            onBlur={props.handleBlur}
                                            error={
                                              props.touched.discount &&
                                              props.errors.discount
                                            }
                                            helperText={
                                              props.touched.discount &&
                                              props.errors.discount
                                                ? `${props.errors.discount}`
                                                : ""
                                            }
                                            sx={{
                                              width: "250px",
                                              "& .MuiInputBase-root": {
                                                fontSize: {
                                                  xs: "0.7rem",
                                                  sm: "0.85rem",
                                                  md: "0.9rem",
                                                },
                                                padding: {
                                                  xs: "1px",
                                                  sm: "2px",
                                                  md: "3px",
                                                  lg: "4px",
                                                },
                                              },
                                            }}
                                          />
                                        </Col>
                                        <Col lg={4} className="mb-3">
                                          <Button
                                            sx={{
                                              width: "200px",
                                              borderColor: "primary.main",
                                              fontSize: {
                                                xs: "0.85rem",
                                                sm: "0.85rem",
                                                md: "1rem",
                                              },
                                              padding: {
                                                xs: "4px",
                                                sm: "5px",
                                                md: "6px",
                                                lg: "6px",
                                              },
                                              "&:hover": {
                                                backgroundColor: "primary.main",
                                                color: "white",
                                              },
                                            }}
                                            variant="outlined"
                                            color="primary"
                                            type="submit"
                                          >
                                            <h4
                                              style={{
                                                float: "left",
                                                marginRight: "5px",
                                                marginTop: "-1px",
                                                marginBottom: "-2px",
                                              }}
                                            >
                                              +
                                            </h4>
                                            <strong
                                              style={{
                                                float: "left",
                                                marginTop: "0",
                                                marginBottom: "-2px",
                                              }}
                                            >
                                              Add to list
                                            </strong>
                                          </Button>
                                        </Col>
                                      </Form.Group>
                                    </Form>
                                    <div style={{ overflow: "scroll" }}>
                                      <div style={{ minWidth: "1000px" }}>
                                        <table className="summary-table ">
                                          <thead className="bg-info">
                                            <tr>
                                              <th>Id</th>
                                              <th>Code</th>
                                              <th>Desc</th>
                                              <th>Model</th>
                                              <th>Unit</th>
                                              <th>U.Price</th> <th>Qty</th>
                                              <th>Discount</th>
                                              <th>T.Price</th>
                                              <th style={{ minWidth: "230px" }}>
                                                Action
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {itemValue.map((request, index) => (
                                              <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                  {items.map((item) =>
                                                    item.Id ===
                                                    parseInt(request.itemId)
                                                      ? item.code
                                                      : ""
                                                  )}
                                                </td>

                                                <td>
                                                  {items.map((item) =>
                                                    item.Id ===
                                                    parseInt(request.itemId)
                                                      ? item.description
                                                      : ""
                                                  )}
                                                </td>
                                                <td>
                                                  {items.map((item) =>
                                                    item.Id ===
                                                    parseInt(request.itemId)
                                                      ? models.find(
                                                          (model) =>
                                                            model.Id ===
                                                            item.modelId
                                                        )?.modelName
                                                      : ""
                                                  )}
                                                </td>
                                                <td>
                                                  {
                                                    units.find(
                                                      (unit) =>
                                                        unit.Id ===
                                                        request.unitId
                                                    )?.unitName
                                                  }
                                                </td>
                                                <td>
                                                  {openUpdate &&
                                                  request.itemId ===
                                                    update.itemId ? (
                                                    <input
                                                      type="number"
                                                      minLength={1}
                                                      maxLength={20}
                                                      className="form-control"
                                                      required
                                                      style={{ width: "100px" }}
                                                      value={update.price}
                                                      onChange={(e) => {
                                                        if (
                                                          parseInt(
                                                            e.target.value
                                                          ) < 1 ||
                                                          parseInt(
                                                            e.target.value
                                                          ) > 1000000
                                                        ) {
                                                          setUpdate((prev) => ({
                                                            ...prev,
                                                            price: 1,
                                                          }));
                                                        } else {
                                                          setUpdate((prev) => ({
                                                            ...prev,
                                                            price: parseInt(
                                                              e.target.value
                                                            ),
                                                          }));
                                                        }
                                                      }}
                                                    />
                                                  ) : (
                                                    parseFloat(
                                                      request.price
                                                    ).toLocaleString()
                                                  )}
                                                </td>
                                                <td>
                                                  {openUpdate &&
                                                  request.itemId ===
                                                    update.itemId ? (
                                                    <input
                                                      type="number"
                                                      minLength={1}
                                                      maxLength={1000}
                                                      className="form-control"
                                                      required
                                                      style={{ width: "10vw" }}
                                                      value={update.quantity}
                                                      onChange={(e) => {
                                                        if (
                                                          parseInt(
                                                            e.target.value
                                                          ) < 1 ||
                                                          parseInt(
                                                            e.target.value
                                                          ) > 10000
                                                        ) {
                                                          setUpdate((prev) => ({
                                                            ...prev,
                                                            quantity: 1,
                                                          }));
                                                        } else {
                                                          setUpdate((prev) => ({
                                                            ...prev,
                                                            quantity: parseInt(
                                                              e.target.value
                                                            ),
                                                          }));
                                                        }
                                                      }}
                                                    />
                                                  ) : (
                                                    parseFloat(
                                                      request.quantity
                                                    ).toLocaleString()
                                                  )}
                                                </td>
                                                <td>
                                                  {openUpdate &&
                                                  request.itemId ===
                                                    update.itemId ? (
                                                    <input
                                                      type="number"
                                                      minLength={1}
                                                      maxLength={20}
                                                      className="form-control"
                                                      required
                                                      style={{ width: "100px" }}
                                                      value={update.discount}
                                                      onChange={(e) => {
                                                        if (
                                                          e.target.value === ""
                                                        ) {
                                                          setUpdate((prev) => ({
                                                            ...prev,
                                                            discount:
                                                              parseInt(0),
                                                          }));
                                                          return;
                                                        }
                                                        let count =
                                                          (parseFloat(
                                                            e.target.value
                                                          ) *
                                                            100) /
                                                          (update.quantity *
                                                            update.price);
                                                        if (
                                                          count < 0 ||
                                                          count > 20
                                                        ) {
                                                          setUpdate((prev) => ({
                                                            ...prev,
                                                            discount: 0,
                                                          }));
                                                        } else {
                                                          setUpdate((prev) => ({
                                                            ...prev,
                                                            discount: parseInt(
                                                              e.target.value
                                                            ),
                                                          }));
                                                        }
                                                      }}
                                                    />
                                                  ) : (
                                                    parseFloat(
                                                      request.discount
                                                    ).toFixed(2)
                                                  )}
                                                </td>
                                                <td>
                                                  {openUpdate &&
                                                  request.itemId ===
                                                    update.itemId ? (
                                                    <input
                                                      type="number"
                                                      minLength={1}
                                                      readOnly
                                                      maxLength={20}
                                                      className="form-control"
                                                      required
                                                      style={{ width: "120px" }}
                                                      value={
                                                        update.price === "" &&
                                                        update.quantity === ""
                                                          ? request.totalPrice
                                                          : update.price === ""
                                                          ? request.price *
                                                            update.quantity
                                                          : update.quantity ===
                                                            ""
                                                          ? update.price *
                                                            request.quantity
                                                          : update.price *
                                                            update.quantity
                                                      }
                                                    />
                                                  ) : (
                                                    request.totalPrice.toLocaleString()
                                                  )}
                                                </td>

                                                <td>
                                                  {(request.itemId !==
                                                    update.itemId ||
                                                    !openUpdate) && (
                                                    <div>
                                                      <Button
                                                        sx={{
                                                          width: "90px",
                                                          borderColor:
                                                            "warning.main",
                                                          fontSize: {
                                                            xs: "0.7rem",
                                                            sm: "0.85rem",
                                                            md: "1rem",
                                                          },
                                                          padding: {
                                                            xs: "3px",
                                                            sm: "4px",
                                                            md: "5px",
                                                            lg: "6px",
                                                          },
                                                          "&:hover": {
                                                            backgroundColor:
                                                              "warning.main",
                                                            color: "white",
                                                          },
                                                        }}
                                                        className="mr-3"
                                                        variant="outlined"
                                                        color="warning"
                                                        onClick={(e) => {
                                                          setOpenUpdate(true);
                                                          setUpdate({
                                                            itemId:
                                                              request.itemId,
                                                            quantity:
                                                              request.quantity,
                                                            price:
                                                              request.price,
                                                            discount:
                                                              request.discount,
                                                            totalPrice:
                                                              request.totalPrice,
                                                          });
                                                        }}
                                                      >
                                                        <div
                                                          className="row"
                                                          style={{
                                                            marginBottom:
                                                              "-10px",
                                                            marginLeft: "-10px",
                                                          }}
                                                        >
                                                          <div className="col">
                                                            <FontAwesomeIcon
                                                              style={{
                                                                float: "right",
                                                                marginRight:
                                                                  "-1px",
                                                                marginTop:
                                                                  "2px",
                                                                marginBottom:
                                                                  "-4px",
                                                              }}
                                                              icon={
                                                                faPenToSquare
                                                              }
                                                            />
                                                          </div>
                                                          <div className="col-6">
                                                            <h6
                                                              style={{
                                                                float: "left",
                                                                marginLeft:
                                                                  "-10px",
                                                                marginTop:
                                                                  "-1px",
                                                                marginBottom:
                                                                  "-7px",
                                                              }}
                                                            >
                                                              <Typography
                                                                sx={{
                                                                  fontSize: {
                                                                    xs: "0.85rem",
                                                                    sm: "0.85rem",
                                                                    md: "1rem",
                                                                  },
                                                                }}
                                                              >
                                                                Edit
                                                              </Typography>
                                                            </h6>
                                                          </div>
                                                        </div>
                                                      </Button>
                                                      <Button
                                                        sx={{
                                                          width: "110px",
                                                          borderColor:
                                                            "error.main",
                                                          fontSize: {
                                                            xs: "0.7rem",
                                                            sm: "0.85rem",
                                                            md: "1rem",
                                                          },
                                                          padding: {
                                                            xs: "3px",
                                                            sm: "4px",
                                                            md: "5px",
                                                            lg: "6px",
                                                          },
                                                          "&:hover": {
                                                            backgroundColor:
                                                              "error.main",
                                                            color: "white",
                                                          },
                                                        }}
                                                        variant="outlined"
                                                        color="error"
                                                        onClick={(e) => {
                                                          const newItem =
                                                            itemValue.find(
                                                              (i) =>
                                                                parseInt(
                                                                  i.itemId
                                                                ) ===
                                                                parseInt(
                                                                  request.itemId
                                                                )
                                                            );
                                                          const tax = taxs.find(
                                                            (t) =>
                                                              t.Id ===
                                                              newItem?.taxId
                                                          );

                                                          const priceVat =
                                                            parseFloat(
                                                              newItem?.totalPrice -
                                                                newItem?.discount
                                                            ) *
                                                            parseFloat(
                                                              tax?.taxValue /
                                                                100
                                                            );
                                                          const newItemValue =
                                                            itemValue?.filter(
                                                              (i) =>
                                                                parseInt(
                                                                  i.itemId
                                                                ) !==
                                                                parseInt(
                                                                  request.itemId
                                                                )
                                                            );
                                                          setItemValue(
                                                            newItemValue
                                                          );

                                                          price.total =
                                                            parseFloat(
                                                              price.total
                                                            ) -
                                                            parseFloat(
                                                              newItem.totalPrice -
                                                                newItem.discount
                                                            );
                                                          price.tax =
                                                            parseFloat(
                                                              price.tax
                                                            ) - priceVat;
                                                          price.withHold =
                                                            parseFloat(
                                                              price.withHold
                                                            ) -
                                                            (newItem.statusId ===
                                                              1 &&
                                                            parseFloat(
                                                              newItem.price
                                                            ) >= 3000
                                                              ? parseFloat(
                                                                  newItem.price
                                                                ) *
                                                                0.02 *
                                                                parseFloat(
                                                                  newItem.quantity
                                                                )
                                                              : newItem.statusId ===
                                                                  2 &&
                                                                parseFloat(
                                                                  newItem.price
                                                                ) >= 10000
                                                              ? parseFloat(
                                                                  newItem.price
                                                                ) *
                                                                0.02 *
                                                                parseFloat(
                                                                  newItem.quantity
                                                                )
                                                              : 0);
                                                          price.grandTotal =
                                                            price.total +
                                                            price.tax +
                                                            price.withHold;
                                                        }}
                                                      >
                                                        <div
                                                          className="row"
                                                          style={{
                                                            marginBottom:
                                                              "-10px",
                                                            marginLeft: "-10px",
                                                          }}
                                                        >
                                                          <div className="col">
                                                            <FontAwesomeIcon
                                                              style={{
                                                                float: "right",
                                                                marginRight:
                                                                  "-1px",
                                                                marginTop:
                                                                  "2px",
                                                                marginBottom:
                                                                  "-4px",
                                                              }}
                                                              icon={faTrashCan}
                                                            />
                                                          </div>
                                                          <div className="col-8">
                                                            <h6
                                                              style={{
                                                                float: "left",
                                                                marginLeft:
                                                                  "-10px",
                                                                marginTop:
                                                                  "-1px",
                                                                marginBottom:
                                                                  "-7px",
                                                              }}
                                                            >
                                                              <Typography
                                                                sx={{
                                                                  fontSize: {
                                                                    xs: "0.85rem",
                                                                    sm: "0.85rem",
                                                                    md: "1rem",
                                                                  },
                                                                }}
                                                              >
                                                                Delete
                                                              </Typography>
                                                            </h6>
                                                          </div>
                                                        </div>
                                                      </Button>
                                                    </div>
                                                  )}

                                                  {openUpdate &&
                                                    request.itemId ===
                                                      update.itemId && (
                                                      <div>
                                                        <Button
                                                          sx={{
                                                            width: "90px",
                                                            borderColor:
                                                              "success.main",
                                                            fontSize: {
                                                              xs: "0.7rem",
                                                              sm: "0.85rem",
                                                              md: "1rem",
                                                            },
                                                            padding: {
                                                              xs: "3px",
                                                              sm: "4px",
                                                              md: "5px",
                                                              lg: "6px",
                                                            },
                                                            "&:hover": {
                                                              backgroundColor:
                                                                "success.main",
                                                              color: "white",
                                                            },
                                                          }}
                                                          variant="outlined"
                                                          color="success"
                                                          className="mr-3"
                                                          onClick={(e) => {
                                                            const item =
                                                              items.find(
                                                                (i) =>
                                                                  i.Id ===
                                                                  update.itemId
                                                              );
                                                            if (
                                                              parseInt(
                                                                logicals.find(
                                                                  (l) =>
                                                                    l.itemId ===
                                                                    item.Id
                                                                )?.quantity
                                                              ) <
                                                                update.quantity &&
                                                              item.statusId ===
                                                                2
                                                            ) {
                                                              setMessages(
                                                                `Only available quantity is ${
                                                                  logicals.find(
                                                                    (l) =>
                                                                      l.itemId ===
                                                                      item.Id
                                                                  )?.quantity
                                                                }`
                                                              );
                                                              setOpenMessage(
                                                                true
                                                              );
                                                              return;
                                                            }
                                                            if (
                                                              parseFloat(
                                                                update.discount
                                                              ) >
                                                              parseFloat(
                                                                update.quantity
                                                              ) *
                                                                parseFloat(
                                                                  update.price
                                                                ) *
                                                                0.2
                                                            ) {
                                                              setMessages(
                                                                `Invalid Discount`
                                                              );
                                                              setOpenMessage(
                                                                true
                                                              );
                                                              return;
                                                            }
                                                            itemValue[
                                                              index
                                                            ].quantity =
                                                              update.quantity;
                                                            itemValue[
                                                              index
                                                            ].price =
                                                              update.price;

                                                            itemValue[
                                                              index
                                                            ].discount =
                                                              update.discount;

                                                            itemValue[
                                                              index
                                                            ].totalPrice =
                                                              itemValue[index]
                                                                .quantity *
                                                              itemValue[index]
                                                                .price;
                                                            setItemValue([
                                                              ...itemValue,
                                                            ]);
                                                            update.itemId = "";
                                                            update.price = "";
                                                            update.quantity =
                                                              "";
                                                            price.total =
                                                              price.withHold =
                                                              price.tax =
                                                                0;

                                                            let service = 0,
                                                              good = 0;
                                                            itemValue.map(
                                                              (iv) => {
                                                                const tax =
                                                                  taxs.find(
                                                                    (t) =>
                                                                      t.Id ===
                                                                      iv.taxId
                                                                  );
                                                                if (
                                                                  iv.statusId ===
                                                                    1 &&
                                                                  parseFloat(
                                                                    iv.price
                                                                  ) >= 3000
                                                                ) {
                                                                  service =
                                                                    service +
                                                                    parseFloat(
                                                                      0.02 *
                                                                        parseFloat(
                                                                          iv.price
                                                                        ) *
                                                                        parseInt(
                                                                          iv.quantity
                                                                        )
                                                                    );
                                                                }
                                                                if (
                                                                  iv.statusId ===
                                                                    2 &&
                                                                  parseFloat(
                                                                    iv.price
                                                                  ) >= 10000
                                                                ) {
                                                                  good =
                                                                    good +
                                                                    parseFloat(
                                                                      0.02 *
                                                                        parseFloat(
                                                                          iv.price
                                                                        ) *
                                                                        parseInt(
                                                                          iv.quantity
                                                                        )
                                                                    );
                                                                }
                                                                price.tax =
                                                                  parseFloat(
                                                                    price.tax
                                                                  ) +
                                                                  (parseFloat(
                                                                    iv.totalPrice
                                                                  ) -
                                                                    parseFloat(
                                                                      iv.discount
                                                                    )) *
                                                                    parseFloat(
                                                                      parseInt(
                                                                        tax?.taxValue
                                                                      ) / 100
                                                                    );
                                                                price.total =
                                                                  parseFloat(
                                                                    price.total
                                                                  ) +
                                                                  parseFloat(
                                                                    iv.totalPrice
                                                                  ) -
                                                                  parseFloat(
                                                                    iv.discount
                                                                  );
                                                              }
                                                            );
                                                            price.withHold =
                                                              good + service;
                                                            price.grandTotal =
                                                              price.total +
                                                              price.tax +
                                                              price.withHold;
                                                            setOpenUpdate(
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          <div
                                                            className="row"
                                                            style={{
                                                              marginBottom:
                                                                "-10px",
                                                              marginLeft:
                                                                "-10px",
                                                            }}
                                                          >
                                                            <div className="col">
                                                              <FontAwesomeIcon
                                                                style={{
                                                                  float:
                                                                    "right",
                                                                  marginRight:
                                                                    "-1px",
                                                                  marginTop:
                                                                    "2px",
                                                                  marginBottom:
                                                                    "-4px",
                                                                }}
                                                                icon={
                                                                  faFloppyDisk
                                                                }
                                                              />
                                                            </div>
                                                            <div className="col-6">
                                                              <h6
                                                                style={{
                                                                  float: "left",
                                                                  marginLeft:
                                                                    "-10px",
                                                                  marginTop:
                                                                    "-1px",
                                                                  marginBottom:
                                                                    "-7px",
                                                                }}
                                                              >
                                                                <Typography
                                                                  sx={{
                                                                    fontSize: {
                                                                      xs: "0.85rem",
                                                                      sm: "0.85rem",
                                                                      md: "1rem",
                                                                    },
                                                                  }}
                                                                >
                                                                  Save
                                                                </Typography>
                                                              </h6>
                                                            </div>
                                                          </div>
                                                        </Button>
                                                        <Button
                                                          sx={{
                                                            width: "100px",
                                                            borderColor:
                                                              "error.main",
                                                            fontSize: {
                                                              xs: "0.7rem",
                                                              sm: "0.85rem",
                                                              md: "1rem",
                                                            },
                                                            padding: {
                                                              xs: "3px",
                                                              sm: "4px",
                                                              md: "5px",
                                                              lg: "6px",
                                                            },
                                                            "&:hover": {
                                                              backgroundColor:
                                                                "error.main",
                                                              color: "white",
                                                            },
                                                          }}
                                                          variant="outlined"
                                                          color="error"
                                                          onClick={(e) => {
                                                            setOpenUpdate(
                                                              false
                                                            );
                                                            setUpdate({
                                                              itemId: "",
                                                              price: "",
                                                              quantity: "",
                                                            });
                                                          }}
                                                        >
                                                          <div
                                                            className="row"
                                                            style={{
                                                              marginBottom:
                                                                "-10px",
                                                              marginLeft:
                                                                "-10px",
                                                            }}
                                                          >
                                                            <div className="col">
                                                              <FontAwesomeIcon
                                                                style={{
                                                                  float:
                                                                    "right",
                                                                  marginRight:
                                                                    "-1px",
                                                                  marginTop:
                                                                    "2px",
                                                                  marginBottom:
                                                                    "-4px",
                                                                }}
                                                                icon={faXmark}
                                                              />
                                                            </div>
                                                            <div className="col-8">
                                                              <h6
                                                                style={{
                                                                  float: "left",
                                                                  marginLeft:
                                                                    "-10px",
                                                                  marginTop:
                                                                    "-1px",
                                                                  marginBottom:
                                                                    "-7px",
                                                                }}
                                                              >
                                                                <Typography
                                                                  sx={{
                                                                    fontSize: {
                                                                      xs: "0.85rem",
                                                                      sm: "0.85rem",
                                                                      md: "1rem",
                                                                    },
                                                                  }}
                                                                >
                                                                  Cancle
                                                                </Typography>
                                                              </h6>
                                                            </div>
                                                          </div>
                                                        </Button>
                                                      </div>
                                                    )}
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                        <div className="row">
                                          <div className="col"></div>
                                          <div
                                            className="col mt-1"
                                            style={{
                                              textAlign: "right",
                                              marginBottom: "20px",
                                              float: "right",
                                            }}
                                          >
                                            <Form.Group
                                              as={Row}
                                              controlId="formHorizontalUsername"
                                              style={{ width: "500px" }}
                                            >
                                              <Col>
                                                <Form.Label>
                                                  Total :{" "}
                                                </Form.Label>
                                              </Col>
                                              <Col>
                                                <Form.Control
                                                  id="outlined-basic"
                                                  disabled
                                                  label="Qty"
                                                  variant="outlined"
                                                  type="text"
                                                  size="small"
                                                  name="qty"
                                                  value={price.total.toLocaleString()}
                                                  style={{ width: "150px" }}
                                                />
                                              </Col>
                                            </Form.Group>
                                            <Form.Group
                                              as={Row}
                                              controlId="formHorizontalUsername"
                                              style={{ width: "500px" }}
                                            >
                                              <Col>
                                                <Form.Label>Vat : </Form.Label>
                                              </Col>
                                              <Col>
                                                <Form.Control
                                                  id="outlined-basic"
                                                  disabled
                                                  label="Qty"
                                                  variant="outlined"
                                                  type="text"
                                                  size="small"
                                                  name="qty"
                                                  value={price.tax.toLocaleString()}
                                                  style={{ width: "150px" }}
                                                />
                                              </Col>
                                            </Form.Group>
                                            <Form.Group
                                              as={Row}
                                              controlId="formHorizontalUsername"
                                              style={{ width: "500px" }}
                                            >
                                              <Col>
                                                <Form.Label>
                                                  WithHold :{" "}
                                                </Form.Label>
                                              </Col>
                                              <Col>
                                                <Form.Control
                                                  id="outlined-basic"
                                                  disabled
                                                  label="Qty"
                                                  variant="outlined"
                                                  type="text"
                                                  size="small"
                                                  name="qty"
                                                  value={price.withHold.toLocaleString()}
                                                  style={{ width: "150px" }}
                                                />
                                              </Col>
                                            </Form.Group>

                                            <Form.Group
                                              as={Row}
                                              controlId="formHorizontalUsername"
                                              style={{ width: "500px" }}
                                            >
                                              <Col>
                                                <Form.Label>
                                                  Grand Total :{" "}
                                                </Form.Label>
                                              </Col>
                                              <Col>
                                                <Form.Control
                                                  id="outlined-basic"
                                                  disabled
                                                  label="Qty"
                                                  variant="outlined"
                                                  type="text"
                                                  size="small"
                                                  name="qty"
                                                  value={price.grandTotal.toLocaleString()}
                                                  style={{ width: "150px" }}
                                                />
                                              </Col>
                                            </Form.Group>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Formik>
                            </div>
                            <Form className="mt-3">
                              <Form.Group as={Row}>
                                <Col lg={4} className="mb-3">
                                  <Autocomplete
                                    {...paymentData}
                                    id="controlled-demo1"
                                    size="small"
                                    variant="outlined"
                                    name="paymentMethodId"
                                    onBlur={(e) => handleValidity(2)}
                                    value={paymentValue}
                                    onChange={(event, newValue) => {
                                      if (!newValue?.Id) {
                                        setPaymentValue(null);
                                        return;
                                      }
                                      setPaymentValue(newValue);
                                      formik.values.paymentMethodId =
                                        newValue?.Id;
                                    }}
                                    sx={{
                                      "& .MuiInputBase-root": {
                                        fontSize: {
                                          xs: "0.7rem",
                                          sm: "0.85rem",
                                          md: "0.9rem",
                                        },
                                        padding: {
                                          xs: "1px",
                                          sm: "2px",
                                          md: "3px",
                                          lg: "4px",
                                        },
                                      },
                                    }}
                                    renderInput={(row) => (
                                      <TextField
                                        {...row}
                                        sx={{ width: "250px" }}
                                        error={
                                          (valids[2] === 1 &&
                                            paymentValue === null) ||
                                          formik.errors.paymentMethodId
                                        }
                                        helperText={
                                          (valids[2] === 1 &&
                                            paymentValue === null) ||
                                          formik.errors.paymentMethodId
                                            ? formik.errors.paymentMethodId
                                            : paymentValue?.account || ""
                                        }
                                        label={
                                          <div style={{ float: "left" }}>
                                            <FontAwesomeIcon
                                              icon={faWallet}
                                              style={{
                                                float: "left",
                                                marginRight: "10px",
                                                fontSize: 24,
                                              }}
                                            />
                                            <span>Bank Name</span>
                                          </div>
                                        }
                                      />
                                    )}
                                  />
                                </Col>
                                <Col lg={4} className="mb-3">
                                  <TextField
                                    id="outlined-basic"
                                    label={
                                      <div style={{ float: "left" }}>
                                        <FontAwesomeIcon
                                          icon={faMoneyBillTransfer}
                                          style={{
                                            float: "left",
                                            marginRight: "10px",
                                            fontSize: 24,
                                          }}
                                        />
                                        <span>Transfer ID</span>
                                      </div>
                                    }
                                    variant="outlined"
                                    type="text"
                                    size="small"
                                    name="transferidId"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.transferidId}
                                    error={
                                      formik.errors.transferidId &&
                                      formik.touched.transferidId
                                    }
                                    helperText={
                                      formik.errors.transferidId &&
                                      formik.touched.transferidId
                                        ? formik.errors.transferidId
                                        : ""
                                    }
                                    sx={{
                                      width: "250px",
                                      "& .MuiInputBase-root": {
                                        fontSize: {
                                          xs: "0.7rem",
                                          sm: "0.85rem",
                                          md: "0.9rem",
                                        },
                                        padding: {
                                          xs: "1px",
                                          sm: "2px",
                                          md: "3px",
                                          lg: "4px",
                                        },
                                      },
                                    }}
                                  />
                                </Col>
                                <Col lg={4} className="mb-3">
                                  <TextField
                                    id="outlined-basic"
                                    label={
                                      <div style={{ float: "left" }}>
                                        <FontAwesomeIcon
                                          icon={faMoneyBill1}
                                          style={{
                                            float: "left",
                                            marginRight: "10px",
                                            fontSize: 24,
                                          }}
                                        />
                                        <span>First Payment</span>
                                      </div>
                                    }
                                    variant="outlined"
                                    type="text"
                                    size="small"
                                    name="firstPayment"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.firstPayment}
                                    error={
                                      formik.errors.firstPayment &&
                                      formik.touched.firstPayment
                                    }
                                    helperText={
                                      formik.errors.firstPayment &&
                                      formik.touched.firstPayment
                                        ? formik.errors.firstPayment
                                        : ""
                                    }
                                    sx={{
                                      width: "250px",
                                      "& .MuiInputBase-root": {
                                        fontSize: {
                                          xs: "0.7rem",
                                          sm: "0.85rem",
                                          md: "0.9rem",
                                        },
                                        padding: {
                                          xs: "1px",
                                          sm: "2px",
                                          md: "3px",
                                          lg: "4px",
                                        },
                                      },
                                    }}
                                  />
                                </Col>
                              </Form.Group>
                            </Form>
                            <center className="mt-3">
                              <div>
                                <Row className="mb-3">
                                  <Col>
                                    <Button
                                      sx={{
                                        width: "150px",
                                        borderColor: "primary.main",
                                        fontSize: {
                                          xs: "0.7rem",
                                          sm: "0.85rem",
                                          md: "1rem",
                                        },
                                        padding: {
                                          xs: "3px",
                                          sm: "4px",
                                          md: "5px",
                                          lg: "6px",
                                        },
                                        "&:hover": {
                                          backgroundColor: "primary.main",
                                          color: "white",
                                        },
                                      }}
                                      variant="outlined"
                                      color="primary"
                                      onClick={clearData}
                                    >
                                      <div
                                        className="row"
                                        style={{
                                          marginBottom: "-10px",
                                          marginLeft: "-15px",
                                        }}
                                      >
                                        <div className="col-2">
                                          <FontAwesomeIcon
                                            style={{
                                              float: "left",
                                              marginLeft: "0px",
                                              marginTop: "2px",
                                              marginBottom: "-4px",
                                            }}
                                            icon={faBackwardStep}
                                          />
                                        </div>
                                        <div className="col-10">
                                          <h6
                                            style={{
                                              float: "left",
                                              marginLeft: "-5px",
                                              marginTop: "-1px",
                                              marginBottom: "-7px",
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: {
                                                  xs: "0.85rem",
                                                  sm: "0.85rem",
                                                  md: "1rem",
                                                },
                                              }}
                                            >
                                              Back to list
                                            </Typography>
                                          </h6>
                                        </div>
                                      </div>
                                    </Button>
                                  </Col>
                                  <Col>
                                    <Form onSubmit={formik.handleSubmit}>
                                      <Button
                                        sx={{
                                          width: "150px",
                                          borderColor: "primary.main",
                                          fontSize: {
                                            xs: "0.7rem",
                                            sm: "0.85rem",
                                            md: "1rem",
                                          },
                                          padding: {
                                            xs: "3px",
                                            sm: "4px",
                                            md: "5px",
                                            lg: "6px",
                                          },
                                          "&:hover": {
                                            backgroundColor: "primary.main",
                                            color: "white",
                                          },
                                        }}
                                        variant="outlined"
                                        color="primary"
                                        type="submit"
                                      >
                                        <div
                                          className="row"
                                          style={{
                                            marginBottom: "-10px",
                                            marginLeft: "-15px",
                                          }}
                                        >
                                          <div className="col-3">
                                            <FontAwesomeIcon
                                              style={{
                                                float: "left",
                                                marginLeft: "0px",
                                                marginTop: "2px",
                                                marginBottom: "-4px",
                                              }}
                                              icon={faFloppyDisk}
                                            />
                                          </div>
                                          <div className="col-9">
                                            <h6
                                              style={{
                                                float: "left",
                                                marginLeft: "-5px",
                                                marginTop: "-1px",
                                                marginBottom: "-7px",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontSize: {
                                                    xs: "0.85rem",
                                                    sm: "0.85rem",
                                                    md: "1rem",
                                                  },
                                                }}
                                              >
                                                Submit
                                              </Typography>
                                            </h6>
                                          </div>
                                        </div>
                                      </Button>
                                    </Form>
                                  </Col>
                                </Row>
                              </div>
                            </center>
                          </div>
                        )}
                        {open === 0 && (
                          <div style={{ overflow: "scroll" }}>
                            <Box
                              sx={{
                                height: 500,
                                width: {
                                  lg: "100%",
                                  md: "1000px",
                                  sm: "1000px",
                                  xs: "1000px",
                                },
                                "& .super-app-theme--header": {
                                  backgroundColor: "#1de5ec",
                                  color: "black",
                                  fontSize: {
                                    xs: "13px",
                                    sm: "15px", //500
                                    md: "17px", //768
                                    lg: "18px", //1024
                                  },
                                },
                              }}
                            >
                              <DataGrid
                                rows={parseInt(id) === 1 ? rows : rowsList}
                                disableColumnFilter
                                disableColumnSelector
                                disableDensitySelector
                                sx={{
                                  "& .MuiDataGrid-cell": {
                                    fontSize: {
                                      xs: "12px",
                                      sm: "14px", //500
                                      md: "16px", //768
                                      lg: "17px", //1024
                                    },
                                  },
                                }}
                                columns={columnreserve}
                                slots={{ toolbar: GridToolbar }}
                                slotProps={{
                                  toolbar: {
                                    showQuickFilter: true,
                                  },
                                }}
                                initialState={
                                  parseInt(id) === 1
                                    ? {
                                        ...reserves.initialState,
                                        pagination: {
                                          paginationModel: { pageSize: 5 },
                                        },
                                      }
                                    : {
                                        ...reservesList.initialState,
                                        pagination: {
                                          paginationModel: { pageSize: 5 },
                                        },
                                      }
                                }
                                pageSizeOptions={[5, 10, 15]}
                                getRowId={(row) => row.Id}
                              />
                            </Box>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {open === 2 && (
                    <div style={{ overflow: "scroll" }}>
                      <div className="card-body" style={{ minWidth: "1000px" }}>
                        <Container ref={contentRef}>
                          <div className="row">
                            <div className="col-sm-6 ">
                              <br />
                              <h1
                                style={{
                                  marginLeft: "5vw",
                                  marginBottom: "2vh",
                                }}
                              >
                                Invoice
                              </h1>
                              <i
                                style={{
                                  marginLeft: "7vw",
                                  marginBottom: "2vh",
                                }}
                                className="fas fa-home fa-3x"
                              />
                            </div>

                            <div className="col-sm-6 ">
                              <br />
                              <div
                                style={{
                                  fontSize: 18,
                                  textAlign: "right",
                                }}
                              >
                                <p style={{ margin: "0", fontSize: 24 }}>
                                  <b> Company Name</b>
                                </p>
                                <p style={{ margin: "0" }}>+251987654321</p>
                                <p style={{ margin: "0" }}>email@gmail.com</p>
                                <p style={{ margin: "0" }}>address1</p>
                                <p style={{ margin: "0" }}>address2</p>
                              </div>
                            </div>
                          </div>
                          <h4>Reserve</h4>
                          <hr />
                          <Row className="">
                            <Col sm={2}>
                              <p>
                                <b>BILL To</b>
                              </p>
                            </Col>

                            <Col sm={5}>
                              <div>
                                <p>
                                  <b>Customer Name : </b>
                                  {customerValue?.fullName}
                                </p>
                              </div>
                              <div>
                                <p>
                                  <b>Till Number : </b>
                                  {customerValue?.tinNumber}
                                </p>
                              </div>
                              <div>
                                <p>
                                  <b>Phone No : </b>
                                  {customerValue.primaryPhoneNumber}
                                </p>
                              </div>
                              <div>
                                <p>
                                  <b>Customer of : </b>
                                  {
                                    users.find(
                                      (u) => u.Id === customerValue.userId
                                    )?.fullName
                                  }
                                </p>
                              </div>
                            </Col>
                            <Col sm={5}>
                              <div>
                                <p>
                                  <b>Bank Name : </b>
                                  {
                                    paymentMethods.find(
                                      (p) =>
                                        p.Id === formik.values.paymentMethodId
                                    )?.name
                                  }
                                </p>
                              </div>
                              <div>
                                <p>
                                  <b>Transfer ID : </b>
                                  {formik.values.transferidId}
                                </p>
                              </div>
                              {formik.values.Id !== "" && (
                                <div>
                                  <p>
                                    <b>Reserve Ref : </b>
                                    {formik.values.Id}
                                  </p>
                                </div>
                              )}
                              <div>
                                <p>
                                  <b>Date : </b>
                                  {`${new Date(formik.values.date).getDate()}/${
                                    new Date(formik.values.date).getMonth() + 1
                                  }/${new Date(
                                    formik.values.date
                                  ).getFullYear()}`}
                                </p>
                              </div>
                            </Col>
                          </Row>
                          <br />
                          <br />
                          <hr />
                          <br />
                          <br />
                          <br />
                          <Row>
                            <Table
                              className="text-center"
                              style={{ width: "100%" }}
                            >
                              <tr>
                                <th>No</th>
                                <td>Code</td>
                                <th>Desc</th>
                                <th>Model</th>
                                <th>Unit</th>
                                <th>U.Price</th>
                                <th>Qty</th>

                                <th>Discount ( % )</th>
                                <th>T.Price</th>
                              </tr>

                              <tbody>
                                {itemValue.map((reserve, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>

                                    <td>
                                      {items.map((item) =>
                                        item.Id === parseInt(reserve.itemId)
                                          ? item.code
                                          : ""
                                      )}
                                    </td>
                                    <td>
                                      {items.map((item) =>
                                        item.Id === parseInt(reserve.itemId)
                                          ? item.description
                                          : ""
                                      )}
                                    </td>
                                    <td>
                                      {items.map((item) =>
                                        item.Id === parseInt(reserve.itemId)
                                          ? models.map((model) =>
                                              model.Id ===
                                              parseInt(item.modelId)
                                                ? model.modelName
                                                : ""
                                            )
                                          : ""
                                      )}
                                    </td>
                                    <td>
                                      {items.map((item) =>
                                        item.Id === parseInt(reserve.itemId)
                                          ? units.map((unit) =>
                                              unit.Id === parseInt(item.unitId)
                                                ? unit.unitName
                                                : ""
                                            )
                                          : ""
                                      )}
                                    </td>
                                    <td>
                                      {parseFloat(
                                        reserve.price
                                      ).toLocaleString()}
                                    </td>
                                    <td>
                                      {parseFloat(
                                        reserve.quantity
                                      ).toLocaleString()}
                                    </td>

                                    <td>
                                      {`${
                                        parseFloat(
                                          (reserve.discount || 0) /
                                            reserve.totalPrice
                                        ).toFixed(5) * 100
                                      } %`}
                                    </td>
                                    <td>
                                      {parseFloat(
                                        reserve.totalPrice
                                      ).toLocaleString()}
                                    </td>
                                  </tr>
                                ))}
                                {itemValue.length < 5 ? handelInvoice() : ""}
                              </tbody>
                            </Table>
                          </Row>
                          <Row>
                            <Col sm={8}>
                              <h3>Terms</h3>{" "}
                              {qrCodeUrl ? (
                                <div>
                                  <img
                                    src={qrCodeUrl}
                                    alt="QR Code"
                                    style={{
                                      marginTop: "20px",
                                      width: "256px",
                                      height: "256px",
                                    }}
                                  />
                                </div>
                              ) : (
                                <p>Generating QR code...</p>
                              )}
                            </Col>
                            <Col sm={4}>
                              <Table>
                                <tbody>
                                  <tr style={{}}>
                                    <td>
                                      <b
                                        style={{
                                          float: "right",
                                          fontSize: 18,
                                          width: "120px",
                                          height: "10px",
                                        }}
                                      >
                                        Total Price
                                      </b>
                                    </td>
                                    <td>
                                      <p
                                        style={{
                                          float: "left",
                                          fontSize: 18,
                                          width: "110px",
                                          height: "10px",
                                        }}
                                      >
                                        {price.total.toLocaleString()}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b
                                        style={{
                                          float: "right",
                                          fontSize: 18,
                                          width: "120px",
                                          height: "10px",
                                        }}
                                      >
                                        VAT
                                      </b>
                                    </td>
                                    <td>
                                      <p
                                        style={{
                                          float: "left",
                                          fontSize: 18,
                                          width: "110px",
                                          height: "10px",
                                        }}
                                      >
                                        {price.tax.toLocaleString()}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b
                                        style={{
                                          float: "right",
                                          fontSize: 18,
                                          width: "120px",
                                          height: "10px",
                                        }}
                                      >
                                        WithHold
                                      </b>
                                    </td>
                                    <td>
                                      <p
                                        style={{
                                          float: "left",
                                          fontSize: 18,
                                          width: "110px",
                                          height: "10px",
                                        }}
                                      >
                                        {price.withHold.toLocaleString()}
                                      </p>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      <b
                                        style={{
                                          float: "right",
                                          fontSize: 18,
                                          width: "120px",
                                          height: "10px",
                                        }}
                                      >
                                        Grand Total
                                      </b>
                                    </td>
                                    <td>
                                      <p
                                        style={{
                                          float: "left",
                                          fontSize: 18,
                                          width: "110px",
                                          height: "10px",
                                        }}
                                      >
                                        {price.grandTotal.toLocaleString()}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b
                                        style={{
                                          float: "right",
                                          fontSize: 18,
                                          width: "140px",
                                          height: "10px",
                                        }}
                                      >
                                        First Payment
                                      </b>
                                    </td>
                                    <td>
                                      <p
                                        style={{
                                          float: "left",
                                          fontSize: 18,
                                          width: "100px",
                                          height: "10px",
                                        }}
                                      >
                                        {parseFloat(
                                          formik.values.firstPayment
                                        ).toLocaleString()}
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                      <div className="card-footer">
                        
                        <Button
                          onClick={() => {
                            reactToPrint();
                            handleBack();
                          }}
                              sx={{
                                width: "100px",
                                borderColor: "primary.main",
                                fontSize: {
                                  xs: "0.7rem",
                                  sm: "0.85rem",
                                  md: "1rem",
                                },
                                padding: {
                                  xs: "3px",
                                  sm: "4px",
                                  md: "5px",
                                  lg: "6px",
                                },
                                "&:hover": {
                                  backgroundColor: "primary.main",
                                  color: "white",
                                },
                              }}
                              variant="outlined"
                              color="primary"
                              className="ml-3 mt-1"
                              style={{ float: "left" }}
                            >
                              <div
                                className="row"
                                style={{
                                  marginBottom: "-10px",
                                  marginLeft: "-10px",
                                }}
                              >
                                <div className="col">
                                  <FontAwesomeIcon
                                    style={{
                                      float: "right",
                                      marginRight: "-1px",
                                      marginTop: "2px",
                                      marginBottom: "-4px",
                                    }}
                                    icon={faPrint}
                                  />
                                </div>
                                <div className="col-7">
                                  <h6
                                    style={{
                                      float: "left",
                                      marginLeft: "-10px",
                                      marginTop: "-1px",
                                      marginBottom: "-7px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: {
                                          xs: "0.85rem",
                                          sm: "0.85rem",
                                          md: "1rem",
                                        },
                                      }}
                                    >
                                      Print
                                    </Typography>
                                  </h6>
                                </div>
                              </div>
                            </Button>
                        <Button
                          onClick={(e) => {
                            handleBack();
                          }}
                          sx={{
                            width: "90px",
                            borderColor: "success.main",
                            fontSize: {
                              xs: "0.7rem",
                              sm: "0.85rem",
                              md: "1rem",
                            },
                            padding: {
                              xs: "3px",
                              sm: "4px",
                              md: "5px",
                              lg: "6px",
                            },
                            "&:hover": {
                              backgroundColor: "success.main",
                              color: "white",
                            },
                          }}
                          variant="outlined"
                          color="success"
                          className="ml-3 mt-1"
                          style={{ float: "left" }}
                        >
                          <div
                            className="row"
                            style={{
                              marginBottom: "-10px",
                              marginLeft: "-10px",
                            }}
                          >
                            <div className="col">
                              <FontAwesomeIcon
                                style={{
                                  float: "right",
                                  marginRight: "-1px",
                                  marginTop: "2px",
                                  marginBottom: "-4px",
                                }}
                                icon={faBackwardStep}
                              />
                            </div>
                            <div className="col-7">
                              <h6
                                style={{
                                  float: "left",
                                  marginLeft: "-10px",
                                  marginTop: "-1px",
                                  marginBottom: "-7px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: {
                                      xs: "0.85rem",
                                      sm: "0.85rem",
                                      md: "1rem",
                                    },
                                  }}
                                >
                                  Back
                                </Typography>
                              </h6>
                            </div>
                          </div>
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                <Formik
                  initialValues={{
                    fullName: "",
                    tinNumber: "",
                    address: "",
                    primaryPhoneNumber: "",
                    secondaryPhoneNumber: "",
                    userId: "",
                  }}
                  validationSchema={Yup.object().shape({
                    fullName: Yup.string().required("Full name is required."),
                    primaryPhoneNumber: Yup.string()
                      .matches(
                        /^\d{9,10}$/,
                        "Phone number must be 9 or 10 digits."
                      )
                      .required("Phone number is required."),
                    secondaryPhoneNumber: Yup.string().matches(
                      /^\d{9,10}$/,
                      "Phone number must be 9 or 10 digits."
                    ),
                    address: Yup.string().required("Address is required"),
                    tinNumber: Yup.string().matches(
                      /^\d{10}$/,
                      "Tin number must be 10 digits."
                    ),
                    userId: Yup.string().required("User is required"),
                  })}
                  onSubmit={async (values) => {
                    if (isSubmitted) return;
                    setIsSubmitted(true);
                    try {
                      setCustomerErrorPhone1(false);
                      setCustomerErrorPhone2(false);
                      setCustomerErrorTin(false);
                      const {
                        primaryPhoneNumber,
                        secondaryPhoneNumber,
                        tinNumber,
                      } = values;
                      const userExists = customers.find(
                        (customer) =>
                          customer?.primaryPhoneNumber === primaryPhoneNumber
                      );
                      const userExists1 = customers.find(
                        (customer) =>
                          customer?.secondaryPhoneNumber ===
                            secondaryPhoneNumber && secondaryPhoneNumber !== ""
                      );
                      const tinExists = customers.find(
                        (customer) =>
                          customer?.tinNumber === tinNumber && tinNumber !== ""
                      );
                      if (userExists) {
                        setCustomerErrorPhone1(true);
                        setIsSubmitted(false);
                        return;
                      }
                      if (userExists1) {
                        setCustomerErrorPhone2(true);
                        setIsSubmitted(false);
                        return;
                      }
                      if (tinExists) {
                        setCustomerErrorTin(true);
                        setIsSubmitted(false);
                        return;
                      }
                      await axios.post(
                        "https://enin.server.highendtradingplc.com/customer/add",
                        values
                      );
                      values.fullName = "";
                      values.tinNumber = "";
                      values.address = "";
                      values.primaryPhoneNumber = "";
                      values.secondaryPhoneNumber = "";
                      values.userId = "";
                      fetchAllData();
                      setOpenCustomer(false);
                    } catch (error) {
                      console.log(error);
                    }
                    setIsSubmitted(false);
                  }}
                >
                  {(props) => (
                    <Modal
                      show={openCustomer}
                      onHide={(e) => {
                        props.values.fullName = "";
                        props.values.tinNumber = "";
                        props.values.address = "";
                        props.values.primaryPhoneNumber = "";
                        props.values.secondaryPhoneNumber = "";
                        props.values.userId = "";
                        handleOpenCustomer();
                      }}
                      style={{ minWidth: "500px" }}
                    >
                      <Modal.Header closeButton>
                        <span>Quotation / Customer / Create</span>
                      </Modal.Header>
                      <Modal.Body>
                        <Form
                          className="m-2 ml-5 pl-5"
                          onSubmit={props.handleSubmit}
                        >
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={8}>
                              <TextField
                                id="outlined-basic"
                                label="Full Name"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="fullName"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.fullName}
                                error={
                                  props.touched.fullName &&
                                  props.errors.fullName
                                }
                                helperText={
                                  props.touched.fullName &&
                                  props.errors.fullName
                                    ? props.errors.fullName
                                    : ""
                                }
                                sx={{
                                  width: "250px",
                                  "& .MuiInputBase-root": {
                                    fontSize: {
                                      xs: "0.7rem",
                                      sm: "0.85rem",
                                      md: "0.9rem",
                                    },
                                    padding: {
                                      xs: "1px",
                                      sm: "2px",
                                      md: "3px",
                                      lg: "4px",
                                    },
                                  },
                                }}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={8}>
                              <TextField
                                id="outlined-basic"
                                label="Till Number"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="tinNumber"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.tinNumber}
                                error={
                                  (props.touched.tinNumber &&
                                    props.errors.tinNumber) ||
                                  customerErrorTin
                                }
                                helperText={
                                  props.touched.tinNumber &&
                                  props.errors.tinNumber
                                    ? props.errors.tinNumber
                                    : customerErrorTin
                                    ? "Tin number already exists."
                                    : ""
                                }
                                sx={{
                                  width: "250px",
                                  "& .MuiInputBase-root": {
                                    fontSize: {
                                      xs: "0.7rem",
                                      sm: "0.85rem",
                                      md: "0.9rem",
                                    },
                                    padding: {
                                      xs: "1px",
                                      sm: "2px",
                                      md: "3px",
                                      lg: "4px",
                                    },
                                  },
                                }}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={8}>
                              <TextField
                                id="outlined-basic"
                                label="Phone Number"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="primaryPhoneNumber"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.primaryPhoneNumber}
                                error={
                                  (props.touched.primaryPhoneNumber &&
                                    props.errors.primaryPhoneNumber) ||
                                  customerErrorPhone1
                                }
                                helperText={
                                  props.touched.primaryPhoneNumber &&
                                  props.errors.primaryPhoneNumber
                                    ? props.errors.primaryPhoneNumber
                                    : customerErrorPhone1
                                    ? "phone number already exists."
                                    : ""
                                }
                                sx={{
                                  width: "250px",
                                  "& .MuiInputBase-root": {
                                    fontSize: {
                                      xs: "0.7rem",
                                      sm: "0.85rem",
                                      md: "0.9rem",
                                    },
                                    padding: {
                                      xs: "1px",
                                      sm: "2px",
                                      md: "3px",
                                      lg: "4px",
                                    },
                                  },
                                }}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={8}>
                              <TextField
                                id="outlined-basic"
                                label="Phone Number"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="secondaryPhoneNumber"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.secondaryPhoneNumber}
                                error={
                                  (props.touched.secondaryPhoneNumber &&
                                    props.errors.secondaryPhoneNumber) ||
                                  customerErrorPhone2
                                }
                                helperText={
                                  props.touched.secondaryPhoneNumber &&
                                  props.errors.secondaryPhoneNumber
                                    ? props.errors.secondaryPhoneNumber
                                    : customerErrorPhone2
                                    ? "phone number already exists."
                                    : ""
                                }
                                sx={{
                                  width: "250px",
                                  "& .MuiInputBase-root": {
                                    fontSize: {
                                      xs: "0.7rem",
                                      sm: "0.85rem",
                                      md: "0.9rem",
                                    },
                                    padding: {
                                      xs: "1px",
                                      sm: "2px",
                                      md: "3px",
                                      lg: "4px",
                                    },
                                  },
                                }}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={8}>
                              <TextField
                                id="outlined-basic"
                                label="Address"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="address"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.address}
                                error={
                                  props.touched.address && props.errors.address
                                }
                                helperText={
                                  props.touched.address && props.errors.address
                                    ? props.errors.address
                                    : ""
                                }
                                sx={{
                                  width: "250px",
                                  "& .MuiInputBase-root": {
                                    fontSize: {
                                      xs: "0.7rem",
                                      sm: "0.85rem",
                                      md: "0.9rem",
                                    },
                                    padding: {
                                      xs: "1px",
                                      sm: "2px",
                                      md: "3px",
                                      lg: "4px",
                                    },
                                  },
                                }}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={8}>
                              <Autocomplete
                                {...userData}
                                id="controlled-demo"
                                size="small"
                                variant="outlined"
                                name="User"
                                value={userValue}
                                onChange={(event, newValue) => {
                                  if (!newValue?.Id) {
                                    setUserCheck(null);
                                    setUserValue(null);
                                    props.values.userId = "";
                                    return;
                                  }
                                  setUserValue(newValue);
                                  props.values.userId = newValue?.Id;
                                  setUserCheck(5);
                                }}
                                sx={{
                                  width: "250px",
                                  "& .MuiInputBase-root": {
                                    fontSize: {
                                      xs: "0.7rem",
                                      sm: "0.85rem",
                                      md: "0.9rem",
                                    },
                                    padding: {
                                      xs: "1px",
                                      sm: "2px",
                                      md: "3px",
                                      lg: "4px",
                                    },
                                  },
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={props.errors.userId}
                                    helperText={
                                      props.errors.userId
                                        ? props.errors.userId
                                        : ""
                                    }
                                    label={
                                      <div style={{ float: "left" }}>
                                        <FontAwesomeIcon
                                          icon={faUser}
                                          style={{
                                            float: "left",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span>Customer Of</span>
                                      </div>
                                    }
                                  />
                                )}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={12}>
                              <Button
                                sx={{
                                  width: "250px",
                                  "& .MuiInputBase-root": {
                                    fontSize: {
                                      xs: "0.85rem",
                                      sm: "0.85rem",
                                      md: "0.9rem",
                                    },
                                    padding: {
                                      xs: "4px",
                                      sm: "5px",
                                      md: "6px",
                                      lg: "6px",
                                    },
                                  },
                                }}
                                variant="outlined"
                                color="success"
                                type="submit"
                              >
                                Create
                              </Button>
                            </Col>
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                    </Modal>
                  )}
                </Formik>{" "}
                <Formik
                  initialValues={{
                    fullName: "",
                    tinNumber: "",
                    address: "",
                    primaryPhoneNumber: "",
                    secondaryPhoneNumber: "",
                    userId: "",
                  }}
                  validationSchema={Yup.object().shape({
                    fullName: Yup.string().required("Full name is required."),
                    primaryPhoneNumber: Yup.string()
                      .matches(
                        /^\d{9,10}$/,
                        "Phone number must be 9 or 10 digits."
                      )
                      .required("Phone number is required."),
                    secondaryPhoneNumber: Yup.string().matches(
                      /^\d{9,10}$/,
                      "Phone number must be 9 or 10 digits."
                    ),
                    address: Yup.string().required("Address is required"),
                    tinNumber: Yup.string().matches(
                      /^\d{10}$/,
                      "Tin number must be 10 digits."
                    ),
                    userId: Yup.string().required("User is required"),
                  })}
                  onSubmit={async (values) => {
                    if (isSubmitted) return;
                    setIsSubmitted(true);
                    try {
                      await axios.post(
                        "https://enin.server.highendtradingplc.com/customer/add",
                        values
                      );
                    } catch (error) {
                      console.log(error);
                    } finally {
                      fetchAllData();
                      setOpenCustomer(false);
                    }
                    setIsSubmitted(false);
                  }}
                >
                  {(props) => (
                    <Modal
                      show={openCustomer}
                      onHide={handleOpenCustomer}
                      style={{ minWidth: "700px" }}
                    >
                      <Modal.Header closeButton>
                        <span>Request / Customer / Create</span>
                      </Modal.Header>
                      <Modal.Body>
                        <Form
                          className="m-2 ml-5 pl-5"
                          onSubmit={props.handleSubmit}
                        >
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={8}>
                              <TextField
                                id="outlined-basic"
                                label="Full Name"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="fullName"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.fullName}
                                error={
                                  props.touched.fullName &&
                                  props.errors.fullName
                                }
                                helperText={
                                  props.touched.fullName &&
                                  props.errors.fullName
                                    ? props.errors.fullName
                                    : ""
                                }
                                style={{ width: "250px" }}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={8}>
                              <TextField
                                id="outlined-basic"
                                label="Till Number"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="tinNumber"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.tinNumber}
                                error={
                                  props.touched.tinNumber &&
                                  props.errors.tinNumber
                                }
                                helperText={
                                  props.touched.tinNumber &&
                                  props.errors.tinNumber
                                    ? props.errors.tinNumber
                                    : ""
                                }
                                style={{ width: "250px" }}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={8}>
                              <TextField
                                id="outlined-basic"
                                label="Phone Number"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="primaryPhoneNumber"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.primaryPhoneNumber}
                                error={
                                  props.touched.primaryPhoneNumber &&
                                  props.errors.primaryPhoneNumber
                                }
                                helperText={
                                  props.touched.primaryPhoneNumber &&
                                  props.errors.primaryPhoneNumber
                                    ? props.errors.primaryPhoneNumber
                                    : ""
                                }
                                style={{ width: "250px" }}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={8}>
                              <TextField
                                id="outlined-basic"
                                label="Phone Number"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="secondaryPhoneNumber"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.secondaryPhoneNumber}
                                error={
                                  props.touched.secondaryPhoneNumber &&
                                  props.errors.secondaryPhoneNumber
                                }
                                helperText={
                                  props.touched.secondaryPhoneNumber &&
                                  props.errors.secondaryPhoneNumber
                                    ? props.errors.secondaryPhoneNumber
                                    : ""
                                }
                                style={{ width: "250px" }}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={8}>
                              <TextField
                                id="outlined-basic"
                                label="Address"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="address"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.address}
                                error={
                                  props.touched.address && props.errors.address
                                }
                                helperText={
                                  props.touched.address && props.errors.address
                                    ? props.errors.address
                                    : ""
                                }
                                style={{ width: "250px" }}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={8}>
                              <Autocomplete
                                {...userData}
                                id="controlled-demo"
                                size="small"
                                variant="outlined"
                                name="User"
                                value={userValue}
                                onChange={(event, newValue) => {
                                  if (!newValue?.Id) {
                                    setUserCheck(null);
                                    setUserValue(null);
                                    props.values.userId = "";
                                    return;
                                  }
                                  setUserValue(newValue);
                                  props.values.userId = newValue?.Id;
                                  setUserCheck(5);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    style={{ width: "250px" }}
                                    error={props.errors.userId}
                                    helperText={
                                      props.errors.userId
                                        ? props.errors.userId
                                        : ""
                                    }
                                    label={
                                      <div style={{ float: "left" }}>
                                        <FontAwesomeIcon
                                          icon={faUser}
                                          style={{
                                            float: "left",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span>Customer Of</span>
                                      </div>
                                    }
                                  />
                                )}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={12}>
                              <Button
                                style={{ width: "250px" }}
                                variant="outline-success"
                                type="submit"
                              >
                                Create
                              </Button>
                            </Col>
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                    </Modal>
                  )}
                </Formik>
              </div>
              <Modal show={warnings[0] !== 0} onHide={() => setWarings([0, 0])}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <Typography variant="h6">
                    Are you sure you want to{" "}
                    {warnings[0] === 1 ? "delete" : "update"} this reserve?
                  </Typography>
                  <Row>
                    <center>
                      <Button
                        className="col mr-4"
                        style={{ maxWidth: "100px" }}
                        variant="contained"
                        color="error"
                        onClick={async () => {
                          if (isSubmitted) return;
                    setIsSubmitted(true);
                          if (warnings[0] === 1) {
                            await axios.put(
                              "https://enin.server.highendtradingplc.com/reserve/update/" +
                                warnings[1],
                              {
                                statusId: 4,
                              }
                            );
                            const reserveItem = reserveItems.filter(
                              (r) => r.reserveId === warnings[1]
                            );
                            reserveItem.map(async (r) => {
                              const logical = logicals.find(
                                (l) => l.itemId === r.itemId
                              );
                              await axios
                                .post(
                                  "https://enin.server.highendtradingplc.com/transaction/add",
                                  {
                                    quantity: parseInt(r.quantity),
                                    statusId: 1,
                                    date: new Date(),
                                  }
                                )
                                .then(async (res1) => {
                                  await axios.post(
                                    "https://enin.server.highendtradingplc.com/logical_stock/add",
                                    {
                                      quantity:
                                        logical.quantity + parseInt(r.quantity),
                                      itemId: r.itemId,
                                      date: new Date(),
                                      statusId: 1,
                                      transactionId: res1.data.data.Id,
                                      ADPRS_ID: warnings[1],
                                    }
                                  );
                                });
                              return null;
                            });
                            fetchAllData();
                            setWarings([0, 0]);
                          } else if (warnings[0] === 2) {
                            formik.handleSubmit();
                          }
                          setIsSubmitted(false);
                        }}
                      >
                        Confirm
                      </Button>
                      <Button
                        className="col ml-4"
                        style={{ maxWidth: "100px" }}
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          warnings[0] = 0;
                          setWarings([...warnings]);
                        }}
                      >
                        Cancel
                      </Button>
                    </center>
                  </Row>
                </Modal.Body>
              </Modal>
            </center>
          </main>
        </div>
      </Typography>
    </ThemeProvider>
  );
}
