/** @format */
import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { useFormik, Formik } from "formik";
import {
  Autocomplete,
  TextField,
  Checkbox,
  MenuItem,
  createTheme,
  useMediaQuery,
  ThemeProvider,
  Typography,
} from "@mui/material";

import axios from "axios";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUsers,
  faBox,
  faShoppingCart,
  faReceipt,
  faChartPie,
  faBaby,
} from "@fortawesome/free-solid-svg-icons";
import PeopleIcon from "@mui/icons-material/People";
import Header from "../Header";
import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const theme = createTheme({
  breakpoints: { values: { xs: 0, sm: 500, md: 769, lg: 1024, xl: 1440 } },
  palette: {
    warning: {
      main: "#ffc107", // Main warning color
      light: "#ffb74d", // Light variation of warning color
      dark: "#f57c00", // Dark variation of warning color
      contrastText: "#000", // Text color on warning
    },
  },
});
const validationSchemaI = Yup.object().shape({
  description: Yup.string()
    .matches(
      /^[A-Za-z0-9 ]{3,30}$/,
      "Description should be 3-30 alphanumeric characters."
    )
    .required("Name is required"),
  code: Yup.string()
    .matches(
      /^[A-Za-z0-9 ]{3,20}$/,
      "Code should be 3-20 alphanumeric characters."
    )
    .required("Code is required"),
  modelId: Yup.string().required("Model is required"),
  taxId: Yup.string().required("Tax is required"),
  statusId: Yup.string().required("Item Type is required"),
  unitId: Yup.string().required("Unit is required"),
  price: Yup.number()
    .min(1, "Too Low!")
    .max(100000, "Too High!")
    .required("Price is required"),
});
const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Full name should be character.")
    .required("Full name is required.")
    .min(3, "Too Short!")
    .max(30, "Too Long"),
  phoneNumber: Yup.string()
    .required("Phone Number is required")
    .matches(/^\d{10}$/, "Phone number must be 10 digits."),
  address: Yup.string().required("Address is required"),
  tinNumber: Yup.string().matches(/^\d{10}$/, "Tin number must be 10 digits."),
});
const validationSchemaC = Yup.object().shape({
  fullName: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Full name should be character.")
    .required("Full name is required.")
    .min(8, "Too Short!")
    .max(60, "Too Long"),
  primaryPhoneNumber: Yup.string()
    .matches(/^\d{10}$/, "Phone number must be 10 digits.")
    .required("Phone number is required."),
  secondaryPhoneNumber: Yup.string().matches(
    /^\d{10}$/,
    "Phone number must be 10 digits."
  ),
  tinNumber: Yup.string().matches(/^\d{10}$/, "Tin number must be 10 digits."),
  address: Yup.string()
    .required("Address is required")
    .min(3, "Too Short!")
    .max(30, "Too Long"),
  userId: Yup.string().required("User is required"),
});
const HomePage = () => {
  const [open, setOpen] = useState(false);
  const [opensup, setOpensup] = useState(false);
  const [openitem, setOpenitem] = useState(false);

  const [customers, setCustomers] = useState([]);
  const [suppliers, setSuppliers] = useState([]);

  const [taxs, setTaxs] = useState([]);
  const [units, setUnits] = useState([]);
  const [models, setModels] = useState([]);
  const [stores, setStores] = useState(null);
  const [items, setItems] = useState([]);
  const [userCheck, setUserCheck] = useState(null);
  const [userValue, setUserValue] = useState(null);
  const [users, setUsers] = useState([]);
  const [valids, setValid] = useState([0]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isUnderMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const user = jwtDecode(sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const fetchAllData = async () => {
    try {
      const [customer, suplier, unit, model, item, tax, user, store] =
        await Promise.all([
          axios.get("https://enin.server.highendtradingplc.com/customer"),
          axios.get("https://enin.server.highendtradingplc.com/supplier"),
          axios.get("https://enin.server.highendtradingplc.com/unit"),
          axios.get("https://enin.server.highendtradingplc.com/model"),
          axios.get("https://enin.server.highendtradingplc.com/item"),
          axios.get("https://enin.server.highendtradingplc.com/tax"),
          axios.get("https://enin.server.highendtradingplc.com/user"),
          axios.get("https://enin.server.highendtradingplc.com/store"),
        ]);
      setCustomers(customer.data);
      setSuppliers(suplier.data);
      setUnits(unit.data);
      setModels(model.data);
      setItems(item.data);
      setTaxs(tax.data);
      setUsers(user.data?.filter((user) => user.roleId >= 2));
      setStores(store.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);
  const userData = {
    options: users,
    getOptionLabel: (option) => option.fullName,
  };
  const handleValidity = (i) => {
    valids[i] = 1;
    setValid([...valids]);
  };

  const formik = useFormik({
    initialValues: {
      fullName: "",
      tinNumber: "",
      address: "",
      phoneNumber: "",
      id: "",
    },

    validationSchema,
    onSubmit: async (values) => {
      if (isSubmitted) return;
      setIsSubmitted(true);
      try {
        const { phoneNumber, tinNumber } = values;
        const userExists = suppliers.find(
          (supplier) => supplier?.phoneNumber === phoneNumber
        );
        const tinExists = suppliers.find(
          (supplier) => supplier?.tinNumber === tinNumber
        );
        if (userExists) {
          formik.setErrors({
            phoneNumber: "phone number already exists.",
          });
          return;
        }
        if (tinExists) {
          formik.setErrors({
            tinNumber: "tin number already exists.",
          });
          return;
        }
        if (
          !(values.tinNumber.length === 10 || values.tinNumber.length === 0)
        ) {
          formik.setErrors({
            tinNumber: "Till number must be empty or exactly 10 digits.",
          });
          return;
        }
        await axios.post(
          "https://enin.server.highendtradingplc.com/supplier/add",
          values
        );
        setOpensup(false);
        clear();
      } catch (error) {
        console.log(error);
      } finally {
        fetchAllData();
      }
      setIsSubmitted(false);
    },
  });
  const formikC = useFormik({
    initialValues: {
      fullName: "",
      tinNumber: "",
      address: "",
      primaryPhoneNumber: "",
      secondaryPhoneNumber: "",
      id: "",
      userId: "",
    },

    validationSchemaC,
    onSubmit: async (values) => {
      if (isSubmitted) return;
      setIsSubmitted(true);
      try {
        if (valids[0] === 1 && userCheck === null) return;

        const { primaryPhoneNumber, secondaryPhoneNumber, tinNumber } = values;
        const userExists = customers.find(
          (customer) => customer?.primaryPhoneNumber === primaryPhoneNumber
        );
        const userExists1 = customers.find(
          (customer) => customer?.secondaryPhoneNumber === secondaryPhoneNumber
        );
        const tinExists = customers.find(
          (customer) => customer?.tinNumber === tinNumber
        );
        if (userExists) {
          formikC.setErrors({
            primaryPhoneNumber: "Phone number already exists.",
          });
          return;
        }
        if (userExists1) {
          formikC.setErrors({
            primaryPhoneNumber: "Phone number already exists.",
          });
          return;
        }
        if (tinExists) {
          formikC.setErrors({
            tinNumber: "Tin number already exists.",
          });
          return;
        }
        if (
          !(values.tinNumber.length === 10 || values.tinNumber.length === 0)
        ) {
          formikC.setErrors({
            tinNumber: "Till number must be empty or exactly 10 digits.",
          });
          return;
        }
        await axios.post(
          "https://enin.server.highendtradingplc.com/customer/add",
          values
        );
        setOpen(false);
        clear();
        setValid([0]);
        setUserCheck(null);
      } catch (error) {
        console.log(error);
      } finally {
        fetchAllData();
      }
      setIsSubmitted(false);
    },
  });
  const formikI = useFormik({
    initialValues: {
      description: "",
      modelId: "",
      taxId: "",
      code: "",
      unitId: "",
      price: "",
      statusId: "",
      id: "",
      physicalQuantity: "",
      logicalQuantity: "",
    },

    validationSchemaI,
    onSubmit: async (values) => {
      if (isSubmitted) return;
      setIsSubmitted(true);
      try {
        const { description } = values;
        const userExists = items.some(
          (user) => user.description === description
        );

        if (userExists) {
          formikI.setErrors({
            description: "Item already exists.",
          });
          return;
        }
        values.physicalQuantity = 0;
        values.logicalQuantity = 0;
        await axios
          .post(`https://enin.server.highendtradingplc.com/item/add`, values)
          .then(async (res) => {
            for (let i = 0; i < stores.length; i++) {
              await axios.post(
                "https://enin.server.highendtradingplc.com/store_item/add",
                {
                  itemId: res.data.data.Id,
                  storeId: stores[i].Id,
                  quantity: 0,
                }
              );
            }
          });

        setOpenitem(false);
        clear();
        fetchAllData();
      } catch (error) {
        console.log(error);
      } finally {
        window.location.reload();
      }
    },
  });
  const handleAdd = () => {
    setOpen(true);
  };
  const clear = () => {
    formik.values.address = "";
    formik.values.fullName = "";
    formik.values.tinNumber = "";
    formik.values.phoneNumber = "";
    formikC.values.fullName = "";
    formikC.values.address = "";
    formikC.values.primaryPhoneNumber = "";
    formikC.values.secondaryPhoneNumber = "";
    formikC.values.tinNumber = "";
    formikC.values.userId = "";
    formikI.values.modelId = "";
    formikI.values.unitId = "";
    formikI.values.taxId = "";
    formikI.values.description = "";
    formikI.values.code = "";
    formikI.values.price = "";
    formikI.values.id = "";
    formikI.values.statusId = "";
    setValid([0]);
    setUserValue(null);
  };

  const handleClose = () => {
    setOpen(false);
    clear();
  };
  const handleAddsup = () => {
    setOpensup(true);
  };

  const handleClosesup = () => {
    setOpensup(false);
    clear();
  };
  const handleAdditem = () => {
    setOpenitem(true);
  };
  const handleCloseitem = () => {
    setOpenitem(false);
    clear();
  };

  return (
    <ThemeProvider theme={theme}>
      <Typography
        sx={{
          fontSize: {
            xs: "12px",
            sm: "12px", //500
            md: "0.85rem", //768
            lg: "17px", //1024
          },
        }}
      >
        <div>
          <Header />
        </div>
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: "13px",
            sm: "14px", //500
            md: "16px", //768
            lg: "18px", //1024
          },
        }}
      >
        {" "}
        <main
          className=""
          style={
            isMobile && !isUnderMobile
              ? { marginTop: "170px" }
              : isUnderMobile
              ? { marginTop: "170px" }
              : isTablet
              ? { marginTop: "115px" }
              : { marginTop: "130px" }
          }
        >
          <center>
            <div
              style={
                isUnderMobile
                  ? {
                      minWidth: "500px",
                      overflow: "scroll",
                      marginBottom: "-5px",
                    }
                  : { maxWidth: "1100px" }
              }
              className="text-left"
            >
              {" "}
              <div
                className="card card-info card-outline text-center"
                style={{ backgroundColor: "white" }}
              >
                <h3 className="p-3">Welcome!.. SystemAdmin</h3>
                <h5>Here is your task provided by system admin.</h5>
                <hr />
                <br />
                <center>
                  <div className="row pl-5 pr-5">
                    <div
                      className="col col-lg-3 ml-1"
                      style={{ minWidth: "250px", maxWidth: "300px" }}
                    >
                      <div className="info-box card-info card-outline row">
                        <span className="info-box-icon bg-info col-sm-3">
                          <FontAwesomeIcon icon={faUser} />
                        </span>
                        <div className="col">
                          <span
                            className="info-box-text"
                            style={{ float: "left" }}
                          >
                            Customer
                          </span>
                          <br />
                          <span
                            className="info-box-number"
                            style={{ float: "left" }}
                          >
                            Total:{customers.length}
                          </span>
                          <br />
                          <span>
                            <Button
                              style={{
                                float: "right",
                                width: "90px",
                                height: "30px",
                              }}
                              onClick={handleAdd}
                            >
                              <h3 style={{ float: "left", marginTop: "-8px" }}>
                                +
                              </h3>
                              <h6 style={{ float: "left" }}>New</h6>
                            </Button>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col col-lg-3 ml-1"
                      style={{ minWidth: "250px", maxWidth: "300px" }}
                    >
                      <div className="info-box card-info card-outline row">
                        <span className="info-box-icon bg-info col-sm-3">
                          <FontAwesomeIcon icon={faUsers} />
                        </span>
                        <div className="col">
                          <span
                            className="info-box-text"
                            style={{ float: "left" }}
                          >
                            Supplier
                          </span>
                          <br />
                          <span
                            className="info-box-number"
                            style={{ float: "left" }}
                          >
                            Total: {suppliers.length}
                          </span>
                          <br />
                          <span>
                            <Button
                              style={{
                                float: "right",
                                width: "90px",
                                height: "30px",
                              }}
                              onClick={handleAddsup}
                            >
                              <h3 style={{ float: "left", marginTop: "-8px" }}>
                                +
                              </h3>
                              <h6 style={{ float: "left" }}>New</h6>
                            </Button>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col col-lg-3 ml-1"
                      style={{ minWidth: "250px", maxWidth: "300px" }}
                    >
                      <div className="info-box card-info card-outline row">
                        <span className="info-box-icon bg-info col-sm-3">
                          <FontAwesomeIcon icon={faBox} />
                        </span>
                        <div className="col">
                          <span
                            className="info-box-text"
                            style={{ float: "left" }}
                          >
                            Item
                          </span>
                          <br />
                          <span
                            className="info-box-number"
                            style={{ float: "left" }}
                          >
                            Total: {items.length}
                          </span>
                          <br />
                          <span>
                            <Button
                              style={{
                                float: "right",
                                width: "90px",
                                height: "30px",
                              }}
                              onClick={handleAdditem}
                            >
                              <h3 style={{ float: "left", marginTop: "-8px" }}>
                                +
                              </h3>
                              <h6 style={{ float: "left" }}>New</h6>
                            </Button>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col col-lg-3 ml-1"
                      style={{ minWidth: "250px", maxWidth: "300px" }}
                    >
                      <div className="info-box card-info card-outline row">
                        <span className="info-box-icon bg-info col-sm-3">
                          <FontAwesomeIcon icon={faShoppingCart} />
                        </span>
                        <div className="col">
                          <span
                            className="info-box-text"
                            style={{ float: "left" }}
                          >
                            Purchase
                          </span>
                          <br />
                          <span
                            className="info-box-number"
                            style={{ float: "left" }}
                          >
                            Request:- 0
                          </span>
                          <br />
                          <span>
                            <Link
                              onClick={() => {
                                if (user.roleId === 2 || user.roleId === 5) {
                                  window.location.replace("/purchase");
                                } else {
                                  window.sessionStorage.setItem(
                                    "access",
                                    "Access Denied"
                                  );
                                }
                              }}
                            >
                              <Button
                                style={{
                                  float: "right",
                                  width: "85px",
                                  height: "30px",
                                }}
                              >
                                <h3
                                  style={{ float: "left", marginTop: "-8px" }}
                                >
                                  +
                                </h3>
                                <h6 style={{ float: "left" }}>New</h6>
                              </Button>
                            </Link>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col col-lg-3 ml-1"
                      style={{ minWidth: "250px", maxWidth: "300px" }}
                    >
                      <div className="info-box card-info card-outline row">
                        <span className="info-box-icon bg-info col-sm-3">
                          <FontAwesomeIcon icon={faReceipt} />
                        </span>
                        <div className="col">
                          <span
                            className="info-box-text"
                            style={{ float: "left" }}
                          >
                            Sales
                          </span>
                          <br />
                          <span
                            className="info-box-number"
                            style={{ float: "left" }}
                          >
                            Total:- 0
                          </span>
                          <br />
                          <span>
                            <Link
                              onClick={() => {
                                if (user.roleId === 2 || user.roleId === 5) {
                                  window.location.replace("/sales");
                                } else {
                                  window.sessionStorage.setItem(
                                    "access",
                                    "Access Denied"
                                  );
                                }
                              }}
                            >
                              <Button
                                style={{
                                  float: "right",
                                  width: "90px",
                                  height: "30px",
                                }}
                              >
                                <h3
                                  style={{ float: "left", marginTop: "-8px" }}
                                >
                                  +
                                </h3>
                                <h6 style={{ float: "left" }}>New</h6>
                              </Button>
                            </Link>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col col-lg-3 ml-1"
                      style={{ minWidth: "250px", maxWidth: "300px" }}
                    >
                      <div className="info-box card-info card-outline row">
                        <span className="info-box-icon bg-info col-sm-3">
                          <FontAwesomeIcon icon={faChartPie} />
                        </span>
                        <div className="col">
                          <span
                            className="info-box-text"
                            style={{ float: "left" }}
                          >
                            Report
                          </span>
                          <br />
                          <span
                            className="info-box-number"
                            style={{ float: "left" }}
                          >
                            All in one
                          </span>
                          <br />
                          <span>
                            <Link
                              onClick={() => {
                                if (user.roleId === 4) {
                                  window.location.replace("/sales_report");
                                } else if (user.roleId === 5) {
                                  window.location.replace("/delivery_report");
                                } else {
                                  window.location.replace("/purchase_report");
                                }
                              }}
                            >
                              <Button
                                style={{
                                  float: "right",
                                  width: "90px",
                                  height: "30px",
                                }}
                              >
                                <h6 style={{ float: "left" }}>View</h6>
                              </Button>
                            </Link>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </center>
              </div>
            </div>{" "}
          </center>
        </main>
      </Typography>

      <Modal show={open} onHide={handleClose} style={{ minWidth: "700px" }}>
        <Modal.Header closeButton>
          <Modal.Title>Create Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <Form className="" onSubmit={formikC.handleSubmit}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formHorizontalUsername"
              >
                <Col sm={12}>
                  <TextField
                    id="outlined-basic"
                    label="Full Name"
                    variant="outlined"
                    size="small"
                    type="text"
                    name="fullName"
                    onChange={formikC.handleChange}
                    onBlur={formikC.handleBlur}
                    value={formikC.values.fullName}
                    error={formikC.touched.fullName && formikC.errors.fullName}
                    helperText={
                      formikC.touched.fullName && formikC.errors.fullName
                        ? `${formikC.errors.fullName}`
                        : ""
                    }
                    style={{ width: "300px" }}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formHorizontalUsername"
              >
                <Col sm={12}>
                  <TextField
                    id="outlined-basic"
                    label="Tin Number"
                    variant="outlined"
                    size="small"
                    type="text"
                    name="tinNumber"
                    onChange={formikC.handleChange}
                    onBlur={formikC.handleBlur}
                    value={formikC.values.tinNumber}
                    error={
                      formikC.touched.tinNumber && formikC.errors.tinNumber
                    }
                    helperText={
                      formikC.touched.tinNumber && formikC.errors.tinNumber
                        ? `${formikC.errors.tinNumber}`
                        : ""
                    }
                    style={{ width: "300px" }}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formHorizontalUsername"
              >
                <Col sm={12}>
                  <TextField
                    id="outlined-basic"
                    label="First Phone Number"
                    variant="outlined"
                    size="small"
                    type="text"
                    name="primaryPhoneNumber"
                    onChange={formikC.handleChange}
                    onBlur={formikC.handleBlur}
                    value={formikC.values.primaryPhoneNumber}
                    error={
                      formikC.touched.primaryPhoneNumber &&
                      formikC.errors.primaryPhoneNumber
                    }
                    helperText={
                      formikC.touched.primaryPhoneNumber &&
                      formikC.errors.primaryPhoneNumber
                        ? `${formikC.errors.primaryPhoneNumber}`
                        : ""
                    }
                    style={{ width: "300px" }}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formHorizontalUsername"
              >
                <Col sm={12}>
                  <TextField
                    id="outlined-basic"
                    label="Second Phone Number"
                    variant="outlined"
                    size="small"
                    type="text"
                    name="secondaryPhoneNumber"
                    onChange={formikC.handleChange}
                    onBlur={formikC.handleBlur}
                    value={formikC.values.secondaryPhoneNumber}
                    error={
                      formikC.touched.secondaryPhoneNumber &&
                      formikC.errors.secondaryPhoneNumber
                    }
                    helperText={
                      formikC.touched.secondaryPhoneNumber &&
                      formikC.errors.secondaryPhoneNumber
                        ? `${formikC.errors.secondaryPhoneNumber}`
                        : ""
                    }
                    style={{ width: "300px" }}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formHorizontalUsername"
              >
                <Col sm={12}>
                  <TextField
                    id="outlined-basic"
                    label="Address"
                    variant="outlined"
                    size="small"
                    type="text"
                    name="address"
                    onChange={formikC.handleChange}
                    onBlur={formikC.handleBlur}
                    value={formikC.values.address}
                    error={formikC.touched.address && formikC.errors.address}
                    helperText={
                      formikC.touched.address && formikC.errors.address
                        ? `${formikC.errors.address}`
                        : ""
                    }
                    style={{ width: "300px" }}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formHorizontalUsername"
              >
                <Col sm={12}>
                  <Autocomplete
                    {...userData}
                    id="controlled-demo"
                    size="small"
                    variant="outlined"
                    name="User"
                    onBlur={(e) => handleValidity(0)}
                    value={userValue}
                    onChange={(event, newValue) => {
                      if (!newValue?.Id) {
                        setUserCheck(null);
                        return;
                      }
                      setUserValue(newValue);
                      formikC.values.userId = newValue?.Id;
                      setUserCheck(5);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ width: "300px" }}
                        error={valids[0] === 1 && userCheck === null}
                        label={
                          <div style={{ float: "left" }}>
                            <FontAwesomeIcon
                              icon={faUser}
                              style={{
                                float: "left",
                                marginRight: "10px",
                              }}
                            />
                            <span>Customer Of</span>
                          </div>
                        }
                      />
                    )}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formHorizontalUsername"
              >
                <Col sm={12}>
                  <Button
                    style={{ width: "300px" }}
                    variant="outline-success"
                    type="submit"
                  >
                    "Create"
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </center>
        </Modal.Body>
      </Modal>
      <Modal
        show={opensup}
        onHide={handleClosesup}
        style={{ minWidth: "700px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Supplier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className=" ml-5 pl-5" onSubmit={formik.handleSubmit}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={12}>
                <TextField
                  id="outlined-basic"
                  label="Full Name"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="fullName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fullName}
                  error={formik.touched.fullName && formik.errors.fullName}
                  helperText={
                    formik.touched.fullName && formik.errors.fullName
                      ? `${formik.errors.fullName}`
                      : ""
                  }
                  style={{ width: "300px" }}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={12}>
                <TextField
                  id="outlined-basic"
                  label="Tin Number"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="tinNumber"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.tinNumber}
                  error={formik.touched.tinNumber && formik.errors.tinNumber}
                  helperText={
                    formik.touched.tinNumber && formik.errors.tinNumber
                      ? `${formik.errors.tinNumber}`
                      : ""
                  }
                  style={{ width: "300px" }}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={12}>
                <TextField
                  id="outlined-basic"
                  label="phone Number"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="phoneNumber"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                  error={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                      ? `${formik.errors.phoneNumber}`
                      : ""
                  }
                  style={{ width: "300px" }}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={12}>
                <TextField
                  id="outlined-basic"
                  label="Address"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                  error={formik.touched.address && formik.errors.address}
                  helperText={
                    formik.touched.address && formik.errors.address
                      ? `${formik.errors.address}`
                      : ""
                  }
                  style={{ width: "300px" }}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={12}>
                <Button
                  style={{ width: "300px" }}
                  variant="outline-success"
                  type="submit"
                >
                  {"Create"}
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={openitem}
        onHide={handleCloseitem}
        style={{ minWidth: "700px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <div>
              <Form lassName="ml-5 pl-5" onSubmit={formikI.handleSubmit}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalUsername"
                >
                  <Col sm={12}>
                    <TextField
                      id="outlined-basic"
                      label="description"
                      variant="outlined"
                      type="text"
                      size="small"
                      name="description"
                      onChange={formikI.handleChange}
                      onBlur={formikI.handleBlur}
                      value={formikI.values.description}
                      error={
                        formikI.touched.description &&
                        formikI.errors.description
                      }
                      helperText={
                        formikI.touched.description &&
                        formikI.errors.description
                          ? `${formikI.errors.description}`
                          : ""
                      }
                      style={{ width: "300px" }}
                    />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalUsername"
                >
                  <Col sm={12}>
                    <TextField
                      id="outlined-basic"
                      label="Price"
                      variant="outlined"
                      size="small"
                      name="price"
                      onChange={formikI.handleChange}
                      onBlur={formikI.handleBlur}
                      value={formikI.values.price}
                      error={formikI.touched.price && formikI.errors.price}
                      helperText={
                        formikI.touched.price && formikI.errors.price
                          ? `${formikI.errors.price}`
                          : ""
                      }
                      style={{ width: "300px" }}
                    />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalUsername"
                >
                  <Col sm={12}>
                    <TextField
                      id="outlined-basic"
                      label="Code"
                      variant="outlined"
                      type="text"
                      name="code"
                      size="small"
                      onChange={formikI.handleChange}
                      onBlur={formikI.handleBlur}
                      value={formikI.values.code}
                      error={formikI.touched.code && formikI.errors.code}
                      helperText={
                        formikI.touched.code && formikI.errors.code
                          ? `${formikI.errors.code}`
                          : ""
                      }
                      style={{ width: "300px" }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalUsername"
                >
                  <Col sm={12}>
                    <TextField
                      id="outlined-basic"
                      label="Select Model"
                      variant="outlined"
                      select
                      size="small"
                      name="modelId"
                      onChange={formikI.handleChange}
                      onBlur={formikI.handleBlur}
                      value={formikI.values.modelId}
                      error={formikI.touched.modelId && formikI.errors.modelId}
                      helperText={
                        formikI.touched.modelId && formikI.errors.modelId
                          ? `${formikI.errors.modelId}`
                          : ""
                      }
                      style={{ width: "300px" }}
                    >
                      <MenuItem key={null} value={null}>
                        None
                      </MenuItem>
                      {models.map((model) => {
                        return (
                          <MenuItem key={model.Id} value={model.Id}>
                            {model.modelName}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalUsername"
                >
                  <Col sm={12}>
                    <TextField
                      id="outlined-basic"
                      label="Select Unit"
                      variant="outlined"
                      select
                      size="small"
                      name="unitId"
                      onChange={formikI.handleChange}
                      onBlur={formikI.handleBlur}
                      value={formikI.values.unitId}
                      error={formikI.touched.unitId && formikI.errors.unitId}
                      helperText={
                        formikI.touched.unitId && formikI.errors.unitId
                          ? `${formikI.errors.unitId}`
                          : ""
                      }
                      style={{ width: "300px" }}
                    >
                      <MenuItem key={null} value={null}>
                        None
                      </MenuItem>
                      {units.map((unit) => {
                        return (
                          <MenuItem key={unit.Id} value={unit.Id}>
                            {unit.unitName}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalUsername"
                >
                  <Col sm={12}>
                    <TextField
                      id="outlined-basic"
                      label="Select Tax Type"
                      variant="outlined"
                      select
                      size="small"
                      name="taxId"
                      onChange={formikI.handleChange}
                      onBlur={formikI.handleBlur}
                      value={formikI.values.taxId}
                      error={formikI.touched.taxId && formikI.errors.taxId}
                      helperText={
                        formikI.touched.taxId && formikI.errors.taxId
                          ? `${formikI.errors.taxId}`
                          : ""
                      }
                      style={{ width: "300px" }}
                    >
                      <MenuItem key={null} value={null}>
                        None
                      </MenuItem>
                      {taxs.map((tax) => {
                        return (
                          <MenuItem key={tax.Id} value={tax.Id}>
                            <span className="row text-center">
                              <div className="col-6" style={{ float: "left" }}>
                                {tax.taxType}
                              </div>
                              <div className="col-6" style={{ float: "right" }}>
                                {`${tax.taxValue} %`}
                              </div>
                            </span>
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalUsername"
                >
                  <Col sm={12}>
                    <TextField
                      id="outlined-basic"
                      label="Select Item Type"
                      variant="outlined"
                      select
                      size="small"
                      name="statusId"
                      onChange={formikI.handleChange}
                      onBlur={formikI.handleBlur}
                      value={formikI.values.statusId}
                      error={
                        formikI.touched.statusId && formikI.errors.statusId
                      }
                      helperText={
                        formikI.touched.statusId && formikI.errors.statusId
                          ? `${formikI.errors.statusId}`
                          : ""
                      }
                      style={{ width: "300px" }}
                    >
                      <MenuItem key={null} value={null}>
                        None
                      </MenuItem>
                      <MenuItem key={1} value={1}>
                        Service
                      </MenuItem>
                      <MenuItem key={2} value={2}>
                        Consumable
                      </MenuItem>
                    </TextField>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalUsername"
                >
                  <Col sm={12}>
                    <Button
                      style={{ width: "300px" }}
                      variant="outline-primary"
                      type="submit"
                    >
                      {"Create"}
                    </Button>
                  </Col>
                </Form.Group>
              </Form>
            </div>
          </center>
        </Modal.Body>
      </Modal>
    </ThemeProvider>
  );
};

export default HomePage;
