import { Navigate, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useEffect, useRef } from "react";
const usePreviousUrl = () => {
  const location = useLocation();
  const previousUrlRef = useRef(null);

  useEffect(() => {
    previousUrlRef.current = location.pathname;
  }, [location]);

  return previousUrlRef.current;
};
const getUserRole = () => {
  const token = sessionStorage.getItem("data");
  if (!token) return null;

  try {
    const decoded = jwtDecode(token);
    return decoded.roleId;
  } catch (err) {
    return null;
  }
};

const ProtectedRoute = ({ children, requiredRole }) => {
  const userRole = getUserRole();
  const location = usePreviousUrl();
  if (!userRole) {
    return <Navigate to={`/`} replace />;
  }
  if (!requiredRole.includes(userRole)) {
    window.sessionStorage.setItem("access", "Access Denied");
    return <Navigate to={location} replace />;
  }
  return children;
};

export default ProtectedRoute;
