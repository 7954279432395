/** @format */

import React, { useEffect, useState } from "react";
import "../AccountManagePage.css";

import { Link } from "react-router-dom";
import Header from "../Header";
import { Col, Form, Row } from "react-bootstrap";
import {
  TextField,
  Button,
  createTheme,
  useMediaQuery,
  ThemeProvider,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { Formik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { jwtDecode } from "jwt-decode";
const theme = createTheme({
  breakpoints: { values: { xs: 0, sm: 500, md: 769, lg: 1024, xl: 1440 } },
  palette: {
    warning: {
      main: "#ffc107", // Main warning color
      light: "#ffb74d", // Light variation of warning color
      dark: "#f57c00", // Dark variation of warning color
      contrastText: "#000", // Text color on warning
    },
  },
});
const AccountMng = () => {
  const [activeTab, setActiveTab] = useState("Profile");
  const [users, setUser] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [verifyData, setVerifyData] = useState([]);
  const [openAlert, setOpenAlert] = useState(0);
  const [alertText, setAlertText] = useState("");
  const userData = jwtDecode(sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isUnderMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const fetchAllData = async () => {
    try {
      const [user] = await Promise.all([
        axios.get("https://enin.server.highendtradingplc.com/user"),
      ]);
      setUser(user.data.find((u) => u.Id === userData.Id));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  const renderContent = () => {
    switch (activeTab) {
      case "Profile":
        return (
          <div className="account-form" style={{ marginTop: "-10px" }}>
            <Formik
              initialValues={{
                userName: "",
                phoneNumber: "",
                file: "",
                check: "",
              }}
              onSubmit={async (values) => {
                setIsloading(true);
                let result = false;
                values.userName =
                  values.userName === "" ? users.userName : values.userName;
                values.phoneNumber =
                  values.phoneNumber === ""
                    ? users.phoneNumber
                    : values.phoneNumber;
                try {
                  const { file } = values;
                  if (!!file) {
                    result = true;
                  }
                  if (result) {
                    const formData = new FormData();
                    formData.append("file", file);
                    formData.append("userName", values.userName);
                    formData.append("phoneNumber", values.phoneNumber);
                    await axios.put(
                      `https://enin.server.highendtradingplc.com/user/update_file/` +
                        users.Id,
                      formData,
                      { headers: { "Content-Type": "multipart/form-data" } }
                    );
                  } else {
                    values.check = 5;
                    await axios.put(
                      `https://enin.server.highendtradingplc.com/user/update/` +
                        users.Id,
                      values
                    );
                  }
                  setOpenAlert(1);
                  setAlertText("Username and Phone No update Successful");
                  fetchAllData();
                  values.phoneNumber = "";
                  values.userName = "";
                  values.file = "";
                  setIsloading(false);
                } catch (error) {
                  setIsloading(false);
                  setOpenAlert(2);
                  setAlertText("Can't update user data");
                  console.log(error);
                }
              }}
            >
              {(props) => (
                <Form
                  encType="multipart/form-data"
                  onSubmit={props.handleSubmit}
                >
                  <div className="row">
                    <div className="col-6" style={{ minWidth: "300px" }}>
                      <h4>Profile</h4>
                      <Form.Group as={Row}>
                        <Col sm={12} className="mb-3">
                          <TextField
                            value={props.values.userName}
                            label="Username"
                            size="small"
                            name="userName"
                            style={{ width: "90%" }}
                            onBlur={props.handleBlur}
                            onChange={props.handleChange}
                            error={
                              props.errors.userName && props.touched.userName
                            }
                            helperText={
                              props.errors.userName && props.touched.userName
                                ? props.errors.userName
                                : ""
                            }
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Col sm={12} className="mb-2">
                          <TextField
                            value={props.values.phoneNumber}
                            label="Phone Number"
                            size="small"
                            name="phoneNumber"
                            style={{ width: "90%" }}
                            onBlur={props.handleBlur}
                            onChange={props.handleChange}
                            error={
                              props.errors.phoneNumber &&
                              props.touched.phoneNumber
                            }
                            helperText={
                              props.errors.phoneNumber &&
                              props.touched.phoneNumber
                                ? props.errors.phoneNumber
                                : ""
                            }
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Col sm={12}>
                          <Button
                            variant="outlined"
                            color="primary"
                            style={{ width: "90%" }}
                            type="submit"
                            loading={isLoading}
                            loadingPosition="start"
                          >
                            Save
                          </Button>
                        </Col>
                      </Form.Group>
                    </div>
                    {/* <div className="col-6 pl-4" style={{ minWidth: "300px" }}>
                      <strong className="row">Profile Picture</strong>
                      <img
                        src={`https://enin.server.highendtradingplc.com/${users?.pic}`}
                        className="row"
                        width="250px"
                        height="300px"
                        alt="user pic"
                      />
                      <input
                        type="file"
                        name="file"
                        className="mb-2 row"
                        onChange={(e) =>
                          props.setFieldValue("file", e.currentTarget.files[0])
                        }
                        style={{ minWidth: "90%" }}
                      />
                    </div> */}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        );
      case "Email":
        return (
          <div className="account-form ml-3" style={{ marginTop: "-10px" }}>
            <Formik
              initialValues={{
                email: "",
                emailConfirmed: "",
                check: "",
              }}
              onSubmit={async (values) => {
                try {
                  setIsloading(true);
                  values.check = 4;
                  values.emailConfirmed = 1;

                  await axios
                    .put(
                      `https://enin.server.highendtradingplc.com/user/update/` +
                        userData.Id,
                      values
                    )
                    .then((res) => console.log(res));
                  fetchAllData();
                  values.email = "";
                  values.emailConfirmed = "";
                  setOpenAlert(1);
                  setAlertText("Email update successful");
                  setIsloading(false);
                } catch (error) {
                  setOpenAlert(2);
                  setAlertText("Can't update email");
                  setIsloading(false);
                  console.log(error);
                }
              }}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <h4>Email</h4>
                  <Form.Group as={Row}>
                    <Col sm={12} className="mb-2">
                      <TextField
                        value={props.values.email}
                        label="Email"
                        size="small"
                        name="email"
                        style={{ width: "300px" }}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        error={props.errors.email && props.touched.email}
                        helperText={
                          props.errors.email && props.touched.email
                            ? props.errors.email
                            : ""
                        }
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm={12}>
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ width: "300px" }}
                        type="submit"
                        loading={isLoading}
                        loadingPosition="start"
                      >
                        Save
                      </Button>
                    </Col>
                  </Form.Group>
                </Form>
              )}
            </Formik>
          </div>
        );
      case "Password":
        return (
          <div className="account-form ml-3" style={{ marginTop: "-10px" }}>
            <Formik
              initialValues={{
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
              }}
              validationSchema={Yup.object().shape({
                oldPassword: Yup.string().required(),
                newPassword: Yup.string()
                  .required()
                  .min(4, "Password must be at least 4 characters")
                  .max(16, "Password must be at most 16 characters"),
                confirmPassword: Yup.string()
                  .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
                  .required("Confirm password is required"),
              })}
              onSubmit={async (values) => {
                try {
                  setIsloading(true);
                  await axios
                    .post("https://enin.server.highendtradingplc.com/login", {
                      userName: users.userName,
                      password: values.oldPassword,
                    })
                    .then(async (res) => {
                      if (res.data.Login) {
                        await axios.put(
                          `https://enin.server.highendtradingplc.com/user/update/` +
                            users.Id,
                          { passwordHash: values.newPassword, check: 2 }
                        );
                        setOpenAlert(1);
                        setAlertText("Password update successful");
                      } else {
                        setOpenAlert(3);
                        setAlertText("You insert incorrect password");
                      }
                    });
                  setIsloading(false);
                  fetchAllData();
                  values.oldPassword = "";
                  values.newPassword = "";
                  values.confirmPassword = "";
                } catch (error) {
                  setOpenAlert(2);
                  setAlertText("Can't update password");
                  setIsloading(false);
                  console.log(error);
                }
              }}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <h4>Change Password</h4>
                  <Form.Group as={Row}>
                    <Col sm={12} className="mb-3">
                      <TextField
                        value={props.values.oldPassword}
                        label="Old Password"
                        size="small"
                        type="password"
                        name="oldPassword"
                        style={{ width: "300px" }}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        error={
                          props.errors.oldPassword && props.touched.oldPassword
                        }
                        helperText={
                          props.errors.oldPassword && props.touched.oldPassword
                            ? props.errors.oldPassword
                            : ""
                        }
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm={12} className="mb-3">
                      <TextField
                        value={props.values.newPassword}
                        label="New Password"
                        size="small"
                        type="password"
                        name="newPassword"
                        style={{ width: "300px" }}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        error={
                          props.errors.newPassword && props.touched.newPassword
                        }
                        helperText={
                          props.errors.newPassword && props.touched.newPassword
                            ? props.errors.newPassword
                            : ""
                        }
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm={12} className="mb-3">
                      <TextField
                        value={props.values.confirmPassword}
                        label="Confirm Password"
                        size="small"
                        type="password"
                        name="confirmPassword"
                        style={{ width: "300px" }}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        error={
                          (props.errors.confirmPassword &&
                            props.touched.confirmPassword) ||
                          props.values.newPassword !==
                            props.values.confirmPassword
                        }
                        helperText={
                          (props.errors.confirmPassword &&
                            props.touched.confirmPassword) ||
                          props.values.newPassword !==
                            props.values.confirmPassword
                            ? props.errors.confirmPassword
                            : ""
                        }
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm={12}>
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ width: "300px" }}
                        type="submit"
                        loading={isLoading}
                        loadingPosition="star"
                      >
                        Save
                      </Button>
                    </Col>
                  </Form.Group>
                </Form>
              )}
            </Formik>
          </div>
        );
      case "Two-factor authentication":
        return (
          <div className="account-form ml-3" style={{ marginTop: "-10px" }}>
            <Formik
              initialValues={{
                value: "",
              }}
              onSubmit={async (values) => {
                setIsloading(true);
                try {
                  if (!verifyEmail) {
                    const data = await axios.put(
                      `https://enin.server.highendtradingplc.com/user/update/${users.Id}`,
                      { check: 1 }
                    );
                    setVerifyData(data.data);
                    setVerifyEmail(true);
                    setIsloading(false);
                    return;
                  } else {
                    if (values.value !== verifyData.verify) {
                      setOpenAlert(3);
                      setAlertText("You insert incorrect verification code");
                      setIsloading(false);
                      return;
                    }
                    await axios.put(
                      `https://enin.server.highendtradingplc.com/user/update/${users.Id}`,
                      { email: users.email, emailConfirmed: true, check: 4 }
                    );
                    setOpenAlert(1);
                    setAlertText("Email verification finished successful");
                    fetchAllData();
                    setVerifyEmail(false);
                  }
                  setIsloading(false);
                } catch (error) {
                  setOpenAlert(2);
                  setAlertText("Can't verify email");
                  setIsloading(false);
                  console.log(error);
                }
              }}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <h4 className="mb-4">Email Verification </h4>

                  <h6>
                    <strong>
                      {users.email}{" "}
                      {users.emailConfirmed ? (
                        <span style={{ marginLeft: "10px" }}>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            style={{ marginBottom: "-2px", color: "green" }}
                          />
                        </span>
                      ) : (
                        <span style={{ marginLeft: "10px" }}>
                          <FontAwesomeIcon
                            icon={faCircleExclamation}
                            style={{ marginBottom: "-2px", color: "red" }}
                          />
                        </span>
                      )}
                    </strong>
                  </h6>
                  <h6 className=" mb-4">
                    your email is{" "}
                    {users.emailConfirmed ? "verify !!!" : "not verify !!!"}
                  </h6>
                  {!users.emailConfirmed && (
                    <div>
                      {!verifyEmail && (
                        <Form.Group as={Row}>
                          <Col sm={12}>
                            <Button
                              variant="outlined"
                              color="primary"
                              style={{ width: "300px" }}
                              type="submit"
                              loading={isLoading}
                              loadingPosition="start"
                            >
                              Verify Email
                            </Button>
                          </Col>
                        </Form.Group>
                      )}
                      {verifyEmail && (
                        <div>
                          <Form.Group as={Row} className="mt-3 mb-3">
                            <Col sm={12}>
                              <TextField
                                label="Verification Code"
                                size="small"
                                style={{ width: "300px" }}
                                name="value"
                                value={props.values.value}
                                onBlur={props.handleBlur}
                                onChange={props.handleChange}
                                error={
                                  props.errors.value && props.touched.value
                                }
                                helperText={
                                  props.errors.value && props.touched.value
                                    ? props.errors.value
                                    : ""
                                }
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Col sm={12}>
                              <Button
                                variant="outlined"
                                color="primary"
                                style={{ width: "300px" }}
                                type="submit"
                                loading={isLoading}
                                loadingPosition="start"
                              >
                                Submit
                              </Button>
                            </Col>
                          </Form.Group>
                        </div>
                      )}
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Typography
        sx={{
          fontSize: {
            xs: "12px",
            sm: "12px", //500
            md: "0.85rem", //768
            lg: "17px", //1024
          },
        }}
      >
        {" "}
        <div>
          <Header />
        </div>
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: "13px",
            sm: "14px", //500
            md: "16px", //768
            lg: "18px", //1024
          },
        }}
      >
        <main
          style={
            isMobile && !isUnderMobile
              ? { marginTop: "190px" }
              : isUnderMobile
              ? { marginTop: "170px" }
              : isTablet
              ? { marginTop: "133px" }
              : { marginTop: "150px" }
          }
        >
          <center>
            <div
              style={
                isUnderMobile
                  ? {
                      minWidth: "500px",
                      overflow: "scroll",
                      marginBottom: "-5px",
                    }
                  : { maxWidth: "1100px" }
              }
              className="text-left"
            >
              <div
                className="tab-container "
                style={
                  isUnderMobile
                    ? { marginTop: "0", marginBottom: "-5px" }
                    : {
                        marginTop: "-20px",
                        marginBottom: "-5px",
                      }
                }
              >
                <Link className="p-2" to={`/user_mgt`}>
                  User Mgt
                </Link>
                <Link className="p-2" to={`/role_mgt`}>
                  Role Mgt
                </Link>
                <Link
                  className="p-2"
                  onClick={(e) => window.location.reload()}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "5px 5px 0 0",
                    border: "solid 0.05em rgb(181, 181, 181)",
                  }}
                >
                  Account Mgt
                </Link>
                <Link className="p-2" to={`/backup`}>
                  Backup Data
                </Link>
              </div>
              <div className="card card-info card-outline">
                <div className="card-body">
                  <div
                    className="nav-bra pl-3 ml-1 p-2 "
                    style={{
                      backgroundColor: "rgb(235, 235, 235)",
                    }}
                  >
                    <span>Home / Manage</span>
                  </div>

                  <div style={{}}>
                    <h3 className="pl-3 pt-2">Manage Your Account</h3>
                    <h5 className="pl-3">Change your account settings</h5>
                  </div>
                  <div style={{ border: "solid 1px #ffb71b" }}></div>
                  <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={openAlert > 0}
                    autoHideDuration={5000}
                    onClose={(e) => setOpenAlert(0)}
                  >
                    <Alert
                      onClose={(e) => setOpenAlert(0)}
                      severity={
                        openAlert === 1
                          ? "success"
                          : openAlert === 2
                          ? "error"
                          : "warning"
                      }
                      variant="filled"
                      sx={{ width: "100%" }}
                    >
                      {alertText}
                    </Alert>
                  </Snackbar>
                  <div className="manage-container mt-1 row">
                    <div className="account-menu col">
                      <ul>
                        <li
                          className={
                            activeTab === "Profile" ? "active bg-primary" : ""
                          }
                          style={
                            activeTab === "Profile"
                              ? { border: "solid 1px white" }
                              : {}
                          }
                          onClick={() => setActiveTab("Profile")}
                        >
                          Profile
                        </li>
                        <li
                          className={
                            activeTab === "Email" ? "active bg-primary" : ""
                          }
                          onClick={() => setActiveTab("Email")}
                          style={
                            activeTab === "Email"
                              ? { border: "solid 1px white" }
                              : {}
                          }
                        >
                          Email
                        </li>
                        <li
                          className={
                            activeTab === "Password" ? "active bg-primary" : ""
                          }
                          onClick={() => setActiveTab("Password")}
                          style={
                            activeTab === "Password"
                              ? { border: "solid 1px white" }
                              : {}
                          }
                        >
                          Password
                        </li>
                        <li
                          className={
                            activeTab === "Two-factor authentication"
                              ? "active bg-primary"
                              : ""
                          }
                          onClick={() =>
                            setActiveTab("Two-factor authentication")
                          }
                          style={
                            activeTab === "Two-factor authentication"
                              ? { border: "solid 1px white" }
                              : {}
                          }
                        >
                          Two-factor authentication
                        </li>
                        {/* <li
                          className={
                            activeTab === "Personal data"
                              ? "active bg-primary"
                              : ""
                          }
                          onClick={() => setActiveTab("Personal data")}
                          style={
                            activeTab === "Personal data"
                              ? { border: "solid 1px white" }
                              : {}
                          }
                        >
                          Personal data
                        </li> */}
                      </ul>
                    </div>
                    <div
                      className="account-content col"
                      style={{ float: "right", minWidth: "330px" }}
                    >
                      {renderContent()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </center>
        </main>
      </Typography>
    </ThemeProvider>
  );
};
export default AccountMng;
