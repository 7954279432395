/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../Header";
import { Col, Form, Modal, Row } from "react-bootstrap";
import {
  TextField,
  createTheme,
  useMediaQuery,
  ThemeProvider,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { jwtDecode } from "jwt-decode";

const validationSchema = Yup.object().shape({
  roleName: Yup.string()
    .required("Role is required")
    .min(2, "Too Short!")
    .max(25, "Too Long"),
});
const theme = createTheme({
  breakpoints: { values: { xs: 0, sm: 500, md: 769, lg: 1024, xl: 1440 } },
  palette: {
    warning: {
      main: "#ffc107", // Main warning color
      light: "#ffb74d", // Light variation of warning color
      dark: "#f57c00", // Dark variation of warning color
      contrastText: "#000", // Text color on warning
    },
  },
});
const RoleMng = () => {
  const [open, setOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const [warnings, setWarings] = useState([0, 0]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isUnderMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const userData = jwtDecode(sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const fetchAllData = async () => {
    const [role] = await Promise.all([
      axios.get("https://enin.server.highendtradingplc.com/role"),
    ]);
    setRoles(role.data);
  };
  useEffect(() => {
    fetchAllData();
  }, []);
  const handleOpen = () => {
    setOpen(!open);
  };
  const formik = useFormik({
    initialValues: {
      roleName: "",
      id: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      if (isSubmitted) return;
      setIsSubmitted(true);
      try {
        if (values.id === "") {
          const { roleName } = values;
          const userExists = roles.find((role) => role?.roleName === roleName);
          if (userExists) {
            formik.setErrors({
              roleName: "Role aame already exists.",
            });
            setIsSubmitted(false);
            return;
          }
          await axios.post(
            "https://enin.server.highendtradingplc.com/role/add",
            values
          );
          setOpen(false);
        } else {
          if (warnings[0] === 0) {
            warnings[0] = 2;
            warnings[1] = values.id;
            setWarings([...warnings]);
            setIsSubmitted(false);
            return;
          }
          await axios.put(
            "https://enin.server.highendtradingplc.com/role/update/" +
              values.id,
            values
          );
          setWarings([0, 0]);
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      } finally {
        fetchAllData();
      }
      setIsSubmitted(false);
    },
  });
  const handleUpdate = (id) => {
    const role = roles.find((r) => r.Id === parseInt(id));
    formik.values.roleName = role.roleName;
    formik.values.id = id;
    setOpen(true);
  };
  const handleDelete = async (id) => {
    try {
      await axios.delete(
        "https://enin.server.highendtradingplc.com/role/delete/" + id
      );
      fetchAllData();
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    {
      field: "Id",
      headerName: "No",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
    },
    {
      field: "roleId",
      headerName: "Role Id",

      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "roleName",
      headerName: "Role Name",

      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    userData.roleId === 1 && {
      field: "action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",

      width: 240,
      renderCell: (params) => (
        <>
          <Button
            onClick={() => handleUpdate(params.row.Id)}
            sx={{
              width: "90px",
              borderColor: "warning.main",
              fontSize: {
                xs: "0.7rem",
                sm: "0.85rem",
                md: "1rem",
              },
              padding: {
                xs: "3px",
                sm: "4px",
                md: "5px",
                lg: "6px",
              },
              "&:hover": {
                backgroundColor: "warning.main",
                color: "white",
              },
            }}
            variant="outlined"
            color="warning"
            className="m-1"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-7px" }}
                  icon={faPenToSquare}
                />
              </div>
              <div className="col-7">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-3px",
                  }}
                >
                  Edit
                </h6>
              </div>
            </div>
          </Button>
          <Button
            onClick={(e) => {
              warnings[1] = params.row.Id;
              warnings[0] = 1;
              setWarings([...warnings]);
            }}
            sx={{
              width: "110px",
              borderColor: "error.main",
              fontSize: {
                xs: "0.7rem",
                sm: "0.85rem",
                md: "1rem",
              },
              padding: {
                xs: "3px",
                sm: "4px",
                md: "5px",
                lg: "6px",
              },
              "&:hover": {
                backgroundColor: "error.main",
                color: "white",
              },
            }}
            variant="outlined"
            color="error"
            className=""
          >
            {" "}
            <div
              className="row "
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-5px" }}
                  icon={faTrashCan}
                />
              </div>
              <div className="col-8">
                <h6 style={{ float: "left", marginLeft: "-3px" }}>Delete</h6>
              </div>
            </div>
          </Button>
        </>
      ),
    },
  ];
  const rows = roles?.map((role, i) => {
    return {
      Id: i + 1,
      roleId: role.Id,
      roleName: role.roleName,
    };
  });
  return (
    <ThemeProvider theme={theme}>
      <Typography
        sx={{
          fontSize: {
            xs: "12px",
            sm: "12px", //500
            md: "0.85rem", //768
            lg: "17px", //1024
          },
        }}
      >
        <div>
          <Header />
        </div>
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: "13px",
            sm: "14px", //500
            md: "16px", //768
            lg: "18px", //1024
          },
        }}
      >
        <main
          style={
            isMobile && !isUnderMobile
              ? { marginTop: "190px" }
              : isUnderMobile
              ? { marginTop: "170px" }
              : isTablet
              ? { marginTop: "133px" }
              : { marginTop: "150px" }
          }
        >
          <center>
            <div
              style={
                isUnderMobile
                  ? {
                      minWidth: "500px",
                      overflow: "scroll",
                      marginBottom: "-5px",
                    }
                  : { maxWidth: "1100px" }
              }
              className="text-left"
            >
              <div
                className="tab-container "
                style={
                  isUnderMobile
                    ? { marginTop: "0", marginBottom: "-5px" }
                    : {
                        marginTop: "-20px",
                        marginBottom: "-5px",
                      }
                }
              >
                <Link className="p-2" to={`/user_mgt`}>
                  User Mgt
                </Link>
                <Link
                  className="p-2"
                  onClick={(e) => {
                    window.location.reload();
                  }}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "5px 5px 0 0",
                    border: "solid 0.05em rgb(181, 181, 181)",
                  }}
                >
                  Role Mgt
                </Link>
                {/* <Link className="p-2" to={`/account_mgt`}>
                  Account Mgt
                </Link> */}
                <Link className="p-2" to={`/backup`}>
                  Backup Data
                </Link>
              </div>
              <div className="card card-info card-outline">
                <div className="card-body">
                  <div
                    style={{
                      textAlign: "center",
                      height: "50px",
                    }}
                  >
                    <h3 className="p-3">
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "1.3rem",
                            sm: "1.3rem",
                            md: "1.5rem",
                          },
                        }}
                      >
                        List Of Role
                      </Typography>
                    </h3>
                  </div>
                  <div className="nav-bra pl-3 ml-1 p-1 ">
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "0.85rem",
                          sm: "0.85rem",
                          md: "1rem",
                        },
                      }}
                    >
                      <div
                        className="nav-bra pl-3 ml-1 p-2"
                        style={{
                          backgroundColor: "rgb(235, 235, 235)",
                          height: "40px",
                        }}
                      >
                        <span style={{ float: "left" }}>Home / Role List </span>
                      </div>
                    </Typography>
                  </div>

                  <div style={{ border: "solid 1px #ffb71b" }}></div>
                  <br />
                  {/* <div style={{ paddingBottom: "10px" }}>
                  <Button
                    variant="outline-info"
                    style={{ width: "150px", height: "35px" }}
                    onClick={handleOpen}
                  >
                    <h3
                      style={{
                        float: "left",
                        marginTop: "-6px",
                        marginRight: "5px",
                      }}
                    >
                      +
                    </h3>
                    <h6 style={{ float: "left", marginTop: "1px" }}>
                      {" "}
                      Create New
                    </h6>
                  </Button>
                </div> */}
                  <center>
                    <div
                      style={{
                        overflow: "scroll",
                      }}
                    >
                      <Box
                        sx={{
                          height: 500,

                          width: {
                            lg: "650px",
                            md: "600",
                            sm: "550",
                            xs: "500",
                          },
                          "& .super-app-theme--header": {
                            backgroundColor: "#1de5ec",
                            color: "black",
                            fontSize: {
                              xs: "13px",
                              sm: "15px", //500
                              md: "17px", //768
                              lg: "18px", //1024
                            },
                          },
                        }}
                      >
                        <DataGrid
                          rows={rows}
                          disableColumnFilter
                          disableColumnSelector
                          disableDensitySelector
                          columns={columns}
                          slots={{ toolbar: GridToolbar }}
                          slotProps={{
                            toolbar: {
                              showQuickFilter: true,
                            },
                          }}
                          initialState={{
                            ...rows.initialState,
                            pagination: {
                              paginationModel: { pageSize: 5 },
                            },
                          }}
                          pageSizeOptions={[5, 10, 15]}
                          getRowId={(row) => row.Id}
                        />
                      </Box>
                    </div>
                  </center>
                </div>
              </div>
              <Modal
                show={open}
                onHide={handleOpen}
                style={{ minWidth: "700px" }}
              >
                <Modal.Header closeButton>
                  Home / Role / {formik.values.id === "" ? "Create" : "Update"}
                </Modal.Header>
                <Modal.Body>
                  <center>
                    <Form onSubmit={formik.handleSubmit}>
                      <Form.Group as={Row} className="mb-3">
                        <Col sm={12}>
                          <TextField
                            id="outlined-basic"
                            label="Role Name"
                            variant="outlined"
                            size="small"
                            type="text"
                            name="roleName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.roleName}
                            error={
                              formik.touched.roleName && formik.errors.roleName
                            }
                            helperText={
                              formik.touched.roleName && formik.errors.roleName
                                ? `${formik.errors.roleName}`
                                : ""
                            }
                            style={{ minWidth: "200px" }}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formHorizontalUsername"
                      >
                        <Col sm={12}>
                          <Button
                            style={{ minWidth: "200px" }}
                            variant="outline-success"
                            type="submit"
                          >
                            {formik.values.id === "" ? "Create" : "Update"}
                          </Button>
                        </Col>
                      </Form.Group>
                    </Form>
                  </center>
                </Modal.Body>
              </Modal>
            </div>
            <Modal show={warnings[0] !== 0} onHide={() => setWarings([0, 0])}>
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <Typography variant="h6">
                  Are you sure you want to{" "}
                  {warnings[0] === 1 ? "delete" : "update"} this role?
                </Typography>
                <Row>
                  <center>
                    <Button
                      className="col mr-4"
                      style={{ maxWidth: "100px" }}
                      variant="contained"
                      color="error"
                      onClick={() => {
                        if (warnings[0] === 1) {
                          handleDelete(warnings[1]);
                          setWarings([0, 0]);
                        } else if (warnings[0] === 2) {
                          formik.handleSubmit();
                        }
                      }}
                    >
                      Confirm
                    </Button>
                    <Button
                      className="col ml-4"
                      style={{ maxWidth: "100px" }}
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        warnings[0] = 0;
                        setWarings([...warnings]);
                      }}
                    >
                      Cancel
                    </Button>
                  </center>
                </Row>
              </Modal.Body>
            </Modal>
          </center>
        </main>
      </Typography>
    </ThemeProvider>
  );
};
export default RoleMng;
