/** @format */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../Header";
import { Col, Form, Row } from "react-bootstrap";
import {
  Autocomplete,
  Box,
  TextField,
  createTheme,
  ThemeProvider,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import { faBackwardStep } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const theme = createTheme({
  breakpoints: { values: { xs: 0, sm: 500, md: 769, lg: 1024, xl: 1440 } },
});
const PurchaseReport = () => {
  const [purchases, setPurchases] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [allPurchases, setAllPurchases] = useState([]);
  const [oneSuppliers, setOneSuppliers] = useState(null);
  const [users, setUsers] = useState([]);
  const [purchaseItems, setPurchaseItems] = useState([]);
  const [newPurchaseItems, setNewPurchaseItems] = useState([]);
  const [items, setItems] = useState([]);
  const [units, setUnits] = useState([]);
  const [models, setModels] = useState([]);
  const [open, setOpen] = useState(0);
  const [purchasesDate, setPurchasesDate] = useState({
    start: new Date(new Date().setDate(new Date().getDate() - 1)),
    end: new Date(new Date().setHours(new Date().getHours() + 7)),
  });
  const [checkDate, setCheckDate] = useState({
    start: new Date(),
    end: new Date(),
  });
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isUnderMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const fetchAllData = async () => {
    const [purchases, user, purchaseItem, item, unit, model, supplier] =
      await Promise.all([
        axios.get("https://enin.server.highendtradingplc.com/purchase"),
        axios.get("https://enin.server.highendtradingplc.com/user"),
        axios.get("https://enin.server.highendtradingplc.com/purchase_item"),
        axios.get("https://enin.server.highendtradingplc.com/item"),
        axios.get("https://enin.server.highendtradingplc.com/unit"),
        axios.get("https://enin.server.highendtradingplc.com/model"),
        axios.get("https://enin.server.highendtradingplc.com/supplier"),
      ]);
    setAllPurchases(purchases.data);
    setUsers(user.data?.filter((user) => user.roleId >= 2));
    setPurchaseItems(purchaseItem.data);
    setItems(item.data);
    setUnits(unit.data);
    setSuppliers(supplier.data);
    setModels(model.data);
    let data = [];
    purchases.data?.map((qua) => {
      const date = new Date(qua.date);
      const startDate = (date - purchasesDate.start) / 86400000;
      const endDate = (purchasesDate.end - date) / 86400000;
      if (startDate >= 0 && endDate >= 0) {
        data.push(qua);
      }
      setPurchases(data);
      return 0;
    });
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  const defaultProps = {
    options: suppliers,
    getOptionLabel: (option) => option.fullName,
  };

  const handlView = (row) => {
    const purchaseItem = purchaseItems.filter((s) => s.purchaseId === row.id);
    setNewPurchaseItems(purchaseItem);
    setOpen(1);
  };
  const columns = [
    {
      field: "Id",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      headerName: "No",
    },
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 0.9,
      headerName: "Purchases Id",
    },
    {
      field: "userId",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1.5,
      headerName: "Purchased By",

      valueGetter: (params) =>
        users.find((user) => user.Id === params)?.fullName || "",
    },
    {
      field: "price",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1.1,
      headerName: "Total Price",
      valueGetter: (params) => params.toLocaleString(),
    },

    {
      field: "date",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerName: "Date",

      valueGetter: (params) =>
        `${new Date(params).getUTCDate()}/${
          new Date(params).getUTCMonth() + 1
        }/${new Date(params).getUTCFullYear()}`,
    },
  ];
  const columnPurchases = [
    {
      field: "Id",
      headerClassName: "super-app-theme--header",
      headerName: "No",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
    },
    {
      field: "id",
      headerName: "Item Id",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "description",
      headerClassName: "super-app-theme--header",
      headerName: "Description",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "modelId",
      headerClassName: "super-app-theme--header",
      headerName: "Model",
      headerAlign: "center",
      align: "center",
      flex: 0.9,
      valueGetter: (params) =>
        models.find((model) => model.Id === params)?.modelName || "",
    },
    {
      field: "unitId",
      headerClassName: "super-app-theme--header",
      headerName: "Unit",
      headerAlign: "center",
      align: "center",
      flex: 0.9,
      valueGetter: (params) =>
        units.find((unit) => unit.Id === params)?.unitName || "",
    },
    {
      field: "qty",
      headerClassName: "super-app-theme--header",
      headerName: "Qty",
      headerAlign: "center",
      align: "center",
      flex: 0.8,
    },
    {
      field: "unitPrice",
      headerClassName: "super-app-theme--header",
      headerName: "U Price",
      headerAlign: "center",
      align: "center",
      flex: 0.8,
      valueGetter: (params) => params.toLocaleString(),
    },
    {
      field: "totalPrice",
      headerClassName: "super-app-theme--header",
      headerName: "T. Price",
      headerAlign: "center",
      align: "center",
      flex: 0.9,
      valueGetter: (params) => params.toLocaleString(),
    },
  ];
  const rows = purchases?.map((purchase, index) => {
    const supplier = suppliers.find((s) => s.Id === purchase.supplierId);
    return {
      Id: index + 1,
      id: purchase.Id,
      fullName: supplier.fullName,
      price: purchase.totalPrice,
      userId: purchase.userId,
      date: purchase.date,
    };
  });
  const rowPurchase = newPurchaseItems?.map((purchase, index) => {
    const item = items.find((s) => s.Id === purchase.itemId);

    return {
      Id: index + 1,
      id: item.Id,
      description: item.description,
      modelId: item.modelId,
      unitId: item.unitId,
      qty: purchase.quantity,
      unitPrice: purchase.price,
      totalPrice: purchase.totalPrice,
    };
  });

  return (
    <ThemeProvider theme={theme}>
      <Typography
        sx={{
          fontSize: {
            xs: "12px",
            sm: "12px", //500
            md: "0.85rem", //768
            lg: "17px", //1024
          },
        }}
      >
        <div>
          <Header />
        </div>
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: "13px",
            sm: "14px", //500
            md: "16px", //768
            lg: "18px", //1024
          },
        }}
      >
        <main
          style={
            isMobile && !isUnderMobile
              ? { marginTop: "190px" }
              : isUnderMobile
              ? { marginTop: "170px" }
              : isTablet
              ? { marginTop: "133px" }
              : { marginTop: "150px" }
          }
        >
          <center>
            <div
              style={
                isUnderMobile
                  ? {
                      minWidth: "500px",
                      overflow: "scroll",
                      marginBottom: "-5px",
                    }
                  : { maxWidth: "1100px" }
              }
              className="text-left"
            >
              <div
                className="tab-container "
                style={
                  isUnderMobile
                    ? { marginTop: "0", marginBottom: "-5px" }
                    : {
                        marginTop: "-20px",
                        marginBottom: "-5px",
                      }
                }
              >
                <Link
                  className="p-2"
                  onClick={(e) => {
                    window.location.reload();
                  }}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "5px 5px 0 0",
                    border: "solid 0.05em rgb(181, 181, 181)",
                  }}
                >
                  Purchase Report
                </Link>
                <Link className="p-2" to={`/sales_report`}>
                  Sales Report
                </Link>
                <Link className="p-2" to={`/delivery_report`}>
                  Deliverys Report
                </Link>
                <Link className="p-2" to={`/stock_report`}>
                  Stock Report
                </Link>

                <Link className="p-2" to={`/quantity_report`}>
                  Item Report
                </Link>
              </div>
              <div className="card card-info card-outline">
                {/* <div className="card-header">
            <center>
              <div>
                <h4>System Stock Balance</h4>
              </div>
            </center>
          </div> */}
                <div className="card-body ">
                  <div
                    className="nav-bra pl-3 ml-1 p-2 row"
                    style={{
                      backgroundColor: "rgb(235, 235, 235)",
                    }}
                  >
                    <span className="col" style={{ padding: "5px" }}>
                      Home / Purchases Report{" "}
                    </span>
                    {open === 1 && (
                      <Button
                        onClick={(e) => {
                          setOpen(0);
                        }}
                        sx={{
                          width: "100px",
                          borderColor: "success.main",
                          fontSize: {
                            xs: "0.85rem",
                            sm: "0.85rem",
                            md: "1rem",
                          },
                          padding: {
                            xs: "4px",
                            sm: "5px",
                            md: "6px",
                            lg: "6px",
                          },
                          "&:hover": {
                            backgroundColor: "success.main",
                            color: "white",
                          },
                        }}
                        variant="outlined"
                        color="success"
                        type="submit"
                        className="m-1"
                        style={{ float: "right" }}
                      >
                        <div
                          className="row"
                          style={{
                            marginBottom: "-10px",
                            marginLeft: "-10px",
                          }}
                        >
                          <div className="col">
                            <FontAwesomeIcon
                              style={{
                                float: "right",
                                marginRight: "-1px",
                                marginTop: "2px",
                                marginBottom: "-4px",
                              }}
                              icon={faBackwardStep}
                            />
                          </div>
                          <div className="col-7">
                            <h6
                              style={{
                                float: "left",
                                marginLeft: "-10px",
                                marginTop: "-1px",
                                marginBottom: "-7px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: {
                                    xs: "0.85rem",
                                    sm: "0.85rem",
                                    md: "1rem",
                                  },
                                }}
                              >
                                Back
                              </Typography>
                            </h6>
                          </div>
                        </div>
                      </Button>
                    )}
                  </div>
                  <br />
                  <div style={{ border: "solid 1px #ffb71b" }}></div>
                  <br />
                  {open === 0 && (
                    <Form>
                      <Form.Group as={Row}>
                        <Col
                          md={4}
                          style={{ minWidth: "270px" }}
                          className="mb-2"
                        >
                          <Autocomplete
                            {...defaultProps}
                            id="controlled-demo"
                            sx={{
                              "& .MuiInputBase-root": {
                                fontSize: {
                                  xs: "0.7rem",
                                  sm: "0.85rem",
                                  md: "0.9rem",
                                },
                                padding: {
                                  xs: "1px",
                                  sm: "2px",
                                  md: "3px",
                                  lg: "5px",
                                },
                              },
                            }}
                            value={oneSuppliers}
                            onChange={(event, newValue) => {
                              setOneSuppliers(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{
                                  width: "200px",
                                }}
                                label="Select Suppliers"
                              />
                            )}
                          />
                        </Col>
                        <Col
                          md={4}
                          style={{ minWidth: "270px" }}
                          className="mb-2"
                        >
                          <Form.Label
                            style={{
                              margin: "7px 5px 0 0",
                            }}
                          >
                            From
                          </Form.Label>
                          <TextField
                            type="date"
                            sx={{
                              width: "200px",
                              "& .MuiInputBase-input": {
                                fontSize: {
                                  xs: "0.7rem",
                                  sm: "0.85rem",
                                  md: "1rem",
                                },
                                padding: {
                                  xs: "6px",
                                  sm: "10px",
                                  md: "9px",
                                  lg: "12px",
                                },
                              },
                            }}
                            onChange={(event) => {
                              if (
                                new Date() >= new Date(event.target.value) &&
                                new Date(purchasesDate.end) >=
                                  new Date(event.target.value)
                              ) {
                                purchasesDate.start = new Date(
                                  event.target.value
                                );
                                setPurchasesDate(purchasesDate);
                              }
                              checkDate.start = new Date(event.target.value);
                              setCheckDate(checkDate);
                            }}
                            error={
                              new Date() < new Date(checkDate.start) ||
                              new Date(checkDate.end) <
                                new Date(checkDate.start)
                            }
                          />
                        </Col>
                        <Col
                          md={4}
                          style={{ minWidth: "270px" }}
                          className="mb-2 "
                        >
                          <Form.Label
                            style={{
                              margin: "7px 25px 0 0",
                            }}
                          >
                            To
                          </Form.Label>
                          <TextField
                            type="date"
                            sx={{
                              width: "200px",
                              "& .MuiInputBase-input": {
                                fontSize: {
                                  xs: "0.7rem",
                                  sm: "0.85rem",
                                  md: "1rem",
                                },
                                padding: {
                                  xs: "6px",
                                  sm: "10px",
                                  md: "9px",
                                  lg: "12px",
                                },
                              },
                            }}
                            onChange={(event) => {
                              if (
                                new Date() >= new Date(event.target.value) &&
                                new Date(event.target.value) >=
                                  new Date(purchasesDate.start)
                              ) {
                                purchasesDate.end = new Date(
                                  event.target.value
                                );
                                setPurchasesDate(purchasesDate);
                              }
                              checkDate.end = new Date(event.target.value);
                              setCheckDate(checkDate);
                            }}
                            error={
                              new Date() < new Date(checkDate.end) ||
                              new Date(checkDate.end) <
                                new Date(checkDate.start)
                            }
                          />
                        </Col>
                        <Col md={2}>
                          <Button
                            style={{ marginLeft: "50px" }}
                            sx={{
                              borderColor: "info.main",
                              fontSize: {
                                xs: "11px",
                                sm: "12px",
                                md: "0.9rem",
                              },
                              padding: {
                                xs: "3px",
                                sm: "3px",
                                md: "4px",
                                lg: "5px",
                              },
                              "&:hover": {
                                backgroundColor: "info.main",
                                color: "white",
                              },
                            }}
                            onClick={(e) => {
                              let data = [];
                              if (oneSuppliers === null) {
                                allPurchases?.map((qua) => {
                                  const date = new Date(qua.date);
                                  const startDate =
                                    (date - purchasesDate.start) / 86400000;
                                  const endDate =
                                    (purchasesDate.end - date) / 86400000;
                                  if (startDate >= 0 && endDate >= 0) {
                                    data.push(qua);
                                  }
                                });
                              } else {
                                allPurchases?.map((qua) => {
                                  const date = new Date(qua.date);
                                  const startDate =
                                    (date - purchasesDate.start) / 86400000;
                                  const endDate =
                                    (purchasesDate.end - date) / 86400000;
                                  if (
                                    startDate >= 0 &&
                                    endDate >= 0 &&
                                    qua.supplierId === oneSuppliers.Id
                                  ) {
                                    data.push(qua);
                                  }
                                });
                              }

                              setPurchases(data);
                            }}
                            variant="outlined"
                            color="info"
                          >
                            Search
                          </Button>
                        </Col>
                      </Form.Group>
                      <hr />
                    </Form>
                  )}
                  <div
                    style={{
                      overflow: "scroll",
                    }}
                  >
                    <Box
                      sx={{
                        height: 500,
                        width: {
                          lg: "100%",
                          md: "1000px",
                          sm: "1000px",
                          xs: "1000px",
                        },
                        "& .super-app-theme--header": {
                          backgroundColor: "#1de5ec",
                          color: "black",
                          fontSize: {
                            xs: "13px",
                            sm: "15px", //500
                            md: "17px", //768
                            lg: "18px", //1024
                          },
                        },
                      }}
                    >
                      <DataGrid
                        rows={open === 0 ? rows : rowPurchase}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        sx={{
                          "& .MuiDataGrid-cell": {
                            fontSize: {
                              xs: "12px",
                              sm: "14px", //500
                              md: "16px", //768
                              lg: "17px", //1024
                            },
                          },
                        }}
                        columns={open === 0 ? columns : columnPurchases}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                          },
                        }}
                        initialState={
                          open === 0
                            ? {
                                ...purchases.initialState,
                                pagination: {
                                  paginationModel: { pageSize: 5 },
                                },
                              }
                            : {
                                ...newPurchaseItems.initialState,
                                pagination: {
                                  paginationModel: { pageSize: 5 },
                                },
                              }
                        }
                        pageSizeOptions={[5, 10, 15]}
                        onCellClick={(row) => {
                          if (open === 0) handlView(row.row);
                        }}
                        getRowId={(row) => row.Id}
                      />
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </center>
        </main>
      </Typography>
    </ThemeProvider>
  );
};
export default PurchaseReport;
