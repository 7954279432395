import React, { useEffect, useRef, useState } from "react";
import Header from "../Header";
import { Link, useParams } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import {
  Autocomplete,
  Button,
  createTheme,
  useMediaQuery,
  ThemeProvider,
  Typography,
  Box,
  Container,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import { Col, Form, Modal, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBackwardStep,
  faCalendar,
  faCode,
  faEye,
  faFloppyDisk,
  faPenToSquare,
  faPrint,
  faTrashCan,
  faTrashRestore,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { useReactToPrint } from "react-to-print";
import QRCode from "qrcode";
import HexCharacterManipulator from "./AllDataFetch";
import { jwtDecode } from "jwt-decode";
const validationSchema = Yup.object().shape({
  date: Yup.date().required("Date is required!"),

  reciptNumber: Yup.string()
    .matches(
      /^[0-9]{4}$/,
      "Recept number must be exactly 4 numeric characters."
    )
    .required("Recept number is required."),
});
const theme = createTheme({
  breakpoints: { values: { xs: 0, sm: 500, md: 769, lg: 1024, xl: 1440 } },
  palette: {
    warning: {
      main: "#ffc107", // Main warning color
      light: "#ffb74d", // Light variation of warning color
      dark: "#f57c00", // Dark variation of warning color
      contrastText: "#000", // Text color on warning
    },
  },
});

const Delivery = () => {
  const [sales, setSales] = useState([]);
  const [saleDeliverys, setSaleDeliverys] = useState([]);
  const [saleItems, setSaleItems] = useState([]);
  const [items, setItems] = useState([]);
  const [units, setUnits] = useState([]);
  const [models, setModels] = useState([]);
  const [taxs, setTaxs] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [users, setUsers] = useState([]);
  const [deliverys, setDeliverys] = useState([]);
  const [deliveryItems, setDeliveryItems] = useState([]);
  const [itemValues, setItemValues] = useState([]);
  const [newItems, setNewItems] = useState([]);
  const [newSaleItems, setNewSaleItems] = useState([]);
  const [selectItems, setSelectItems] = useState(null);
  const [open, setOpen] = useState(0);
  const [openReject, setOpenReject] = useState(0);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [logicals, setlogicals] = useState([]);
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [messages, setMessages] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [update, setUpdate] = useState({
    itemId: "",
    quantity: "",
    remark: "",
  });
  const [price, setPrice] = useState({
    total: 0,
    discount: 0,
    grandTotal: 0,
    tax: 0,
    withHold: 0,
  });
  const userData = jwtDecode(sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const contentRef = useRef(null);
  const { id } = useParams();
  const reactToPrint = useReactToPrint({
    contentRef: contentRef, // Pass the ref to the hook
  });
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isUnderMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const fetchAllData = async () => {
    try {
      const [
        sale,
        saleItem,
        item,
        unit,
        model,
        tax,
        customer,
        user,
        delivery,
        deliveryItem,
        logical,
      ] = await Promise.all([
        axios.get("https://enin.server.highendtradingplc.com/sale"),
        axios.get("https://enin.server.highendtradingplc.com/sale_item"),
        axios.get("https://enin.server.highendtradingplc.com/item"),
        axios.get("https://enin.server.highendtradingplc.com/unit"),
        axios.get("https://enin.server.highendtradingplc.com/model"),
        axios.get("https://enin.server.highendtradingplc.com/tax"),
        axios.get("https://enin.server.highendtradingplc.com/customer"),
        axios.get("https://enin.server.highendtradingplc.com/user"),
        axios.get("https://enin.server.highendtradingplc.com/delivery"),
        axios.get("https://enin.server.highendtradingplc.com/delivery_item"),
        axios.get("https://enin.server.highendtradingplc.com/logical_stock"),
      ]);
      setSales(sale.data?.filter((s) => s.statusId === 2));
      setSaleDeliverys(sale.data);
      setSaleItems(saleItem.data);
      setItems(item.data);
      setUnits(unit.data);
      setModels(model.data);
      setTaxs(tax.data);
      setCustomers(customer.data);
      setUsers(user.data?.filter((user) => user.roleId >= 2));
      setDeliverys(delivery.data);
      setDeliveryItems(deliveryItem.data);
      setlogicals(logical.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  const itemData = {
    options: newItems,
    getOptionLabel: (option) => option.description,
  };

  const formik = useFormik({
    initialValues: {
      date: "",
      deliveryPersonName: "",
      reciptNumber: "",
      saleId: "",
      customerId: "",
      Id: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      if (itemValues.length === 0) return;
      if (isSubmitted) return;
      setIsSubmitted(true);
      const currentTime = new Date();
      const hours = currentTime.getHours();
      const minutes = currentTime.getMinutes();
      const seconds = currentTime.getSeconds();

      // Append the current time to the date value
      values.date = `${values.date} ${hours}:${minutes}:${seconds}`;

      try {
        const customer = customers.find((c) => c.Id === values.customerId);
        const hexCharacterManipulator = new HexCharacterManipulator();
        let charTohexa = hexCharacterManipulator.charTohexa(
          [price.total, price.tax, price.withHold],
          price.grandTotal
        );
        const data = `
        Delivery ID: ${charTohexa},
        Customer Name : ${customer.fullName},
        Customer Phone : ${customer.primaryPhoneNumber},
        Grand Price : ${price.grandTotal}`;
        generateQRCode(data);
        setOpen(2);
        const saleItem = saleItems.filter((s) => {
          return (
            s.saleId === values.saleId && parseInt(s.remainingQuantity) > 0
          );
        });
        if (saleItem.length === itemValues.length) {
          let checkResult = saleItem.filter((s) => {
            const itemQuantity = itemValues.find((i) => i.itemId === s.itemId);
            return (
              parseInt(s.remainingQuantity) !== parseInt(itemQuantity.quantity)
            );
          });
          if (checkResult.length === 0) {
            await axios.put(
              "https://enin.server.highendtradingplc.com/sale/update/" +
                values.saleId,
              { statusId: 4 }
            );
          }
        }
        await axios
          .post("https://enin.server.highendtradingplc.com/delivery/add", {
            date: values.date,
            customerId: values.customerId,
            saleId: values.saleId,
            reciptNumber: values.reciptNumber,
            statusId: 1,
            userId: userData.Id,
          })
          .then(async (res) => {
            for (let i = 0; i < itemValues.length; ) {
              let counter = 0;
              const saleItem = newSaleItems.find(
                (s) => s.itemId === itemValues[i].itemId
              );
              await axios
                .post(
                  "https://enin.server.highendtradingplc.com/delivery_item/add",
                  {
                    unitPrice: saleItem.unitPrice,
                    totalPrice: saleItem.totalPrice,
                    discount: saleItem.discount,
                    quantity: itemValues[i].quantity,
                    remainingQuantity: itemValues[i].quantity,
                    itemId: itemValues[i].itemId,
                    remark: itemValues[i].remark,
                    deliveryId: res.data.data.Id,
                  }
                )
                .then((ress) => counter++);
              await axios
                .put(
                  "https://enin.server.highendtradingplc.com/sale_item/update/" +
                    saleItem.Id,
                  {
                    remainingQuantity:
                      parseInt(saleItem.remainingQuantity) -
                      parseInt(itemValues[i].quantity),
                  }
                )
                .then((ress) => counter++);
              if (counter === 2) i++;
            }
          });
        handleBack();
      } catch (error) {
        console.log(error);
      }
      setIsSubmitted(false);
    },
  });

  const handleOpenSalesItem = (row) => {
    if (parseInt(id) === 1) {
      let itemValueData = [],
        newItemData = [];
      const saleItem = saleItems.filter((s) => {
        const item = items.find((i) => i.Id === s.itemId);
        if (s.saleId === row.saleId) {
          const tax = taxs.find((t) => t.Id === item.taxId);
          let vat = ((s.totalPrice - s.discount) * tax.taxValue) / 100;
          price.tax += vat;
          price.total += s.totalPrice - s.discount;
          price.withHold +=
            item.statusId === 2
              ? s.unitPrice >= 10000
                ? s.totalPrice * 0.02
                : 0
              : s.unitPrice >= 3000
              ? s.totalPrice * 0.02
              : 0;
        }

        if (
          s.saleId === row.saleId &&
          s.remainingQuantity > 0 &&
          item.statusId === 2
        ) {
          itemValueData.push({
            itemId: item.Id,
            quantity: s.remainingQuantity,
            remark: "",
          });
          newItemData.push(item);
        }
        return (
          s.saleId === row.saleId &&
          s.remainingQuantity > 0 &&
          item.statusId === 2
        );
      });
      price.grandTotal = row.totalPrice;
      formik.values.saleId = row.saleId;
      formik.values.customerId = row.customerId;
      setNewItems(newItemData);
      setItemValues(itemValueData);
      setNewSaleItems(saleItem);
      setOpen(1);
    } else {
      if (row.statusId === 1) {
        let itemValueData = [];
        const saleItem = saleItems.filter((s) => {
          const item = items.find((i) => i.Id === s.itemId);
          if (s.saleId === row.saleId) {
            const tax = taxs.find((t) => t.Id === item.taxId);
            let vat = ((s.totalPrice - s.discount) * tax.taxValue) / 100;
            price.tax += vat;
            price.total += s.totalPrice - s.discount;
            price.withHold +=
              item.statusId === 2
                ? s.unitPrice >= 10000
                  ? s.totalPrice * 0.02
                  : 0
                : s.unitPrice >= 3000
                ? s.totalPrice * 0.02
                : 0;
          }
          return s.saleId === row.saleId;
        });
        deliveryItems.filter((d) => {
          if (d.deliveryId === row.deliveryId) {
            const item = items.find((i) => i.Id === d.itemId);
            itemValueData.push({
              itemId: item.Id,
              quantity: d.quantity,
              remark: d.remark,
              remainingQuantity: d.remainingQuantity,
            });
          }
          return d.deliveryId === row.deliveryId;
        });
        formik.values.customerId = row.customerId;
        formik.values.reciptNumber = row.reciptNumber;
        formik.values.deliveryPersonName = row?.deliveryPersonName;
        formik.values.date = row.date;
        price.grandTotal = row.totalPrice;
        formik.values.Id = row.deliveryId;
        const customer = customers.find((c) => c.Id === row.customerId);
        const hexCharacterManipulator = new HexCharacterManipulator();
        let charTohexa = hexCharacterManipulator.charTohexa(
          [price.total, price.tax, price.withHold],
          price.grandTotal
        );
        const data = `
        Delivery ID: ${charTohexa},
        Customer Name : ${customer.fullName},
        Customer Phone : ${customer.primaryPhoneNumber},
        Grand Price : ${price.grandTotal}`;
        generateQRCode(data);
        setItemValues(itemValueData);
        setNewSaleItems(saleItem);
        setOpen(2);
      }
    }
  };

  const handleBack = () => {
    fetchAllData();
    formik.values.customerId = "";
    formik.values.date = "";
    formik.values.deliveryPersonName = "";
    formik.values.reciptNumber = "";
    formik.values.saleId = "";
    formik.values.Id = "";
    setItemValues([]);
    setSelectItems(null);
    setOpen(0);
    setOpenReject(0);
    setPrice({ total: 0, discount: 0, grandTotal: 0, tax: 0, withHold: 0 });
  };
  const generateQRCode = (text) => {
    QRCode.toDataURL(text)
      .then((url) => {
        setQrCodeUrl(url); // Set the QR code image URL
      })
      .catch((err) => console.error("Error generating QR code:", err));
  };
  const columnSales = [
    {
      field: "Id",
      headerName: "No",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "saleId",
      headerName: "SalesID",
      headerAlign: "center",
      align: "center",
      flex: 0.7,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "userId",
      headerName: "Sales By",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        users.find((user) => user.Id === params)?.fullName || "",
    },
    {
      field: "customerId",
      headerName: "Customer Name",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        customers.find((customer) => customer.Id === params)?.fullName || "",
    },

    {
      field: "totalPrice",
      headerName: "Total Price",
      headerAlign: "center",
      align: "center",
      flex: 0.8,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params.toLocaleString(),
    },
    {
      field: "date",
      headerName: "Date",
      headerAlign: "center",
      align: "center",
      flex: 0.7,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        `${new Date(params).getDate()}/${
          new Date(params).getMonth() + 1
        }/${new Date(params).getFullYear()}`,
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 150,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <>
          <Button
            onClick={() => handleOpenSalesItem(params.row)}
            sx={{
              width: "90px",
              borderColor: "primary.main",
              fontSize: {
                xs: "0.85rem",
                sm: "0.85rem",
                md: "1rem",
              },
              padding: {
                xs: "4px",
                sm: "5px",
                md: "6px",
                lg: "6px",
              },
              "&:hover": {
                backgroundColor: "primary.main",
                color: "white",
              },
            }}
            variant="outlined"
            color="primary"
            className="m-1"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{
                    float: "right",
                    marginRight: "-1px",
                    marginTop: "2px",
                    marginBottom: "-4px",
                  }}
                  icon={faEye}
                />
              </div>
              <div className="col-6">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-10px",
                    marginTop: "-1px",
                    marginBottom: "-7px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "0.85rem",
                        sm: "0.85rem",
                        md: "1rem",
                      },
                    }}
                  >
                    View
                  </Typography>
                </h6>
              </div>
            </div>
          </Button>
        </>
      ),
    },
  ];
  const rowSales = sales.map((sale, index) => {
    return {
      Id: index + 1,
      userId: sale.userId,
      saleId: sale.Id,
      date: sale.date,
      totalPrice: sale.totalPrice,
      customerId: sale.customerId,
    };
  });
  const columnDelivery = [
    {
      field: "Id",
      headerName: "No",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "deliveryId",
      headerName: "DeliveryID",
      headerAlign: "center",
      align: "center",
      flex: 0.9,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "saleId",
      headerName: "SaleID",
      headerAlign: "center",
      align: "center",
      flex: 0.8,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "userId",
      headerName: "Deliverd By",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        users.find((user) => user.Id === params)?.fullName || "",
    },
    {
      field: "customerId",
      headerName: "Customer Name",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        customers.find((customer) => customer.Id === params)?.fullName || "",
    },

    {
      field: "reason",
      headerName: "Reason",
      headerAlign: "center",
      align: "center",
      flex: 0.9,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "date",
      headerName: "Date",
      headerAlign: "center",
      align: "center",
      flex: 0.7,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        `${new Date(params).getDate()}/${
          new Date(params).getMonth() + 1
        }/${new Date(params).getFullYear()}`,
    },
    {
      field: "statusId",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      flex: 0.7,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => (params === 1 ? "Delivery" : "Rejected"),
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 150,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <>
          <Button
            onClick={() => handleOpenSalesItem(params.row)}
            sx={{
              width: "90px",
              borderColor: "primary.main",
              fontSize: {
                xs: "0.85rem",
                sm: "0.85rem",
                md: "1rem",
              },
              padding: {
                xs: "4px",
                sm: "5px",
                md: "6px",
                lg: "6px",
              },
              "&:hover": {
                backgroundColor: "primary.main",
                color: "white",
              },
            }}
            variant="outlined"
            color="primary"
            className="m-1"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{
                    float: "right",
                    marginRight: "-1px",
                    marginTop: "2px",
                    marginBottom: "-4px",
                  }}
                  icon={faEye}
                />
              </div>
              <div className="col-6">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-10px",
                    marginTop: "-1px",
                    marginBottom: "-7px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "0.85rem",
                        sm: "0.85rem",
                        md: "1rem",
                      },
                    }}
                  >
                    View
                  </Typography>
                </h6>
              </div>
            </div>
          </Button>
        </>
      ),
    },
  ];

  const rowDelivery = deliverys.map((delivery, index) => {
    const sale = saleDeliverys.find((s) => s.Id === delivery?.saleId);

    return {
      saleId: sale?.Id,
      Id: index + 1,
      userId: delivery?.userId,
      deliveryId: delivery?.Id,
      reciptNumber: delivery.reciptNumber,
      date: delivery?.date,
      statusId: delivery?.statusId,
      customerId: delivery?.customerId,
      reason: delivery?.reason,
      totalPrice: sale?.totalPrice,
    };
  });
  const handelInvoice = () => {
    let data = [];
    for (let index = itemValues.length; index < 5; index++) {
      data.push(
        <tr key={index}>
          <td>{index + 1}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          {parseInt(id) === 1 && <td></td>}
        </tr>
      );
    }
    return data;
  };
  const style = {
    backgroundColor: "white",
    borderRadius: "5px 5px 0 0",
    border: "solid 0.05em rgb(181, 181, 181)",
  };
  return (
    <ThemeProvider theme={theme}>
      <Typography
        sx={{
          fontSize: {
            xs: "12px",
            sm: "12px", //500
            md: "0.85rem", //768
            lg: "17px", //1024
          },
        }}
      >
        <div>
          <Header />
        </div>
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: "13px",
            sm: "14px", //500
            md: "16px", //768
            lg: "18px", //1024
          },
        }}
      >
        {" "}
        <div>
          <main
            className=""
            style={
              isMobile && !isUnderMobile
                ? { marginTop: "190px" }
                : isUnderMobile
                ? { marginTop: "170px" }
                : isTablet
                ? { marginTop: "133px" }
                : { marginTop: "150px" }
            }
          >
            <center>
              <div
                style={
                  isUnderMobile
                    ? {
                        minWidth: "500px",
                        overflow: "scroll",
                        marginBottom: "-5px",
                      }
                    : { maxWidth: "1100px" }
                }
                className="text-left"
              >
                <div
                  className="tab-container "
                  style={
                    isUnderMobile
                      ? { marginTop: "0", marginBottom: "-5px" }
                      : {
                          marginTop: "-20px",
                          marginBottom: "-5px",
                        }
                  }
                >
                  <Link
                    className="p-2"
                    onClick={(e) => {
                      if (parseInt(id) === 1) window.location.reload();
                      else window.location.replace(`/delivery/1`);
                    }}
                    style={parseInt(id) === 1 ? style : {}}
                  >
                    Sales List
                  </Link>
                  <Link
                    className="p-2"
                    to={`/delivery/2`}
                    onClick={(e) => {
                      if (parseInt(id) === 2) window.location.reload();
                      else window.location.replace(`/delivery/2`);
                    }}
                    style={parseInt(id) === 2 ? style : {}}
                  >
                    Delivery List
                  </Link>
                </div>
                <Snackbar
                  open={openMessage}
                  onClose={(e) => setOpenMessage(false)}
                  autoHideDuration={6000}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  <Alert
                    variant="outlined"
                    severity="error"
                    onClose={(e) => setOpenMessage(false)}
                    sx={{ bgcolor: "background.paper" }}
                  >
                    {messages}
                  </Alert>
                </Snackbar>
                <div className="card card-info card-outline">
                  <div className="card-header">
                    {open === 1 && (
                      <Button
                        onClick={handleBack}
                        sx={{
                          width: "100px",
                          borderColor: "success.main",
                          fontSize: {
                            xs: "0.85rem",
                            sm: "0.85rem",
                            md: "1rem",
                          },
                          padding: {
                            xs: "4px",
                            sm: "5px",
                            md: "6px",
                            lg: "6px",
                          },
                          "&:hover": {
                            backgroundColor: "success.main",
                            color: "white",
                          },
                        }}
                        variant="outlined"
                        color="success"
                        type="submit"
                        className="m-1"
                        style={{ float: "right" }}
                      >
                        <div
                          className="row"
                          style={{
                            marginBottom: "-10px",
                            marginLeft: "-10px",
                          }}
                        >
                          <div className="col">
                            <FontAwesomeIcon
                              style={{
                                float: "right",
                                marginRight: "-1px",
                                marginTop: "2px",
                                marginBottom: "-4px",
                              }}
                              icon={faBackwardStep}
                            />
                          </div>
                          <div className="col-7">
                            <h6
                              style={{
                                float: "left",
                                marginLeft: "-10px",
                                marginTop: "-1px",
                                marginBottom: "-7px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: {
                                    xs: "0.85rem",
                                    sm: "0.85rem",
                                    md: "1rem",
                                  },
                                }}
                              >
                                Back
                              </Typography>
                            </h6>
                          </div>
                        </div>
                      </Button>
                    )}
                  </div>
                  {open < 2 && (
                    <div className="card-body">
                      {open === 0 && (
                        <div style={{ overflow: "scroll" }}>
                          <Box
                            sx={{
                              height: 500,
                              width: {
                                lg: "100%",
                                md: "1000px",
                                sm: "1000px",
                                xs: "1000px",
                              },
                              "& .super-app-theme--header": {
                                backgroundColor: "#1de5ec",
                                color: "black",
                                fontSize: {
                                  xs: "13px",
                                  sm: "15px", //500
                                  md: "17px", //768
                                  lg: "18px", //1024
                                },
                              },
                            }}
                          >
                            <DataGrid
                              rows={parseInt(id) === 1 ? rowSales : rowDelivery}
                              disableColumnFilter
                              disableColumnSelector
                              disableDensitySelector
                              sx={{
                                "& .MuiDataGrid-cell": {
                                  fontSize: {
                                    xs: "12px",
                                    sm: "14px", //500
                                    md: "16px", //768
                                    lg: "17px", //1024
                                  },
                                },
                              }}
                              columns={
                                parseInt(id) === 1
                                  ? columnSales
                                  : columnDelivery
                              }
                              slots={{ toolbar: GridToolbar }}
                              slotProps={{
                                toolbar: {
                                  showQuickFilter: true,
                                },
                              }}
                              initialState={
                                parseInt(id) === 1
                                  ? {
                                      ...sales.initialState,
                                      pagination: {
                                        paginationModel: { pageSize: 5 },
                                      },
                                    }
                                  : {
                                      ...deliverys.initialState,
                                      pagination: {
                                        paginationModel: { pageSize: 5 },
                                      },
                                    }
                              }
                              pageSizeOptions={[5, 10, 15]}
                              getRowId={(row) => row.Id}
                            />
                          </Box>
                        </div>
                      )}
                      {open === 1 && (
                        <div>
                          <div>
                            <Form>
                              <Form.Group as={Row}>
                                <Col md={6} className="mb-3">
                                  <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.reciptNumber}
                                    type="number"
                                    label={
                                      <div style={{ float: "left" }}>
                                        <FontAwesomeIcon
                                          icon={faCode}
                                          style={{
                                            float: "left",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span> Recipt Number</span>
                                      </div>
                                    }
                                    size="small"
                                    name="reciptNumber"
                                    error={
                                      formik.touched.reciptNumber &&
                                      formik.errors.reciptNumber
                                    }
                                    helperText={
                                      formik.touched.reciptNumber &&
                                      formik.errors.reciptNumber
                                        ? `${formik.errors.reciptNumber}`
                                        : ""
                                    }
                                    sx={{
                                      width: "250px",
                                      "& .MuiInputBase-root": {
                                        fontSize: {
                                          xs: "0.7rem",
                                          sm: "0.85rem",
                                          md: "0.9rem",
                                        },
                                        padding: {
                                          xs: "1px",
                                          sm: "2px",
                                          md: "3px",
                                          lg: "4px",
                                        },
                                      },
                                    }}
                                  />
                                </Col>
                                <Col md={6} className="mb-3">
                                  <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.date}
                                    type="date"
                                    label={
                                      <div
                                        style={{
                                          float: "left",
                                          width: "70px",
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faCalendar}
                                          size="1x"
                                          style={{
                                            float: "left",
                                            marginTop: "5px",
                                            marginRight: "5px",
                                          }}
                                        />
                                        <h4>Date</h4>
                                      </div>
                                    }
                                    size="small"
                                    name="date"
                                    error={
                                      formik.touched.date && formik.errors.date
                                    }
                                    helperText={
                                      formik.touched.date && formik.errors.date
                                        ? `${formik.errors.date}`
                                        : ""
                                    }
                                    sx={{
                                      width: "250px",
                                      "& .MuiInputBase-root": {
                                        fontSize: {
                                          xs: "0.7rem",
                                          sm: "0.85rem",
                                          md: "0.9rem",
                                        },
                                        padding: {
                                          xs: "1px",
                                          sm: "2px",
                                          md: "3px",
                                          lg: "4px",
                                        },
                                      },
                                    }}
                                  />
                                </Col>
                              </Form.Group>
                            </Form>
                          </div>
                          <Formik
                            initialValues={{
                              quantity: "",
                              itemId: "",
                              remark: "",
                            }}
                            validationSchema={Yup.object().shape({
                              quantity: Yup.number()
                                .required("Quantity is required")
                                .min(1, "Too Low!"),
                              itemId: Yup.string().required("Item is required"),
                              remark: Yup.string()
                                .min(3, "Too Short!")
                                .max(20, "Too Long!"),
                            })}
                            onSubmit={(values) => {
                              const checkItem = itemValues.find(
                                (i) => i.itemId === values.itemId
                              );
                              if (!!checkItem) {
                                setMessages("You use the same item!");
                                setOpenMessage(true);
                                return;
                              }
                              const saleItem = newSaleItems.find(
                                (p) => p.itemId === values.itemId
                              );
                              if (
                                parseFloat(values.quantity) >
                                parseFloat(saleItem.remainingQuantity)
                              ) {
                                setMessages(
                                  `Only available quantity is ${saleItem.remainingQuantity}`
                                );
                                setOpenMessage(true);
                                return;
                              }
                              setItemValues([
                                ...itemValues,
                                {
                                  itemId: values.itemId,
                                  quantity: values.quantity,
                                  remark: values.remark,
                                },
                              ]);
                              values.itemId = "";
                              values.quantity = "";
                              values.remark = "";
                              setSelectItems(null);
                            }}
                          >
                            {(props) => (
                              <div>
                                <hr />
                                <h3>
                                  <Typography
                                    sx={{
                                      fontSize: {
                                        xs: "1.3rem",
                                        sm: "1.3rem",
                                        md: "1.5rem",
                                        lg: "2rem",
                                      },
                                    }}
                                  >
                                    Delivery Item
                                  </Typography>{" "}
                                </h3>
                                <hr />
                                {newItems.length !== itemValues.length && (
                                  <Form onSubmit={props.handleSubmit}>
                                    <Form.Group as={Row}>
                                      <Col lg={4} className="mb-2">
                                        <Autocomplete
                                          {...itemData}
                                          id="controlled-demo"
                                          size="small"
                                          variant="outlined"
                                          name="itemId"
                                          value={selectItems}
                                          onChange={(event, newValue) => {
                                            if (!newValue?.Id) {
                                              setSelectItems(null);
                                              props.values.itemId = "";
                                              return;
                                            }
                                            props.values.itemId = newValue?.Id;
                                            setSelectItems(newValue);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              sx={{
                                                width: "250px",
                                                "& .MuiInputBase-root": {
                                                  fontSize: {
                                                    xs: "0.7rem",
                                                    sm: "0.85rem",
                                                    md: "0.9rem",
                                                  },
                                                  padding: {
                                                    xs: "1px",
                                                    sm: "2px",
                                                    md: "3px",
                                                    lg: "4px",
                                                  },
                                                },
                                              }}
                                              {...params}
                                              error={props.errors.itemId}
                                              helperText={
                                                props.errors.itemId
                                                  ? props.errors.itemId
                                                  : ""
                                              }
                                              label={
                                                <div style={{ float: "left" }}>
                                                  <span>Select Item</span>
                                                </div>
                                              }
                                            />
                                          )}
                                        />
                                      </Col>
                                      <Col lg={4} className="mb-2">
                                        <TextField
                                          id="outlined-basic"
                                          label="Remain Qty"
                                          InputLabelProps={{
                                            shrink: !!selectItems,
                                          }}
                                          disabled
                                          variant="outlined"
                                          type="number"
                                          size="small"
                                          value={
                                            !!selectItems
                                              ? newSaleItems.find(
                                                  (p) =>
                                                    p.itemId === selectItems.Id
                                                )?.remainingQuantity
                                              : ""
                                          }
                                          sx={{
                                            width: "250px",
                                            "& .MuiInputBase-root": {
                                              fontSize: {
                                                xs: "0.7rem",
                                                sm: "0.85rem",
                                                md: "0.9rem",
                                              },
                                              padding: {
                                                xs: "1px",
                                                sm: "2px",
                                                md: "3px",
                                                lg: "4px",
                                              },
                                            },
                                          }}
                                        />
                                      </Col>
                                      <Col lg={4} className="mb-2">
                                        <TextField
                                          id="outlined-basic"
                                          label="Qty"
                                          variant="outlined"
                                          type="number"
                                          size="small"
                                          name="quantity"
                                          onChange={props.handleChange}
                                          value={props.values.quantity}
                                          onBlur={props.handleBlur}
                                          error={
                                            props.touched.quantity &&
                                            props.errors.quantity
                                          }
                                          helperText={
                                            props.touched.quantity &&
                                            props.errors.quantity
                                              ? `${props.errors.quantity}`
                                              : ""
                                          }
                                          sx={{
                                            width: "250px",
                                            "& .MuiInputBase-root": {
                                              fontSize: {
                                                xs: "0.7rem",
                                                sm: "0.85rem",
                                                md: "0.9rem",
                                              },
                                              padding: {
                                                xs: "1px",
                                                sm: "2px",
                                                md: "3px",
                                                lg: "4px",
                                              },
                                            },
                                          }}
                                        />
                                      </Col>

                                      <Col lg={4} className="mb-2">
                                        <TextField
                                          id="outlined-basic"
                                          label="Remark"
                                          variant="outlined"
                                          type="text"
                                          size="small"
                                          name="remark"
                                          onChange={props.handleChange}
                                          value={props.values.remark}
                                          onBlur={props.handleBlur}
                                          error={
                                            props.touched.remark &&
                                            props.errors.remark
                                          }
                                          helperText={
                                            props.touched.remark &&
                                            props.errors.remark
                                              ? `${props.errors.remark}`
                                              : ""
                                          }
                                          sx={{
                                            width: "250px",
                                            "& .MuiInputBase-root": {
                                              fontSize: {
                                                xs: "0.7rem",
                                                sm: "0.85rem",
                                                md: "0.9rem",
                                              },
                                              padding: {
                                                xs: "1px",
                                                sm: "2px",
                                                md: "3px",
                                                lg: "4px",
                                              },
                                            },
                                          }}
                                        />
                                      </Col>
                                      <Col lg={4} className="mb-2">
                                        <Button
                                          sx={{
                                            width: "200px",
                                            borderColor: "primary.main",
                                            fontSize: {
                                              xs: "0.85rem",
                                              sm: "0.85rem",
                                              md: "1rem",
                                            },
                                            padding: {
                                              xs: "4px",
                                              sm: "5px",
                                              md: "6px",
                                              lg: "6px",
                                            },
                                            "&:hover": {
                                              backgroundColor: "primary.main",
                                              color: "white",
                                            },
                                          }}
                                          variant="outlined"
                                          color="primary"
                                          type="submit"
                                        >
                                          <h4
                                            style={{
                                              float: "left",
                                              marginRight: "5px",
                                              marginTop: "-1px",
                                              marginBottom: "-2px",
                                            }}
                                          >
                                            +
                                          </h4>
                                          <strong
                                            style={{
                                              float: "left",
                                              marginTop: "0",
                                              marginBottom: "-2px",
                                            }}
                                          >
                                            Add to list
                                          </strong>
                                        </Button>
                                      </Col>
                                    </Form.Group>
                                  </Form>
                                )}
                                <div style={{ overflow: "scroll" }}>
                                  <div style={{ minWidth: "1000px" }}>
                                    <table className="summary-table">
                                      <thead className="bg-info">
                                        <tr>
                                          <th>Id</th>
                                          <th>Code</th> <th>Desc</th>
                                          <th>Model</th>
                                          <th>Unit</th>
                                          <th>Delivery Qty</th>
                                          <th>Remaining Qty</th>
                                          <th>Total Qty</th>
                                          <th>Remark</th>
                                          <th style={{ minWidth: "240px" }}>
                                            Action
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {itemValues.map((request, index) => {
                                          const item = items.find(
                                            (i) => i.Id === request.itemId
                                          );
                                          const saleItem = newSaleItems.find(
                                            (p) => p.itemId === request.itemId
                                          );
                                          return (
                                            <tr key={index}>
                                              <td>{index + 1}</td>
                                              <td>{item.code}</td>
                                              <td>{item.description}</td>
                                              <td>
                                                {
                                                  models.find(
                                                    (model) =>
                                                      model.Id === item.modelId
                                                  )?.modelName
                                                }
                                              </td>

                                              <td>
                                                {
                                                  units.find(
                                                    (unit) =>
                                                      unit.Id === item.unitId
                                                  )?.unitName
                                                }
                                              </td>
                                              <td>
                                                {openUpdate &&
                                                request.itemId ===
                                                  update.itemId ? (
                                                  <input
                                                    type="number"
                                                    className="form-control"
                                                    required
                                                    style={{ width: "80px" }}
                                                    value={update.quantity}
                                                    onChange={(e) => {
                                                      if (
                                                        parseInt(
                                                          e.target.value
                                                        ) < 0 ||
                                                        parseInt(
                                                          e.target.value
                                                        ) >
                                                          saleItem.remainingQuantity
                                                      ) {
                                                        setUpdate((prev) => ({
                                                          ...prev,
                                                          quantity: 1,
                                                        }));
                                                      } else {
                                                        setUpdate((prev) => ({
                                                          ...prev,
                                                          quantity: parseInt(
                                                            e.target.value
                                                          ),
                                                        }));
                                                      }
                                                    }}
                                                  />
                                                ) : (
                                                  request.quantity
                                                )}
                                              </td>
                                              <td>
                                                {saleItem.remainingQuantity -
                                                  request.quantity}
                                              </td>
                                              <td>{saleItem.quantity}</td>
                                              <td>
                                                {openUpdate &&
                                                request.itemId ===
                                                  update.itemId ? (
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    style={{ width: "80px" }}
                                                    value={update.remark}
                                                    onChange={(e) => {
                                                      if (
                                                        parseInt(
                                                          e.target.value.length
                                                        ) > 20
                                                      ) {
                                                        return;
                                                      } else {
                                                        setUpdate((prev) => ({
                                                          ...prev,
                                                          remark:
                                                            e.target.value,
                                                        }));
                                                      }
                                                    }}
                                                  />
                                                ) : (
                                                  request.remark
                                                )}
                                              </td>
                                              <td>
                                                {(request.itemId !==
                                                  update.itemId ||
                                                  !openUpdate) && (
                                                  <div>
                                                    <Button
                                                      onClick={(e) => {
                                                        setOpenUpdate(true);
                                                        setUpdate({
                                                          itemId:
                                                            request.itemId,
                                                          quantity:
                                                            request.quantity,
                                                          remark:
                                                            request.remark,
                                                        });
                                                      }}
                                                      sx={{
                                                        width: "90px",
                                                        borderColor:
                                                          "warning.main",
                                                        fontSize: {
                                                          xs: "0.7rem",
                                                          sm: "0.85rem",
                                                          md: "1rem",
                                                        },
                                                        padding: {
                                                          xs: "3px",
                                                          sm: "4px",
                                                          md: "5px",
                                                          lg: "6px",
                                                        },
                                                        "&:hover": {
                                                          backgroundColor:
                                                            "warning.main",
                                                          color: "white",
                                                        },
                                                      }}
                                                      className="mr-3"
                                                      variant="outlined"
                                                      color="warning"
                                                    >
                                                      <div
                                                        className="row"
                                                        style={{
                                                          marginBottom: "-10px",
                                                          marginLeft: "-10px",
                                                        }}
                                                      >
                                                        <div className="col">
                                                          <FontAwesomeIcon
                                                            style={{
                                                              float: "right",
                                                              marginRight:
                                                                "-1px",
                                                              marginTop: "2px",
                                                              marginBottom:
                                                                "-4px",
                                                            }}
                                                            icon={faPenToSquare}
                                                          />
                                                        </div>
                                                        <div className="col-6">
                                                          <h6
                                                            style={{
                                                              float: "left",
                                                              marginLeft:
                                                                "-10px",
                                                              marginTop: "-1px",
                                                              marginBottom:
                                                                "-7px",
                                                            }}
                                                          >
                                                            <Typography
                                                              sx={{
                                                                fontSize: {
                                                                  xs: "0.85rem",
                                                                  sm: "0.85rem",
                                                                  md: "1rem",
                                                                },
                                                              }}
                                                            >
                                                              Edit
                                                            </Typography>
                                                          </h6>
                                                        </div>
                                                      </div>
                                                    </Button>
                                                    <Button
                                                      onClick={(e) => {
                                                        const newItemValue =
                                                          itemValues.filter(
                                                            (i) =>
                                                              parseInt(
                                                                i.itemId
                                                              ) !==
                                                              parseInt(
                                                                request.itemId
                                                              )
                                                          );
                                                        setItemValues(
                                                          newItemValue
                                                        );
                                                      }}
                                                      sx={{
                                                        width: "110px",
                                                        borderColor:
                                                          "error.main",
                                                        fontSize: {
                                                          xs: "0.7rem",
                                                          sm: "0.85rem",
                                                          md: "1rem",
                                                        },
                                                        padding: {
                                                          xs: "3px",
                                                          sm: "4px",
                                                          md: "5px",
                                                          lg: "6px",
                                                        },
                                                        "&:hover": {
                                                          backgroundColor:
                                                            "error.main",
                                                          color: "white",
                                                        },
                                                      }}
                                                      variant="outlined"
                                                      color="error"
                                                    >
                                                      <div
                                                        className="row"
                                                        style={{
                                                          marginBottom: "-10px",
                                                          marginLeft: "-10px",
                                                        }}
                                                      >
                                                        <div className="col">
                                                          <FontAwesomeIcon
                                                            style={{
                                                              float: "right",
                                                              marginRight:
                                                                "-1px",
                                                              marginTop: "2px",
                                                              marginBottom:
                                                                "-4px",
                                                            }}
                                                            icon={faTrashCan}
                                                          />
                                                        </div>
                                                        <div className="col-8">
                                                          <h6
                                                            style={{
                                                              float: "left",
                                                              marginLeft:
                                                                "-10px",
                                                              marginTop: "-1px",
                                                              marginBottom:
                                                                "-7px",
                                                            }}
                                                          >
                                                            <Typography
                                                              sx={{
                                                                fontSize: {
                                                                  xs: "0.85rem",
                                                                  sm: "0.85rem",
                                                                  md: "1rem",
                                                                },
                                                              }}
                                                            >
                                                              Delete
                                                            </Typography>
                                                          </h6>
                                                        </div>
                                                      </div>
                                                    </Button>
                                                  </div>
                                                )}

                                                {openUpdate &&
                                                  request.itemId ===
                                                    update.itemId && (
                                                    <div>
                                                      <Button
                                                        onClick={(e) => {
                                                          const saleItem =
                                                            newSaleItems.find(
                                                              (i) =>
                                                                i.itemId ===
                                                                update.itemId
                                                            );

                                                          if (
                                                            parseInt(
                                                              saleItem.remainingQuantity
                                                            ) < update.quantity
                                                          ) {
                                                            setMessages(
                                                              `Only available quantity is ${saleItem.remainingQuantity}`
                                                            );
                                                            setOpenMessage(
                                                              true
                                                            );
                                                            return;
                                                          }

                                                          itemValues[
                                                            index
                                                          ].quantity =
                                                            update.quantity;
                                                          itemValues[
                                                            index
                                                          ].remark =
                                                            update.remark;
                                                          setItemValues([
                                                            ...itemValues,
                                                          ]);
                                                          update.itemId = "";
                                                          update.quantity = "";
                                                          update.remark = "";
                                                          setOpenUpdate(false);
                                                        }}
                                                        sx={{
                                                          width: "90px",
                                                          borderColor:
                                                            "success.main",
                                                          fontSize: {
                                                            xs: "0.7rem",
                                                            sm: "0.85rem",
                                                            md: "1rem",
                                                          },
                                                          padding: {
                                                            xs: "3px",
                                                            sm: "4px",
                                                            md: "5px",
                                                            lg: "6px",
                                                          },
                                                          "&:hover": {
                                                            backgroundColor:
                                                              "success.main",
                                                            color: "white",
                                                          },
                                                        }}
                                                        variant="outlined"
                                                        color="success"
                                                        className="mr-3"
                                                      >
                                                        <div
                                                          className="row"
                                                          style={{
                                                            marginBottom:
                                                              "-10px",
                                                            marginLeft: "-10px",
                                                          }}
                                                        >
                                                          <div className="col">
                                                            <FontAwesomeIcon
                                                              style={{
                                                                float: "right",
                                                                marginRight:
                                                                  "-1px",
                                                                marginTop:
                                                                  "2px",
                                                                marginBottom:
                                                                  "-4px",
                                                              }}
                                                              icon={
                                                                faFloppyDisk
                                                              }
                                                            />
                                                          </div>
                                                          <div className="col-6">
                                                            <h6
                                                              style={{
                                                                float: "left",
                                                                marginLeft:
                                                                  "-10px",
                                                                marginTop:
                                                                  "-1px",
                                                                marginBottom:
                                                                  "-7px",
                                                              }}
                                                            >
                                                              <Typography
                                                                sx={{
                                                                  fontSize: {
                                                                    xs: "0.85rem",
                                                                    sm: "0.85rem",
                                                                    md: "1rem",
                                                                  },
                                                                }}
                                                              >
                                                                Save
                                                              </Typography>
                                                            </h6>
                                                          </div>
                                                        </div>
                                                      </Button>
                                                      <Button
                                                        onClick={(e) => {
                                                          setOpenUpdate(false);
                                                          setUpdate({
                                                            itemId: "",
                                                            quantity: "",
                                                          });
                                                        }}
                                                        sx={{
                                                          width: "100px",
                                                          borderColor:
                                                            "error.main",
                                                          fontSize: {
                                                            xs: "0.7rem",
                                                            sm: "0.85rem",
                                                            md: "1rem",
                                                          },
                                                          padding: {
                                                            xs: "3px",
                                                            sm: "4px",
                                                            md: "5px",
                                                            lg: "6px",
                                                          },
                                                          "&:hover": {
                                                            backgroundColor:
                                                              "error.main",
                                                            color: "white",
                                                          },
                                                        }}
                                                        variant="outlined"
                                                        color="error"
                                                      >
                                                        <div
                                                          className="row"
                                                          style={{
                                                            marginBottom:
                                                              "-10px",
                                                            marginLeft: "-10px",
                                                          }}
                                                        >
                                                          <div className="col">
                                                            <FontAwesomeIcon
                                                              style={{
                                                                float: "right",
                                                                marginRight:
                                                                  "-1px",
                                                                marginTop:
                                                                  "2px",
                                                                marginBottom:
                                                                  "-4px",
                                                              }}
                                                              icon={faXmark}
                                                            />
                                                          </div>
                                                          <div className="col-8">
                                                            <h6
                                                              style={{
                                                                float: "left",
                                                                marginLeft:
                                                                  "-10px",
                                                                marginTop:
                                                                  "-1px",
                                                                marginBottom:
                                                                  "-7px",
                                                              }}
                                                            >
                                                              <Typography
                                                                sx={{
                                                                  fontSize: {
                                                                    xs: "0.85rem",
                                                                    sm: "0.85rem",
                                                                    md: "1rem",
                                                                  },
                                                                }}
                                                              >
                                                                Cancle
                                                              </Typography>
                                                            </h6>
                                                          </div>
                                                        </div>
                                                      </Button>
                                                    </div>
                                                  )}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                    <div className="row">
                                      <div className="col"></div>
                                      <div
                                        className="col mt-1"
                                        style={{
                                          textAlign: "right",
                                          marginBottom: "20px",
                                          float: "right",
                                        }}
                                      >
                                        <Form.Group
                                          as={Row}
                                          controlId="formHorizontalUsername"
                                          style={{ width: "500px" }}
                                        >
                                          <Col>
                                            <Form.Label>Total : </Form.Label>
                                          </Col>
                                          <Col>
                                            <Form.Control
                                              id="outlined-basic"
                                              disabled
                                              label="Qty"
                                              variant="outlined"
                                              type="text"
                                              size="small"
                                              name="qty"
                                              value={price.total.toLocaleString()}
                                              style={{ width: "150px" }}
                                            />
                                          </Col>
                                        </Form.Group>
                                        <Form.Group
                                          as={Row}
                                          controlId="formHorizontalUsername"
                                          style={{ width: "500px" }}
                                        >
                                          <Col>
                                            <Form.Label>Vat : </Form.Label>
                                          </Col>
                                          <Col>
                                            <Form.Control
                                              id="outlined-basic"
                                              disabled
                                              label="Qty"
                                              variant="outlined"
                                              type="text"
                                              size="small"
                                              name="qty"
                                              value={price.tax.toLocaleString()}
                                              style={{ width: "150px" }}
                                            />
                                          </Col>
                                        </Form.Group>
                                        <Form.Group
                                          as={Row}
                                          controlId="formHorizontalUsername"
                                          style={{ width: "500px" }}
                                        >
                                          <Col>
                                            <Form.Label>WithHold : </Form.Label>
                                          </Col>
                                          <Col>
                                            <Form.Control
                                              id="outlined-basic"
                                              disabled
                                              label="Qty"
                                              variant="outlined"
                                              type="text"
                                              size="small"
                                              name="qty"
                                              value={price.withHold.toLocaleString()}
                                              style={{ width: "150px" }}
                                            />
                                          </Col>
                                        </Form.Group>

                                        <Form.Group
                                          as={Row}
                                          controlId="formHorizontalUsername"
                                          style={{ width: "500px" }}
                                        >
                                          <Col>
                                            <Form.Label>
                                              Grand Total :{" "}
                                            </Form.Label>
                                          </Col>
                                          <Col>
                                            <Form.Control
                                              id="outlined-basic"
                                              disabled
                                              label="Qty"
                                              variant="outlined"
                                              type="text"
                                              size="small"
                                              name="qty"
                                              value={price.grandTotal.toLocaleString()}
                                              style={{ width: "150px" }}
                                            />
                                          </Col>
                                        </Form.Group>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Formik>
                          <center className="mt-3">
                            <div style={{ overflow: "scroll" }}>
                              <Row
                                className="mb-3 p-2"
                                style={{
                                  minWidth: "550px",
                                  backgroundColor: "#ffffff",
                                }}
                              >
                                <Col className="mb-2">
                                  <Button
                                    sx={{
                                      width: "150px",
                                      borderColor: "primary.main",
                                      fontSize: {
                                        xs: "0.7rem",
                                        sm: "0.85rem",
                                        md: "1rem",
                                      },
                                      padding: {
                                        xs: "3px",
                                        sm: "4px",
                                        md: "5px",
                                        lg: "6px",
                                      },
                                      "&:hover": {
                                        backgroundColor: "primary.main",
                                        color: "white",
                                      },
                                    }}
                                    variant="outlined"
                                    color="primary"
                                    onClick={(e) => setItemValues([])}
                                  >
                                    <div
                                      className="row"
                                      style={{
                                        marginBottom: "-10px",
                                        marginLeft: "-15px",
                                      }}
                                    >
                                      <div className="col-2">
                                        <FontAwesomeIcon
                                          style={{
                                            float: "left",
                                            marginLeft: "0px",
                                            marginTop: "2px",
                                            marginBottom: "-4px",
                                          }}
                                          icon={faBackwardStep}
                                        />
                                      </div>
                                      <div className="col-10">
                                        <h6
                                          style={{
                                            float: "left",
                                            marginLeft: "-5px",
                                            marginTop: "-1px",
                                            marginBottom: "-7px",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: {
                                                xs: "0.85rem",
                                                sm: "0.85rem",
                                                md: "1rem",
                                              },
                                            }}
                                          >
                                            Back to list
                                          </Typography>
                                        </h6>
                                      </div>
                                    </div>
                                  </Button>
                                </Col>
                                <Col className="mb-2">
                                  <Form onSubmit={formik.handleSubmit}>
                                    <Button
                                      sx={{
                                        width: "150px",
                                        borderColor: "primary.main",
                                        fontSize: {
                                          xs: "0.7rem",
                                          sm: "0.85rem",
                                          md: "1rem",
                                        },
                                        padding: {
                                          xs: "3px",
                                          sm: "4px",
                                          md: "5px",
                                          lg: "6px",
                                        },
                                        "&:hover": {
                                          backgroundColor: "primary.main",
                                          color: "white",
                                        },
                                      }}
                                      variant="outlined"
                                      color="primary"
                                      type="submit"
                                    >
                                      <div
                                        className="row"
                                        style={{
                                          marginBottom: "-10px",
                                          marginLeft: "-15px",
                                        }}
                                      >
                                        <div className="col-3">
                                          <FontAwesomeIcon
                                            style={{
                                              float: "left",
                                              marginLeft: "0px",
                                              marginTop: "2px",
                                              marginBottom: "-4px",
                                            }}
                                            icon={faFloppyDisk}
                                          />
                                        </div>
                                        <div className="col-9">
                                          <h6
                                            style={{
                                              float: "left",
                                              marginLeft: "-5px",
                                              marginTop: "-1px",
                                              marginBottom: "-7px",
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: {
                                                  xs: "0.85rem",
                                                  sm: "0.85rem",
                                                  md: "1rem",
                                                },
                                              }}
                                            >
                                              Submit
                                            </Typography>
                                          </h6>
                                        </div>
                                      </div>
                                    </Button>
                                  </Form>
                                </Col>

                                <Col className="mb-2">
                                  <Button
                                    onClick={(e) => setOpenReject(2)}
                                    sx={{
                                      width: "150px",
                                      borderColor: "error.main",
                                      fontSize: {
                                        xs: "0.7rem",
                                        sm: "0.85rem",
                                        md: "1rem",
                                      },
                                      padding: {
                                        xs: "3px",
                                        sm: "4px",
                                        md: "5px",
                                        lg: "6px",
                                      },
                                      "&:hover": {
                                        backgroundColor: "error.main",
                                        color: "white",
                                      },
                                    }}
                                    variant="outlined"
                                    color="error"
                                    type="submit"
                                  >
                                    <div
                                      className="row"
                                      style={{
                                        marginBottom: "-10px",
                                        marginLeft: "-15px",
                                      }}
                                    >
                                      <div className="col-3">
                                        <FontAwesomeIcon
                                          style={{
                                            float: "left",
                                            marginLeft: "0px",
                                            marginTop: "2px",
                                            marginBottom: "-4px",
                                          }}
                                          icon={faTrashRestore}
                                        />
                                      </div>
                                      <div className="col-9">
                                        <h6
                                          style={{
                                            float: "left",
                                            marginLeft: "-5px",
                                            marginTop: "-1px",
                                            marginBottom: "-7px",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: {
                                                xs: "0.85rem",
                                                sm: "0.85rem",
                                                md: "1rem",
                                              },
                                            }}
                                          >
                                            rejecte
                                          </Typography>
                                        </h6>
                                      </div>
                                    </div>
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </center>
                        </div>
                      )}
                    </div>
                  )}
                  {open === 2 && (
                    <div style={{ overflow: "scroll" }}>
                      <div className="card-body" style={{ minWidth: "1000px" }}>
                        <Container ref={contentRef}>
                          <div className="row">
                            <div className="col-sm-6 ">
                              <br />
                              <h1
                                style={{
                                  marginLeft: "5vw",
                                  marginBottom: "2vh",
                                }}
                              >
                                Invoice
                              </h1>
                              <i
                                style={{
                                  marginLeft: "7vw",
                                  marginBottom: "2vh",
                                }}
                                className="fas fa-home fa-3x"
                              />
                            </div>

                            <div className="col-sm-6 ">
                              <br />
                              <div
                                style={{
                                  fontSize: 18,
                                  textAlign: "right",
                                }}
                              >
                                <p style={{ margin: "0", fontSize: 24 }}>
                                  <b> Company Name</b>
                                </p>
                                <p style={{ margin: "0" }}>+251987654321</p>
                                <p style={{ margin: "0" }}>email@gmail.com</p>
                                <p style={{ margin: "0" }}>address1</p>
                                <p style={{ margin: "0" }}>address2</p>
                              </div>
                            </div>
                          </div>
                          <h4>Sales</h4>
                          <hr />
                          <Row className="">
                            <Col sm={2}>
                              <p>
                                <b>BILL To</b>
                              </p>
                            </Col>

                            <Col sm={5}>
                              <div>
                                <p>
                                  <b>Customer Name : </b>
                                  {
                                    customers.find(
                                      (c) => c.Id === formik.values.customerId
                                    )?.fullName
                                  }
                                </p>
                              </div>
                              <div>
                                <p>
                                  <b>Till Number : </b>
                                  {
                                    customers.find(
                                      (c) => c.Id === formik.values.customerId
                                    )?.tinNumber
                                  }
                                </p>
                              </div>
                              <div>
                                <p>
                                  <b>Customer of : </b>
                                  {
                                    users.find(
                                      (u) =>
                                        u.Id ===
                                        customers.find(
                                          (c) =>
                                            c.Id === formik.values.customerId
                                        )?.Id
                                    )?.fullName
                                  }
                                </p>
                              </div>
                              <div>
                                <p>
                                  <b>Phone No : </b>
                                  {
                                    customers.find(
                                      (c) => c.Id === formik.values.customerId
                                    )?.primaryPhoneNumber
                                  }
                                </p>
                              </div>
                            </Col>
                            <Col sm={5}>
                              {formik.values.Id !== "" && (
                                <div>
                                  <p>
                                    <b>Delivery Ref : </b>
                                    {formik.values.Id}
                                  </p>
                                </div>
                              )}
                              <div>
                                <p>
                                  <b>Date : </b>
                                  {`${new Date(formik.values.date).getDate()}/${
                                    new Date(formik.values.date).getMonth() + 1
                                  }/${new Date(
                                    formik.values.date
                                  ).getFullYear()}`}
                                </p>
                              </div>
                            </Col>
                          </Row>
                          <br />
                          <br />
                          <hr />
                          <br />
                          <br />
                          <br />
                          <Row>
                            <Table
                              className="text-center"
                              style={{ width: "100%" }}
                            >
                              <tr>
                                <th>No</th>
                                <th>Code</th> <th>Desc</th>
                                <th>Model</th>
                                <th>Unit</th>
                                <th>Delivery Qty</th>
                                {parseInt(id) === 1 && <th>Remaining Qty</th>}
                                <th>Total Qty</th>
                                <th>Remark</th>
                              </tr>

                              <tbody>
                                {itemValues.map((request, index) => {
                                  const item = items.find(
                                    (i) => i.Id === request.itemId
                                  );
                                  const saleItem = newSaleItems.find(
                                    (p) => p.itemId === request.itemId
                                  );
                                  return (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{item.code}</td>
                                      <td>{item.description}</td>
                                      <td>
                                        {
                                          models.find(
                                            (model) => model.Id === item.modelId
                                          )?.modelName
                                        }
                                      </td>

                                      <td>
                                        {
                                          units.find(
                                            (unit) => unit.Id === item.unitId
                                          )?.unitName
                                        }
                                      </td>
                                      <td>{request.quantity}</td>

                                      {parseInt(id) === 1 && (
                                        <td>
                                          {saleItem.remainingQuantity -
                                            request.quantity}
                                        </td>
                                      )}

                                      <td>{saleItem.quantity}</td>
                                      <td>{request.remark}</td>
                                    </tr>
                                  );
                                })}
                                {itemValues.length < 5 ? handelInvoice() : ""}
                              </tbody>
                            </Table>
                          </Row>
                          <Row>
                            <Col sm={8}>
                              <h3>Terms</h3>{" "}
                              {qrCodeUrl ? (
                                <div>
                                  <img
                                    src={qrCodeUrl}
                                    alt="QR Code"
                                    style={{
                                      marginTop: "20px",
                                      width: "256px",
                                      height: "256px",
                                    }}
                                  />
                                </div>
                              ) : (
                                <p>Generating QR code...</p>
                              )}
                            </Col>
                            <Col sm={4}>
                              <Table>
                                <tbody>
                                  <tr style={{}}>
                                    <td>
                                      <b
                                        style={{
                                          float: "right",
                                          fontSize: 18,
                                          width: "100px",
                                          height: "10px",
                                        }}
                                      >
                                        Total Price
                                      </b>
                                    </td>
                                    <td>
                                      <p
                                        style={{
                                          float: "left",
                                          fontSize: 18,
                                          width: "110px",
                                          height: "10px",
                                        }}
                                      >
                                        {price.total.toLocaleString()}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b
                                        style={{
                                          float: "right",
                                          fontSize: 18,
                                          width: "100px",
                                          height: "10px",
                                        }}
                                      >
                                        VAT
                                      </b>
                                    </td>
                                    <td>
                                      <p
                                        style={{
                                          float: "left",
                                          fontSize: 18,
                                          width: "110px",
                                          height: "10px",
                                        }}
                                      >
                                        {price.tax.toLocaleString()}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b
                                        style={{
                                          float: "right",
                                          fontSize: 18,
                                          width: "100px",
                                          height: "10px",
                                        }}
                                      >
                                        WithHold
                                      </b>
                                    </td>
                                    <td>
                                      <p
                                        style={{
                                          float: "left",
                                          fontSize: 18,
                                          width: "110px",
                                          height: "10px",
                                        }}
                                      >
                                        {price.withHold.toLocaleString()}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b
                                        style={{
                                          float: "right",
                                          fontSize: 18,
                                          width: "120px",
                                          height: "10px",
                                        }}
                                      >
                                        Grand Total
                                      </b>
                                    </td>
                                    <td>
                                      <p
                                        style={{
                                          float: "left",
                                          fontSize: 18,
                                          width: "110px",
                                          height: "10px",
                                        }}
                                      >
                                        {price.grandTotal.toLocaleString()}
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                      <div className="card-footer">
                        <Button
                          onClick={() => {
                            reactToPrint();
                            handleBack();
                          }}
                          sx={{
                            width: "100px",
                            borderColor: "primary.main",
                            fontSize: {
                              xs: "0.7rem",
                              sm: "0.85rem",
                              md: "1rem",
                            },
                            padding: {
                              xs: "3px",
                              sm: "4px",
                              md: "5px",
                              lg: "6px",
                            },
                            "&:hover": {
                              backgroundColor: "primary.main",
                              color: "white",
                            },
                          }}
                          variant="outlined"
                          color="primary"
                          className="ml-3 mt-1"
                          style={{ float: "left" }}
                        >
                          <div
                            className="row"
                            style={{
                              marginBottom: "-10px",
                              marginLeft: "-10px",
                            }}
                          >
                            <div className="col">
                              <FontAwesomeIcon
                                style={{
                                  float: "right",
                                  marginRight: "-1px",
                                  marginTop: "2px",
                                  marginBottom: "-4px",
                                }}
                                icon={faPrint}
                              />
                            </div>
                            <div className="col-7">
                              <h6
                                style={{
                                  float: "left",
                                  marginLeft: "-10px",
                                  marginTop: "-1px",
                                  marginBottom: "-7px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: {
                                      xs: "0.85rem",
                                      sm: "0.85rem",
                                      md: "1rem",
                                    },
                                  }}
                                >
                                  Print
                                </Typography>
                              </h6>
                            </div>
                          </div>
                        </Button>

                        <Button
                          onClick={(e) => {
                            handleBack();
                          }}
                          sx={{
                            width: "90px",
                            borderColor: "success.main",
                            fontSize: {
                              xs: "0.7rem",
                              sm: "0.85rem",
                              md: "1rem",
                            },
                            padding: {
                              xs: "3px",
                              sm: "4px",
                              md: "5px",
                              lg: "6px",
                            },
                            "&:hover": {
                              backgroundColor: "success.main",
                              color: "white",
                            },
                          }}
                          variant="outlined"
                          color="success"
                          className="ml-3 mt-1"
                          style={{ float: "left" }}
                        >
                          <div
                            className="row"
                            style={{
                              marginBottom: "-10px",
                              marginLeft: "-10px",
                            }}
                          >
                            <div className="col">
                              <FontAwesomeIcon
                                style={{
                                  float: "right",
                                  marginRight: "-1px",
                                  marginTop: "2px",
                                  marginBottom: "-4px",
                                }}
                                icon={faBackwardStep}
                              />
                            </div>
                            <div className="col-7">
                              <h6
                                style={{
                                  float: "left",
                                  marginLeft: "-10px",
                                  marginTop: "-1px",
                                  marginBottom: "-7px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: {
                                      xs: "0.85rem",
                                      sm: "0.85rem",
                                      md: "1rem",
                                    },
                                  }}
                                >
                                  Back
                                </Typography>
                              </h6>
                            </div>
                          </div>
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                <Formik
                  initialValues={{
                    remark: "",
                  }}
                  validationSchema={Yup.object().shape({
                    remark: Yup.string()
                      .required("Reason is required.")
                      .min(4, "Too Short!")
                      .max(60, "Too Long"),
                  })}
                  onSubmit={async (values) => {
                    if (isSubmitted) return;
                    setIsSubmitted(true);
                    try {
                      await axios.put(
                        "https://enin.server.highendtradingplc.com/sale/update/" +
                          formik.values.saleId,
                        {
                          statusId: 4,
                        }
                      );
                      const salesIte = saleItems.filter(
                        (s) => s.saleId === formik.values.saleId
                      );

                      await axios
                        .post(
                          "https://enin.server.highendtradingplc.com/delivery/add/",
                          {
                            statusId: 3,
                            saleId: formik.values.saleId,
                            customerId: formik.values.customerId,
                            userId: userData.Id,
                            date: new Date(),
                            reason: values.remark,
                          }
                        )
                        .then((res) => {
                          salesIte.map(async (sale) => {
                            await axios
                              .post(
                                "https://enin.server.highendtradingplc.com/transaction/add",
                                {
                                  quantity: parseInt(sale.remainingQuantity),
                                  statusId: 1,
                                  date: new Date(),
                                }
                              )
                              .then(async (res1) => {
                                const logical = logicals.find(
                                  (p) => p.itemId === sale.itemId
                                );
                                await axios.post(
                                  "https://enin.server.highendtradingplc.com/logical_stock/add",
                                  {
                                    quantity:
                                      parseInt(logical.quantity) +
                                      parseInt(sale.remainingQuantity),
                                    itemId: sale.itemId,
                                    date: new Date(),
                                    statusId: 1,
                                    transactionId: res1.data.data.Id,
                                    ADPRS_ID: res.data.data.Id,
                                  }
                                );
                              });
                          });
                        });
                      fetchAllData();
                      setOpenReject(1);
                      setOpen(0);
                    } catch (error) {
                      console.log(error);
                    }
                    setIsSubmitted(false);
                  }}
                >
                  {(props) => (
                    <Modal
                      show={openReject === 2}
                      onHide={(e) => setOpenReject(0)}
                      style={{ minWidth: "700px" }}
                    >
                      <Modal.Header closeButton>
                        <span>Delivery / Reject </span>
                      </Modal.Header>
                      <Modal.Body>
                        <Form
                          className="m-2 ml-5 pl-5"
                          onSubmit={props.handleSubmit}
                        >
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={8}>
                              <TextField
                                id="outlined-basic"
                                label="Reason"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="remark"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.remark}
                                isInvalid={
                                  props.touched.remark && props.errors.remark
                                }
                                sx={{
                                  width: "250px",
                                  "& .MuiInputBase-root": {
                                    fontSize: {
                                      xs: "0.7rem",
                                      sm: "0.85rem",
                                      md: "0.9rem",
                                    },
                                    padding: {
                                      xs: "1px",
                                      sm: "2px",
                                      md: "3px",
                                      lg: "4px",
                                    },
                                  },
                                }}
                              />

                              {props.touched.remark && props.errors.remark && (
                                <div className="text-danger mt-1">
                                  {props.errors.remark}
                                </div>
                              )}
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={12}>
                              <Button
                                sx={{
                                  width: "250px",
                                  "& .MuiInputBase-root": {
                                    fontSize: {
                                      xs: "0.7rem",
                                      sm: "0.85rem",
                                      md: "0.9rem",
                                    },
                                    padding: {
                                      xs: "1px",
                                      sm: "2px",
                                      md: "3px",
                                      lg: "4px",
                                    },
                                  },
                                }}
                                variant="outlined"
                                color="primary"
                                type="submit"
                              >
                                Submit
                              </Button>
                            </Col>
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                    </Modal>
                  )}
                </Formik>
              </div>
            </center>
          </main>
        </div>
      </Typography>
    </ThemeProvider>
  );
};

export default Delivery;
