import React, { useEffect, useRef, useState } from "react";
import Header from "../Header";
import { Link } from "react-router-dom";
import { Col, Form, Row, Table } from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Button,
  Autocomplete,
  Box,
  Container,
  TextField,
  createTheme,
  useMediaQuery,
  ThemeProvider,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import {
  faBackwardStep,
  faCalendar,
  faEye,
  faFloppyDisk,
  faPenToSquare,
  faPrint,
  faStore,
  faTrashCan,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { jwtDecode } from "jwt-decode";

const validationSchema = Yup.object().shape({
  date: Yup.date().required("Date is required!"),
  senderId: Yup.string().required("Store is required!"),
  reciverId: Yup.string().required("Store is required!"),
});
const theme = createTheme({
  breakpoints: { values: { xs: 0, sm: 500, md: 769, lg: 1024, xl: 1440 } },
  palette: {
    warning: {
      main: "#ffc107", // Main warning color
      light: "#ffb74d", // Light variation of warning color
      dark: "#f57c00", // Dark variation of warning color
      contrastText: "#000", // Text color on warning
    },
  },
});
const StoreTransfer = () => {
  const [stores, setStores] = useState([]);
  const [storeItems, setStoreItems] = useState([]);
  const [storeTransfers, setStoreTransfers] = useState([]);
  const [storeTransferItems, setStoreTransferItems] = useState([]);
  const [items, setItems] = useState([]);
  const [units, setUnits] = useState([]);
  const [models, setModels] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectSendes, setSelectSendes] = useState(null);
  const [selectRecives, setSelectRecives] = useState(null);
  const [selectItems, setSelectItems] = useState(null);
  const [itemValues, setItemValues] = useState([]);
  const [open, setOpen] = useState(0);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [update, setUpdate] = useState({ itemId: "", quantity: "" });
  const [openMessage, setOpenMessage] = useState(false);
  const [messages, setMessages] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const userData = jwtDecode(sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isUnderMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const componentRef = useRef();
  const fetchAllData = async () => {
    try {
      const [
        store,
        storeItem,
        storeTransfer,
        storeTransferItem,
        item,
        unit,
        model,
        user,
      ] = await Promise.all([
        axios.get("https://enin.server.highendtradingplc.com/store"),
        axios.get("https://enin.server.highendtradingplc.com/store_item"),
        axios.get("https://enin.server.highendtradingplc.com/store_transfer"),
        axios.get(
          "https://enin.server.highendtradingplc.com/store_transfer_item"
        ),
        axios.get("https://enin.server.highendtradingplc.com/item"),
        axios.get("https://enin.server.highendtradingplc.com/unit"),
        axios.get("https://enin.server.highendtradingplc.com/model"),
        axios.get("https://enin.server.highendtradingplc.com/user"),
      ]);
      setStores(store.data);
      setStoreItems(storeItem.data);
      setStoreTransfers(storeTransfer.data);
      setStoreTransferItems(storeTransferItem.data);
      setItems(item.data);
      setUnits(unit.data);
      setModels(model.data);
      setUsers(user.data?.filter((user) => user.roleId >= 2));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const itemData = {
    options: items,
    getOptionLabel: (option) => option.description,
  };
  const storeData = {
    options: stores,
    getOptionLabel: (option) => option.storeName,
  };

  const formik = useFormik({
    initialValues: {
      date: "",
      senderId: "",
      reciverId: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      if (isSubmitted) return;
                    setIsSubmitted(true);
      try {
        for (let i = 0; i < itemValues.length; i++) {
          const storeItem = storeItems.find(
            (s) =>
              s.itemId === itemValues[i].itemId && s.storeId === values.senderId
          );
          if (storeItem.quantity < itemValues[i].quantity) {
            setMessages(`qty ${storeItem.quantity}`);
            setOpenMessage(true);
            setIsSubmitted(false);
            return;
          }
        }
        // Get the current time
        const currentTime = new Date();
        const hours = currentTime.getHours();
        const minutes = currentTime.getMinutes();
        const seconds = currentTime.getSeconds();

        // Append the current time to the date value
        values.date = `${values.date} ${hours}:${minutes}:${seconds}`;

        await axios
          .post(
            "https://enin.server.highendtradingplc.com/store_transfer/add",
            {
              senderId: values.senderId,
              reciverId: values.reciverId,
              date: values.date,
              userId: userData.Id,
            }
          )
          .then(async (res) => {
            for (let i = 0; i < itemValues.length; ) {
              let counter = 0;
              const storeItemSender = storeItems.find(
                (s) =>
                  s.itemId === itemValues[i].itemId &&
                  s.storeId === values.senderId
              );
              const storeItemReciver = storeItems.find(
                (s) =>
                  s.itemId === itemValues[i].itemId &&
                  s.storeId === values.reciverId
              );
              await axios
                .post(
                  "https://enin.server.highendtradingplc.com/store_transfer_item/add",
                  {
                    storeTransferId: res.data.data.Id,
                    itemId: itemValues[i].itemId,
                    quantity: itemValues[i].quantity,
                  }
                )
                .then((ress) => counter++);
              await axios
                .put(
                  "https://enin.server.highendtradingplc.com/store_item/update/" +
                    storeItemSender.Id,
                  {
                    quantity: storeItemSender.quantity - itemValues[i].quantity,
                  }
                )
                .then((ress) => counter++);
              await axios
                .put(
                  "https://enin.server.highendtradingplc.com/store_item/update/" +
                    storeItemReciver.Id,
                  {
                    quantity:
                      storeItemReciver.quantity + itemValues[i].quantity,
                  }
                )
                .then((ress) => counter++);
              if (counter === 3) i = i + 1;
            }
          });
        handleBack();
      } catch (error) {
        console.log(error);
      }
      setIsSubmitted(false);
    },
  });

  const handleOpenStoreTransferItem = (row) => {
    let data = [];
    storeTransferItems.map((s) => {
      if (s.storeTransferId === row.storeTransferId) {
        data.push({ itemId: s.itemId, quantity: s.quantity });
      }
      return 0;
    });
    setItemValues(data);
    setOpen(2);
  };

  const handleBack = () => {
    fetchAllData();
    setItemValues([]);
    setSelectItems(null);
    setSelectRecives(null);
    setSelectSendes(null);
    setOpen(0);
    formik.values.date = "";
    formik.values.senderId = "";
    formik.values.reciverId = "";
  };

  const columns = [
    {
      field: "Id",
      headerName: "No",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "storeTransferId",
      headerName: "Store Transfer ID",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "userId",
      headerName: "Transfered By",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",

      valueGetter: (params) =>
        users.find((user) => user.Id === params)?.fullName || "",
    },
    {
      field: "senderId",
      headerName: "Sender Store",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",

      valueGetter: (params) =>
        stores.find((store) => store.Id === params)?.storeName || "",
    },
    {
      field: "reciverId",
      headerName: "Recive Store",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",

      valueGetter: (params) =>
        stores.find((store) => store.Id === params)?.storeName || "",
    },

    {
      field: "total",
      headerName: "Total Item",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",

      valueGetter: (params) => params.toLocaleString(),
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",

      valueGetter: (params) =>
        `${new Date(params).getDate()}/${
          new Date(params).getMonth() + 1
        }/${new Date(params).getFullYear()}`,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,

      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <>
          <Button
            onClick={() => handleOpenStoreTransferItem(params.row)}
            sx={{
              width: "90px",
              borderColor: "primary.main",
              fontSize: {
                xs: "0.85rem",
                sm: "0.85rem",
                md: "1rem",
              },
              padding: {
                xs: "4px",
                sm: "5px",
                md: "6px",
                lg: "6px",
              },
              "&:hover": {
                backgroundColor: "primary.main",
                color: "white",
              },
            }}
            variant="outlined"
            color="primary"
            className="m-1"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{
                    float: "right",
                    marginRight: "-1px",
                    marginTop: "2px",
                    marginBottom: "-4px",
                  }}
                  icon={faEye}
                />
              </div>
              <div className="col-6">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-10px",
                    marginTop: "-1px",
                    marginBottom: "-7px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "0.85rem",
                        sm: "0.85rem",
                        md: "1rem",
                      },
                    }}
                  >
                    View
                  </Typography>
                </h6>
              </div>
            </div>
          </Button>
        </>
      ),
    },
  ];
  const rows = storeTransfers.map((storeTransfer, index) => {
    const storeTransferItem = storeTransferItems.filter(
      (s) => s.storeTransferId === storeTransfer.Id
    );

    return {
      Id: index + 1,
      userId: storeTransfer.userId,
      storeTransferId: storeTransfer.Id,
      date: storeTransfer.date,
      total: storeTransferItem.length,
      senderId: storeTransfer.senderId,
      reciverId: storeTransfer.reciverId,
    };
  });

  const columnItem = [
    {
      field: "id",
      headerName: "No",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Id",
      headerName: "Item Id",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "description",
      headerName: "description",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "model",
      headerName: "Model",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "code",
      headerName: "Code",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "unit",
      headerName: "Unit",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },

    {
      field: "qty",
      headerName: "Transferd Qty",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
    },
  ];

  const rowItem = itemValues.map((itemvalue, index) => {
    const item = items.find((i) => i.Id === itemvalue.itemId);
    const unit = units.find((u) => u.Id === item.unitId);
    const model = models.find((u) => u.Id === item.modelId);

    return {
      id: index + 1,
      Id: item.Id,
      description: item.description,
      code: item.code,
      model: model?.modelName,
      unit: unit?.unitName,
      qty: itemvalue.quantity,
    };
  });

  return (
    <ThemeProvider theme={theme}>
      <Typography
        sx={{
          fontSize: {
            xs: "12px",
            sm: "12px", //500
            md: "0.85rem", //768
            lg: "17px", //1024
          },
        }}
      >
        <div>
          <Header />
        </div>
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: "13px",
            sm: "14px", //500
            md: "16px", //768
            lg: "18px", //1024
          },
        }}
      >
        <div>
          <main
            style={
              isMobile && !isUnderMobile
                ? { marginTop: "190px" }
                : isUnderMobile
                ? { marginTop: "170px" }
                : isTablet
                ? { marginTop: "133px" }
                : { marginTop: "150px" }
            }
          >
            <center>
              <div
                style={
                  isUnderMobile
                    ? {
                        minWidth: "500px",
                        overflow: "scroll",
                        marginBottom: "-5px",
                      }
                    : { maxWidth: "1100px" }
                }
                className="text-left"
              >
                <div
                  className="tab-container "
                  style={
                    isUnderMobile
                      ? { marginTop: "0", marginBottom: "-5px" }
                      : {
                          marginTop: "-20px",
                          marginBottom: "-5px",
                        }
                  }
                >
                  <Link className="p-2" to={`/store`}>
                    Store
                  </Link>
                  <Link className="p-2" to={`/purchase_delivery/1`}>
                    Purchase Item
                  </Link>
                  <Link
                    className="p-2"
                    onClick={(e) => {
                      window.location.reload();
                    }}
                    style={{
                      backgroundColor: "white",
                      borderRadius: "5px 5px 0 0",
                      border: "solid 0.05em rgb(181, 181, 181)",
                    }}
                  >
                    Transfer Item
                  </Link>

                  <Link className="p-2" to={`/delivery_item/1`}>
                    Delivery Item
                  </Link>
                  <Link className="p-2" to={`/adjustment_delivery/1`}>
                    Adjustment Item
                  </Link>
                </div>
                <Snackbar
                  open={openMessage}
                  onClose={(e) => setOpenMessage(false)}
                  autoHideDuration={6000}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  <Alert
                    variant="outlined"
                    severity="error"
                    onClose={(e) => setOpenMessage(false)}
                    sx={{ bgcolor: "background.paper" }}
                  >
                    {messages}
                  </Alert>
                </Snackbar>
                <div className="card card-info card-outline">
                  <div className="card-header">
                    {open === 0 && (
                      <Button
                        sx={{
                          width: "150px",
                          borderColor: "info.main",
                          fontSize: {
                            xs: "0.7rem",
                            sm: "0.85rem",
                            md: "1rem",
                          },
                          padding: {
                            xs: "0",
                            sm: "0",
                            md: "1px",
                            lg: "3px",
                          },
                          "&:hover": {
                            backgroundColor: "info.main",
                            color: "white",
                          },
                        }}
                        variant="outlined"
                        color="info"
                        onClick={(e) => setOpen(1)}
                      >
                        <h4
                          style={{
                            float: "left",
                            marginRight: "5px",
                            marginTop: "-1",
                            marginBottom: "-2px",
                          }}
                        >
                          +
                        </h4>
                        <strong
                          style={{
                            float: "left",
                            marginTop: "0",
                            marginBottom: "-2px",
                          }}
                        >
                          Create New
                        </strong>
                      </Button>
                    )}
                    {open > 0 && (
                      <Button
                        onClick={(e) => handleBack()}
                        sx={{
                          width: "100px",
                          borderColor: "success.main",
                          fontSize: {
                            xs: "0.85rem",
                            sm: "0.85rem",
                            md: "1rem",
                          },
                          padding: {
                            xs: "4px",
                            sm: "5px",
                            md: "6px",
                            lg: "6px",
                          },
                          "&:hover": {
                            backgroundColor: "success.main",
                            color: "white",
                          },
                        }}
                        variant="outlined"
                        color="success"
                        type="submit"
                        className="m-1"
                        style={{ float: "right" }}
                      >
                        <div
                          className="row"
                          style={{
                            marginBottom: "-10px",
                            marginLeft: "-10px",
                          }}
                        >
                          <div className="col">
                            <FontAwesomeIcon
                              style={{
                                float: "right",
                                marginRight: "-1px",
                                marginTop: "4px",
                              }}
                              icon={faBackwardStep}
                            />
                          </div>
                          <div className="col-7">
                            <h6
                              style={{
                                float: "left",
                                marginLeft: "-10px",
                                marginTop: "1px",
                                marginBottom: "-3px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: {
                                    xs: "0.85rem",
                                    sm: "0.85rem",
                                    md: "1rem",
                                  },
                                }}
                              >
                                Back
                              </Typography>
                            </h6>
                          </div>
                        </div>
                      </Button>
                    )}
                  </div>
                  <div className="card-body">
                    {(open === 0 || open === 2) && (
                      <div
                        style={{
                          overflow: "scroll",
                        }}
                      >
                        <Box
                          sx={{
                            height: 500,

                            width: {
                              lg: "100%",
                              md: "1000px",
                              sm: "1000px",
                              xs: "1000px",
                            },
                            "& .super-app-theme--header": {
                              backgroundColor: "#1de5ec",
                              color: "black",
                              fontSize: {
                                xs: "13px",
                                sm: "15px", //500
                                md: "17px", //768
                                lg: "18px", //1024
                              },
                            },
                          }}
                        >
                          <DataGrid
                            rows={open === 0 ? rows : rowItem}
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            sx={{
                              "& .MuiDataGrid-cell": {
                                fontSize: {
                                  xs: "12px",
                                  sm: "14px", //500
                                  md: "16px", //768
                                  lg: "17px", //1024
                                },
                              },
                            }}
                            columns={open === 0 ? columns : columnItem}
                            slots={{ toolbar: GridToolbar }}
                            slotProps={{
                              toolbar: {
                                showQuickFilter: true,
                              },
                            }}
                            initialState={
                              open === 0
                                ? {
                                    ...storeTransfers.initialState,
                                    pagination: {
                                      paginationModel: { pageSize: 5 },
                                    },
                                  }
                                : {
                                    ...itemValues.initialState,
                                    pagination: {
                                      paginationModel: { pageSize: 5 },
                                    },
                                  }
                            }
                            pageSizeOptions={[5, 10, 15]}
                            getRowId={(row) => row.Id}
                          />
                        </Box>{" "}
                      </div>
                    )}
                    {open === 1 && (
                      <div>
                        <div>
                          <Form>
                            <Form.Group as={Row}>
                              <Col lg={4} className="mb-3">
                                <Autocomplete
                                  {...storeData}
                                  id="controlled-demo"
                                  size="small"
                                  variant="outlined"
                                  name="senderId"
                                  value={selectSendes}
                                  onChange={(event, newValue) => {
                                    if (
                                      !newValue?.Id ||
                                      formik.values.reciverId === newValue.Id
                                    ) {
                                      setSelectSendes(null);
                                      formik.values.senderId = "";
                                      return;
                                    }
                                    setSelectSendes(newValue);
                                    formik.values.senderId = newValue?.Id;
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      sx={{
                                        width: "224px",
                                        "& .MuiInputBase-root": {
                                          fontSize: {
                                            xs: "0.7rem",
                                            sm: "0.85rem",
                                            md: "1.1rem",
                                          },
                                          padding: {
                                            xs: "1px",
                                            sm: "2px",
                                            md: "3px",
                                            lg: "4px",
                                          },
                                        },
                                      }}
                                      error={formik.errors.senderId}
                                      helperText={
                                        formik.errors.senderId
                                          ? `Store is required!`
                                          : ""
                                      }
                                      label={
                                        <div style={{ float: "left" }}>
                                          <FontAwesomeIcon
                                            icon={faStore}
                                            style={{
                                              float: "left",
                                              marginRight: "10px",
                                            }}
                                          />
                                          <span>Sender Store</span>
                                        </div>
                                      }
                                    />
                                  )}
                                />
                              </Col>{" "}
                              <Col lg={4} className="mb-3">
                                <Autocomplete
                                  {...storeData}
                                  id="controlled-demo"
                                  size="small"
                                  variant="outlined"
                                  name="reciverId"
                                  value={selectRecives}
                                  onChange={(event, newValue) => {
                                    console.log(
                                      formik.values.senderId === newValue.Id,
                                      formik.values.senderId,
                                      newValue.Id
                                    );

                                    if (
                                      !newValue?.Id ||
                                      formik.values.senderId === newValue.Id
                                    ) {
                                      setSelectRecives(null);
                                      formik.values.reciverId = "";
                                      return;
                                    }
                                    setSelectRecives(newValue);
                                    formik.values.reciverId = newValue?.Id;
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      sx={{
                                        width: "224px",
                                        "& .MuiInputBase-root": {
                                          fontSize: {
                                            xs: "0.7rem",
                                            sm: "0.85rem",
                                            md: "1.1rem",
                                          },
                                          padding: {
                                            xs: "1px",
                                            sm: "2px",
                                            md: "3px",
                                            lg: "4px",
                                          },
                                        },
                                      }}
                                      error={formik.errors.reciverId}
                                      helperText={
                                        formik.errors.reciverId
                                          ? `Store is required!`
                                          : ""
                                      }
                                      label={
                                        <div style={{ float: "left" }}>
                                          <FontAwesomeIcon
                                            icon={faStore}
                                            style={{
                                              float: "left",
                                              marginRight: "10px",
                                            }}
                                          />
                                          <span>Reciver Store</span>
                                        </div>
                                      }
                                    />
                                  )}
                                />
                              </Col>
                              <Col lg={4} className="mb-2">
                                <TextField
                                  id="outlined-basic"
                                  variant="outlined"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.date}
                                  type="date"
                                  sx={{
                                    width: "224px",
                                    "& .MuiInputBase-root": {
                                      fontSize: {
                                        xs: "0.7rem",
                                        sm: "0.85rem",
                                        md: "0.9rem",
                                      },
                                      padding: {
                                        xs: "1px",
                                        sm: "2px",
                                        md: "3px",
                                        lg: "4px",
                                      },
                                    },
                                  }}
                                  label={
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70px",
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faCalendar}
                                        size="1x"
                                        style={{
                                          float: "left",
                                          marginTop: "5px",
                                          marginRight: "5px",
                                        }}
                                      />
                                      <h4>Date</h4>
                                    </div>
                                  }
                                  size="small"
                                  name="date"
                                  error={
                                    formik.touched.date && formik.errors.date
                                  }
                                  helperText={
                                    formik.touched.date && formik.errors.date
                                      ? `${formik.errors.date}`
                                      : ""
                                  }
                                />
                              </Col>
                            </Form.Group>
                          </Form>
                        </div>
                        <Formik
                          initialValues={{ quantity: "", itemId: "" }}
                          validationSchema={Yup.object().shape({
                            quantity: Yup.number()
                              .required("Quantity is required")
                              .min(1, "Too Low!"),
                            itemId: Yup.string().required("Item is required"),
                          })}
                          onSubmit={(values) => {
                            const checkItem = itemValues.find(
                              (i) => i.itemId === values.itemId
                            );
                            if (!!checkItem) {
                              setMessages("You use the same item!");
                              setOpenMessage(true);
                              return;
                            }
                            if (formik.values.senderId === "") {
                              setMessages("You must insert sender data first");
                              setOpenMessage(true);
                              return;
                            }
                            const storeItem = storeItems.find(
                              (i) =>
                                i.itemId === values.itemId &&
                                formik.values.senderId === i.storeId
                            );
                            if (
                              parseInt(storeItem.quantity) < values.quantity
                            ) {
                              setMessages(
                                `Only available quantity is ${storeItem.quantity}`
                              );
                              setOpenMessage(true);
                              return;
                            }
                            setItemValues([
                              ...itemValues,
                              {
                                itemId: values.itemId,
                                quantity: values.quantity,
                              },
                            ]);
                            values.itemId = "";
                            values.quantity = "";
                            setSelectItems(null);
                          }}
                        >
                          {(props) => (
                            <div>
                              <hr />
                              <Typography
                                sx={{
                                  fontSize: {
                                    xs: "1.3rem",
                                    sm: "1.3rem",
                                    md: "1.5rem",
                                  },
                                }}
                              >
                                <h3>Transfer Item</h3>
                              </Typography>

                              <hr />
                              <Form onSubmit={props.handleSubmit}>
                                <Form.Group as={Row}>
                                  <Col lg={4} className="mb-3 ">
                                    <Autocomplete
                                      {...itemData}
                                      id="controlled-demo"
                                      size="small"
                                      variant="outlined"
                                      name="itemId"
                                      value={selectItems}
                                      onChange={(event, newValue) => {
                                        if (!newValue?.Id) {
                                          setSelectItems(null);
                                          props.values.itemId = "";
                                          return;
                                        }
                                        props.values.itemId = newValue?.Id;
                                        setSelectItems(newValue);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          sx={{
                                            width: "250px",
                                            "& .MuiInputBase-root": {
                                              fontSize: {
                                                xs: "0.7rem",
                                                sm: "0.8rem",
                                                md: "1.1rem",
                                              },
                                              padding: {
                                                xs: "1px",
                                                sm: "2px",
                                                md: "3px",
                                                lg: "4px",
                                              },
                                            },
                                          }}
                                          error={props.errors.itemId}
                                          helperText={
                                            props.errors.itemId
                                              ? props.errors.itemId
                                              : ""
                                          }
                                          label={
                                            <div style={{ float: "left" }}>
                                              <span>Select Item</span>
                                            </div>
                                          }
                                        />
                                      )}
                                    />
                                  </Col>
                                  <Col lg={4} className="mb-3 ">
                                    <TextField
                                      id="outlined-basic"
                                      label="Remain Qty In Store"
                                      InputLabelProps={{
                                        shrink: !!selectItems,
                                      }}
                                      disabled
                                      variant="outlined"
                                      type="number"
                                      size="small"
                                      value={
                                        !!selectItems
                                          ? storeItems.find(
                                              (p) =>
                                                p.itemId === selectItems.Id &&
                                                p.storeId ===
                                                  formik.values.senderId
                                            )?.quantity
                                          : ""
                                      }
                                      sx={{
                                        width: "250px",
                                        "& .MuiInputBase-root": {
                                          fontSize: {
                                            xs: "0.7rem",
                                            sm: "0.8rem",
                                            md: "0.9rem",
                                          },
                                          padding: {
                                            xs: "1px",
                                            sm: "2px",
                                            md: "3px",
                                            lg: "4px",
                                          },
                                        },
                                      }}
                                    />
                                  </Col>
                                  <Col lg={4} className="mb-3 ">
                                    <TextField
                                      id="outlined-basic"
                                      label="Qty"
                                      variant="outlined"
                                      type="number"
                                      size="small"
                                      name="quantity"
                                      onChange={props.handleChange}
                                      value={props.values.quantity}
                                      onBlur={props.handleBlur}
                                      error={
                                        props.touched.quantity &&
                                        props.errors.quantity
                                      }
                                      helperText={
                                        props.touched.quantity &&
                                        props.errors.quantity
                                          ? `${props.errors.quantity}`
                                          : ""
                                      }
                                      sx={{
                                        width: "250px",
                                        "& .MuiInputBase-root": {
                                          fontSize: {
                                            xs: "0.7rem",
                                            sm: "0.8rem",
                                            md: "0.9rem",
                                          },
                                          padding: {
                                            xs: "1px",
                                            sm: "2px",
                                            md: "3px",
                                            lg: "4px",
                                          },
                                        },
                                      }}
                                    />
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                  <Col className="mb-3 ">
                                    <Button
                                      sx={{
                                        width: "200px",
                                        borderColor: "primary.main",
                                        fontSize: {
                                          xs: "0.85rem",
                                          sm: "0.85rem",
                                          md: "1rem",
                                        },
                                        padding: {
                                          xs: "4px",
                                          sm: "5px",
                                          md: "6px",
                                          lg: "6px",
                                        },
                                        "&:hover": {
                                          backgroundColor: "primary.main",
                                          color: "white",
                                        },
                                      }}
                                      variant="outlined"
                                      color="primary"
                                      type="submit"
                                    >
                                      <h4
                                        style={{
                                          float: "left",
                                          marginRight: "5px",
                                          marginTop: "-1px",
                                          marginBottom: "-2px",
                                        }}
                                      >
                                        +
                                      </h4>
                                      <strong
                                        style={{
                                          float: "left",
                                          marginTop: "0",
                                          marginBottom: "-2px",
                                        }}
                                      >
                                        Add to list
                                      </strong>
                                    </Button>
                                  </Col>
                                </Form.Group>
                              </Form>
                              <div>
                                <div style={{ overflow: "scroll" }}>
                                  <table className="summary-table">
                                    <thead className="bg-info">
                                      <tr>
                                        <th>Id</th>
                                        <th>Desc</th>
                                        <th>Model</th>
                                        <th>Code</th>
                                        <th>Unit</th>
                                        <th>Transfer Qty</th>
                                        <th style={{ minWidth: "200px" }}>
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {itemValues.map((request, index) => {
                                        const item = items.find(
                                          (i) => i.Id === request.itemId
                                        );

                                        return (
                                          <tr key={index}>
                                            <td>{index + 1}</td>

                                            <td>{item.description}</td>
                                            <td>
                                              {
                                                models.find(
                                                  (model) =>
                                                    model.Id === item.modelId
                                                )?.modelName
                                              }
                                            </td>
                                            <td>{item.code}</td>
                                            <td>
                                              {
                                                units.find(
                                                  (unit) =>
                                                    unit.Id === item.unitId
                                                )?.unitName
                                              }
                                            </td>
                                            <td>
                                              {openUpdate &&
                                              request.itemId ===
                                                update.itemId ? (
                                                <input
                                                  type="number"
                                                  className="form-control"
                                                  required
                                                  style={{ width: "80px" }}
                                                  value={update.quantity}
                                                  onChange={(e) => {
                                                    if (
                                                      parseInt(
                                                        e.target.value
                                                      ) <= 0
                                                    ) {
                                                      setUpdate((prev) => ({
                                                        ...prev,
                                                        quantity: 1,
                                                      }));
                                                    } else {
                                                      setUpdate((prev) => ({
                                                        ...prev,
                                                        quantity: parseInt(
                                                          e.target.value
                                                        ),
                                                      }));
                                                    }
                                                  }}
                                                />
                                              ) : (
                                                request.quantity
                                              )}
                                            </td>
                                            <td>
                                              {(request.itemId !==
                                                update.itemId ||
                                                !openUpdate) && (
                                                <div>
                                                  <Button
                                                    sx={{
                                                      width: "90px",
                                                      borderColor:
                                                        "warning.main",
                                                      fontSize: {
                                                        xs: "0.7rem",
                                                        sm: "0.85rem",
                                                        md: "1rem",
                                                      },
                                                      padding: {
                                                        xs: "3px",
                                                        sm: "4px",
                                                        md: "5px",
                                                        lg: "6px",
                                                      },
                                                      "&:hover": {
                                                        backgroundColor:
                                                          "warning.main",
                                                        color: "white",
                                                      },
                                                    }}
                                                    variant="outlined"
                                                    color="warning"
                                                    className="mr-3"
                                                    onClick={(e) => {
                                                      setOpenUpdate(true);
                                                      setUpdate({
                                                        itemId: request.itemId,
                                                        quantity:
                                                          request.quantity,
                                                      });
                                                    }}
                                                  >
                                                    <div
                                                      className="row "
                                                      style={{
                                                        marginBottom: "-10px",
                                                        marginLeft: "-10px",
                                                      }}
                                                    >
                                                      <div className="col">
                                                        <FontAwesomeIcon
                                                          style={{
                                                            float: "right",
                                                          }}
                                                          icon={faPenToSquare}
                                                        />
                                                      </div>
                                                      <div className="col-6">
                                                        <h6
                                                          style={{
                                                            float: "left",
                                                            marginLeft: "-10px",
                                                          }}
                                                        >
                                                          Edit
                                                        </h6>
                                                      </div>
                                                    </div>
                                                  </Button>
                                                  <Button
                                                    sx={{
                                                      width: "110px",
                                                      borderColor: "error.main",
                                                      fontSize: {
                                                        xs: "0.7rem",
                                                        sm: "0.85rem",
                                                        md: "1rem",
                                                      },
                                                      padding: {
                                                        xs: "3px",
                                                        sm: "4px",
                                                        md: "5px",
                                                        lg: "6px",
                                                      },
                                                      "&:hover": {
                                                        backgroundColor:
                                                          "error.main",
                                                        color: "white",
                                                      },
                                                    }}
                                                    variant="outlined"
                                                    color="error"
                                                    onClick={(e) => {
                                                      const newItemValue =
                                                        itemValues.filter(
                                                          (i) =>
                                                            parseInt(
                                                              i.itemId
                                                            ) !==
                                                            parseInt(
                                                              request.itemId
                                                            )
                                                        );
                                                      setItemValues(
                                                        newItemValue
                                                      );
                                                    }}
                                                  >
                                                    <div
                                                      className="row"
                                                      style={{
                                                        marginBottom: "-10px",
                                                        marginLeft: "-10px",
                                                      }}
                                                    >
                                                      <div className="col">
                                                        <FontAwesomeIcon
                                                          style={{
                                                            float: "right",
                                                            marginRight: "-5px",
                                                          }}
                                                          icon={faTrashCan}
                                                        />
                                                      </div>
                                                      <div className="col-8">
                                                        <h6
                                                          style={{
                                                            float: "left",
                                                            marginLeft: "-3px",
                                                          }}
                                                        >
                                                          Delete
                                                        </h6>
                                                      </div>
                                                    </div>
                                                  </Button>
                                                </div>
                                              )}

                                              {openUpdate &&
                                                request.itemId ===
                                                  update.itemId && (
                                                  <div>
                                                    <Button
                                                      sx={{
                                                        width: "90px",
                                                        borderColor:
                                                          "success.main",
                                                        fontSize: {
                                                          xs: "0.7rem",
                                                          sm: "0.85rem",
                                                          md: "1rem",
                                                        },
                                                        padding: {
                                                          xs: "3px",
                                                          sm: "4px",
                                                          md: "5px",
                                                          lg: "6px",
                                                        },
                                                        "&:hover": {
                                                          backgroundColor:
                                                            "success.main",
                                                          color: "white",
                                                        },
                                                      }}
                                                      variant="outlined"
                                                      color="success"
                                                      className="mr-3"
                                                      onClick={(e) => {
                                                        if (
                                                          formik.values
                                                            .senderId === ""
                                                        ) {
                                                          setMessages(
                                                            "You must insert sender data first"
                                                          );
                                                          setOpenMessage(true);
                                                          return;
                                                        }
                                                        const storeItem =
                                                          storeItems.find(
                                                            (i) =>
                                                              i.itemId ===
                                                                update.itemId &&
                                                              formik.values
                                                                .senderId ===
                                                                i.storeId
                                                          );

                                                        if (
                                                          parseInt(
                                                            storeItem.quantity
                                                          ) < update.quantity
                                                        ) {
                                                          setMessages(
                                                            `Only available quantity is ${storeItem.quantity}`
                                                          );
                                                          setOpenMessage(true);
                                                          return;
                                                        }

                                                        itemValues[
                                                          index
                                                        ].quantity =
                                                          update.quantity;
                                                        setItemValues([
                                                          ...itemValues,
                                                        ]);
                                                        update.itemId = "";
                                                        update.quantity = "";
                                                        setOpenUpdate(false);
                                                      }}
                                                    >
                                                      <div
                                                        className="row "
                                                        style={{
                                                          marginBottom: "-10px",
                                                          marginLeft: "-20px",
                                                        }}
                                                      >
                                                        <div className="col">
                                                          <FontAwesomeIcon
                                                            style={{
                                                              float: "right",
                                                            }}
                                                            className="fa-regular"
                                                            icon={faFloppyDisk}
                                                          />
                                                        </div>
                                                        <div className="col-6">
                                                          <h6
                                                            style={{
                                                              float: "left",
                                                              marginLeft:
                                                                "-10px",
                                                            }}
                                                          >
                                                            Save
                                                          </h6>
                                                        </div>
                                                      </div>
                                                    </Button>
                                                    <Button
                                                      sx={{
                                                        width: "100px",
                                                        borderColor:
                                                          "error.main",
                                                        fontSize: {
                                                          xs: "0.7rem",
                                                          sm: "0.85rem",
                                                          md: "1rem",
                                                        },
                                                        padding: {
                                                          xs: "3px",
                                                          sm: "4px",
                                                          md: "5px",
                                                          lg: "6px",
                                                        },
                                                        "&:hover": {
                                                          backgroundColor:
                                                            "error.main",
                                                          color: "white",
                                                        },
                                                      }}
                                                      variant="outlined"
                                                      color="error"
                                                      onClick={(e) => {
                                                        setOpenUpdate(false);
                                                        setUpdate({
                                                          itemId: "",
                                                          quantity: "",
                                                        });
                                                      }}
                                                    >
                                                      <div
                                                        className="row"
                                                        style={{
                                                          marginBottom: "-10px",
                                                          marginLeft: "-20px",
                                                        }}
                                                      >
                                                        <div className="col">
                                                          <FontAwesomeIcon
                                                            style={{
                                                              float: "right",
                                                              marginRight:
                                                                "-5px",
                                                            }}
                                                            icon={faXmark}
                                                          />
                                                        </div>
                                                        <div className="col-8">
                                                          <h6
                                                            style={{
                                                              float: "left",
                                                              marginLeft:
                                                                "-3px",
                                                            }}
                                                          >
                                                            Cancle
                                                          </h6>
                                                        </div>
                                                      </div>
                                                    </Button>
                                                  </div>
                                                )}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          )}
                        </Formik>
                        <center className="mt-3">
                          <Row className="mb-3">
                            <Col>
                              <Button
                                sx={{
                                  width: "150px",
                                  borderColor: "primary.main",
                                  fontSize: {
                                    xs: "0.7rem",
                                    sm: "0.85rem",
                                    md: "1rem",
                                  },
                                  padding: {
                                    xs: "3px",
                                    sm: "4px",
                                    md: "5px",
                                    lg: "6px",
                                  },
                                  "&:hover": {
                                    backgroundColor: "primary.main",
                                    color: "white",
                                  },
                                }}
                                variant="outlined"
                                color="primary"
                                onClick={(e) => setItemValues([])}
                              >
                                <div
                                  className="row"
                                  style={{
                                    marginBottom: "-10px",
                                    marginLeft: "-15px",
                                  }}
                                >
                                  <div className="col-2">
                                    <FontAwesomeIcon
                                      style={{
                                        float: "left",
                                        marginLeft: "0px",
                                        marginTop: "2px",
                                        marginBottom: "-4px",
                                      }}
                                      icon={faBackwardStep}
                                    />
                                  </div>
                                  <div className="col-10">
                                    <h6
                                      style={{
                                        float: "left",
                                        marginLeft: "-5px",
                                        marginTop: "-1px",
                                        marginBottom: "-7px",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: {
                                            xs: "0.85rem",
                                            sm: "0.85rem",
                                            md: "1rem",
                                          },
                                        }}
                                      >
                                        Back to list
                                      </Typography>
                                    </h6>
                                  </div>
                                </div>
                              </Button>
                            </Col>
                            <Col>
                              <Form onSubmit={formik.handleSubmit}>
                                <Button
                                  sx={{
                                    width: "150px",
                                    borderColor: "primary.main",
                                    fontSize: {
                                      xs: "0.7rem",
                                      sm: "0.85rem",
                                      md: "1rem",
                                    },
                                    padding: {
                                      xs: "3px",
                                      sm: "4px",
                                      md: "5px",
                                      lg: "6px",
                                    },
                                    "&:hover": {
                                      backgroundColor: "primary.main",
                                      color: "white",
                                    },
                                  }}
                                  variant="outlined"
                                  color="primary"
                                  type="submit"
                                >
                                  <div
                                    className="row"
                                    style={{
                                      marginBottom: "-10px",
                                      marginLeft: "-15px",
                                    }}
                                  >
                                    <div className="col-3">
                                      <FontAwesomeIcon
                                        style={{
                                          float: "left",
                                          marginLeft: "0px",
                                          marginTop: "2px",
                                          marginBottom: "-4px",
                                        }}
                                        icon={faFloppyDisk}
                                      />
                                    </div>
                                    <div className="col-9">
                                      <h6
                                        style={{
                                          float: "left",
                                          marginLeft: "-5px",
                                          marginTop: "-1px",
                                          marginBottom: "-7px",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: {
                                              xs: "0.85rem",
                                              sm: "0.85rem",
                                              md: "1rem",
                                            },
                                          }}
                                        >
                                          Submit
                                        </Typography>
                                      </h6>
                                    </div>
                                  </div>
                                </Button>
                              </Form>
                            </Col>
                          </Row>
                        </center>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </center>
          </main>
        </div>
      </Typography>
    </ThemeProvider>
  );
};

export default StoreTransfer;
