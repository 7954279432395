import React, { useEffect, useState } from "react";
import { Html5QrcodeScanner } from "html5-qrcode";
import {
  Button,
  createTheme,
  useMediaQuery,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Header from "../Header";
import { faBackwardStep } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HexCharacterManipulator from "./AllDataFetch";

const theme = createTheme({
  breakpoints: { values: { xs: 0, sm: 500, md: 769, lg: 1024, xl: 1440 } },
  palette: {
    warning: {
      main: "#ffc107", // Main warning color
      light: "#ffb74d", // Light variation of warning color
      dark: "#f57c00", // Dark variation of warning color
      contrastText: "#000", // Text color on warning
    },
  },
});
const Scanner = () => {
  const [scanResult, setScanResult] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(true);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isUnderMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    if (buttonClicked) {
      const success = async (result) => {
        setScanResult([]);
        const hexCharacterManipulator = new HexCharacterManipulator();
        scanner.clear();
        const data = result.split(",");
        const firstData = data[0].split(": ");

        let firstValueScan = firstData[0].replace(/[\n\s]+/g, "");
        console.log(firstValueScan);
        if (firstValueScan === "ItemID") {
          let realValue = data[5].split(": ");
          let hexaTochar = hexCharacterManipulator.hexaTochar(
            firstData[1],
            Number(realValue[1])
          );
          setScanResult([...data, ...[`Physical Qty : ${hexaTochar[0]}`]]);
          return;
        }
        if (firstValueScan === "DeliveryID") {
          let realValue = data[3].split(": ");
          let hexaTochar = hexCharacterManipulator.hexaTochar(
            firstData[1],
            Number(realValue[1])
          );
          setScanResult([
            ...data,
            ...[
              `Total Price : ${hexaTochar[0]}`,
              `VAT : ${hexaTochar[1]}`,
              `WithHold : ${hexaTochar[2]}`,
            ],
          ]);
          return;
        }
        if (firstValueScan === "PurchaseID") {
          let realValue = data[3].split(": ");
          let hexaTochar = hexCharacterManipulator.hexaTochar(
            firstData[1],
            Number(realValue[1])
          );
          setScanResult([
            ...data,
            ...[`Total Price : ${hexaTochar[0]}`, `VAT : ${hexaTochar[1]}`],
          ]);
          return;
        }
        if (firstValueScan === "QueueOrderID") {
          let realValue = data[3].split(": ");
          let hexaTochar = hexCharacterManipulator.hexaTochar(
            firstData[1],
            Number(realValue[1])
          );
          setScanResult([
            ...data,
            ...[
              `Total Price : ${hexaTochar[0]}`,
              `VAT : ${hexaTochar[1]}`,
              `WithHold : ${hexaTochar[2]}`,
            ],
          ]);
          return;
        }
        if (firstValueScan === "QuotationID") {
          let realValue = data[3].split(": ");
          let hexaTochar = hexCharacterManipulator.hexaTochar(
            firstData[1],
            Number(realValue[1])
          );
          setScanResult([
            ...data,
            ...[
              `Total Price : ${hexaTochar[0]}`,
              `VAT : ${hexaTochar[1]}`,
              `WithHold : ${hexaTochar[2]}`,
            ],
          ]);
          return;
        }
        if (firstValueScan === "RequestID") {
          let realValue = data[3].split(": ");
          let hexaTochar = hexCharacterManipulator.hexaTochar(
            firstData[1],
            Number(realValue[1])
          );
          setScanResult([
            ...data,
            ...[
              `Total Price : ${hexaTochar[0]}`,
              `VAT : ${hexaTochar[1]}`,
              `WithHold : ${hexaTochar[2]}`,
            ],
          ]);
          return;
        }

        if (firstValueScan === "ReserveID") {
          let realValue = data[3].split(": ");
          let hexaTochar = hexCharacterManipulator.hexaTochar(
            firstData[1],
            Number(realValue[1])
          );
          setScanResult([
            ...data,
            ...[
              `Total Price : ${hexaTochar[0]}`,
              `VAT : ${hexaTochar[1]}`,
              `WithHold : ${hexaTochar[2]}`,
            ],
          ]);
          return;
        }
        if (firstValueScan === "SaleID") {
          console.log("jjjjj");
          let realValue = data[3].split(": ");
          let hexaTochar = hexCharacterManipulator.hexaTochar(
            firstData[1],
            Number(realValue[1])
          );
          setScanResult([
            ...data,
            ...[
              `Total Price : ${hexaTochar[0]}`,
              `VAT : ${hexaTochar[1]}`,
              `WithHold : ${hexaTochar[2]}`,
            ],
          ]);
          return;
        }
      };
      const error = (error) => {
        console.error(error);
      };
      let scanner = new Html5QrcodeScanner("reader", {
        fps: 5,
        qrbox: { width: 250, height: 250 },
      });
      scanner.render(success, error);
      setButtonClicked(false);
    }
  }, [buttonClicked]);
  const handleView = (data, index) => {
    const dataValue = data.split(": ");
    return (
      <tr>
        <th>{index + 1}</th>
        <th>{dataValue[0]}</th>
        <td>{dataValue[1]}</td>
      </tr>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Typography
        sx={{
          fontSize: {
            xs: "12px",
            sm: "12px", //500
            md: "0.85rem", //768
            lg: "17px", //1024
          },
        }}
      >
        <div>
          <Header />
        </div>
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: "13px",
            sm: "14px", //500
            md: "16px", //768
            lg: "18px", //1024
          },
        }}
      >
        <div>
          <main
            className=""
            style={
              isMobile && !isUnderMobile
                ? { marginTop: "170px" }
                : isUnderMobile
                ? { marginTop: "170px" }
                : isTablet
                ? { marginTop: "115px" }
                : { marginTop: "130px" }
            }
          >
            <center>
              <div
                style={
                  isUnderMobile
                    ? {
                        minWidth: "500px",
                        overflow: "scroll",
                        marginBottom: "-5px",
                      }
                    : { maxWidth: "1100px" }
                }
                className="text-left"
              >
                <div className="card card-outline card-info">
                  {scanResult && (
                    <div className="card-header">
                      <Button
                        onClick={(e) => {
                          setButtonClicked(true);
                          setScanResult(null);
                        }}
                        sx={{
                          width: "100px",
                          borderColor: "success.main",
                          fontSize: {
                            xs: "0.85rem",
                            sm: "0.85rem",
                            md: "1rem",
                          },
                          padding: {
                            xs: "4px",
                            sm: "5px",
                            md: "6px",
                            lg: "6px",
                          },
                          "&:hover": {
                            backgroundColor: "success.main",
                            color: "white",
                          },
                        }}
                        variant="outlined"
                        color="success"
                        className="m-1"
                        style={{ float: "right" }}
                      >
                        <div
                          className="row"
                          style={{
                            marginBottom: "-10px",
                            marginLeft: "-10px",
                          }}
                        >
                          <div className="col">
                            <FontAwesomeIcon
                              style={{
                                float: "right",
                                marginRight: "-1px",
                                marginTop: "2px",
                                marginBottom: "-4px",
                              }}
                              icon={faBackwardStep}
                            />
                          </div>
                          <div className="col-7">
                            <h6
                              style={{
                                float: "left",
                                marginLeft: "-10px",
                                marginTop: "-1px",
                                marginBottom: "-7px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: {
                                    xs: "0.85rem",
                                    sm: "0.85rem",
                                    md: "1rem",
                                  },
                                }}
                              >
                                Back
                              </Typography>
                            </h6>
                          </div>
                        </div>
                      </Button>
                    </div>
                  )}
                  <center>
                    <div className="card-body" style={{ overflow: "scroll" }}>
                      {scanResult ? (
                        <div
                          className="row m-3"
                          style={{
                            minWidth: "400px",
                            maxWidth: "800px",
                            maxHeight: "500px",
                          }}
                        >
                          {isMobile ? (
                            <>
                              <table>
                                <thead>
                                  <th>No</th>
                                  <th></th>
                                  <th></th>
                                </thead>
                                <tbody>
                                  {scanResult.map((s, i) => {
                                    return handleView(s, i);
                                  })}
                                </tbody>
                              </table>
                              <div
                                className="mt-4"
                                style={{ color: "transparent" }}
                              >
                                hello
                              </div>
                            </>
                          ) : (
                            <>
                              {" "}
                              <div className="col-md-6">
                                <table>
                                  <thead>
                                    <th>No</th>
                                    <th></th>
                                    <th></th>
                                  </thead>
                                  <tbody>
                                    {scanResult.map((s, i) => {
                                      if (i >= scanResult.length / 2) return "";
                                      return handleView(s, i);
                                    })}
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-md-6">
                                {" "}
                                <table>
                                  <thead>
                                    <th>No</th>
                                    <th></th>
                                    <th></th>
                                  </thead>
                                  <tbody>
                                    {scanResult.map((s, i) => {
                                      if (i < scanResult.length / 2) return "";
                                      return handleView(s, i);
                                    })}
                                  </tbody>
                                </table>
                              </div>
                              <div
                                className="mt-5"
                                style={{ color: "transparent" }}
                              >
                                hello
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        <div style={{ maxWidth: "400px" }}>
                          <div id="reader" className="m-5"></div>
                        </div>
                      )}
                    </div>
                  </center>
                </div>
              </div>
            </center>
          </main>
        </div>
      </Typography>
    </ThemeProvider>
  );
};
export default Scanner;
