/** @format */
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUsers,
  faCog,
  faSignOutAlt,
  faUserPlus,
  faBarcode,
  faBasketShopping,
  faList,
  faPaperPlane,
  faCalendarDay,
  faTruck,
  faChartColumn,
  faStore,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import "./App.css";
import {
  createTheme,
  useMediaQuery,
  ThemeProvider,
  Snackbar,
  Alert,
  AlertTitle,
} from "@mui/material";
import { QrCodeScannerOutlined } from "@mui/icons-material";
import { jwtDecode } from "jwt-decode";
const custTheme = createTheme({
  breakpoints: { values: { xs: 0, sm: 500, md: 768, lg: 850, xl: 1440 } },
});
const Header = () => {
  const [open, setOpen] = useState(false);
  const userData = jwtDecode(sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const session = window.sessionStorage.getItem("access");
  const navigate = useNavigate();
  const fetchAllData = async () => {
    if (session) setOpen(true);
    window.sessionStorage.removeItem("access");
    if (userData.Id === 0) {
      navigate(`/`);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);
  const isMobile = useMediaQuery(custTheme.breakpoints.down("md"));
  const isUnderMobile = useMediaQuery(custTheme.breakpoints.down("sm"));
  return (
    <ThemeProvider theme={custTheme}>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={(e) => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity="error"
          onClose={(e) => setOpen(false)}
          variant="outlined"
          sx={{ bgcolor: "background.paper" }}
        >
          <AlertTitle>Access Denied</AlertTitle>
          You do not have permission to access this page.
        </Alert>
      </Snackbar>

      <header
        className="bg-body-tertiary fixed-top "
        style={isUnderMobile ? { minWidth: "500px", overflow: "scroll" } : {}}
      >
        <div
          className="App-header-top row"
          style={{ marginTop: "-7px", marginBottom: "-8px" }}
        >
          <div className=" navbar-menu">
            <div className="row">
              <div className="col" style={{ marginTop: "6px" }}>
                <span style={{ color: "white", fontSize: 18 }}>Enin</span>
              </div>
              <div className="col">
                <Link to="/home" style={{ color: "white", fontSize: 14 }}>
                  Home
                </Link>
              </div>
              <div className="col"></div>
              <div className="col">
                {" "}
                <Link
                  to="/user_mgt"
                  style={{
                    color: "white",
                    fontSize: 14,
                  }}
                >
                  <FontAwesomeIcon icon={faCog} />
                  Setting
                </Link>
              </div>
              <div className="col">
                {" "}
                <Link
                  to={"/scanner"}
                  style={{ color: "white", fontSize: 14, overflow: "hidden" }}
                >
                  <QrCodeScannerOutlined /> Scanner
                </Link>
              </div>
              <div className="col">
                <Link
                  onClick={(e) => {
                    sessionStorage.removeItem("data");
                  }}
                  to="/"
                  style={{ color: "white", fontSize: 14 }}
                >
                  <FontAwesomeIcon icon={faSignOutAlt} />
                  Logout
                </Link>
              </div>
            </div>
          </div>
        </div>
        {isMobile ? (
          <div>
            <div className="App-header-bottom row">
              <nav className="navbar-menu">
                <div className="row">
                  <Link
                    className="col"
                    to="/customers"
                    onClick={(e) => {
                      if (window.location.pathname === "/customers")
                        window.location.reload();
                    }}
                    style={
                      window.location.pathname === "/customers"
                        ? { backgroundColor: "rgb(235,235,235)" }
                        : {}
                    }
                  >
                    <center>
                      <div
                        style={
                          window.location.pathname === "/customers"
                            ? { color: "black" }
                            : {}
                        }
                      >
                        <div>
                          <FontAwesomeIcon icon={faUserPlus} size="2x" />
                        </div>
                        <div>Customers</div>
                      </div>
                    </center>
                  </Link>
                  <Link
                    className="col"
                    to="/supplier"
                    onClick={(e) => {
                      if (window.location.pathname === "/supplier")
                        window.location.reload();
                    }}
                    style={
                      window.location.pathname === "/supplier"
                        ? { backgroundColor: "rgb(235,235,235)" }
                        : {}
                    }
                  >
                    <center>
                      <div
                        style={
                          window.location.pathname === "/supplier"
                            ? { color: "black" }
                            : {}
                        }
                      >
                        <div>
                          <FontAwesomeIcon icon={faUser} size="2x" />
                        </div>
                        <div>Supplier</div>
                      </div>
                    </center>
                  </Link>
                  <Link
                    className="col"
                    to="/item"
                    onClick={(e) => {
                      if (
                        window.location.pathname === "/item" ||
                        window.location.pathname === "/unit" ||
                        window.location.pathname === "/model" ||
                        window.location.pathname === "/tax"
                      )
                        window.location.reload();
                    }}
                    style={
                      window.location.pathname === "/item" ||
                      window.location.pathname === "/unit" ||
                      window.location.pathname === "/model" ||
                      window.location.pathname === "/tax"
                        ? { backgroundColor: "rgb(235,235,235)" }
                        : {}
                    }
                  >
                    <center>
                      <div
                        style={
                          window.location.pathname === "/item" ||
                          window.location.pathname === "/unit" ||
                          window.location.pathname === "/tax" ||
                          window.location.pathname === "/model"
                            ? { color: "black" }
                            : {}
                        }
                      >
                        <div>
                          <FontAwesomeIcon size="2x" icon={faList} />
                        </div>
                        <div>Items</div>
                      </div>
                    </center>
                  </Link>
                  <Link
                    className="col"
                    to="/purchase"
                    onClick={(e) => {
                      if (
                        window.location.pathname === "/purchase" ||
                        window.location.pathname === "/adjustment" ||
                        window.location.pathname === "/stock_available/1" ||
                        window.location.pathname === "/stock_available/2" ||
                        window.location.pathname === "/payment"
                      )
                        window.location.reload();
                    }}
                    style={
                      window.location.pathname === "/purchase" ||
                      window.location.pathname === "/adjustment" ||
                      window.location.pathname === "/stock_available/1" ||
                      window.location.pathname === "/stock_available/2" ||
                      window.location.pathname === "/payment"
                        ? { backgroundColor: "rgb(235,235,235)" }
                        : {}
                    }
                  >
                    <center>
                      <div
                        style={
                          window.location.pathname === "/purchase" ||
                          window.location.pathname === "/adjustment" ||
                          window.location.pathname === "/stock_available/1" ||
                          window.location.pathname === "/stock_available/2" ||
                          window.location.pathname === "/payment"
                            ? { color: "black" }
                            : {}
                        }
                      >
                        <div>
                          <FontAwesomeIcon size="2x" icon={faBarcode} />
                        </div>
                        <div>Invertory</div>
                      </div>
                    </center>
                  </Link>
                  <Link
                    className="col"
                    to="/request"
                    onClick={(e) => {
                      if (
                        window.location.pathname === "/request" ||
                        window.location.pathname === "/quota/1" ||
                        window.location.pathname === "/quota/2"
                      )
                        window.location.reload();
                    }}
                    style={
                      window.location.pathname === "/request" ||
                      window.location.pathname === "/quota/1" ||
                      window.location.pathname === "/quota/2"
                        ? { backgroundColor: "rgb(235,235,235)" }
                        : {}
                    }
                  >
                    <center>
                      <div
                        style={
                          window.location.pathname === "/request" ||
                          window.location.pathname === "/quota/1" ||
                          window.location.pathname === "/quota/2"
                            ? { color: "black" }
                            : {}
                        }
                      >
                        <div>
                          <FontAwesomeIcon icon={faPaperPlane} size="2x" />
                        </div>
                        <div>Request</div>
                      </div>
                    </center>
                  </Link>
                  <Link
                    className="col"
                    to={"/reserve/1"}
                    onClick={(e) => {
                      if (
                        window.location.pathname === "/reserve/1" ||
                        window.location.pathname === "/reserve/2" ||
                        window.location.pathname === "/queue_order/1" ||
                        window.location.pathname === "/queue_order/2"
                      )
                        window.location.reload();
                    }}
                    style={
                      window.location.pathname === "/reserve/1" ||
                      window.location.pathname === "/reserve/2" ||
                      window.location.pathname === "/queue_order/1" ||
                      window.location.pathname === "/queue_order/2"
                        ? { backgroundColor: "rgb(235,235,235)" }
                        : {}
                    }
                  >
                    <center>
                      <div
                        style={
                          window.location.pathname === "/reserve/1" ||
                          window.location.pathname === "/reserve/2" ||
                          window.location.pathname === "/queue_order/1" ||
                          window.location.pathname === "/queue_order/2"
                            ? { color: "black" }
                            : {}
                        }
                      >
                        <div>
                          <FontAwesomeIcon icon={faCalendarDay} size="2x" />
                        </div>
                        <div>Reserve</div>
                      </div>
                    </center>
                  </Link>
                </div>
              </nav>
            </div>
          </div>
        ) : (
          <div className="App-header-bottom ">
            <nav className="navbar-menu">
              <div className="row">
                <Link
                  className="col"
                  to="/customers"
                  onClick={(e) => {
                    if (window.location.pathname === "/customers")
                      window.location.reload();
                  }}
                  style={
                    window.location.pathname === "/customers"
                      ? { backgroundColor: "rgb(235,235,235)" }
                      : {}
                  }
                >
                  <center>
                    <div
                      style={
                        window.location.pathname === "/customers"
                          ? { color: "black" }
                          : {}
                      }
                    >
                      <div>
                        <FontAwesomeIcon icon={faUserPlus} size="2x" />
                      </div>
                      <div>Customers</div>
                    </div>
                  </center>
                </Link>
                <Link
                  className="col"
                  to="/supplier"
                  onClick={(e) => {
                    if (window.location.pathname === "/supplier")
                      window.location.reload();
                  }}
                  style={
                    window.location.pathname === "/supplier"
                      ? { backgroundColor: "rgb(235,235,235)" }
                      : {}
                  }
                >
                  <center>
                    <div
                      style={
                        window.location.pathname === "/supplier"
                          ? { color: "black" }
                          : {}
                      }
                    >
                      <div>
                        <FontAwesomeIcon icon={faUser} size="2x" />
                      </div>
                      <div>Supplier</div>
                    </div>
                  </center>
                </Link>
                <Link
                  className="col"
                  to="/item"
                  onClick={(e) => {
                    if (
                      window.location.pathname === "/item" ||
                      window.location.pathname === "/unit" ||
                      window.location.pathname === "/model" ||
                      window.location.pathname === "/tax"
                    )
                      window.location.reload();
                  }}
                  style={
                    window.location.pathname === "/item" ||
                    window.location.pathname === "/unit" ||
                    window.location.pathname === "/model" ||
                    window.location.pathname === "/tax"
                      ? { backgroundColor: "rgb(235,235,235)" }
                      : {}
                  }
                >
                  <center>
                    <div
                      style={
                        window.location.pathname === "/item" ||
                        window.location.pathname === "/unit" ||
                        window.location.pathname === "/tax" ||
                        window.location.pathname === "/model"
                          ? { color: "black" }
                          : {}
                      }
                    >
                      <div>
                        <FontAwesomeIcon size="2x" icon={faList} />
                      </div>
                      <div>Items</div>
                    </div>
                  </center>
                </Link>
                <Link
                  className="col"
                  to="/purchase"
                  onClick={(e) => {
                    if (
                      window.location.pathname === "/purchase" ||
                      window.location.pathname === "/adjustment" ||
                      window.location.pathname === "/stock_available/1" ||
                      window.location.pathname === "/stock_available/2" ||
                      window.location.pathname === "/payment"
                    )
                      window.location.reload();
                  }}
                  style={
                    window.location.pathname === "/purchase" ||
                    window.location.pathname === "/adjustment" ||
                    window.location.pathname === "/stock_available/1" ||
                    window.location.pathname === "/stock_available/2" ||
                    window.location.pathname === "/payment"
                      ? { backgroundColor: "rgb(235,235,235)" }
                      : {}
                  }
                >
                  <center>
                    <div
                      style={
                        window.location.pathname === "/purchase" ||
                        window.location.pathname === "/adjustment" ||
                        window.location.pathname === "/stock_available/1" ||
                        window.location.pathname === "/stock_available/2" ||
                        window.location.pathname === "/payment"
                          ? { color: "black" }
                          : {}
                      }
                    >
                      <div>
                        <FontAwesomeIcon size="2x" icon={faBarcode} />
                      </div>
                      <div>Invertory</div>
                    </div>
                  </center>
                </Link>
                <Link
                  className="col"
                  to="/request"
                  onClick={(e) => {
                    if (
                      window.location.pathname === "/request" ||
                      window.location.pathname === "/quota/1" ||
                      window.location.pathname === "/quota/2"
                    )
                      window.location.reload();
                  }}
                  style={
                    window.location.pathname === "/request" ||
                    window.location.pathname === "/quota/1" ||
                    window.location.pathname === "/quota/2"
                      ? { backgroundColor: "rgb(235,235,235)" }
                      : {}
                  }
                >
                  <center>
                    <div
                      style={
                        window.location.pathname === "/request" ||
                        window.location.pathname === "/quota/1" ||
                        window.location.pathname === "/quota/2"
                          ? { color: "black" }
                          : {}
                      }
                    >
                      <div>
                        <FontAwesomeIcon icon={faPaperPlane} size="2x" />
                      </div>
                      <div>Request</div>
                    </div>
                  </center>
                </Link>
                <Link
                  className="col"
                  to={"/reserve/1"}
                  onClick={(e) => {
                    if (
                      window.location.pathname === "/reserve/1" ||
                      window.location.pathname === "/reserve/2" ||
                      window.location.pathname === "/queue_order/1" ||
                      window.location.pathname === "/queue_order/2"
                    )
                      window.location.reload();
                  }}
                  style={
                    window.location.pathname === "/reserve/1" ||
                    window.location.pathname === "/reserve/2" ||
                    window.location.pathname === "/queue_order/1" ||
                    window.location.pathname === "/queue_order/2"
                      ? { backgroundColor: "rgb(235,235,235)" }
                      : {}
                  }
                >
                  <center>
                    <div
                      style={
                        window.location.pathname === "/reserve/1" ||
                        window.location.pathname === "/reserve/2" ||
                        window.location.pathname === "/queue_order/1" ||
                        window.location.pathname === "/queue_order/2"
                          ? { color: "black" }
                          : {}
                      }
                    >
                      <div>
                        <FontAwesomeIcon icon={faCalendarDay} size="2x" />
                      </div>
                      <div>Reserve</div>
                    </div>
                  </center>
                </Link>
                <Link
                  className="col"
                  to="/sales/1"
                  onClick={(e) => {
                    if (
                      window.location.pathname === "/sales/1" ||
                      window.location.pathname === "/sales/2"
                    )
                      window.location.reload();
                  }}
                  style={
                    window.location.pathname === "/sales/1" ||
                    window.location.pathname === "/sales/2"
                      ? { backgroundColor: "rgb(235,235,235)" }
                      : {}
                  }
                >
                  <center>
                    <div
                      style={
                        window.location.pathname === "/sales/1" ||
                        window.location.pathname === "/sales/2"
                          ? { color: "black" }
                          : {}
                      }
                    >
                      <div>
                        <FontAwesomeIcon size="2x" icon={faBasketShopping} />
                      </div>
                      <div>Sales</div>
                    </div>
                  </center>
                </Link>
                <Link
                  className="col"
                  to="/delivery/1"
                  onClick={(e) => {
                    if (
                      window.location.pathname === "/delivery/1" ||
                      window.location.pathname === "/delivery/2"
                    )
                      window.location.reload();
                  }}
                  style={
                    window.location.pathname === "/delivery/1" ||
                    window.location.pathname === "/delivery/2"
                      ? { backgroundColor: "rgb(235,235,235)" }
                      : {}
                  }
                >
                  <center>
                    <div
                      style={
                        window.location.pathname === "/delivery/1" ||
                        window.location.pathname === "/delivery/2"
                          ? { color: "black" }
                          : {}
                      }
                    >
                      <div>
                        <FontAwesomeIcon icon={faTruck} size="2x" />
                      </div>
                      <div>Delivery</div>
                    </div>
                  </center>
                </Link>
                <Link
                  className="col"
                  to="/store"
                  onClick={(e) => {
                    if (
                      window.location.pathname === "/store" ||
                      window.location.pathname === "/purchase_delivery/1" ||
                      window.location.pathname === "/purchase_delivery/2" ||
                      window.location.pathname === "/store_transfer" ||
                      window.location.pathname === "/delivery_item/1" ||
                      window.location.pathname === "/delivery_item/2" ||
                      window.location.pathname === "/adjustment_delivery/1" ||
                      window.location.pathname === "/adjustment_delivery/2"
                    )
                      window.location.reload();
                  }}
                  style={
                    window.location.pathname === "/store" ||
                    window.location.pathname === "/purchase_delivery/1" ||
                    window.location.pathname === "/purchase_delivery/2" ||
                    window.location.pathname === "/store_transfer" ||
                    window.location.pathname === "/delivery_item/1" ||
                    window.location.pathname === "/delivery_item/2" ||
                    window.location.pathname === "/adjustment_delivery/1" ||
                    window.location.pathname === "/adjustment_delivery/2"
                      ? { backgroundColor: "rgb(235,235,235)" }
                      : {}
                  }
                >
                  <center>
                    <div
                      style={
                        window.location.pathname === "/store" ||
                        window.location.pathname === "/purchase_delivery/1" ||
                        window.location.pathname === "/purchase_delivery/2" ||
                        window.location.pathname === "/store_transfer" ||
                        window.location.pathname === "/delivery_item/1" ||
                        window.location.pathname === "/delivery_item/2" ||
                        window.location.pathname === "/adjustment_delivery/1" ||
                        window.location.pathname === "/adjustment_delivery/2"
                          ? { color: "black" }
                          : {}
                      }
                    >
                      <div>
                        <FontAwesomeIcon icon={faStore} size="2x" />
                      </div>
                      <div>Ware House</div>
                    </div>
                  </center>
                </Link>
                <Link
                  className="col"
                  to={userData.roleId > 2 ? "/account_mgt" : "/user_mgt"}
                  onClick={(e) => {
                    if (
                      window.location.pathname === "/user_mgt" ||
                      window.location.pathname === "/role_mgt" ||
                      window.location.pathname === "/account_mgt"
                    )
                      window.location.reload();
                  }}
                  style={
                    window.location.pathname === "/user_mgt" ||
                    window.location.pathname === "/role_mgt" ||
                    window.location.pathname === "/account_mgt"
                      ? { backgroundColor: "rgb(235,235,235)" }
                      : {}
                  }
                >
                  <center>
                    <div
                      style={
                        window.location.pathname === "/user_mgt" ||
                        window.location.pathname === "/role_mgt" ||
                        window.location.pathname === "/account_mgt"
                          ? { color: "black" }
                          : {}
                      }
                    >
                      <div>
                        <FontAwesomeIcon size="2x" icon={faUsers} />
                      </div>
                      <div>
                        User
                        <span
                          style={
                            window.location.pathname === "/user_mgt" ||
                            window.location.pathname === "/role_mgt" ||
                            window.location.pathname === "/account_mgt"
                              ? { color: "rgb(235,235,235)" }
                              : { color: "#1de5ec" }
                          }
                        >
                          _
                        </span>
                        Mgt
                      </div>
                    </div>
                  </center>
                </Link>
                <Link
                  className="col"
                  to={
                    userData.roleId === 4
                      ? "/sales_report"
                      : userData.roleId === 5
                      ? "/delivery_report"
                      : "/purchase_report"
                  }
                  onClick={(e) => {
                    if (
                      window.location.pathname === "/purchase_report" ||
                      window.location.pathname === "/sales_report" ||
                      window.location.pathname === "/stock_report" ||
                      window.location.pathname === "/delivery_report" ||
                      window.location.pathname === "/quantity_report"
                    )
                      window.location.reload();
                  }}
                  style={
                    window.location.pathname === "/purchase_report" ||
                    window.location.pathname === "/sales_report" ||
                    window.location.pathname === "/stock_report" ||
                    window.location.pathname === "/delivery_report" ||
                    window.location.pathname === "/quantity_report"
                      ? { backgroundColor: "rgb(235,235,235)" }
                      : {}
                  }
                >
                  <center>
                    <div
                      style={
                        window.location.pathname === "/purchase_report" ||
                        window.location.pathname === "/sales_report" ||
                        window.location.pathname === "/stock_report" ||
                        window.location.pathname === "/delivery_report" ||
                        window.location.pathname === "/quantity_report"
                          ? { color: "black" }
                          : {}
                      }
                    >
                      <div>
                        <FontAwesomeIcon size="2x" icon={faChartColumn} />
                      </div>
                      <div>Report</div>
                    </div>
                  </center>
                </Link>
              </div>
            </nav>
          </div>
        )}
        {isMobile && (
          <center>
            <div className="App-header-bottom row">
              <nav className="navbar-menu">
                <div className="row">
                  <Link
                    className="col"
                    to="/sales/1"
                    onClick={(e) => {
                      if (
                        window.location.pathname === "/sales/1" ||
                        window.location.pathname === "/sales/2"
                      )
                        window.location.reload();
                    }}
                    style={
                      window.location.pathname === "/sales/1" ||
                      window.location.pathname === "/sales/2"
                        ? { backgroundColor: "rgb(235,235,235)" }
                        : {}
                    }
                  >
                    <center>
                      <div
                        style={
                          window.location.pathname === "/sales/1" ||
                          window.location.pathname === "/sales/2"
                            ? { color: "black" }
                            : {}
                        }
                      >
                        <div>
                          <FontAwesomeIcon size="2x" icon={faBasketShopping} />
                        </div>
                        <div>Sales</div>
                      </div>
                    </center>
                  </Link>
                  <Link
                    className="col"
                    to="/delivery/1"
                    onClick={(e) => {
                      if (
                        window.location.pathname === "/delivery/1" ||
                        window.location.pathname === "/delivery/2"
                      )
                        window.location.reload();
                    }}
                    style={
                      window.location.pathname === "/delivery/1" ||
                      window.location.pathname === "/delivery/2"
                        ? { backgroundColor: "rgb(235,235,235)" }
                        : {}
                    }
                  >
                    <center>
                      <div
                        style={
                          window.location.pathname === "/delivery/1" ||
                          window.location.pathname === "/delivery/2"
                            ? { color: "black" }
                            : {}
                        }
                      >
                        <div>
                          <FontAwesomeIcon icon={faTruck} size="2x" />
                        </div>
                        <div>Delivery</div>
                      </div>
                    </center>
                  </Link>
                  <Link
                    className="col"
                    to="/store"
                    onClick={(e) => {
                      if (
                        window.location.pathname === "/store" ||
                        window.location.pathname === "/purchase_delivery/1" ||
                        window.location.pathname === "/purchase_delivery/2" ||
                        window.location.pathname === "/store_transfer" ||
                        window.location.pathname === "/delivery_item/1" ||
                        window.location.pathname === "/delivery_item/2" ||
                        window.location.pathname === "/adjustment_delivery/1" ||
                        window.location.pathname === "/adjustment_delivery/2"
                      )
                        window.location.reload();
                    }}
                    style={
                      window.location.pathname === "/store" ||
                      window.location.pathname === "/purchase_delivery/1" ||
                      window.location.pathname === "/purchase_delivery/2" ||
                      window.location.pathname === "/store_transfer" ||
                      window.location.pathname === "/delivery_item/1" ||
                      window.location.pathname === "/delivery_item/2" ||
                      window.location.pathname === "/adjustment_delivery/1" ||
                      window.location.pathname === "/adjustment_delivery/2"
                        ? { backgroundColor: "rgb(235,235,235)" }
                        : {}
                    }
                  >
                    <center>
                      <div
                        style={
                          window.location.pathname === "/store" ||
                          window.location.pathname === "/purchase_delivery/1" ||
                          window.location.pathname === "/purchase_delivery/2" ||
                          window.location.pathname === "/store_transfer" ||
                          window.location.pathname === "/delivery_item/1" ||
                          window.location.pathname === "/delivery_item/2" ||
                          window.location.pathname ===
                            "/adjustment_delivery/1" ||
                          window.location.pathname === "/adjustment_delivery/2"
                            ? { color: "black" }
                            : {}
                        }
                      >
                        <div>
                          <FontAwesomeIcon icon={faStore} size="2x" />
                        </div>
                        <div>Ware House</div>
                      </div>
                    </center>
                  </Link>
                  <Link
                    className="col"
                    to={userData.roleId > 2 ? "/account_mgt" : "/user_mgt"}
                    onClick={(e) => {
                      if (
                        window.location.pathname === "/user_mgt" ||
                        window.location.pathname === "/role_mgt" ||
                        window.location.pathname === "/account_mgt"
                      )
                        window.location.reload();
                    }}
                    style={
                      window.location.pathname === "/user_mgt" ||
                      window.location.pathname === "/role_mgt" ||
                      window.location.pathname === "/account_mgt"
                        ? { backgroundColor: "rgb(235,235,235)" }
                        : {}
                    }
                  >
                    <center>
                      <div
                        style={
                          window.location.pathname === "/user_mgt" ||
                          window.location.pathname === "/role_mgt" ||
                          window.location.pathname === "/account_mgt"
                            ? { color: "black" }
                            : {}
                        }
                      >
                        <div>
                          <FontAwesomeIcon size="2x" icon={faUsers} />
                        </div>
                        <div>
                          User
                          <span
                            style={
                              window.location.pathname === "/user_mgt" ||
                              window.location.pathname === "/role_mgt" ||
                              window.location.pathname === "/account_mgt"
                                ? { color: "rgb(235,235,235)" }
                                : { color: "#1de5ec" }
                            }
                          >
                            _
                          </span>
                          Mgt
                        </div>
                      </div>
                    </center>
                  </Link>
                  <Link
                    className="col"
                    to={
                      userData.roleId === 4
                        ? "/sales_report"
                        : userData.roleId === 5
                        ? "/delivery_report"
                        : "/purchase_report"
                    }
                    onClick={(e) => {
                      if (
                        window.location.pathname === "/purchase_report" ||
                        window.location.pathname === "/sales_report" ||
                        window.location.pathname === "/stock_report" ||
                        window.location.pathname === "/delivery_report" ||
                        window.location.pathname === "/quantity_report"
                      )
                        window.location.reload();
                    }}
                    style={
                      window.location.pathname === "/purchase_report" ||
                      window.location.pathname === "/sales_report" ||
                      window.location.pathname === "/stock_report" ||
                      window.location.pathname === "/delivery_report" ||
                      window.location.pathname === "/quantity_report"
                        ? { backgroundColor: "rgb(235,235,235)" }
                        : {}
                    }
                  >
                    <center>
                      <div
                        style={
                          window.location.pathname === "/purchase_report" ||
                          window.location.pathname === "/sales_report" ||
                          window.location.pathname === "/stock_report" ||
                          window.location.pathname === "/delivery_report" ||
                          window.location.pathname === "/quantity_report"
                            ? { color: "black" }
                            : {}
                        }
                      >
                        <div>
                          <FontAwesomeIcon size="2x" icon={faChartColumn} />
                        </div>
                        <div>Report</div>
                      </div>
                    </center>
                  </Link>
                </div>
              </nav>
            </div>
          </center>
        )}
      </header>
    </ThemeProvider>
  );
};

export default Header;
