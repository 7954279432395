/** @format */

import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import { Form, Col } from "react-bootstrap";
import { TextField, Button, Snackbar } from "@mui/material";
import { Formik } from "formik";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    ochre: {
      main: "#222222",
      light: "#FF2222",
      dark: "#222222",
      contrastText: "#FFFFFF",
    },
  },
});
const Login = () => {
  const [users, setUser] = useState({
    userName: "",
    password: "",
    email: "",
  });
  const [opens, setOpens] = useState(false);
  const [forget, setForgets] = useState(false);
  const [invalidData, setInvalidData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(0);
  const [check, setChecks] = useState({
    email: false,
    userName: true,
  });
  const navigate = useNavigate();

  const handelForget = (e) => {
    e.preventDefault();
    setForgets(true);
  };

  const handelchange = (e) => {
    setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handelclick = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const datas = await axios.post(
        "https://enin.server.highendtradingplc.com/login",
        users
      );
      setInvalidData(datas.data?.error);
      const decodedToken = JSON.parse(atob(datas.data.token.split(".")[1]));
      console.log(decodedToken);
      window.sessionStorage.setItem("data", datas.data.token);
      if (decodedToken.roleId === 1) return navigate("/user_mgt");
      else if (decodedToken.roleId >= 2 && decodedToken.roleId <= 5)
        return navigate("/home");
      setOpens(true);
    } catch (error) {
      setOpens(true);
    }
    setIsLoading(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <div id="" className="">
        <center>
          <div className="">
            <div className="">
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={openAlert > 0}
                autoHideDuration={5000}
                onClose={(e) => setOpenAlert(0)}
              >
                <Alert
                  onClose={(e) => setOpenAlert(0)}
                  severity={
                    openAlert === 1
                      ? "success"
                      : openAlert === 2
                      ? "error"
                      : "warning"
                  }
                  variant="filled"
                  sx={{ width: "100%" }}
                >
                  {message}
                </Alert>
              </Snackbar>
              {!forget && (
                <div
                  className="card card-outline card-info"
                  style={{
                    width: "500px",
                    marginTop: "50px",
                  }}
                >
                  <div className="card-header">
                    <h4 className="mb-2">Welcome ! 👋</h4>

                    <Snackbar
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      open={opens}
                      autoHideDuration={5000}
                      onClose={(e) => setOpens(false)}
                    >
                      <Alert
                        onClose={(e) => setOpens(false)}
                        severity={"error"}
                        variant="filled"
                        sx={{ width: "100%" }}
                      >
                        <AlertTitle>Try Again</AlertTitle>
                        {invalidData}
                      </Alert>
                    </Snackbar>
                  </div>

                  <div className="card-body">
                    <br />
                    <center>
                      <div>
                        <Form id="formAuthentication" className="mb-2">
                          <div className="">
                            <Form.Group className="mb-3 row">
                              <Col sm={12}>
                                {check.userName && (
                                  <TextField
                                    type="text"
                                    className="form-control"
                                    id="email"
                                    name="userName"
                                    size="small"
                                    label="Username"
                                    autofocus
                                    onChange={handelchange}
                                    style={{ width: "350px" }}
                                  />
                                )}{" "}
                                {check.email && (
                                  <TextField
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    label="Email"
                                    autofocus
                                    onChange={handelchange}
                                    style={{ width: "350px" }}
                                  />
                                )}
                              </Col>
                            </Form.Group>
                            <Form.Group className="mb-3 row form-password-toggle">
                              <Col sm={12}>
                                <TextField
                                  type="password"
                                  id="password"
                                  className="form-control"
                                  name="password"
                                  size="small"
                                  value={users.password}
                                  label="password"
                                  aria-describedby="password"
                                  onChange={handelchange}
                                  style={{ width: "350px" }}
                                />
                              </Col>
                            </Form.Group>
                          </div>
                          <Form.Group className="mb-1 row">
                            <Col sm={12}>
                              <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={handelclick}
                                style={{ width: "350px" }}
                                loading={isLoading}
                                loadingPosition="start"
                              >
                                Sign in
                              </Button>
                            </Col>
                          </Form.Group>
                          <center>
                            <Form.Group className="row ">
                              <Col sm={12}>
                                <span>------ or -------</span>
                              </Col>

                              {/* <div className="col">
                          <button
                            className="btn btn-primary d-grid w-10"
                            type="submit"
                            onClick={handleCheck}
                            style={{ maxWidth: "200px", minWidth: "200px" }}
                          >
                            {check.userName && <span>Sign in With Email</span>}
                            {check.email && <span>Sign in With userName</span>}
                          </button>
                        </div> */}
                            </Form.Group>
                          </center>
                          <Form.Group className="row">
                            <Col sm={12}>
                              <Link
                                onClick={handelForget}
                                style={{ width: "350px" }}
                              >
                                <u>Forget your password?</u>
                              </Link>
                            </Col>
                          </Form.Group>
                        </Form>
                      </div>
                    </center>
                  </div>
                </div>
              )}
            </div>
            {forget && (
              <div className=" ">
                <center>
                  <div id="" className="card-body">
                    <div className="">
                      <div className="">
                        <div
                          className="card"
                          style={{
                            width: "500px",
                            marginTop: "50px",
                          }}
                        >
                          <div className="card-header">
                            <h4>Forget Password</h4>
                          </div>
                          <div className="card-body">
                            <Formik
                              initialValues={{
                                email: "",
                              }}
                              onSubmit={async (values) => {
                                setIsLoading(true);
                                try {
                                  await axios
                                    .put(
                                      `https://enin.server.highendtradingplc.com/user/update/${values.email}`,
                                      { email: values.email, check: 3 }
                                    )
                                    .then((res) => {
                                      if (res.data === 0) {
                                        setMessage(
                                          "Your email is not verified !"
                                        );
                                        setOpenAlert(2);
                                        values.email = "";
                                      } else if (res.data === 1) {
                                        setMessage(
                                          "The new password send by email please check your email !"
                                        );
                                        setOpenAlert(1);
                                        setForgets(false);
                                      } else {
                                        setMessage(
                                          "You insert incorrect email !"
                                        );
                                        setOpenAlert(2);
                                        values.email = "";
                                      }
                                      setIsLoading(false);
                                    });
                                } catch (error) {
                                  console.log(error);
                                  setIsLoading(false);
                                }
                                setIsLoading(false);
                              }}
                            >
                              {(props) => (
                                <Form
                                  className="mb-3 "
                                  onSubmit={props.handleSubmit}
                                >
                                  <div className="mb-3">
                                    <TextField
                                      type="email"
                                      className="form-control"
                                      id="email"
                                      name="email"
                                      label="Email"
                                      size="small"
                                      value={props.values.email}
                                      onBlur={props.handleBlur}
                                      onChange={props.handleChange}
                                    />
                                  </div>
                                  <div className="row">
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      className="m-2 col"
                                      type="submit"
                                      loading={isLoading}
                                      loadingPosition="start"
                                    >
                                      Send
                                    </Button>
                                    <Button
                                      color="ochre"
                                      variant="contained"
                                      className="m-2   col"
                                      onClick={(e) => {
                                        setForgets(false);
                                      }}
                                      loading={isLoading}
                                      loadingPosition="start"
                                    >
                                      Back
                                    </Button>
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </center>
              </div>
            )}
          </div>
        </center>
      </div>
    </ThemeProvider>
  );
};

export default Login;
